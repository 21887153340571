import filterPills from 'assets/filter-pills.png';
import filters from 'assets/filters.png';
import filterDropdown from 'assets/review-dropdown.png';
import SimpleModal from 'components/SimpleModal';
import React, { useEffect, useState } from 'react';

type ComponentProps = {
  forceOpen?: number;
};

const CreateReviewSetHowTo: React.FC<ComponentProps> = ({ forceOpen = 0 }) => {
  const isFirstTimeHeadingToExplore = window.localStorage.getItem('isFirstTimeHeadingToExplore');

  const [isModalOpen, setModalOpen] = useState(isFirstTimeHeadingToExplore === 'true');
  const [stage, setStage] = useState(0);

  useEffect(() => {
    if (isModalOpen) {
      window.localStorage.setItem('isFirstTimeHeadingToExplore', 'false');
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (forceOpen > 0) {
      setStage(0);
      setModalOpen(true);
    }
  }, [forceOpen]);

  const toggleShowModal = (): void => {
    setStage(0);
    setModalOpen(!isModalOpen);
  };

  const handleNextStage = (): void => {
    if (stage === 2) {
      setModalOpen(false);
      setStage(0);
    } else {
      setStage((s) => s + 1);
    }
  };

  const handlePrevStage = (): void => {
    setStage((s) => s - 1);
  };

  const grayDot = (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="12" height="12" rx="6" fill="#D6D6D6" />
    </svg>
  );

  const coloredDot = (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="12" height="12" rx="6" fill="#70CEA1" />
    </svg>
  );

  const selectFiltersModalBody = (
    <div className="relative w-full flex flex-row" data-testid="confirm-modal">
      <div className="pl-6 flex flex-col items-start w-1/2">
        <div className="relative">
          <img className="h-78" src={filters} alt="filters" />
        </div>
      </div>
      <div className="w-1/2 pt-10 pb-6 flex flex-col justify-between">
        <div className="flex flex-col gap-8">
          <div className="relative pr-6 self-end">
            <span className="text-heading-1">Select your filters</span>
            <div className="absolute" style={{ right: '100%', top: 10 }}>
              <svg
                width="79"
                height="79"
                viewBox="0 0 79 79"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.3472 63.62C14.6471 60.288 14.948 57.092 15.2368 53.8971C15.2972 53.2604 15.3392 52.811 15.2834 52.0002C15.2483 51.3977 15.1463 50.1216 14.5081 50.2962C13.9961 50.4955 13.658 50.7022 13.4454 51.7994C12.9107 54.6352 12.8022 57.5277 12.679 60.3969C12.572 62.9306 12.529 64.8642 12.5667 67.1114C12.5843 68.161 13.1401 68.5263 14.0832 68.3089C14.9295 68.1136 15.8016 67.928 16.5954 67.5771C19.4643 66.3275 22.2892 65.008 25.141 63.7107C25.4962 63.5517 25.7829 63.449 26.1786 63.1995C26.6552 62.8924 27.019 62.6955 26.8198 62.1835C26.6352 61.6947 25.9028 61.7922 25.5439 61.9144C22.5221 62.87 16.3216 64.9452 15.9665 64.9805C16.8359 63.2739 17.6048 61.6763 18.4643 60.1192C24.5017 49.3037 31.2589 39.0105 40.3771 30.4492C47.5947 23.6722 55.4902 17.8667 64.2738 13.284C64.7908 13.0101 65.2907 12.6883 65.7661 12.369C66.4166 11.9458 66.6801 11.6104 66.4844 11.2588C66.0281 10.6486 65.5136 10.8234 65.091 10.9272C64.2447 11.1226 63.4288 11.5004 62.6363 11.8636C52.0852 16.832 43.121 23.9557 35.0561 32.2887C26.7779 40.8407 20.7509 50.8883 15.5574 61.4839C15.3047 62.0532 14.5482 63.4022 14.3472 63.62Z"
                  fill="#F8351A"
                />
              </svg>
            </div>
          </div>
          <span className="pr-2 text-body">
            The filter options are located on the left panel. Expand the categories and make your
            selections.
          </span>
        </div>
        <button
          type="button"
          className="h-8 button button--secondary w-35 justify-center self-end mr-2"
          onClick={handleNextStage}
        >
          <span className="font-bold">{`Next >`}</span>
        </button>
      </div>
      <div
        className="absolute flex flex-row gap-2 transform -translate-x-1/2"
        style={{ bottom: 30, left: '50%' }}
      >
        {coloredDot}
        {grayDot}
        {grayDot}
      </div>
    </div>
  );

  const checkLogicModalBody = (
    <div className="relative w-full h-full pb-6 flex flex-row" data-testid="confirm-modal">
      <div className="pb-6 w-full flex flex-col justify-between">
        <div className="pl-6 pt-18 flex flex-col gap-2">
          <span className="text-heading-1">Check your logic</span>

          <span className="pr-2 text-body">The selection is shown above the table.</span>
          <img src={filterPills} alt="filter pills" />
        </div>
        <div className="flex flex-row justify-between">
          <button
            type="button"
            className="h-8 button button--secondary w-35 justify-center self-end ml-2"
            onClick={handlePrevStage}
          >
            <span className="font-bold">{`< Back`}</span>
          </button>
          <button
            type="button"
            className="h-8 button button--secondary w-35 justify-center self-end mr-2"
            onClick={handleNextStage}
          >
            <span className="font-bold">{`Next >`}</span>
          </button>
        </div>
      </div>
      <div
        className="absolute flex flex-row gap-2 transform -translate-x-1/2"
        style={{ bottom: 55, left: '50%' }}
      >
        {grayDot}
        {coloredDot}
        {grayDot}
      </div>
    </div>
  );

  const createReviewModal = (
    <div className="relative w-full h-full pb-6 flex flex-row" data-testid="confirm-modal">
      <div className="pb-6 w-6/10 flex flex-col justify-between">
        <div className="pl-6 pt-13 flex flex-col gap-2">
          <span className="text-heading-1">Create review</span>

          <span className="pr-8 text-body">
            When you’re ready, create your review with the Actions button.
          </span>
        </div>
        <button
          type="button"
          className="h-8 button button--secondary w-35 justify-center self-start ml-2"
          onClick={handlePrevStage}
        >
          <span className="font-bold">{`< Back`}</span>
        </button>
      </div>
      <div className="pb-6 pt-7 w-4/10 flex flex-col justify-between">
        <div className="relative pr-10 self-end">
          <img src={filterDropdown} className="h-45" alt="review dropdown" />
        </div>

        <button
          type="button"
          className="h-8 button button--primary w-35 justify-center self-end mr-2"
          onClick={handleNextStage}
        >
          <span className="font-bold">Okay</span>
        </button>
      </div>
      <div
        className="absolute flex flex-row gap-2 transform -translate-x-1/2"
        style={{ bottom: 55, left: '50%' }}
      >
        {grayDot}
        {grayDot}
        {coloredDot}
      </div>
    </div>
  );

  const modalStages = [selectFiltersModalBody, checkLogicModalBody, createReviewModal];

  return (
    <>
      {isModalOpen && (
        <SimpleModal
          toggle={toggleShowModal}
          style={{
            padding: '16px 16px 0px 16px',
            width: '600px',
            height: '340px',
          }}
        >
          {modalStages[stage]}
        </SimpleModal>
      )}
    </>
  );
};

export default CreateReviewSetHowTo;
