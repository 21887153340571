/* eslint-disable camelcase */
import { fetchCommunicationDocument, fetchEventSummary } from 'actions';
import BackToTopButton from 'components/Button/BackToTopButton';
import Permissions from 'components/Permissions';
import Redacted from 'components/Redacted';
import fileExtensionIconParser from 'constants/fileIcons';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SectionType } from 'reducers/envelopeReview';
import { getCommunicationDocumentLoading, getCommunications } from 'selectors/communications';
import { getEnvelopeToPreview } from 'selectors/envelopeListView';
import { getSelectedRule, getSelectedSection } from 'selectors/envelopeReview';
import { getShowMoreLikeThis } from 'selectors/envelopeView';
import {
  getCommsFullMessageHighlighted,
  getEventBulkSummariesLoading,
  getEventsList,
  getSingleEvent,
} from 'selectors/events';
import { useSelector } from 'store';
import { CommunicationWithEvents } from 'types';
import AttachmentTranslation from './AttachmentTranslation';
import EnvelopeSkeleton from './EnvelopeSkeleton';
import HighlightedSentenceComponent from './HighlightedSentence';
import MoreLikeThis from './MoreLikeThis';

type ComponentProps = {
  communication: CommunicationWithEvents;
};

const AttachmentContent: React.FC<ComponentProps> = ({ communication }) => {
  const dispatch = useDispatch();
  const sections: SectionType[] = ['message', 'history', 'attachment'];

  const filteredEvents = communication.events
    ? communication.events.filter((event) => !event.hidden)
    : [];
  const eventId = filteredEvents && filteredEvents[0] ? filteredEvents[0].uuid : '';
  const event = useSelector((state) => getSingleEvent(state, eventId));
  const events = useSelector(getEventsList);
  const loading = useSelector((state) => getEventBulkSummariesLoading(state, communication.uuid));
  const envelopeToPreview = useSelector(getEnvelopeToPreview);
  const showMoreLikeThis = useSelector(getShowMoreLikeThis);
  const communicationHighlighted = useSelector((state) =>
    getCommsFullMessageHighlighted(state, communication.uuid, 'false', 'false')
  );
  const selectedRule = useSelector(getSelectedRule);
  const selectedSection = useSelector(getSelectedSection);
  const existingComm = useSelector(getCommunications).find((c) => communication.uuid === c.uuid);
  const communicationLoading = useSelector(getCommunicationDocumentLoading);

  const [showButton, setShowButton] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const [countUp, setCountup] = useState(0);

  useEffect(() => {
    if (communication.events) {
      communication.events
        .filter((e) => !events.some((ev) => ev.uuid === e.uuid))
        .forEach((e) => {
          if (!e.hidden) dispatch(fetchEventSummary({ eventId: e.uuid }));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, communication.uuid]);

  useEffect(() => {
    if (communication && !existingComm) {
      dispatch(fetchCommunicationDocument({ communication }));
    }
  }, [communication, dispatch, existingComm]);

  useEffect(() => {
    if (selectedRule && communicationHighlighted) {
      const element = document.getElementById(selectedRule.uuid);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [selectedRule, communicationHighlighted]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountup((prev) => prev + 1);
      if (loading === false) {
        clearInterval(intervalId);
      }
    }, 1000);

    return (): void => clearInterval(intervalId);
  }, [loading]);

  useEffect(() => {
    if ((contentRef.current?.clientHeight || 0) < (contentRef.current?.scrollHeight || 0)) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [event]);

  const name = communication.meta_data?.filename?.split('/').pop() || '';
  const extension = communication.meta_data?.filename?.split('/').pop()?.split('.').pop() || '';

  if (communicationLoading) return <EnvelopeSkeleton />;

  return (
    <div className="overflow-y-hidden w-full h-full flex flex-col justify-between">
      <div className="relative w-full flex flex-col py-4 gap-4 overflow-hidden">
        <BackToTopButton scrollRef={contentRef} showButton={showButton} rightOffset={30} />

        <div
          ref={contentRef}
          className={`relative flex flex-col gap-4 overflow-auto ${!envelopeToPreview && 'px-6'}`}
        >
          <div className="w-full flex flex-row items-center gap-2">
            <div className="flex flex-none h-5 w-5">{fileExtensionIconParser(extension || '')}</div>
            <div className="w-full flex flex-row justify-between">
              <span className="truncate">{name}</span>
              <AttachmentTranslation />
            </div>
          </div>

          <div className="w-full mb-4 border-b border-bg-litlingo-gray-2" />
          {loading && countUp > 10 ? (
            'This file is too big to display highlighting. Large file highlighting is currently unavailable.'
          ) : (
            <Redacted field="communications.body">
              <div className="flex flex-col break-words">
                <HighlightedSentenceComponent
                  eventId={eventId}
                  commUuid={event?.communication_uuid || ''}
                  fullMessageOptions="HIGHLIGHTED"
                  hideHighlight={!sections.includes(selectedSection)}
                />
              </div>
            </Redacted>
          )}
        </div>
      </div>
      {showMoreLikeThis && communication.envelope_uuid && (
        <Permissions action="communication_envelopes.more_like_this">
          <div className="relative flex flex-col">
            <MoreLikeThis envelopeUuid={communication.envelope_uuid} />
          </div>
        </Permissions>
      )}
    </div>
  );
};

export default AttachmentContent;
