/* eslint-disable jsx-a11y/control-has-associated-label */
import FilterListHeader from 'components/Filters/FilterListHeader';
import ListFooter from 'components/ListFooter';
import LoadingIndicator from 'components/LoadingIndicator';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useSelector } from 'react-redux';

import Permissions from 'components/Permissions';
import {
  getSavedSearchesList,
  getSavedSearchesLoading,
  getSavedSearchesTotalCount,
} from 'selectors/savedSearches';
import EmptyReviewList from './EmptyReviewList';

import ReviewSetListItem from './ReviewSetListItem';

const ReviewSetTable: React.FC = () => {
  const loading = useSelector(getSavedSearchesLoading);
  const reviewSets = useSelector(getSavedSearchesList);

  return (
    <div className="table-wrapper__new no-scrollbar overflow-auto">
      <LoadingOverlay
        active={loading}
        spinner={<LoadingIndicator size="10" />}
        className="relative w-full h-full"
        fadeSpeed={0}
        styles={{
          content: (base) => ({
            ...base,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }),
          wrapper: () => ({}),
          spinner: () => ({}),
          overlay: (base) => ({
            ...base,
            zIndex: 10,
          }),
        }}
      >
        <table className="table-wrapper__table table-wrapper_table--fixed">
          <thead className="sticky top-0 z-10">
            <tr className="table-wrapper__header-tr h-10">
              <FilterListHeader
                resource={resourceQueryParamName.savedSearch}
                text="Name"
                field="name"
                className="table-wrapper__new-th table-wrapper__th--w-40 pl-6"
                redesign
              />
              <Permissions action="reviewSets.needToUpdateWarning">
                <th aria-hidden className="table-wrapper__new-th table-wrapper__th--w-6" />
              </Permissions>
              <FilterListHeader
                resource={resourceQueryParamName.savedSearch}
                text="Priority"
                field="priority"
                className="table-wrapper__new-th table-wrapper__th--w-12 p-0"
                inverted
                redesign
              />

              <FilterListHeader
                resource={resourceQueryParamName.savedSearch}
                text="Count"
                field="_record_count"
                className="table-wrapper__new-th table-wrapper__th--w-8 p-0"
                redesign
              />

              <FilterListHeader
                resource={resourceQueryParamName.savedSearch}
                text="Last Updated"
                field="updated_at"
                className="table-wrapper__new-th table-wrapper__th--w-14 p-0"
                redesign
              />
              <th className="table-wrapper__new-th table-wrapper__th--w-16 pl-2 p-0">Digest</th>
            </tr>
          </thead>
          <tbody className="table-wrapper__tbody">
            {!loading && reviewSets.length === 0 ? (
              <EmptyReviewList colCount="5" />
            ) : (
              <>
                {reviewSets.map((reviewSet) => (
                  <ReviewSetListItem reviewSet={reviewSet} key={reviewSet.uuid} />
                ))}
              </>
            )}
          </tbody>
        </table>
        {!loading && reviewSets.length !== 0 && (
          <div className="border-t border-gray-200 sticky bottom-0 z-10 bg-white">
            <ListFooter
              resourceStateName={resourceQueryParamName.savedSearch}
              resourceName="streams"
              getResourceList={getSavedSearchesList}
              getResourceTotalCount={getSavedSearchesTotalCount}
              getResourceLoading={getSavedSearchesLoading}
            />
          </div>
        )}
      </LoadingOverlay>
    </div>
  );
};

export default ReviewSetTable;
