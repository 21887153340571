/* eslint-disable camelcase */

import { fetchMetricsData, fetchMetricsExportData } from 'actions';
import WidgetExpandedView from 'components/Dashboard/WidgetExpandedView';
import WidgetTable from 'components/Dashboard/WidgetTable';
import Permissions from 'components/Permissions';
import Tooltip from 'components/Tooltip';
import { CHART_ICON, EXPAND_ICON, TABLE_ICON } from 'constants/dashboardIcons';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { toPng } from 'html-to-image';
import moment from 'moment';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getMetricsError,
  getQueryDataById,
  getWidgetDataById,
  getWidgetDataLoading,
} from 'selectors/analytics';
import { getExporting, getSelectedWidgets } from 'selectors/dashboard';
import { getNavParams, getNavParamsByResourceMemo, getNavWidgetFiltersMemo } from 'selectors/nav';
import { useSelector } from 'store';
import {
  DashboardMetric,
  DashboardWidget,
  DefaultWidgetComponentProps,
  IntersectedError,
  MetricV2,
  MetricsData,
  WidgetDecoratorFilterConfig,
} from 'types';
import logEvent from 'utils/analytics';
import { boundariesValidation, validateNivoBarProps } from 'utils/dashboard';
import { useUpdateEffect } from 'utils/hooks';
import { NUMBER_OF_PERIODS, TIME_RANGE_FIELD, UNIT_OF_TIME, useTimeRange } from 'utils/timeRanges';
import WidgetError from './WidgetError';
import WidgetHeader from './WidgetHeader';
import WidgetLoading from './WidgetLoading';

export type WidgetDecoratorConfig = {
  title?: string;
  subtitle?: string;
  icon?: JSX.Element | React.FC;
  iconTooltip?: string;
  iconContent?: string;
  bgClassColor?: string;
  minWidth?: number;
  minHeight?: number;
  fetchOnePeriod?: boolean;
  fetchAvgPeriod?: boolean;
  filters?: WidgetDecoratorFilterConfig[];
  containerClassName?: string;
  titleClassName?: string;
  noHeader?: boolean;
  customSubtitle?: boolean;
  allowDownloadCsv?: boolean;
  allowDownload?: boolean;
  allowTableView?: boolean;
  allowExpandedView?: boolean;
  reRenderWhitGrouping?: boolean;
};

type WidgetProps = DashboardWidget & {
  intersected: IntersectedError | null;
  widgetId: string;
  icon?: string;
  time_range?: string;
  use_cache?: boolean;
};

const withWidget =
  (config: WidgetDecoratorConfig) =>
  (Component: React.FC<DefaultWidgetComponentProps>): JSX.Element => {
    const { fetchOnePeriod = false, fetchAvgPeriod = false, reRenderWhitGrouping } = config;
    Component.displayName = config.title || 'Dashboard Widget';

    // @ts-expect-error check types
    return function HOC(props: WidgetProps): JSX.Element {
      const {
        metrics,
        time_grouping,
        type,
        intersected,
        widgetId,
        filters,
        title,
        subtitle,
        nivo_args,
        show_legend,
        order,
        container_class_name,
        title_class_name,
        allow_download_csv,
        time_range,
        icon,
        use_cache = true,
        // @ts-ignore
        export_label,
      } = props;
      const dispatch = useDispatch();
      const widgetData = useSelector((state) => getWidgetDataById(state, widgetId));
      const queryData = useSelector((state) => getQueryDataById(state, widgetId));
      const loading = useSelector((state) => getWidgetDataLoading(state, widgetId));
      const error = useSelector((state) => getMetricsError(state, widgetId));
      const widgetFilters = useSelector((state) => getNavWidgetFiltersMemo(state, widgetId));
      const navParams = useSelector((state) =>
        getNavParamsByResourceMemo(state, resourceQueryParamName.metrics)
      );
      const unit_of_time = navParams[UNIT_OF_TIME];
      const { is_exporting: isExporting } = useSelector(getNavParams);

      const selectedWidgets = useSelector(getSelectedWidgets);
      const { created_after, created_before } = useTimeRange();

      const [isPercent, setIsPercent] = useState(false);
      const [isTableView, setIsTableView] = useState(false);
      const [isExpandedView, setIsExpandedView] = useState(false);
      const [reversed, setReversed] = useState(false);

      const finalTitle = title || config.title;

      const exporting = useSelector(getExporting);
      const ref = useRef<HTMLDivElement>(null);

      useEffect(() => {
        if (exporting > 0 && isExporting === 'true') {
          if (ref.current === null) {
            return;
          }

          if (!selectedWidgets.includes(export_label) && !selectedWidgets.includes('all')) {
            return;
          }

          // cleaning classes
          const classes = ref.current.className;
          ref.current.className = classes
            .replaceAll('h-full', '')
            .replaceAll('justify-between', '');

          toPng(ref.current, {
            cacheBust: true,
            style: {
              padding: '10px',
              borderRadius: '4px',
              height: `${ref.current.scrollHeight + 20}px`,
              width: `${ref.current.scrollWidth + 20}px`,
              display: 'flex',
              flexDirection: 'col',
              overflow: 'visible',
              ...(classes.includes('transparent') ? { backgroundColor: '#E5E5E5' } : {}),
            },
            height: ref.current.scrollHeight + 20,
            width: ref.current.scrollWidth + 20,
          }).then((dataUrl) => {
            if (ref.current) {
              ref.current.className = classes;
            }

            // invisible link to download
            const link = document.createElement('a');
            link.download = `${moment().format('MMDD_YY')}_${export_label.trim()}`;
            link.href = dataUrl;
            link.click();
          });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [exporting]);

      const filterTypes = Object.keys(widgetFilters)
        .map((key) => widgetFilters[key])
        .join(',');

      const navFilterTypes = Object.keys(navParams)
        .map((key) => navParams[key])
        .join(',');

      const configFilters = filters?.filter(({ dimension }) => dimension !== 'toggle_percent');

      const range: string | string[] | null = useSelector(
        (state) => getNavParams(state)[TIME_RANGE_FIELD]
      );

      useEffect(() => {
        if (metrics != null && range) {
          dispatch(
            fetchMetricsData({
              metrics,
              fetchOnePeriod,
              fetchAvgPeriod,
              widgetId,
              timeRange: time_range,
              metricType: type,
              unitOfTime: unit_of_time as string,
              use_cache,
            })
          );
        }

        if (config.filters) {
          config.filters.forEach((filter) => {
            dispatch(filter.fetchDataAction());
          });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        dispatch,
        metrics,
        type,
        widgetId,
        time_grouping,
        filterTypes,
        navFilterTypes,
        range,
        time_range,
        use_cache,
      ]);

      // special refetch case effect for when unit_of_time nav param changes
      // only charts with the unit_of_time paramerter enabled (ie date_histogram) will run through this effect
      // this prevents components that dont change from refetching
      useUpdateEffect(() => {
        if (metrics != null && reRenderWhitGrouping) {
          dispatch(
            fetchMetricsData({
              metrics,
              fetchOnePeriod,
              fetchAvgPeriod,
              widgetId,
              timeRange: time_range,
              metricType: type,
              unitOfTime: unit_of_time as string,
              use_cache: true,
            })
          );
        }

        if (config.filters) {
          config.filters.forEach((filter) => {
            dispatch(filter.fetchDataAction());
          });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [unit_of_time]);

      const onExportClick = useCallback(() => {
        if (metrics != null) {
          dispatch(
            fetchMetricsExportData({
              metrics,
              fetchOnePeriod,
              fetchAvgPeriod,
              widgetId,
              timeRange: time_range,
              metricType: type,
              timeGrouping: time_grouping,
              finalTitle,
            })
          );
        }
      }, [metrics, dispatch, widgetId, time_range, type, time_grouping, finalTitle]);

      const handlePercentClick = (): void => {
        setIsPercent(!isPercent);

        const [id, widgetType] = widgetId.split('--');

        logEvent(`Dashboard-hover-${id}-${widgetType}-percent-button`);
      };

      const IsPercentCheckbox = (
        <div className="flex flex-row items-center gap-1">
          <label
            htmlFor="hundred-percent-checkbox"
            className="flex flex-row gap-2 text-body items-center"
          >
            <input
              onChange={handlePercentClick}
              id="hundred-percent-checkbox"
              type="checkbox"
              checked={isPercent}
              className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out"
            />
            <div className="text">100%</div>
          </label>
        </div>
      );

      const handleExpandedView = (): void => {
        setIsExpandedView(true);
        if (metrics && type === 'histogram') {
          dispatch(
            fetchMetricsData({
              metrics: metrics.map((metric: DashboardMetric) => ({
                ...metric,
                limit: 100,
              })) as unknown as DashboardMetric[],
              fetchOnePeriod,
              fetchAvgPeriod,
              widgetId,
              timeRange: time_range,
              metricType: type,
              timeGrouping: time_grouping,
              use_cache: false,
            })
          );
        }
      };

      const TableButton = (
        <Permissions action="analytics.tableView">
          <Tooltip tooltip="dashboard.tableView">
            <button
              type="button"
              className="focus:outline-none"
              onClick={(): void => setIsTableView(true)}
            >
              {TABLE_ICON}
            </button>
          </Tooltip>
        </Permissions>
      );
      const ChartButton = (
        <Permissions action="analytics.tableView">
          <Tooltip tooltip="dashboard.chartView">
            <button
              type="button"
              className="focus:outline-none"
              onClick={(): void => setIsTableView(false)}
            >
              {CHART_ICON}
            </button>
          </Tooltip>
        </Permissions>
      );
      const ExpandButton = (
        <Permissions action="analytics.expandView">
          <Tooltip tooltip="dashboard.expandTable">
            <button
              type="button"
              className="focus:outline-none"
              onClick={(): void => handleExpandedView()}
            >
              {EXPAND_ICON}
            </button>
          </Tooltip>
        </Permissions>
      );

      const renderAdditionalOptions = (): JSX.Element | null => {
        let tableButtonComponent: JSX.Element | null = null;
        if (config.allowTableView) {
          tableButtonComponent = isTableView ? ChartButton : TableButton;
        }

        const returnElements: JSX.Element[] = [];

        if (!isTableView && filters?.some((f) => f.dimension === 'toggle_percent')) {
          returnElements.push(IsPercentCheckbox);
        }
        if (tableButtonComponent) returnElements.push(tableButtonComponent);
        if (config.allowExpandedView && !isExpandedView) returnElements.push(ExpandButton);

        if (returnElements.length > 0) {
          return (
            <div className="flex flex-row items-center gap-4 space-x-1.5 pt-1.5">
              {returnElements}
            </div>
          );
        }

        return null;
      };

      const finalSubtitle = subtitle || config.subtitle;
      const finalIcon = config.icon;
      const hasHeader = finalTitle || finalSubtitle;
      const containerClassName =
        container_class_name || config.containerClassName || 'rounded-md box-shadow p-5';
      const finalIconContent = (): string | undefined => {
        let time: 'hours' | 'days' | 'years' = 'years';

        const text = config.iconContent;
        const start = moment(created_after);
        const end = moment(created_before);

        if (range) {
          if ((range as string).includes('hours')) {
            time = 'hours';
          } else if ((range as string).includes('days')) {
            time = 'days';
          } else {
            time = 'years';
          }
        }

        const number = (end.diff(start, time) + 1) * NUMBER_OF_PERIODS;

        return text?.replace('number', number.toString()).replace('time', time.toString());
      };

      const WidgetHeaderComponent = (
        <WidgetHeader
          widgetId={widgetId}
          title={finalTitle}
          subtitle={finalSubtitle}
          icon={finalIcon}
          iconTooltip={config.iconTooltip}
          iconContent={finalIconContent()}
          decoratorFilters={config.filters}
          configFilters={configFilters}
          customSubtitle={config.customSubtitle}
          titleClassName={title_class_name || config.titleClassName}
          additionalFilterComponent={renderAdditionalOptions()}
        />
      );

      const renderWidgetBody = (): JSX.Element => {
        if (loading) {
          return <WidgetLoading />;
        }

        if (error) {
          // FIXME: Show the response error message
          return <WidgetError msg="Error getting widget data" />;
        }

        if (intersected) {
          return <WidgetError msg="" intersected />;
        }

        const boundaries = boundariesValidation(props, config.minWidth, config.minHeight);
        if (boundaries != null) {
          return <WidgetError msg={boundaries} intersected />;
        }

        const nivoResult = validateNivoBarProps(nivo_args);

        if (nivoResult.error != null) {
          return <WidgetError msg={nivoResult.error} />;
        }

        if (isExpandedView && widgetData) {
          const headers = widgetData.results[0].x_axis;
          let rows: MetricV2['y_axis'];
          let dataToReturnDashboard;

          if (typeof widgetData.results[0].y_axis[0] === 'number') {
            rows = [{ id: '', label: '', values: widgetData.results[0].y_axis }];
          } else {
            rows = widgetData.results[0].y_axis as unknown as MetricV2['y_axis'];
          }
          if (metrics) {
            dataToReturnDashboard = {
              metrics,
              fetchOnePeriod,
              fetchAvgPeriod,
              widgetId,
              timeRange: time_range,
              metricType: type,
              timeGrouping: time_grouping,
              use_cache: false,
            };
          }

          return (
            <div>
              <WidgetExpandedView
                setIsExpandedView={setIsExpandedView}
                headers={headers}
                rows={rows}
                title={finalTitle}
                onExportClick={(): void => onExportClick()}
                allowDownloadCsv={allow_download_csv}
                dataToReturnDashboard={dataToReturnDashboard}
                type={type}
                isTableView={isTableView}
                reversed={reversed}
                setReversed={setReversed}
              >
                <Component
                  widgetId={widgetId}
                  widgetData={widgetData as MetricsData}
                  queryData={queryData}
                  nivoProps={nivoResult.props}
                  isExpandedView={isExpandedView}
                />
              </WidgetExpandedView>
            </div>
          );
        }

        if (isTableView && widgetData) {
          const headers = widgetData.results[0].x_axis;
          let rows: MetricV2['y_axis'];

          if (typeof widgetData.results[0].y_axis[0] === 'number') {
            rows = [{ id: '', label: '', values: widgetData.results[0].y_axis }];
          } else {
            rows = widgetData.results[0].y_axis as unknown as MetricV2['y_axis'];
          }

          return (
            <WidgetTable
              headers={headers}
              rows={rows}
              reversed={reversed}
              setReversed={setReversed}
            />
          );
        }

        return (
          <Component
            widgetId={widgetId}
            widgetData={widgetData as MetricsData}
            queryData={queryData}
            nivoProps={nivoResult.props}
            order={order}
            title={title}
            icon={icon}
            subtitle={subtitle}
            showLegend={show_legend}
            onExportClick={(): void => onExportClick()}
            allowDownloadCsv={allow_download_csv}
            isPercent={isPercent}
            // @ts-ignore
            fetchAvgPeriod={fetchAvgPeriod}
          />
        );
      };

      return (
        <div className="relative flex flex-col w-full h-full">
          <div
            ref={ref}
            className={`relative flex flex-col w-full h-full ${
              config.bgClassColor || 'bg-white'
            } ${containerClassName}`}
          >
            {config.noHeader ? (
              <>{renderWidgetBody()}</>
            ) : (
              <>
                {WidgetHeaderComponent}
                <div
                  className={`h-full ${
                    hasHeader
                      ? 'flex flex-col pt-4 overflow-hidden'
                      : 'flex align-middle items-center'
                  }`}
                >
                  {renderWidgetBody()}
                </div>
              </>
            )}
          </div>
          {isExporting === 'true' && (
            <div
              className={`${
                selectedWidgets.includes(export_label)
                  ? 'border-4 border-litlingo-success'
                  : 'opacity-0'
              } absolute bg-white bg-opacity-70 z-50 flex flex-row items-center justify-center gap-4 rounded  hover:opacity-100`}
              style={{ top: -5, bottom: -5, left: -5, right: -5 }}
            >
              {!selectedWidgets.includes(export_label) && (
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 3.2002V36.4922"
                    stroke="#333333"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M36.8881 20H3.6001"
                    stroke="#333333"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
              <span className="text-heading-1">{`${export_label} ${
                selectedWidgets.includes(export_label) ? 'added' : ''
              }`}</span>
            </div>
          )}
        </div>
      );
    };
  };

export default withWidget;
