/* eslint-disable max-lines */

import { Datum } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';
import { clearTree, requestTreeFiltersToogle } from 'actions';
import { allowedReadOnlyDimensions, metricsLinksMap } from 'constants/dashboard';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getNavParamsByResource, getNavWidgetFilters } from 'selectors/nav';
import { getPermissionsPolicy } from 'selectors/permissions';
import { useSelector } from 'store';
import { DefaultWidgetComponentProps } from 'types';
import logEvent from 'utils/analytics';
import { buildPieHistogramDataFromMetrics } from 'utils/dashboard';
import { getValuesFromDimensions, setNewValue, transformToString } from 'utils/parserTree';
import { isActionAuthorized } from 'utils/permissions';
import { useHistory } from 'utils/urls';

const PieMetricWidget: React.FC<DefaultWidgetComponentProps> = (props) => {
  const { widgetId, widgetData, queryData, isExpandedView } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const customerDomain = useSelector(getCustomerDomain);
  const widgetFilters = useSelector(getNavWidgetFilters(widgetId));
  const user = useSelector(getUser);
  const filters = useSelector(getNavParamsByResource(resourceQueryParamName.metrics));
  const policy = useSelector(getPermissionsPolicy);

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }
  const processedData = buildPieHistogramDataFromMetrics(widgetData);

  if (processedData.data.length === 0) {
    return <WidgetEmptyState />;
  }

  const handleClick = (datum: Datum): void => {
    const [id, type] = widgetId.split('--');
    logEvent(`Dashboard-click-${id}-${type}`);

    // this widget only ever has a single metric config
    // so we pluck the first query config
    const query = queryData.queries[0];
    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info == null || !isActionAuthorized(info.action, user.roles, policy)) {
      return;
    }

    const series = widgetData.results[0];

    if (series == null) {
      return;
    }

    const start = moment.utc(queryData.start_time * 1000).format(FULL_DATE_FORMAT);
    const end = moment.utc(queryData.end_time * 1000).format(FULL_DATE_FORMAT);

    const dimensions: Record<string, string | string[]> = {};
    if (info.dimensions != null) {
      Object.entries(info.dimensions).forEach(([dimension, param]) => {
        const allowed = allowedReadOnlyDimensions[query.metric];

        // if allowedDimension is not defined or the dimension is not included,
        // just ignore it

        if (allowed == null || !allowed.includes(dimension)) {
          return;
        }

        let value: string | string[] = '';
        if (filters[dimension] != null) {
          const topValue = filters[dimension];
          value = topValue;
        }

        if (widgetFilters[dimension] != null) {
          const widgetValue = filters[dimension];
          value = widgetValue;
        }

        if (query.group_by === dimension) {
          value = datum.data.labelValue;
        }

        dimensions[`${info.resource}__${param}`] = value;
      });
    }
    const tree = getValuesFromDimensions(dimensions);
    dispatch(
      requestTreeFiltersToogle({
        value: true,
      })
    );
    dispatch(clearTree());

    history.pushLookup({
      customerDomain,
      routeName: info.route,
      queryParams: {
        [`${info.resource}__created_after`]: start,
        [`${info.resource}__created_before`]: end,
        [`${info.resource}__filters_search`]: transformToString(tree),
        [`${info.resource}__has_events`]: ['true'],
      },
    });
  };

  const handleMessagesClick = (): void => {
    const [id, type] = widgetId.split('--');
    logEvent(`Dashboard-click-${id}-${type}`);

    // this widget only ever has a single metric config
    // so we pluck the first query config
    const query = queryData.queries[0];
    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info == null || !isActionAuthorized(info.action, user.roles, policy)) {
      return;
    }

    const series = widgetData.results[0];

    if (series == null) {
      return;
    }

    const start = moment.utc(queryData.start_time * 1000).format(FULL_DATE_FORMAT);
    const end = moment.utc(queryData.end_time * 1000).format(FULL_DATE_FORMAT);

    const dimensions: Record<string, string | string[]> = {};
    if (info.dimensions != null) {
      Object.entries(info.dimensions).forEach(([dimension, param]) => {
        const allowed = allowedReadOnlyDimensions[query.metric];

        // if allowedDimension is not defined or the dimension is not included,
        // just ignore it
        if (allowed == null || !allowed.includes(dimension)) {
          return;
        }

        const value: string[] = [];

        if (queryData.queries[0].dimensions) {
          queryData.queries[0].dimensions.forEach((dim) => {
            if (dim.name === dimension) value.push(dim.value);
          });
        }

        dimensions[`${info.resource}__${param}`] = value;
      });
    }

    const tree = getValuesFromDimensions(dimensions);

    const newTree = setNewValue(tree, 'date_range', `${start}<>${end}`, '', true);

    dispatch(
      requestTreeFiltersToogle({
        value: true,
      })
    );
    dispatch(clearTree());

    history.pushLookup({
      customerDomain,
      routeName: info.route,
      queryParams: {
        [`${info.resource}__filters_search`]: transformToString(newTree),
        [`${info.resource}__has_events`]: ['true'],
      },
    });
  };

  return (
    <div className="w-3/4 h-full flex flex-col items-start overflow-visible chart-svg-overflow">
      <button type="button" className="text-body" onClick={handleMessagesClick}>
        <span className="text-litlingo-primary-120 underline hover:cursor-pointer">
          {`${processedData.data.reduce(
            // @ts-ignore
            (acc, cur) => acc + Number.parseInt(cur.value, 10),
            0
          )} Messages`}
        </span>
      </button>
      <ResponsivePie
        data={processedData.data}
        theme={{
          tooltip: {
            container: {
              background: 'none',
              boxShadow: 'none',
            },
          },
          labels: {
            text: {
              fontWeight: 'bold',
            },
          },
        }}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            itemWidth: 100,
            itemHeight: 20,
            translateX: isExpandedView ? 150 : 110,
            translateY: isExpandedView ? -300 : -50,
            onClick: handleClick,
          },
        ]}
        margin={{ right: 20 }}
        tooltip={(d): JSX.Element => (
          <div className="bg-litlingo-off-white p-1 rounded flex items-center shadow-md">
            <div
              style={{
                background: d.datum.color,
              }}
              className="h-3 w-3 mr-2"
            />
            <span className="whitespace-no-wrap text">
              {d.datum.label}: <b>{d.datum.value}</b>
            </span>
          </div>
        )}
        onClick={handleClick}
        colors={['#A3E1C1', '#58A280', '#314A48', '#FFEB9F', '#F8A01A', '#858585']}
        startAngle={90}
        endAngle={450}
      />
    </div>
  );
};

export default withWidget({
  minWidth: 4,
  minHeight: 9,
  allowDownload: true,
  allowExpandedView: true,
  allowTableView: true,
})(PieMetricWidget);
