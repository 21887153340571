/* eslint-disable max-lines */
import { fetchSingleCustomer, toggleShortcutsModal } from 'actions';
import Card from 'components/Card';
import CustomerActions from 'components/Customer/CustomerActions';
import CustomerData from 'components/Customer/CustomerData';
import CustomerGeneral from 'components/Customer/CustomerGeneral';
import CustomerIntegrations from 'components/Customer/CustomerIntegrations';
import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import EmptyNavbar from 'components/Navbar/EmptyNavbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import Permissions from 'components/Permissions';
import SETTINGS_NEW_CUSTOMER_BANNER from 'constants/banners';
import { NEW_CHEVRON_LEFT } from 'constants/filterIcons';
import React, { useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import {
  getCustomer,
  getFetchSingleCustomersLoading,
  getIsNewCustomer,
  getIsOnlySystemAdmin,
  getUser,
} from 'selectors/auth';
import { getNavParams } from 'selectors/nav';
import { useSelector } from 'store';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';

const Customer: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const customer = useSelector(getCustomer);
  const fetchLoading = useSelector(getFetchSingleCustomersLoading);
  const isOnlySystemAdmin = useSelector(getIsOnlySystemAdmin);
  const isNewCustomer = useSelector(getIsNewCustomer);
  const scroll = useSelector((state) => getNavParams(state).scroll);

  useEffect(() => {
    const section = document.querySelector(`#${scroll}`);
    if (section) section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [scroll]);

  useEffect(() => {
    if (user) {
      dispatch(fetchSingleCustomer());
    }
  }, [dispatch, user]);

  const mainPage = (
    <div className="mx-auto w-174 flex flex-col gap-6">
      {customer.name && (
        <>
          <div id="General" className="flex flex-row gap-5">
            <CustomerGeneral />
            <CustomerIntegrations />
          </div>

          <div id="Data">
            <CustomerData />
          </div>
        </>
      )}
      {!isOnlySystemAdmin && (
        <Permissions action="customers.advancedOptions">
          <div id="Actions">
            <CustomerActions />
          </div>
        </Permissions>
      )}
      <Permissions action="customers.advancedOptions">
        <div id="Advanced">
          <Card
            title="Advanced"
            items={[
              <>
                {!isOnlySystemAdmin && (
                  <LinkLookup
                    routeName="customer-json-editor"
                    className="p-4 hover:bg-litlingo-gray-0.5 focus:outline-none"
                  >
                    <div className="flex justify-between items-center">
                      <span className="text-body">Json editor</span>
                      <div className="transform rotate-180">{NEW_CHEVRON_LEFT}</div>
                    </div>
                  </LinkLookup>
                )}
              </>,
              <button
                key="0" /* 0 as a key cause this will never need to be rerendered */
                type="button"
                className="text-body p-4 hover:bg-litlingo-gray-0.5 focus:outline-none flex items-start"
                onClick={(): void => {
                  dispatch(toggleShortcutsModal());
                }}
              >
                See keyboard shortcuts
              </button>,
              <>
                {!isOnlySystemAdmin && (
                  <LinkLookup
                    routeName="customer-index"
                    className="p-4 hover:bg-litlingo-gray-0.5 focus:outline-none"
                  >
                    <div className="flex justify-between items-center">
                      <span className="text-body">See Index</span>
                      <div className="transform rotate-180">{NEW_CHEVRON_LEFT}</div>
                    </div>
                  </LinkLookup>
                )}
              </>,
              <>
                {!isOnlySystemAdmin && (
                  <LinkLookup
                    routeName="customer-log-metrics"
                    className="p-4 hover:bg-litlingo-gray-0.5 focus:outline-none"
                  >
                    <div className="flex justify-between items-center">
                      <span className="text-body">See Log Metrics</span>
                      <div className="transform rotate-180">{NEW_CHEVRON_LEFT}</div>
                    </div>
                  </LinkLookup>
                )}
              </>,
              <>
                {!isOnlySystemAdmin && (
                  <LinkLookup
                    routeName="customer-indexing-status"
                    className="p-4 hover:bg-litlingo-gray-0.5 focus:outline-none"
                  >
                    <div className="flex justify-between items-center">
                      <span className="text-body">See Indexing Status</span>
                      <div className="transform rotate-180">{NEW_CHEVRON_LEFT}</div>
                    </div>
                  </LinkLookup>
                )}
              </>,
              <>
                {!isOnlySystemAdmin && (
                  <LinkLookup
                    routeName="customer-processing-status"
                    className="p-4 hover:bg-litlingo-gray-0.5 focus:outline-none"
                  >
                    <div className="flex justify-between items-center">
                      <span className="text-body">See Processing Status</span>
                      <div className="transform rotate-180">{NEW_CHEVRON_LEFT}</div>
                    </div>
                  </LinkLookup>
                )}
              </>,
            ]}
          />
        </div>
      </Permissions>
    </div>
  );

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <div
      className="litlingo-gray-bg-color-4 relative"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      {user.roles.length === 1 && user.roles.includes('system-admin') ? (
        <EmptyNavbar />
      ) : (
        <Navbar />
      )}
      <div
        className="flex flex-row w-full"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <SettingSidebar />
        <LoadingOverlay
          data-testid="loading-overlay"
          active={fetchLoading && !customer.secrets_summary}
          spinner={<LoadingIndicator size="20" />}
          fadeSpeed={0}
          className="relative flex w-full"
          styles={{
            content: (base) => ({
              ...base,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }),
            wrapper: () => ({}),
            spinner: () => ({}),
            overlay: (
              base: Record<string, unknown>
            ): { [key: string]: number; zIndex: number } => ({
              ...base,
              zIndex: 10,
            }),
          }}
        >
          <div className="flex flex-col h-full w-full overflow-auto custom-scrollbar">
            {isNewCustomer && (
              <div className="relative bg-white">
                <div className="w-full absolute" style={{ top: 136 }}>
                  <div className="m-auto w-174 flex flex-row gap-4">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_13146_105080)">
                        <path
                          d="M28.6107 11.394V17.0918H24.5804L22.915 18.1325V11.394H28.6107Z"
                          fill="#F8A01A"
                        />
                        <path
                          d="M17.1705 5.6957V0H0V5.6957V40H4.51223H17.1705V34.3023H5.69569V5.6957H17.1705Z"
                          fill="#58A280"
                        />
                        <path
                          d="M22.8662 0V5.6957H34.3044V34.3023H22.8662V40H40.0001V36.6765V34.3023V5.6957V3.32351V0H22.8662Z"
                          fill="#58A280"
                        />
                        <path
                          d="M28.6088 22.8261V28.6072H11.3916V11.394H17.1768V22.8261H28.6088Z"
                          fill="#0D2928"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_13146_105080">
                          <rect width="40" height="40" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <div className="pt-2 flex flex-col gap-2">
                      <span className="text-heading-1">Welcome to LitLingo</span>
                      <span className="text-subtitle">Let’s get set up</span>
                    </div>
                  </div>
                </div>
                {SETTINGS_NEW_CUSTOMER_BANNER}
              </div>
            )}

            <div className="w-full py-10">{mainPage}</div>
          </div>
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default Customer;
