/* eslint-disable camelcase */
import { removeValuesFromTree, upsertSavedSearch } from 'actions';
import FilterBadge from 'components/EnvelopeList/FilterPills/FilterBadge';
import Modal from 'components/Modal';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getAllFilterValuesFromTreeWithLabels } from 'selectors/envelopes';
import { getSelectedReviewSet } from 'selectors/savedSearches';
import { useSelector } from 'store';
import { Tree } from 'types';
import { removeValue, transformToString, TreeFieldsValuesWithLabels } from 'utils/parserTree';
import { getParamsFromUrl } from 'utils/urls';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
  treeParams: TreeFieldsValuesWithLabels;
  tree?: Tree;
  modifyExploreTree?: boolean;
};

const SubsamplesModal: React.FC<ComponentProps> = ({
  isModalOpen,
  toggleModalOpen,
  treeParams,
  tree,
  modifyExploreTree = false,
}) => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const reviewSet = useSelector(getSelectedReviewSet);
  const urlParams = useSelector(getAllFilterValuesFromTreeWithLabels);

  const [removeSamples, setRemoveSamples] = useState<{ label: string; value: string }[]>([]);

  const params = treeParams.sample_uuids || urlParams.sample_uuids || [];

  const modifyExplore = (): void => {
    dispatch(
      removeValuesFromTree({
        values: removeSamples.map((s) => s.value),
        field: 'sample_uuids',
      })
    );

    toggleModalOpen();
  };

  const handleSaveSavedSearch = (): void => {
    if (modifyExploreTree) {
      modifyExplore();
      return;
    }

    if (reviewSet && tree) {
      let finalTree = tree;
      removeSamples.forEach((s) => {
        finalTree = removeValue(finalTree, s.value, 'sample_uuids');
      });

      const finalFs = transformToString(finalTree);

      if (user.customer) {
        const p = getParamsFromUrl(
          reviewSet.url || '',
          resourceQueryParamName.envelopes,
          'envelope-list',
          user.customer.config
        );

        p.filters_search = finalFs;

        const finalUrlParams = new URLSearchParams();

        Object.entries(p).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((v) => finalUrlParams.append(`envelopes__${key}`, v));
          } else {
            finalUrlParams.append(`envelopes__${key}`, value);
          }
        });

        dispatch(
          upsertSavedSearch({
            ...reviewSet,
            url: `?${finalUrlParams.toString()}`,
          })
        );

        toggleModalOpen();
      }
    }
  };

  const handleAddToNormalSamples = (value: string): void => {
    setRemoveSamples((samples) => samples.filter((s) => s.value !== value));
  };

  const handleAddToRemoveSamples = (label: string, value: string): void => {
    setRemoveSamples((samples) => [...samples, { label, value }]);
  };

  const renderSubsamples = (): JSX.Element[] | null => {
    if (params) {
      return params
        .filter((s) => !removeSamples.find((sample) => sample.value === s.value))
        .map((s) => (
          <FilterBadge
            key={s.value}
            title={s.label || s.value}
            deleteAction={(): void => handleAddToRemoveSamples(s.label, s.value)}
          />
        ));
    }
    return null;
  };

  const renderSubsamplesToRemove = (): JSX.Element[] | null =>
    removeSamples.map((s) => (
      <FilterBadge
        key={s.value}
        title={s.label || s.value}
        deleteAction={(): void => handleAddToNormalSamples(s.value)}
      />
    ));

  const renderBody = (
    <>
      <div className="flex flex-col gap-6 mb-20 mt-4">
        <span>
          The samples below make up the current sample set. They’re ordered by newest to oldest
          sample (left to right, top down). Remove samples by selecting the x in the tag.
        </span>
        <div className="flex flex-row gap-1 flex-wrap">{renderSubsamples()}</div>
        <span>Remove from sample set on save:</span>
        <div className="flex flex-row gap-1 flex-wrap">{renderSubsamplesToRemove()}</div>
      </div>
    </>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderBody}
          title="Subsamples"
          okButton
          okButtonText={modifyExploreTree ? 'Apply' : 'Save'}
          okButtonOnClick={handleSaveSavedSearch}
          toggleShowModal={toggleModalOpen}
          style={{
            width: '800px',
          }}
        />
      )}
    </>
  );
};

export default SubsamplesModal;
