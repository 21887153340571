import EmptyTableState from 'components/EmptyTableState';
import ListFooter from 'components/ListFooter';
import LoadingIndicator from 'components/LoadingIndicator';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useSelector } from 'react-redux';

import { getEntities, getEntitiesLoading, getEntitiesTotalCount } from 'selectors/entities';
import { Entity, UUID } from 'types';
import EntityListActionRow from './EntityListActionRow';
import EntityListRow from './EntityListRow';
import MergeEntitiesModal from './MergeEntitiesModal';

const EntityTable: React.FC = () => {
  const entities = useSelector(getEntities);
  const loading = useSelector(getEntitiesLoading);
  const [selectedEntities, setSelectedEntities] = useState<UUID[]>([]);
  const [isMergingEntities, setIsMergingEntities] = useState(false);

  const isEmpty = entities.length === 0;
  const toggleMergeEntitiesModal = (): void => setIsMergingEntities(!isMergingEntities);

  return (
    <>
      <div className="table-wrapper__new custom-scrollbar overflow-auto">
        <LoadingOverlay
          active={loading}
          spinner={<LoadingIndicator size="10" />}
          fadeSpeed={0}
          styles={{
            content: (base) => ({
              ...base,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }),
            wrapper: (base) => ({
              ...base,
              height: '100%',
            }),
            spinner: () => ({}),
            overlay: (base) => ({
              ...base,
              zIndex: 10,
            }),
          }}
        >
          <table className="table-wrapper__table table-wrapper_table--fixed">
            <thead className="sticky top-0 z-10">
              <tr>
                <th className="table-wrapper__new-th table-wrapper__th--w-3"> </th>
                <th className="table-wrapper__new-th table-wrapper__th--w-18">Name</th>
                <th className="table-wrapper__new-th px-1">Description</th>
                <th className="table-wrapper__new-th px-1">Score/Justification</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-15 px-1">Market(s)</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-15 px-1">Division(s)</th>
              </tr>
              {selectedEntities?.length === 2 && (
                <EntityListActionRow
                  selectedEntities={selectedEntities}
                  toggleModalOpen={setIsMergingEntities}
                />
              )}
            </thead>
            <tbody className="table-wrapper__tbody">
              {isEmpty ? (
                <EmptyTableState message="There are no entities to display" colCount="6" />
              ) : (
                entities?.map((entity) => (
                  <EntityListRow
                    entity={entity}
                    key={entity.uuid}
                    selected={selectedEntities.includes(entity.uuid)}
                    setSelectedEntities={setSelectedEntities}
                    selectedEntities={selectedEntities}
                  />
                ))
              )}
            </tbody>
          </table>
          <div className="border-t border-gray-200 sticky bottom-0 z-10 bg-white">
            <ListFooter
              resourceStateName={resourceQueryParamName.entities}
              resourceName="entity"
              getResourceList={getEntities}
              getResourceTotalCount={getEntitiesTotalCount}
              getResourceLoading={getEntitiesLoading}
            />
          </div>
        </LoadingOverlay>
      </div>

      {isMergingEntities && (
        <MergeEntitiesModal
          isModalOpen={isMergingEntities}
          toggleModalOpen={toggleMergeEntitiesModal}
          selectedEntities={selectedEntities.map(
            (entityId) => entities.find((entity) => entity.uuid === entityId) as Entity
          )}
          setSelectedEntities={setSelectedEntities}
        />
      )}
    </>
  );
};

export default EntityTable;
