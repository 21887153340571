import React, { useState } from 'react';

import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import { useSelector } from 'react-redux';
import { getPromptsLoading } from 'selectors/entities';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import EditPromptModal from './EditPromptModal';
import PromptTable from './PromptTable';

const PrompList: React.FC = () => {
  const [isCreatingPrompt, setIsCreatingPrompt] = useState(false);

  const loading = useSelector(getPromptsLoading);

  const toggleCreateModal = (): void => setIsCreatingPrompt(!isCreatingPrompt);

  const handleCreateNew = (): void => {
    setIsCreatingPrompt(true);
  };

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <>
      <div
        className="flex flex-col min-w-screen bg-white overflow-hidden"
        style={{
          height: `calc(100vh - ${warningBarOffset}px)`,
        }}
      >
        <Navbar />
        <div
          className="flex flex-row"
          style={{
            height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
          }}
        >
          <SettingSidebar />
          <div className="py-10 flex flex-col h-full w-full overflow-hidden">
            <div className="flex flex-row justify-between mx-auto sm:px-6 lg:px-8 w-full">
              <div className="flex flex-row items-center">
                <h2 className="title">Prompts</h2>
                {loading && <LoadingIndicator className="ml-2" size="5" />}
              </div>
              <div className="flex justify-end w-full">
                <div>
                  <span className="ml-3 shadow-sm rounded-md">
                    <button
                      onClick={handleCreateNew}
                      type="button"
                      data-testid="create-report-button"
                      className="button button--primary"
                    >
                      Create New Prompt
                    </button>
                  </span>
                </div>
              </div>
            </div>
            <PromptTable />
          </div>
        </div>
      </div>
      {isCreatingPrompt && (
        <EditPromptModal
          isCreating
          isModalOpen={isCreatingPrompt}
          toggleModalOpen={toggleCreateModal}
        />
      )}
    </>
  );
};

export default PrompList;
