/* eslint-disable camelcase */

import {
  addUserToSavedSearch,
  addUserToSavedSearchFailure,
  addUserToSavedSearchRequest,
  addUserToSavedSearchSuccess,
  addUserToViewers,
  addUserToViewersFailure,
  addUserToViewersRequest,
  addUserToViewersSuccess,
  addUsersToReviewers,
  addUsersToReviewersFailure,
  addUsersToReviewersRequest,
  addUsersToReviewersSuccess,
  addUsersToViewers,
  addUsersToViewersFailure,
  addUsersToViewersSuccess,
  appendSample,
  appendSampleFailure,
  appendSampleRequest,
  appendSampleSuccess,
  createSavedSearch,
  deleteSavedSearch,
  deleteSavedSearchFailure,
  deleteSavedSearchFulfill,
  deleteSavedSearchRequest,
  deleteSavedSearchSuccess,
  fetchOwnSavedSearches,
  fetchOwnSavedSearchesSuccess,
  fetchOwnSavedSearchesViewers,
  fetchOwnSavedSearchesViewersFailure,
  fetchOwnSavedSearchesViewersFulfill,
  fetchOwnSavedSearchesViewersRequest,
  fetchOwnSavedSearchesViewersSuccess,
  fetchSavedSearches,
  fetchSavedSearchesByRole,
  fetchSavedSearchesFailure,
  fetchSavedSearchesFulfill,
  fetchSavedSearchesRequest,
  fetchSavedSearchesSuccess,
  fetchSingleSavedSearch,
  fetchSingleSavedSearchFailure,
  fetchSingleSavedSearchRequest,
  fetchSingleSavedSearchSuccess,
  removeUserFromSavedSearch,
  removeUserFromSavedSearchFailure,
  removeUserFromSavedSearchRequest,
  removeUserFromSavedSearchSuccess,
  removeUserFromViewers,
  removeUserFromViewersFailure,
  removeUserFromViewersRequest,
  removeUserFromViewersSuccess,
  selectReviewSet,
  setURLParams,
  showSuccessAlert,
  upsertSavedSearch,
  upsertSavedSearchFailure,
  upsertSavedSearchFulfill,
  upsertSavedSearchParams,
  upsertSavedSearchParamsFailure,
  upsertSavedSearchParamsRequest,
  upsertSavedSearchParamsSuccess,
  upsertSavedSearchRequest,
  upsertSavedSearchSuccess,
} from 'actions';
import { reviewSetCreated } from 'actions/envelopeListView';
import { apiClient as LitLingoClient } from 'client';
import { push, replace } from 'connected-react-router';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { getAllowDeleteDigests, getUser } from 'selectors/auth';
import { getNavParamsByResource } from 'selectors/nav';
import { getSelectedReviewSet } from 'selectors/savedSearches';
import type { API, SagaReturn } from 'types';
import { removeField, setNewValue, transformToString } from 'utils/parserTree';
import { getParamsFromUrl } from 'utils/urls';

function* fetchSavedSearchesListSaga(action: ReturnType<typeof fetchSavedSearches>): SagaReturn {
  const { payload } = action;
  yield put(fetchSavedSearchesRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.savedSearch)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = {
    include_count: true,
    include_private: true,
    include_pii: 'true',
    ...resourceParams,
    ...payload,
  };

  const response = (yield call([LitLingoClient.resources.savedSearches, 'list'], {
    params: { ...params, relationships: ['created_by', 'users', 'viewers'] },
  })) as API.Response<API.SavedSearches.List>;
  if (response.error != null) {
    yield put(fetchSavedSearchesFailure(response.error));
  } else {
    yield put(fetchSavedSearchesSuccess(response.data));
  }
  yield put(fetchSavedSearchesFulfill());
}

function* fetchOwnSavedSearchesViewersListSaga(): SagaReturn {
  yield put(fetchOwnSavedSearchesViewersRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.savedSearch)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const response = (yield call(
    [LitLingoClient.resources.savedSearches.extras, 'getOwnSavedSearches'],
    {
      params: {
        ...resourceParams,
        include_count: true,
        include_private: true,
        include_pii: 'true',
        relationships: ['created_by', 'users', 'viewers'],
      },
    }
  )) as API.Response<API.SavedSearches.getOwnSavedSearches>;
  if (response.error != null) {
    yield put(fetchOwnSavedSearchesViewersFailure(response.error));
  } else {
    yield put(fetchOwnSavedSearchesViewersSuccess(response.data));
  }

  yield put(fetchOwnSavedSearchesViewersFulfill());
}

function* fetchSavedSearchesByRoleListSaga(
  action: ReturnType<typeof fetchSavedSearchesByRole>
): SagaReturn {
  const { payload } = action;

  yield put(fetchSavedSearchesRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.savedSearch)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = {
    include_count: true,
    include_private: true,
    include_pii: 'true',
    ...resourceParams,
    ...payload,
  };

  const response = (yield call([LitLingoClient.resources.savedSearches, 'list'], {
    params: {
      ...params,
      ...(resourceParams.order_by === 'priority' ? { custom_order: 'true' } : {}),
      relationships: [
        'created_by',
        'users',
        'updated_by',
        'assignments',
        'assignments.user',
        'viewers',
        'custom_reports',
        'audit_logs',
        'audit_logs.created_by',
      ],
    },
  })) as API.Response<API.SavedSearches.List>;

  if (response.error != null) {
    yield put(fetchSavedSearchesFailure(response.error));
  } else if (response.data) {
    yield put(fetchSavedSearchesSuccess(response.data));
  }
  yield put(fetchSavedSearchesFulfill());
}

function* fetchOwnSavedSearchesSaga(
  action: ReturnType<typeof fetchSavedSearchesByRole>
): SagaReturn {
  const { payload } = action;

  yield put(fetchSavedSearchesRequest());

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.savedSearch)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = {
    include_count: true,
    include_private: true,
    include_pii: 'true',
    ...resourceParams,
    ...payload,
  };

  const response = (yield call(
    [LitLingoClient.resources.savedSearches.extras, 'getOwnAssignments'],
    {
      params: {
        ...params,
        relationships: ['created_by', 'users', 'assignments', 'assignments.user'],
      },
    }
  )) as API.Response<API.SavedSearches.List>;

  if (response.error != null) {
    yield put(fetchSavedSearchesFailure(response.error));
  } else if (response.data) {
    yield put(fetchOwnSavedSearchesSuccess(response.data));
  }
  yield put(fetchSavedSearchesFulfill());
}

function* addUsersToReviewersSaga(action: ReturnType<typeof addUsersToReviewers>): SagaReturn {
  const { payload } = action;
  const { users, savedSearchId } = payload;

  yield put(addUsersToReviewersRequest());
  const response = (yield all(
    users.map((user) =>
      call([LitLingoClient.resources.savedSearches.extras, 'addUser'], {
        urlParams: { userId: user.uuid, savedSearchId },
      })
    )
  )) as API.Response<API.SavedSearches.AddUser>[];
  const errors = response.some((e) => e.error != null);
  if (errors) {
    yield put(addUsersToReviewersFailure({ message: `Couldn't add reviewers` }));
  } else {
    yield put(addUsersToReviewersSuccess({ users, savedSearchId }));
  }
}

function* addUsersToViewersSaga(action: ReturnType<typeof addUsersToViewers>): SagaReturn {
  const { payload } = action;
  const { users, savedSearchId } = payload;

  const response = (yield all(
    users.map((user) =>
      call([LitLingoClient.resources.savedSearches.extras, 'addViewer'], {
        urlParams: { userId: user.uuid, savedSearchId },
      })
    )
  )) as API.Response<API.SavedSearches.AddViewer>[];
  const errors = response.some((e) => e.error != null);
  if (errors) {
    yield put(addUsersToViewersFailure({ message: `Couldn't add viewers` }));
  } else {
    yield put(addUsersToViewersSuccess({ users, savedSearchId }));
  }
}

function* createSavedSearchSaga(action: ReturnType<typeof upsertSavedSearch>): SagaReturn {
  const { payload } = action;
  const { users, viewers } = payload;

  const params: Record<string, unknown> = {
    ...payload,
    based_on: payload.based_on ?? null,
    cadence: payload.cadence ?? null,
    sample_size: payload.sample_size ?? null,
    url: payload.url,
  };

  yield put(upsertSavedSearchRequest());
  const response = (yield call(
    [LitLingoClient.resources.savedSearches.extras, 'createReviewStream'],
    {
      data: params,
      params: { relationships: ['created_by', 'users', 'assignments'] },
    }
  )) as API.Response<API.SavedSearches.createReviewStream>;
  if (response.error) {
    yield put(upsertSavedSearchFailure(response.error));
  } else {
    yield put(upsertSavedSearchSuccess(response.data));
    if (payload.isReviewSet) {
      yield put(reviewSetCreated(true));
      if (users) {
        yield put(addUsersToReviewers({ users, savedSearchId: response.data.uuid }));
      }
      yield put(showSuccessAlert('Review Stream Saved'));
    } else {
      if (viewers) {
        yield put(addUsersToViewers({ users: viewers, savedSearchId: response.data.uuid }));
      }
      yield put(showSuccessAlert('Search Saved'));
    }
    const isFirstTimeCreateReviewStream = window.localStorage.getItem(
      'isFirstTimeCreateReviewStream'
    );
    if (!isFirstTimeCreateReviewStream) {
      window.localStorage.setItem('isFirstTimeCreateReviewStream', 'true');
      yield put(setURLParams({ envelopes__is_first_time_create_review_set: 'true' }));
    }
    if (payload.is_assignable && payload.redirect && isFirstTimeCreateReviewStream) {
      yield take(addUsersToReviewersSuccess.toString());
      yield put(replace(payload.redirect));
    }
  }
  yield put(upsertSavedSearchFulfill());
}

function* upsertSavedSearchParamsSaga(
  action: ReturnType<typeof upsertSavedSearchParams>
): SagaReturn {
  const { payload } = action;
  const { uuid, url, redirect } = payload;
  yield put(upsertSavedSearchParamsRequest());
  const singleSavedSearchResponse = (yield call(
    [LitLingoClient.resources.savedSearches, 'retrieve'],
    uuid,
    {
      params: { include_pii: 'true' },
    }
  )) as API.Response<API.SavedSearches.Retrieve>;

  if (singleSavedSearchResponse.error) {
    yield put(upsertSavedSearchParamsFailure(singleSavedSearchResponse.error));
  } else {
    const params = {
      ...singleSavedSearchResponse.data,
      url,
    };
    const response = (yield call([LitLingoClient.resources.savedSearches, 'upsert'], {
      data: params,
      params: { relationships: ['created_by', 'users', 'assignments'] },
    })) as API.Response<API.SavedSearches.Upsert>;

    if (response.error) {
      yield put(upsertSavedSearchParamsFailure(response.error));
    } else {
      yield put(upsertSavedSearchParamsSuccess(response.data));
      yield put(showSuccessAlert('Params updated'));
      yield put(push(redirect));
    }
  }
}

function* upsertSavedSearchSaga(action: ReturnType<typeof upsertSavedSearch>): SagaReturn {
  const { payload } = action;
  const { users, viewers } = payload;

  const params = {
    ...payload,
    url: payload.url,
  };

  yield put(upsertSavedSearchRequest());
  const response = (yield call([LitLingoClient.resources.savedSearches, 'upsert'], {
    data: params,
    params: {
      relationships: [
        'created_by',
        'users',
        'updated_by',
        'assignments',
        'assignments.user',
        'viewers',
        'custom_reports',
        'audit_logs',
        'audit_logs.created_by',
      ],
    },
  })) as API.Response<API.SavedSearches.Upsert>;
  if (response.error) {
    yield put(upsertSavedSearchFailure(response.error));
  } else {
    yield put(upsertSavedSearchSuccess(response.data));
    if (payload.isReviewSet) {
      if (users) {
        yield put(addUsersToReviewers({ users, savedSearchId: response.data.uuid }));
      }
      yield put(showSuccessAlert('Review Stream Saved'));
    } else {
      if (viewers) {
        yield put(addUsersToViewers({ users: viewers, savedSearchId: response.data.uuid }));
      }
      yield put(showSuccessAlert('Search Saved'));
    }
    if (payload.is_assignable && payload.redirect) {
      yield take(addUsersToReviewersSuccess.toString());
      yield put(push(payload.redirect));
    }
  }
  yield put(upsertSavedSearchFulfill());
}

function* deleteSavedSearchSaga(action: ReturnType<typeof deleteSavedSearch>): SagaReturn {
  const { payload } = action;

  const deleteDigests = (yield select(getAllowDeleteDigests)) as ReturnType<
    typeof getAllowDeleteDigests
  >;

  const data = {
    delete_digests: deleteDigests,
  };

  yield put(deleteSavedSearchRequest());

  const response = (yield call([LitLingoClient.resources.savedSearches, 'delete'], payload.uuid, {
    data,
  })) as API.Response<API.SavedSearches.Delete>;
  if (response.error != null) {
    yield put(deleteSavedSearchFailure(response.error));
  } else {
    yield put(deleteSavedSearchSuccess(payload.uuid));
    yield put(showSuccessAlert('Search Deleted'));
  }
  yield put(deleteSavedSearchFulfill());
}

function* addUserToSavedSearchSaga(action: ReturnType<typeof addUserToSavedSearch>): SagaReturn {
  const { payload } = action;
  const { user, savedSearchId } = payload;

  yield put(addUserToSavedSearchRequest());
  const response = (yield call([LitLingoClient.resources.savedSearches.extras, 'addUser'], {
    urlParams: { userId: user.uuid, savedSearchId },
    params: { include_pii: true, relationships: ['audit_logs', 'users'] },
  })) as API.Response<API.SavedSearches.Upsert>;

  if (response.error != null) {
    yield put(addUserToSavedSearchFailure(response.error));
  } else {
    yield put(addUserToSavedSearchSuccess({ user, savedSearch: response.data }));
  }
}

function* removeUserFromSavedSearchSaga(
  action: ReturnType<typeof removeUserFromSavedSearch>
): SagaReturn {
  const { payload } = action;
  const { user, savedSearchId } = payload;

  yield put(removeUserFromSavedSearchRequest());
  const response = (yield call([LitLingoClient.resources.savedSearches.extras, 'removeUser'], {
    urlParams: { userId: user.uuid, savedSearchId },
    params: { include_pii: true, relationships: ['audit_logs', 'users'] },
  })) as API.Response<API.SavedSearches.Upsert>;

  if (response.error != null) {
    yield put(removeUserFromSavedSearchFailure(response.error));
  } else {
    yield put(removeUserFromSavedSearchSuccess({ user, savedSearch: response.data }));
  }
}

function* addUserToViewersSavedSearchSaga(action: ReturnType<typeof addUserToViewers>): SagaReturn {
  const { payload } = action;
  const { user, savedSearchId } = payload;

  yield put(addUserToViewersRequest());
  const response = (yield call([LitLingoClient.resources.savedSearches.extras, 'addViewer'], {
    urlParams: { userId: user.uuid, savedSearchId },
  })) as API.Response<API.SavedSearches.AddViewer>;

  if (response.error != null) {
    yield put(addUserToViewersFailure(response.error));
  } else {
    yield put(addUserToViewersSuccess({ user, savedSearchId }));
  }
}

function* removeUserFromViewersSavedSearchSaga(
  action: ReturnType<typeof removeUserFromViewers>
): SagaReturn {
  const { payload } = action;
  const { user, savedSearchId } = payload;

  yield put(removeUserFromViewersRequest());
  const response = (yield call([LitLingoClient.resources.savedSearches.extras, 'removeViewer'], {
    urlParams: { userId: user.uuid, savedSearchId },
  })) as API.Response<API.SavedSearches.RemoveViewer>;

  if (response.error != null) {
    yield put(removeUserFromViewersFailure(response.error));
  } else {
    yield put(removeUserFromViewersSuccess({ user, savedSearchId }));
  }
}

function* getSavedSearchSaga(action: ReturnType<typeof fetchSingleSavedSearch>): SagaReturn {
  const { payload } = action;
  yield put(fetchSingleSavedSearchRequest());
  const response = (yield call([LitLingoClient.resources.savedSearches, 'retrieve'], payload.uuid, {
    params: {
      include_pii: 'true',
      relationships: [
        'created_by',
        'users',
        'updated_by',
        'assignments',
        'assignments.user',
        'viewers',
        'custom_reports',
        'audit_logs',
        'audit_logs.created_by',
      ],
    },
  })) as API.Response<API.SavedSearches.Retrieve>;
  if (response.error != null) {
    yield put(fetchSingleSavedSearchFailure(response.error));
  } else {
    yield put(fetchSingleSavedSearchSuccess(response.data));
    if (payload.selectReviewSet) {
      yield put(selectReviewSet({ reviewSet: response.data }));
    }
  }
}

function* appendSampleSaga({ payload }: ReturnType<typeof appendSample>): SagaReturn {
  yield put(appendSampleRequest());

  const {
    params: resourceParams,
    dateRange,
    overrideDateRange,
    newDateRange,
    prevDateRange,
  } = payload;

  const strippedParams = { ...resourceParams };
  const savedSearch = (yield select(getSelectedReviewSet)) as ReturnType<
    typeof getSelectedReviewSet
  >;
  const user = (yield select(getUser)) as ReturnType<typeof getUser>;

  if (!savedSearch) return;

  let params: Record<string, unknown> = {
    // @ts-ignore
    ...payload,
    include_count: true,
    ...strippedParams,
  };
  if ('filters_search' in strippedParams) {
    params = {
      include_count: true,
      filters_search: strippedParams.filters_search,
    };
  }

  if ('states' in strippedParams) {
    strippedParams.event_states = strippedParams.states;
    delete strippedParams.states;
  }

  let nTree = { ...payload.tree };

  nTree = removeField(nTree, 'sample_uuids');
  nTree = removeField(nTree, 'date_range');
  nTree = setNewValue(
    nTree,
    'date_range',
    `${dateRange.createdAfter}<>${dateRange.createdBefore}`,
    ''
  );
  params.filters_search = transformToString(nTree);

  const response = (yield call([LitLingoClient.resources.envelopes.extras, 'createSample'], {
    data: {
      name: payload.name,
      description: payload.description,
      sample_size: payload.sample_size,
    },
    params,
  })) as API.Response<API.Envelopes.CreateSample>;

  if (response.error != null) {
    yield put(appendSampleFailure(response.error));
  } else {
    let finalTree = { ...payload.tree };
    finalTree = setNewValue(finalTree, 'sample_uuids', response.data?.sample_uuid, '', false);

    if (overrideDateRange) {
      finalTree = removeField(finalTree, 'date_range');
      finalTree = setNewValue(
        finalTree,
        'date_range',
        `${newDateRange?.createdAfter}<>${newDateRange?.createdBefore}`,
        ''
      );
    }
    if (!prevDateRange) {
      finalTree = setNewValue(
        finalTree,
        'date_range',
        `${dateRange?.createdAfter}<>${dateRange?.createdBefore}`,
        ''
      );
    }

    const finalFs = transformToString(finalTree);

    if (user.customer) {
      const p = getParamsFromUrl(
        savedSearch?.url || '',
        resourceQueryParamName.envelopes,
        'envelope-list',
        user.customer.config
      );

      p.filters_search = finalFs;

      const urlParams = new URLSearchParams();

      Object.entries(p).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => urlParams.append(`envelopes__${key}`, v));
        } else {
          urlParams.append(`envelopes__${key}`, value);
        }
      });

      yield put(
        upsertSavedSearch({
          ...savedSearch,
          url: `?${urlParams.toString()}`,
        })
      );

      yield put(
        appendSampleSuccess({
          savedSearchUuid: savedSearch?.uuid || '',
          newUrl: `?${urlParams.toString()}`,
        })
      );
    }
    yield put(showSuccessAlert('Sample created.'));
  }
}

function* savedSearchesSaga(): SagaReturn {
  yield takeLatest(createSavedSearch.toString(), createSavedSearchSaga);
  yield takeLatest(upsertSavedSearch.toString(), upsertSavedSearchSaga);
  yield takeLatest(deleteSavedSearch.toString(), deleteSavedSearchSaga);
  yield takeLatest(fetchSavedSearches.toString(), fetchSavedSearchesListSaga);
  yield takeLatest(fetchSavedSearchesByRole.toString(), fetchSavedSearchesByRoleListSaga);
  yield takeLatest(fetchOwnSavedSearches.toString(), fetchOwnSavedSearchesSaga);
  yield takeLatest(addUserToSavedSearch.toString(), addUserToSavedSearchSaga);
  yield takeLatest(addUsersToReviewers.toString(), addUsersToReviewersSaga);
  yield takeLatest(removeUserFromSavedSearch.toString(), removeUserFromSavedSearchSaga);
  yield takeLatest(addUserToViewers.toString(), addUserToViewersSavedSearchSaga);
  yield takeLatest(removeUserFromViewers.toString(), removeUserFromViewersSavedSearchSaga);
  yield takeLatest(fetchOwnSavedSearchesViewers.toString(), fetchOwnSavedSearchesViewersListSaga);
  yield takeLatest(addUsersToViewers.toString(), addUsersToViewersSaga);
  yield takeLatest(upsertSavedSearchParams.toString(), upsertSavedSearchParamsSaga);
  yield takeEvery(fetchSingleSavedSearch.toString(), getSavedSearchSaga);
  yield takeLatest(appendSample.toString(), appendSampleSaga);
}

export default savedSearchesSaga;
