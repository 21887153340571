import React from 'react';

const SETTINGS_NEW_CUSTOMER_BANNER = (
  <svg width="auto" viewBox="0 0 1190 366" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_13146_105034"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="1191"
      height="366"
    >
      <rect width="1191" height="366" fill="white" />
    </mask>
    <g mask="url(#mask0_13146_105034)">
      <path
        opacity="0.5"
        d="M742.121 333.401C666.719 333.401 605.593 271.794 605.593 195.798C605.593 119.802 666.719 58.1948 742.121 58.1948C817.524 58.1948 878.649 119.802 878.649 195.798C878.649 271.794 817.524 333.401 742.121 333.401Z"
        fill="#8FE3BE"
      />
      <path
        d="M927.212 99.8773L922.403 95.0684C921.802 94.4673 921.802 93.2651 922.403 92.3634C923.004 91.7623 924.206 91.7623 925.108 92.3634L929.917 97.1723L934.726 92.3634C935.327 91.7623 936.529 91.7623 937.431 92.3634C938.333 92.9645 938.032 94.1668 937.431 95.0684L932.622 99.8773L937.431 104.686C938.032 105.287 938.032 106.49 937.431 107.391C936.83 108.293 935.628 107.992 934.726 107.391L929.917 102.582L925.108 107.391C924.507 107.992 923.305 107.992 922.403 107.391C921.802 106.79 921.802 105.588 922.403 104.686L927.212 99.8773Z"
        fill="#FF9B00"
      />
      <path
        d="M78.212 96.9647L73.4032 92.1558C72.802 91.5547 72.802 90.3525 73.4032 89.4508C74.0043 88.8497 75.2065 88.8497 76.1082 89.4508L80.917 94.2597L85.7259 89.4508C86.327 88.8497 87.5293 88.8497 88.4309 89.4508C89.3326 90.0519 89.032 91.2542 88.4309 92.1558L83.622 96.9647L88.4309 101.774C89.032 102.375 89.032 103.577 88.4309 104.479C87.8298 105.38 86.6276 105.08 85.7259 104.479L80.917 99.6697L76.1082 104.479C75.507 105.08 74.3048 105.08 73.4032 104.479C72.802 103.877 72.802 102.675 73.4032 101.774L78.212 96.9647Z"
        fill="#FF9B00"
      />
      <path
        d="M665.133 108.4H669.04C669.641 108.4 670.242 109.001 670.242 109.602C670.242 110.203 669.641 110.804 669.04 110.804H665.133V114.712C665.133 115.313 664.532 115.914 663.931 115.914C663.329 115.914 662.728 115.313 662.728 114.712V110.804H658.821C658.22 110.804 657.619 110.203 657.619 109.602C657.619 109.001 658.22 108.4 658.821 108.4H662.728V104.493C662.728 103.892 663.329 103.291 663.931 103.291C664.532 103.291 665.133 103.892 665.133 104.493V108.4Z"
        fill="#45D09C"
      />
      <path
        d="M665.133 91.8707H669.04C669.641 91.8707 670.242 92.4718 670.242 93.0729C670.242 93.674 669.641 94.2751 669.04 94.2751H665.133V98.1823C665.133 98.7834 664.532 99.3846 663.931 99.3846C663.329 99.3846 662.728 98.7834 662.728 98.1823V94.2751H658.821C658.22 94.2751 657.619 93.674 657.619 93.0729C657.619 92.4718 658.22 91.8707 658.821 91.8707H662.728V87.9635C662.728 87.3623 663.329 86.7612 663.931 86.7612C664.532 86.7612 665.133 87.3623 665.133 87.9635V91.8707Z"
        fill="#45D09C"
      />
      <path
        d="M665.133 75.6405H669.04C669.641 75.6405 670.242 76.2416 670.242 76.8427C670.242 77.4438 669.641 78.0449 669.04 78.0449H665.133V81.9521C665.133 82.5532 664.532 83.1543 663.931 83.1543C663.329 83.1543 662.728 82.5532 662.728 81.9521V77.7444H658.821C658.22 77.7444 657.619 77.1432 657.619 76.5421C657.619 75.941 658.22 75.3399 658.821 75.3399H662.728V71.4327C662.728 70.8316 663.329 70.2305 663.931 70.2305C664.532 70.2305 665.133 70.8316 665.133 71.4327V75.6405Z"
        fill="#45D09C"
      />
      <path
        d="M665.133 59.1094H669.04C669.641 59.1094 670.242 59.7106 670.242 60.3117C670.242 60.9128 669.641 61.5139 669.04 61.5139H665.133V65.4211C665.133 66.0222 664.532 66.6233 663.931 66.6233C663.329 66.6233 662.728 66.0222 662.728 65.4211V61.5139H658.821C658.22 61.5139 657.619 60.9128 657.619 60.3117C657.619 59.7106 658.22 59.1094 658.821 59.1094H662.728V55.2022C662.728 54.6011 663.329 54 663.931 54C664.532 54 665.133 54.6011 665.133 55.2022V59.1094Z"
        fill="#45D09C"
      />
      <path
        d="M648.9 108.401H652.808C653.409 108.401 654.01 109.002 654.01 109.603C654.01 110.204 653.409 110.805 652.808 110.805H648.9V114.713C648.9 115.314 648.299 115.915 647.698 115.915C647.097 115.915 646.496 115.314 646.496 114.713V110.805H642.589C641.988 110.805 641.386 110.204 641.386 109.603C641.386 109.002 641.988 108.401 642.589 108.401H646.496V104.494C646.496 103.893 647.097 103.292 647.698 103.292C648.299 103.292 648.9 103.893 648.9 104.494V108.401Z"
        fill="#45D09C"
      />
      <path
        d="M648.9 91.8707H652.808C653.409 91.8707 654.01 92.4718 654.01 93.0729C654.01 93.674 653.409 94.2751 652.808 94.2751H648.9V98.1823C648.9 98.7834 648.299 99.3846 647.698 99.3846C647.097 99.3846 646.496 98.7834 646.496 98.1823V94.2751H642.589C641.988 94.2751 641.386 93.674 641.386 93.0729C641.386 92.4718 641.988 91.8707 642.589 91.8707H646.496V87.9635C646.496 87.3623 647.097 86.7612 647.698 86.7612C648.299 86.7612 648.9 87.3623 648.9 87.9635V91.8707Z"
        fill="#45D09C"
      />
      <path
        d="M648.9 75.6405H652.808C653.409 75.6405 654.01 76.2416 654.01 76.8427C654.01 77.4438 653.409 78.0449 652.808 78.0449H648.9V81.9521C648.9 82.5532 648.299 83.1543 647.698 83.1543C647.097 83.1543 646.496 82.5532 646.496 81.9521V77.7444H642.589C641.988 77.7444 641.386 77.1432 641.386 76.5421C641.386 75.941 641.988 75.3399 642.589 75.3399H646.496V71.4327C646.496 70.8316 647.097 70.2305 647.698 70.2305C648.299 70.2305 648.9 70.8316 648.9 71.4327V75.6405Z"
        fill="#45D09C"
      />
      <path
        d="M648.9 59.1094H652.808C653.409 59.1094 654.01 59.7106 654.01 60.3117C654.01 60.9128 653.409 61.5139 652.808 61.5139H648.9V65.4211C648.9 66.0222 648.299 66.6233 647.698 66.6233C647.097 66.6233 646.496 66.0222 646.496 65.4211V61.5139H642.589C641.988 61.5139 641.386 60.9128 641.386 60.3117C641.386 59.7106 641.988 59.1094 642.589 59.1094H646.496V55.2022C646.496 54.6011 647.097 54 647.698 54C648.299 54 648.9 54.6011 648.9 55.2022V59.1094Z"
        fill="#45D09C"
      />
      <path
        d="M632.672 108.401H636.579C637.18 108.401 637.781 109.002 637.781 109.603C637.781 110.204 637.18 110.805 636.579 110.805H632.672V114.713C632.672 115.314 632.071 115.915 631.47 115.915C630.868 115.915 630.267 115.314 630.267 114.713V110.805H626.36C625.759 110.805 625.158 110.204 625.158 109.603C625.158 109.002 625.759 108.401 626.36 108.401H630.267V104.494C630.267 103.893 630.868 103.292 631.47 103.292C632.071 103.292 632.672 103.893 632.672 104.494V108.401Z"
        fill="#45D09C"
      />
      <path
        d="M632.672 91.8707H636.579C637.18 91.8707 637.781 92.4718 637.781 93.0729C637.781 93.674 637.18 94.2751 636.579 94.2751H632.672V98.1823C632.672 98.7834 632.071 99.3846 631.47 99.3846C630.868 99.3846 630.267 98.7834 630.267 98.1823V94.2751H626.36C625.759 94.2751 625.158 93.674 625.158 93.0729C625.158 92.4718 625.759 91.8707 626.36 91.8707H630.267V87.9635C630.267 87.3623 630.868 86.7612 631.47 86.7612C632.071 86.7612 632.672 87.3623 632.672 87.9635V91.8707Z"
        fill="#45D09C"
      />
      <path
        d="M632.672 75.6405H636.579C637.18 75.6405 637.781 76.2416 637.781 76.8427C637.781 77.4438 637.18 78.0449 636.579 78.0449H632.672V81.9521C632.672 82.5532 632.071 83.1543 631.47 83.1543C630.868 83.1543 630.267 82.5532 630.267 81.9521V77.7444H626.36C625.759 77.7444 625.158 77.1432 625.158 76.5421C625.158 75.941 625.759 75.3399 626.36 75.3399H630.267V71.4327C630.267 70.8316 630.868 70.2305 631.47 70.2305C632.071 70.2305 632.672 70.8316 632.672 71.4327V75.6405Z"
        fill="#45D09C"
      />
      <path
        d="M632.672 59.1094H636.579C637.18 59.1094 637.781 59.7106 637.781 60.3117C637.781 60.9128 637.18 61.5139 636.579 61.5139H632.672V65.4211C632.672 66.0222 632.071 66.6233 631.47 66.6233C630.868 66.6233 630.267 66.0222 630.267 65.4211V61.5139H626.36C625.759 61.5139 625.158 60.9128 625.158 60.3117C625.158 59.7106 625.458 59.1094 626.36 59.1094H630.267V55.2022C630.267 54.6011 630.868 54 631.47 54C632.071 54 632.672 54.6011 632.672 55.2022V59.1094Z"
        fill="#45D09C"
      />
      <path
        d="M777.209 147.255H770.597C769.695 147.255 768.794 146.353 768.794 145.452C768.794 144.55 769.695 143.648 770.597 143.648H777.209V137.036C777.209 136.135 778.111 135.233 779.013 135.233C779.914 135.233 780.816 136.135 780.816 137.036V143.648H787.428C788.33 143.648 789.231 144.55 789.231 145.452C789.231 146.353 788.33 147.255 787.428 147.255H780.816V153.867C780.816 154.769 779.914 155.671 779.013 155.671C778.111 155.671 777.209 154.769 777.209 153.867V147.255Z"
        fill="#45D09C"
      />
      <path
        d="M121.978 60.0222H115.366C114.464 60.0222 113.562 59.1205 113.562 58.2189C113.562 57.3172 114.464 56.4155 115.366 56.4155H121.978V49.8033C121.978 48.9017 122.879 48 123.781 48C124.683 48 125.584 48.9017 125.584 49.8033V56.4155H132.197C133.098 56.4155 134 57.3172 134 58.2189C134 59.1205 133.098 60.0222 132.197 60.0222H125.584V66.6344C125.584 67.5361 124.683 68.4378 123.781 68.4378C122.879 68.4378 121.978 67.5361 121.978 66.6344V60.0222Z"
        fill="#45D09C"
      />
      <path
        d="M370.258 272.942L365.449 268.133C364.848 267.532 364.848 266.33 365.449 265.428C366.05 264.827 367.252 264.827 368.154 265.428L372.963 270.237L377.772 265.428C378.373 264.827 379.575 264.827 380.477 265.428C381.078 266.029 381.078 267.232 380.477 268.133L375.668 272.942L380.477 277.751C381.078 278.352 381.078 279.554 380.477 280.456C379.876 281.057 378.674 281.057 377.772 280.456L372.963 275.647L368.154 280.456C367.553 281.057 366.351 281.057 365.449 280.456C364.848 279.855 364.848 278.653 365.449 277.751L370.258 272.942Z"
        fill="#45D09C"
      />
      <path
        d="M1095.79 322.123C1100.9 322.123 1105.11 317.915 1105.11 312.806C1105.11 307.696 1100.9 303.488 1095.79 303.488C1090.68 303.488 1086.47 307.696 1086.47 312.806C1086.47 317.915 1090.68 322.123 1095.79 322.123ZM1095.79 307.696C1098.8 307.696 1101.2 310.101 1101.2 313.106C1101.2 316.112 1098.8 318.516 1095.79 318.516C1092.78 318.516 1090.38 316.112 1090.38 313.106C1090.68 309.8 1092.78 307.696 1095.79 307.696Z"
        fill="#45D09C"
      />
      <path
        d="M1135.37 424.399C1174.47 338.935 1170.2 239.606 1124.62 157.408C1117.1 143.836 1108.62 131.698 1098.04 124.669C1072.92 107.948 1041.24 99.2539 1011.99 106.667C980.775 114.567 956.433 139.342 939.083 166.472C921.732 193.603 909.747 223.845 892.832 251.244C882.153 268.542 867.274 286.006 847.068 288.259C819.653 291.319 798.384 265.815 783.172 242.82C767.96 219.825 748.125 193.731 720.569 194.742C698.558 195.549 681.092 214.409 672.244 234.574C663.396 254.74 660.527 277.069 652.396 297.542C644.252 318.002 628.464 337.809 606.658 340.907C587.117 343.673 568.307 332.329 553.812 318.937C539.317 305.544 526.948 289.514 510.122 279.207C483.706 263.036 448.915 263.741 421.052 277.274C393.189 290.807 371.984 315.851 358.974 343.955C335.734 394.132 337.987 455.345 364.852 503.678C404.431 574.892 487.291 609.794 566.591 628.513C661.001 650.804 760.379 657.269 855.02 635.977C949.66 614.685 1039.33 564.098 1098.03 486.88C1112.7 467.572 1125.25 446.549 1135.38 424.412L1135.37 424.399Z"
        stroke="#FF9B00"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M468.944 391.837C468.033 360.677 454.802 327.733 427.494 312.685C397.902 296.367 360.674 305.078 330.312 319.908C299.95 334.737 271.923 355.404 239.135 363.55C222.44 367.694 202.588 367.566 190.576 355.237C179.938 344.32 178.899 327.643 175.087 312.89C169.839 292.531 157.185 273.032 137.911 264.604C119.355 256.496 97.3337 259.819 79.2652 268.953C44.2319 286.669 22.0184 325.09 19.3107 364.256C16.603 403.409 31.8097 442.561 56.4229 473.145C81.0361 503.728 114.465 526.319 150.089 542.829C202.1 566.947 261.143 578.878 317.428 567.884C379.243 555.812 431.254 514.991 455.559 456.852C464.273 435.993 469.611 414.582 468.944 391.837Z"
        stroke="#37A47D"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
    </g>
  </svg>
);

export default SETTINGS_NEW_CUSTOMER_BANNER;
