import { fetchAllIdentifiers } from 'actions/identifier';
import {
  addAnnotationMatcherToRule,
  fetchRuleCustomers,
  setShowUtilization,
} from 'actions/ruleGroup';
import EnvelopeCollapsableSection from 'components/CollapsableSection/EnvelopeCollapsableSection';
import LoadingIndicator from 'components/LoadingIndicator';
import SearchInput from 'components/SearchInput';
import TestSentenceVisualizerSidebarRuleGroup from 'components/TestSentenceVisualizer/TestSentenceVisualizerSidebarRuleGroup';

import { selectCustomerConfig, selectItem } from 'actions';
import SelectRedesign from 'components/Select/SelectRedesign';
import { CLOSE_ICON_12 } from 'constants/commonIcons';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDomain } from 'selectors/auth';
import { getSelectedNode } from 'selectors/config';
import { getFetchAllIdentifiersLoading, getIdentifiers } from 'selectors/identifier';
import { getNavParamsByResource } from 'selectors/nav';
import type { MRuleRevision, NormalizedAnnotator } from 'types';
import { reverse } from 'utils/urls';
import MakeAnnotatorDraggable from './MakeAnnotatorDraggable';
import RenderIdentifier from './RenderIdentifier';
import RuleAnnotatorBank from './RuleAnnotatorBank';
import RuleHeader from './RuleHeader';
import ScopesSection from './ScopesSection/ScopesSection';

type ComponentProps = {
  rule: MRuleRevision;
};

const options: { value: string; label: string }[] = [
  {
    value: 'uncategorized',
    label: 'All',
  },
  {
    value: 'development',
    label: 'Dev',
  },

  {
    value: 'production',
    label: 'Prod',
  },
  {
    value: 'testing',
    label: 'Test',
  },
];

const ModelManagerSidebar: React.FC<ComponentProps> = ({ rule }) => {
  const dispatch = useDispatch();
  const identifiers = useSelector(getIdentifiers);
  // eslint-disable-next-line camelcase
  const { broad_search } = useSelector(getNavParamsByResource(resourceQueryParamName.identifier));
  const loading = useSelector(getFetchAllIdentifiersLoading);
  const customerDomain = useSelector(getCustomerDomain);
  const selectedNodeIndex = useSelector(getSelectedNode);

  const [searchValue, setSearchValue] = useState<string>('');
  const [group, setGroup] = useState<string>('uncategorized');

  useEffect(() => {
    dispatch(selectCustomerConfig(''));
    dispatch(setShowUtilization(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule.uuid]);

  useEffect(() => {
    dispatch(setShowUtilization(true));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rule?.uuid) {
      dispatch(fetchRuleCustomers(rule.uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule?.uuid]);

  useEffect(() => {
    dispatch(
      fetchAllIdentifiers({
        group,
      })
    );
    // eslint-disable-next-line camelcase
  }, [dispatch, broad_search, group]);

  useEffect(() => {
    const channel = new BroadcastChannel('create-identifier-channel');

    const handleEvent = (event: MessageEvent<{ identifier: NormalizedAnnotator }>): void => {
      if (event.data.identifier && (selectedNodeIndex === 0 || selectedNodeIndex)) {
        dispatch(
          addAnnotationMatcherToRule({
            rule,
            annotator: event.data.identifier,
            dropIndex: selectedNodeIndex,
          })
        );
      }
    };

    channel.addEventListener('message', handleEvent);

    return () => {
      channel.removeEventListener('message', handleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedNodeIndex]);

  const handleAddIdentifier = (): void => {
    const route = {
      routeName: 'global-identifier-choose-type',
      customerDomain,
    };
    const path = reverse(route);
    window.open(path, '_blank');

    window.localStorage.setItem('create-identifier-from-model', 'true');
  };

  const handleChangeGroup = (value: string): void => {
    setGroup(value);
  };

  // starship const buttonMainClass = 'button button--secondary font-bold';
  const renderItems = (): JSX.Element | JSX.Element[] => {
    if (loading) {
      return <LoadingIndicator size="10" data-testid="loading-indicator" />;
    }
    if (!loading && identifiers.length === 0) {
      return <div data-testid="no-data-message">No identifiers match this search</div>;
    }
    return identifiers.map((item, idx) => (
      <>
        <MakeAnnotatorDraggable
          Component={RenderIdentifier}
          identifier={item}
          key={item.uuid}
          rule={rule}
          searchValue={searchValue}
          idx={idx}
        />
        {/* {true && <TermsSerchMatch annotatorId={item.uuid} searchValue={searchValue} />} */}
      </>
    ));
  };

  const handleSearchChange = (value: string): void => {
    setSearchValue(value);
    dispatch(fetchAllIdentifiers({ searchValue: value }));
  };

  const handleUnselectNode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation();
    dispatch(selectItem({ index: null }));
    dispatch(setShowUtilization(true));
    dispatch(selectCustomerConfig(''));
  };

  const renderSelectedRow = (): JSX.Element | null => {
    if (selectedNodeIndex === -1 || selectedNodeIndex === null) {
      return null;
    }
    return (
      <div className="flex flex-row gap-2 px-2 py-1 bg-litlingo-white h-7.5">
        <div className="italic text-litlingo-gray-6">Row {selectedNodeIndex}</div>
        <button
          type="button"
          className="focus:outline-none"
          onClick={(e): void => handleUnselectNode(e)}
        >
          {CLOSE_ICON_12}
        </button>
      </div>
    );
  };

  return (
    <>
      <EnvelopeCollapsableSection headerTitle={rule.name}>
        <div className="flex flex-col">
          <RuleHeader />
          <RuleAnnotatorBank />
        </div>
      </EnvelopeCollapsableSection>
      <EnvelopeCollapsableSection
        headerTitle="Scope"
        extraAction={renderSelectedRow()}
        groupExtraAction
        isDefaultClosed
      >
        <ScopesSection />
      </EnvelopeCollapsableSection>
      <EnvelopeCollapsableSection headerTitle="Build & Tune" isDefaultClosed>
        <div className="flex flex-col h-full pt-6 overflow-hidden">
          <div className="flex flex-row justify-between items-center px-6">
            <div className="flex text-left self-end">
              <p className="text-heading-3">Identifiers</p>
            </div>
            <button
              type="button"
              className="button button--secondary font-bold h-6"
              onClick={handleAddIdentifier}
              id="add-identifier-button"
            >
              Add
            </button>
          </div>

          <div className="flex flex-row gap-2 justify-between px-6 h-8 mt-2">
            <SearchInput
              className="w-full rounded-md"
              placeholder="Search identifiers"
              handleSearch={handleSearchChange}
            />
            <div className="w-30">
              <SelectRedesign
                className="font-bold"
                dataTestid="config-type-select"
                onChange={(value): void => {
                  handleChangeGroup(value as string);
                }}
                options={options}
                value={group}
                placeholder=""
                valueClassName="font-bold"
              />
            </div>
          </div>

          <div
            className="flex flex-col items-center pb-6 mt-4 px-6 h-full w-full overflow-auto custom-scrollbar"
            data-testid="annotators-list-rule-page"
          >
            {renderItems()}
          </div>
        </div>
      </EnvelopeCollapsableSection>

      <EnvelopeCollapsableSection headerTitle="Test">
        <div className="p-4">
          <TestSentenceVisualizerSidebarRuleGroup />
        </div>
      </EnvelopeCollapsableSection>
    </>
  );
};

export default ModelManagerSidebar;
