import config from '@config';

// to have the proper types on the client this can't have a type, if debugging
// is needed ClientSpec can be used as the type
const V1APISpec = {
  base: config.api.v1,
  logs: false,

  headers: {
    'x-litlingo-auth-no-redirect': 'true',
    'block-overwrite': 'true',
    'cache-control': 'no-cache',
  },

  resources: {
    analytics: {
      path: '/analytics',
      include: [],
      extras: {
        eventByCampaign: {
          path: '/event-by-campaign',
          method: 'GET',
        },
        topUsersByEvents: {
          path: '/top-users-by-events',
          method: 'GET',
        },
        eventBuckets: {
          path: '/event-buckets',
          method: 'GET',
        },
        envelopeBuckets: {
          path: '/envelope-buckets',
          method: 'GET',
        },
        commsByPlatforms: {
          path: '/communications-by-platform',
          method: 'GET',
        },
        workforceRisk: {
          path: '/workforce-risk',
          method: 'GET',
        },
        eventsByStte: {
          path: '/events-by-state',
          method: 'GET',
        },
        sentimentOverTime: {
          path: '/sentiment-over-time',
          method: 'GET',
        },
        commonWords: {
          path: '/common-words',
          method: 'GET',
        },
        tags: {
          path: '/tags',
          method: 'GET',
        },
        metrics: {
          path: '/metrics',
          method: 'POST',
        },
        metricsExport: {
          path: '/metrics-export',
          method: 'POST',
        },
        multiCustomerMetrics: {
          path: '/multi-customer-metrics',
          method: 'POST',
        },
        multiCustomerMetricsExport: {
          path: '/multi-customer-metrics-export',
          method: 'POST',
        },
      },
    },

    annotators: {
      path: '/annotators',
      include: ['LIST', 'UPSERT', 'RETRIEVE', 'DELETE'],
      extras: {
        auditLogs: {
          path: '/audit-logs',
          method: 'GET',
        },
        changelog: {
          path: '/audit-logs/{annotatorId}/{version}',
          method: 'GET',
        },
        languageMatchers: {
          path: '/language-matchers',
          method: 'GET',
        },
        types: {
          path: '/types',
          method: 'GET',
        },
        relationshipTypes: {
          path: '/relationship-types',
          method: 'GET',
        },
        undelete: {
          path: '/{uuid}/undelete',
          method: 'POST',
        },
        cloneAnnotator: {
          path: '/{annotatorId}/clone/{customerId}',
          method: 'POST',
        },
        replaceAnnotator: {
          path: '/replace-identifiers',
          method: 'POST',
        },
        generateTerms: {
          path: '/generate_terms',
          method: 'POST',
        },
      },
    },

    anonymous: {
      path: '/anonymous',
      extras: {
        evaluate: {
          path: '/evaluate',
          method: 'POST',
        },
        campaigns: {
          path: '/campaigns',
          method: 'GET',
        },
      },
    },

    assignments: {
      path: '/assignments',
      include: ['LIST', 'UPSERT', 'RETRIEVE', 'DELETE'],
      extras: {
        mine: {
          path: '/mine',
          method: 'GET',
        },
        getNextEnvelope: {
          path: '/{assignmentId}/envelope',
          method: 'GET',
        },
        open: {
          path: '/{assignmentId}/open',
          method: 'POST',
        },
        close: {
          path: '/{assignmentId}/close',
          method: 'POST',
        },
        skipToPending: {
          path: '/{assignmentId}/skip-to-pending',
          method: 'PUT',
        },
        getNextSkippedEnvelope: {
          path: '/{assignmentId}/skipped-envelope',
          method: 'GET',
        },
        openSkipped: {
          path: '/{assignmentId}/open-skipped',
          method: 'GET',
        },
        getSelectedSkipped: {
          path: '/{assignmentId}/skipped/{envelopeId}',
          method: 'GET',
        },
        updateSkips: {
          path: '/{assignmentId}/update-skips',
          method: 'POST',
        },
      },
    },

    campaigns: {
      path: '/campaigns',
      include: ['LIST', 'UPSERT', 'RETRIEVE', 'DELETE'],
      extras: {
        getActiveIntegrations: {
          path: '/active_integrations',
          method: 'GET',
        },
        addRuleOutcome: {
          path: '/{campaignId}/add-rule-outcome',
          method: 'POST',
        },
        removeRuleOutcome: {
          path: '/{campaignId}/remove-rule',
          method: 'POST',
        },
        clone: {
          path: '/{ruleOutcomeId}/clone-rule/{campaignId}',
          method: 'POST',
        },
        cloneCampaign: {
          path: '/{campaignId}/clone/{customerId}',
          method: 'POST',
        },
        undelete: {
          path: '/{uuid}/undelete',
          method: 'POST',
        },
      },
    },

    campaignRuleOutcomes: {
      path: '/campaign_rule_outcomes',
      include: ['UPSERT', 'LIST', 'RETRIEVE'],
      extras: {},
    },

    communications: {
      path: '/communications',
      include: ['RETRIEVE'],
      extras: {
        preview: {
          path: '/preview',
          method: 'POST',
        },
        previewAnnotations: {
          path: '/preview-annotations',
          method: 'POST',
        },
        evaluate: {
          path: '/evaluate',
          method: 'POST',
        },
        document: {
          path: '/{commUuid}/document',
          method: 'GET',
        },
        body: {
          path: '/{commUuid}/raw_body',
          method: 'GET',
        },
        threadContext: {
          path: '/{commUuid}/thread_context',
          method: 'GET',
        },
        updatePlatformGUIDs: {
          path: '/update-platform-guids',
          method: 'POST',
        },
        updatePlatformThreadGUIDs: {
          path: '/update-platform-thread-guids',
          method: 'POST',
        },
        preprocess: {
          path: '/preprocess',
          method: 'POST',
        },
        attachments: {
          path: '/{commUuid}/attachments',
          method: 'GET',
        },
        events_summaries: {
          path: '/{commUuid}/events_summaries',
          method: 'GET',
        },
        clones: {
          path: '/{commUuid}/clones',
          method: 'GET',
        },
      },
    },

    customers: {
      path: '/customers',
      include: ['UPSERT', 'RETRIEVE'],
      extras: {
        reprocessWithFilter: {
          path: '/reprocess-with-filter',
          method: 'POST',
        },
        extractWithFilter: {
          path: '/extract-with-filter',
          method: 'POST',
        },
        extractSenderDomains: {
          path: '/extract-sender-domains',
          method: 'POST',
        },
        extractRecipientDomains: {
          path: '/extract-recipient-domains',
          method: 'POST',
        },
        create: {
          path: '/create',
          method: 'PUT',
        },
        reprocessComms: {
          path: '/reprocess',
          method: 'POST',
        },
        reindexComms: {
          path: '/reindex',
          method: 'GET',
        },
        getZendeskSecret: {
          path: '/zendesk-secret-key',
          method: 'GET',
        },
        regenZendeskSecret: {
          path: '/regen-zendesk-secret-key',
          method: 'GET',
        },
        extensions: {
          path: '/extension/{name}',
          method: 'GET',
        },
        auditLogs: {
          path: '/audit-logs',
          method: 'GET',
        },
        uploadURL: {
          path: '/upload-url',
          method: 'GET',
        },
        documentURL: {
          path: '/documents/{documentId}',
          method: 'GET',
        },
        verifyZendeskCredentials: {
          path: '/verify-zendesk-credentials',
          method: 'GET',
        },
        verifyGmailCredentials: {
          path: '/verify-gmail-credentials',
          method: 'GET',
        },
        verifyO365Credentials: {
          path: '/verify-o365-credentials',
          method: 'GET',
        },
        indexesComms: {
          path: '/indexes',
          method: 'GET',
        },
        logMetrics: {
          path: '/queue-stats',
          method: 'GET',
        },
        indexingStatus: {
          path: '/indexing-status',
          method: 'GET',
        },
        processingStatus: {
          path: '/processing-status',
          method: 'GET',
        },
        provider: {
          path: '/provider',
          method: 'POST',
        },
        newSandbox: {
          path: '/new-sandbox',
          method: 'POST',
        },
        getSandboxes: {
          path: '/sandboxes',
          method: 'GET',
        },
        changelog: {
          path: '/audit-logs/{version}',
          method: 'GET',
        },
        exportUsers: {
          path: '/export-synced-users',
          method: 'POST',
        },
        exportQueryUsers: {
          path: '/export-query-users',
          method: 'POST',
        },
      },
    },

    envelopes: {
      path: '/communication_envelopes',
      include: ['LIST', 'RETRIEVE'],
      extras: {
        summary: {
          path: '/summaries',
          method: 'GET',
        },
        exportSummaries: {
          path: '/summaries/export',
          method: 'GET',
        },
        exportSummary: {
          path: '/summary/export',
          method: 'POST',
        },
        exportNgrams: {
          path: '/n-grams/export',
          method: 'GET',
        },
        star: {
          path: '/{envelopeId}/star',
          method: 'PUT',
        },
        read: {
          path: '/{envelopeId}/read',
          method: 'PUT',
        },
        addTag: {
          path: '/{envelopeId}/tags/add?relationships=tags',
          method: 'POST',
        },
        removeTag: {
          path: '/{envelopeId}/tags/remove?relationships=tags',
          method: 'POST',
        },
        bulkAddTags: {
          path: '/bulk/tags/add',
          method: 'POST',
        },
        bulkRemoveTags: {
          path: '/bulk/tags/remove',
          method: 'POST',
        },
        esDocs: {
          path: '/{envelopeId}/es-docs',
          method: 'GET',
        },
        esDocsAsIndexed: {
          path: '/{envelopeId}/es-docs-as-indexed',
          method: 'GET',
        },
        review: {
          path: '/{envelopeId}/review',
          method: 'POST',
        },
        bulkReview: {
          path: '/bulk/review',
          method: 'POST',
        },
        bulkReviewQuery: {
          path: '/bulk/review/query',
          method: 'POST',
        },
        addAction: {
          path: '/{envelopeId}/action?relationships=actions',
          method: 'PUT',
        },
        bulkAddAction: {
          path: '/{envelopeId}/add-action-to-all-events',
          method: 'PUT',
        },
        bulkAddAllTags: {
          path: '/bulk/tags/query',
          method: 'POST',
        },
        bulkRemoveAllTags: {
          path: '/bulk/tags/remove/query',
          method: 'POST',
        },
        reprocessSingleEnvelope: {
          path: '/{envelopeId}/reprocess',
          method: 'POST',
        },
        createSample: {
          path: '/sample',
          method: 'PUT',
        },
        notifyAuthor: {
          path: '/{envelopeId}/notify_author',
          method: 'POST',
        },
        flaggedText: {
          path: '/flagged-text',
          method: 'GET',
        },
        attachments: {
          path: '/{envelopeUuid}/attachments',
          method: 'GET',
        },
        moreLikeThis: {
          path: '/{envelopeUuid}/more_like_this',
          method: 'GET',
        },
        searchTree: {
          path: '/tree',
          method: 'GET',
        },
        createSearchOnSandbox: {
          path: '/apply-search-on-clone/{sandboxId}',
          method: 'POST',
        },
      },
    },
    envelope_actions: {
      path: '/envelope_actions',
      include: ['LIST', 'UPSERT', 'RETRIEVE', 'DELETE'],
      extras: {},
    },
    events: {
      path: '/events',
      include: ['LIST'],
      extras: {
        summary: {
          path: '/{eventId}/summary',
          method: 'GET',
        },
        review: {
          path: '/{eventId}/action?relationships=actions',
          method: 'PUT',
        },
        listLabels: {
          path: '/labels/all',
          method: 'GET',
        },
        addLabel: {
          path: '/{eventId}/labels/add?relationships=actions',
          method: 'POST',
        },
        removeLabel: {
          path: '/{eventId}/labels/remove?relationships=actions',
          method: 'POST',
        },
        count: {
          path: '/count',
          method: 'GET',
        },
        reprocess: {
          path: '/reprocess',
          method: 'POST',
        },
        timeout: {
          path: '/{eventId}/timeout',
          method: 'POST',
        },
        notifyAuthor: {
          path: '/{eventId}/notify_author',
          method: 'POST',
        },
      },
    },

    login: {
      path: '/login',
      extras: {
        confirm: {
          path: '/confirm',
          method: 'GET',
        },
        confirmChrome: {
          path: '/confirm-chrome',
          method: 'POST',
        },
        confirmOpenID: {
          path: '/confirm-open-id',
          method: 'POST',
        },
        gmail: {
          path: '/auth_gmail',
          method: 'POST',
        },
        logout: {
          path: '/logout',
          method: 'GET',
        },
        changeCustomer: {
          path: '/change-customer/{customerDomain}',
          method: 'GET',
        },
      },
    },

    notifications: {
      path: '/notifications',
      extras: {
        getEmailTemplate: {
          path: '/email_templates/{templateId}',
          method: 'GET',
        },
        renderEmailTemplate: {
          path: '/email_templates/{templateId}/render',
          method: 'POST',
        },
      },
    },

    outcomes: {
      path: '/outcomes',
      include: ['LIST', 'UPSERT', 'RETRIEVE'],
      extras: {
        types: {
          path: '/types',
          method: 'GET',
        },
      },
    },

    rules: {
      path: '/rules',
      include: ['UPSERT', 'LIST', 'RETRIEVE', 'DELETE'],
      extras: {
        auditLogs: {
          path: '/audit-logs',
          method: 'GET',
        },
        changelog: {
          path: '/audit-logs/{ruleId}/{version}',
          method: 'GET',
        },
        undelete: {
          path: '/{uuid}/undelete',
          method: 'POST',
        },
        scheduleCompare: {
          path: '/schedule-compare',
          method: 'POST',
        },
        publishVersion: {
          path: '/{ruleId}/publish-version/{version}',
          method: 'POST',
        },
        undoVersion: {
          path: '/{ruleId}/undo-version',
          method: 'POST',
        },
        diffRules: {
          path: '/diff-models',
          method: 'POST',
        },
      },
    },

    savedSearches: {
      path: '/saved_searches',
      include: ['LIST', 'UPSERT', 'RETRIEVE', 'DELETE'],
      extras: {
        getOwnSavedSearches: {
          path: '/mine-viewer',
          method: 'GET',
        },
        getOwnAssignments: {
          path: '/mine',
          method: 'GET',
        },
        createReviewStream: {
          path: '/review-stream',
          method: 'POST',
        },
        addUser: {
          path: '/{savedSearchId}/add-user/{userId}',
          method: 'PUT',
        },
        removeUser: {
          path: '/{savedSearchId}/remove-user/{userId}',
          method: 'PUT',
        },
        addViewer: {
          path: '/{savedSearchId}/add-viewer/{userId}',
          method: 'PUT',
        },
        removeViewer: {
          path: '/{savedSearchId}/remove-viewer/{userId}',
          method: 'PUT',
        },
      },
    },

    customReports: {
      path: '/custom_reports',
      include: ['UPSERT', 'LIST', 'RETRIEVE', 'DELETE'],
      extras: {},
    },

    tags: {
      path: '/tags',
      include: ['LIST', 'UPSERT', 'RETRIEVE', 'DELETE'],
      extras: {},
    },

    tagGroups: {
      path: '/tag_value_groups',
      include: ['LIST', 'UPSERT', 'RETRIEVE', 'DELETE'],
      extras: {
        addTags: {
          path: '/{tagGroupId}/add-tags',
          method: 'PUT',
        },
        removeTags: {
          path: '/{tagGroupId}/remove-tags',
          method: 'PUT',
        },
      },
    },

    tasks: {
      path: '/tasks',
      include: ['LIST', 'UPSERT', 'RETRIEVE', 'DELETE'],
      extras: {
        getActiveTasks: {
          path: '/get-active-tasks',
          method: 'GET',
        },
      },
    },

    teams: {
      path: '/teams',
      include: ['LIST', 'UPSERT', 'RETRIEVE', 'DELETE'],
      extras: {
        addUser: {
          path: '/{teamId}/add-user/{userId}',
          method: 'PUT',
        },
        removeUser: {
          path: '/{teamId}/remove-user/{userId}',
          method: 'PUT',
        },
      },
    },

    templates: {
      path: '/templates',
      include: ['LIST', 'UPSERT', 'RETRIEVE', 'DELETE'],
      extras: {},
    },

    testCases: {
      path: '/tests',
      include: ['RETRIEVE', 'LIST', 'UPSERT', 'DELETE'],
      extras: {
        results: {
          path: '/results',
          method: 'GET',
        },
        evaluate: {
          path: '/evaluate',
          method: 'GET',
        },
        evaluateAsync: {
          path: '/evaluate-async',
          method: 'GET',
        },
        evaluateAsyncResults: {
          path: '/evaluate-async/results/{resultKey}',
          method: 'GET',
        },
        summary: {
          path: '/summary',
          method: 'GET',
        },
        undelete: {
          path: '/{uuid}/undelete',
          method: 'POST',
        },
        export: {
          path: '/export.csv',
          method: 'GET',
        },
        import: {
          path: '/bulk-import',
          method: 'POST',
        },
        importEntity: {
          path: '/bulk-import/{entity}/{entityId}',
          method: 'POST',
        },
        bulkDelete: {
          path: '/bulk/delete',
          method: 'POST',
        },
        addTag: {
          path:
            '/{testCaseId}/tags/add?relationships=tags&relationships=tags.tag_value',
          method: 'POST',
        },
        removeTag: {
          path:
            '/{testCaseId}/tags/remove?relationships=tags&relationships=tags.tag_value',
          method: 'POST',
        },
        bulkAddTags: {
          path: '/bulk/tags/add',
          method: 'POST',
        },
        bulkRemoveTags: {
          path: '/bulk/tags/remove',
          method: 'POST',
        },
        removeFromBranch: {
          path: '/{testCaseId}/remove_from_branch/{branchId}',
          method: 'PUT',
        },
        addToBranch: {
          path: '/{testCaseId}/add_to_branch/{branchId}',
          method: 'PUT',
        },
        bulkMatch: {
          path: '/bulk/match',
          method: 'PUT',
        },
        generate: {
          path: '/generate_test_case',
          method: 'POST',
        },
      },
    },

    users: {
      path: '/users',
      include: ['LIST', 'UPSERT', 'RETRIEVE', 'DELETE'],
      extras: {
        me: {
          path: '/me',
          method: 'GET',
        },
        availableRoles: {
          path: '/available-roles',
          method: 'GET',
        },
        customers: {
          path: '/me/customers',
          method: 'GET',
        },
        newInstall: {
          path: '/new-install',
          method: 'POST',
        },
        undelete: {
          path: '/{userId}/undelete',
          method: 'POST',
        },
        unlockEnvelope: {
          path: '/me/unlock-envelope',
          method: 'POST',
        },
        lockEnvelope: {
          path: '/me/lock-envelope/{envelopeId}',
          method: 'POST',
        },
        updateUsers: {
          path: '/bulk-set',
          method: 'PUT',
        },
        deleteUsers: {
          path: '/bulk-delete',
          method: 'POST',
        },
        invitationState: {
          path: '/{userId}/invitation-state',
          method: 'GET',
        },
        senderDomains: {
          path: '/sender-domains',
          method: 'GET',
        },
        recipientDomains: {
          path: '/recipients-domains',
          method: 'GET',
        },
        integrationsCount: {
          path: '/integrations',
          method: 'GET',
        },
        rolesCount: {
          path: '/available-roles-count',
          method: 'GET',
        },
        teamUsersCount: {
          path: '/divisions-count',
          method: 'GET',
        },
        bulkUpdateQuery: {
          path: '/bulk-update-query',
          method: 'POST',
        },
        count: {
          path: '/count',
          method: 'GET',
        },
      },
    },

    dashboards: {
      path: '/dashboards',
      include: ['LIST', 'UPSERT', 'RETRIEVE', 'DELETE'],
      extras: {},
    },

    entityMetadata: {
      path: '/entity_metadata',
      include: ['LIST', 'UPSERT', 'RETRIEVE'],
      extras: {},
    },
    entities: {
      path: '/entities',
      include: ['LIST', 'UPSERT', 'RETRIEVE'],
      extras: {
        merge: {
          path: '/merge',
          method: 'POST',
        },
      },
    },
    prompts: {
      path: '/prompts',
      include: ['LIST', 'UPSERT', 'RETRIEVE', 'DELETE'],
      extras: {},
    },
  },
} as const;

export default Object.freeze(V1APISpec);
