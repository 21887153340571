/* eslint-disable camelcase */
import { removeValuesFromTree, upsertSavedSearch } from 'actions';
import FilterBadge from 'components/EnvelopeList/FilterPills/FilterBadge';
import Modal from 'components/Modal';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUser } from 'selectors/auth';
import { getAllFilterValuesFromTreeWithLabels } from 'selectors/envelopes';
import { getSelectedReviewSet } from 'selectors/savedSearches';
import { useSelector } from 'store';
import { Tree } from 'types';
import { removeValue, transformToString, TreeFieldsValuesWithLabels } from 'utils/parserTree';
import { getParamsFromUrl } from 'utils/urls';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
  treeParams: TreeFieldsValuesWithLabels;
  tree?: Tree;
  modifyExploreTree?: boolean;
};

const MessagesModal: React.FC<ComponentProps> = ({
  isModalOpen,
  toggleModalOpen,
  treeParams,
  tree,
  modifyExploreTree = false,
}) => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const reviewSet = useSelector(getSelectedReviewSet);
  const urlParams = useSelector(getAllFilterValuesFromTreeWithLabels);

  const [removeMessages, setRemoveMessages] = useState<{ label: string; value: string }[]>([]);

  const params = treeParams.uuid || urlParams.uuid || [];

  const modifyExplore = (): void => {
    dispatch(
      removeValuesFromTree({
        values: removeMessages.map((m) => m.value),
        field: 'uuid',
      })
    );

    toggleModalOpen();
  };

  const handleSaveSavedSearch = (): void => {
    if (modifyExploreTree) {
      modifyExplore();
      return;
    }

    if (reviewSet && tree) {
      let finalTree = tree;
      removeMessages.forEach((m) => {
        finalTree = removeValue(finalTree, m.value, 'uuid');
      });

      const finalFs = transformToString(finalTree);

      if (user.customer) {
        const p = getParamsFromUrl(
          reviewSet.url || '',
          resourceQueryParamName.envelopes,
          'envelope-list',
          user.customer.config
        );

        p.filters_search = finalFs;

        const finalUrlParams = new URLSearchParams();

        Object.entries(p).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((v) => finalUrlParams.append(`envelopes__${key}`, v));
          } else {
            finalUrlParams.append(`envelopes__${key}`, value);
          }
        });

        dispatch(
          upsertSavedSearch({
            ...reviewSet,
            url: `?${finalUrlParams.toString()}`,
          })
        );

        toggleModalOpen();
      }
    }
  };

  const handleAddToNormalMessages = (value: string): void => {
    setRemoveMessages((messages) => messages.filter((m) => m.value !== value));
  };

  const handleAddToRemoveMessages = (label: string, value: string): void => {
    setRemoveMessages((messages) => [...messages, { label, value }]);
  };

  const renderMessages = (): JSX.Element[] | null => {
    if (params) {
      return params
        .filter((m) => !removeMessages.find((message) => message.value === m.value))
        .map((m) => (
          <FilterBadge
            key={m.value}
            title={m.label || m.value}
            deleteAction={(): void => handleAddToRemoveMessages(m.label, m.value)}
          />
        ));
    }
    return null;
  };

  const renderMessagesToRemove = (): JSX.Element[] | null =>
    removeMessages.map((m) => (
      <FilterBadge
        key={m.value}
        title={m.label || m.value}
        deleteAction={(): void => handleAddToNormalMessages(m.value)}
      />
    ));

  const renderBody = (
    <>
      <div className="flex flex-col gap-6 mb-20 mt-4">
        <span>
          The messages below have been appended to the current set. They’re ordered by newest to
          oldest sample (left to right, top down). Remove messages by selecting the x in the tag.
        </span>
        <div className="flex flex-row gap-1 flex-wrap">{renderMessages()}</div>
        <span>Remove from message set on save:</span>
        <div className="flex flex-row gap-1 flex-wrap">{renderMessagesToRemove()}</div>
      </div>
    </>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderBody}
          title="Appended Messages"
          okButton
          okButtonText={modifyExploreTree ? 'Apply' : 'Save'}
          okButtonOnClick={handleSaveSavedSearch}
          toggleShowModal={toggleModalOpen}
          style={{
            width: '800px',
          }}
        />
      )}
    </>
  );
};

export default MessagesModal;
