/* eslint-disable react-hooks/exhaustive-deps */
import { setURLParams } from 'actions';
import { firstVisit } from 'actions/envelopeListView';
import Navbar from 'components/Navbar';
import moment from 'moment-timezone';
import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { getDashboardState } from 'selectors/dashboard';
import { useSelector } from 'store';
import { DashboardFilter, DashboardWidget as DashboardWidgetType } from 'types';
import { areIntersected, reduceFilters } from 'utils/dashboard';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import { TIME_RANGE_FIELD } from 'utils/timeRanges';
import { useHistory } from 'utils/urls';
import DashboardSidebar from './DashboardSidebar';
import IntersectedError from './IntersectedError';
import reduceWidgetGroup from './reduceWidgetGroup';

const Dashboard: React.FC<
  RouteComponentProps<{
    [paramName: string]: string | undefined;
  }>
> = ({ match }) => {
  const { params } = match;

  const history = useHistory();

  const dashboardState = useSelector(getDashboardState(params?.activeUuid));

  const intersected = areIntersected(dashboardState);

  if (dashboardState.primary_timezone) {
    moment.tz.setDefault(dashboardState.primary_timezone);
  }

  const dispatch = useDispatch();

  const defaultFilters = useMemo(
    () =>
      dashboardState.filters?.reduce(
        (current: string, filter: DashboardFilter) => reduceFilters(current, filter),
        ''
      ),
    [dashboardState.filters]
  );

  useEffect(() => {
    dispatch(firstVisit(false));
    if (dashboardState.override_time_range) {
      dispatch(
        setURLParams({
          [TIME_RANGE_FIELD]: dashboardState.override_time_range,
        })
      );
    }
  }, []);

  useLayoutEffect(() => {
    if (history.location.search) {
      return;
    }
    if (defaultFilters?.length !== 0 && match.path !== '/:customerDomain/dashboards/:activeUuid') {
      history.push(`${dashboardState.user.customer?.domain}?${defaultFilters?.slice(1)}`);
    }
  }, [history.location.search]);

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <Navbar />
      <div
        className="flex flex-row"
        style={{
          height: `calc(100vh - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <DashboardSidebar params={params} dashboardConfig={dashboardState} />
        <div
          className={`pb-4 overflow-y-scroll flex-1 overflow-x-hidden ${
            dashboardState.name === 'Compliance Dashboard'
              ? 'bg-litlingo-white'
              : 'bg-litlingo-gray-8'
          }`}
        >
          <>
            {intersected != null && (
              <div className="mt-2 ml-4">
                <IntersectedError
                  widget1={intersected.widget1}
                  widget2={intersected.widget2}
                  Ax1={intersected.Ax1}
                  Ax2={intersected.Ax2}
                  Ay1={intersected.Ay1}
                  Ay2={intersected.Ay2}
                  Bx1={intersected.Bx1}
                  Bx2={intersected.Bx2}
                  By1={intersected.By1}
                  By2={intersected.By2}
                />
              </div>
            )}

            <main>
              <div className="mt-3 grid grid-cols-16 auto-rows-layout gap-4 px-8">
                {dashboardState.widgets.reduce<(JSX.Element | DashboardWidgetType)[]>(
                  reduceWidgetGroup(intersected, dashboardState.key),
                  []
                )}
              </div>
            </main>
          </>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
