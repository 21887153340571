import { fetchSingleTestCase } from 'actions';
import TestCase from 'components/TestCase';
import TestPageAdmin from 'components/TestPageAdmin';
import TestResultsVisualizerPage from 'components/TestResultsVisualizer/TestResultsVisualizerPage';
import { RouteSpec, SuperRouteSpec } from 'types';

const testCasesRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'test-cases-detail',
    path: '/:customerDomain/test-cases/:testCaseId',
    component: TestCase,
    private: true,
    data: {
      idFromPath: true,
      actions: [fetchSingleTestCase],
    },
  },
  {
    name: 'test-viz-result',
    path: '/:customerDomain/test-viz-result',
    component: TestResultsVisualizerPage,
    private: true,
  },
  {
    name: 'test-viz-admin',
    path: '/:customerDomain/test-viz-admin',
    component: TestPageAdmin,
    private: true,
    data: {
      params: {
        body: {},
      },
    },
  },
  {
    path: '/test-viz-admin',
    component: (): null => null,
    private: true,
  },
];

export default testCasesRoutes;
