import { apiClient as LitLingoClient } from 'client';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { all, call, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { getEventsList } from 'selectors/events';
import { getNavParams, getNavParamsByResource } from 'selectors/nav';
import { API, ErrorObject, Event, SagaReturn } from 'types';
import {
  addCommentEvent,
  addCommentEventFailure,
  addCommentEventRequest,
  addCommentEventSuccess,
  addEventLabel,
  addEventLabelFailure,
  addEventLabelRequest,
  addEventLabelSuccess,
  fetchAllCommunicationDocument,
  fetchAllEventDocComm,
  fetchAllEventDocCommRequest,
  fetchAllEventDocCommSuccess,
  fetchBulkEventSummaries,
  fetchBulkEventSummariesFailure,
  fetchBulkEventSummariesRequest,
  fetchBulkEventSummariesSuccess,
  fetchCommThreadContext,
  fetchCommThreadContextFailure,
  fetchCommThreadContextRequest,
  fetchCommThreadContextSuccess,
  fetchEventBodyComm,
  fetchEventBodyCommFailure,
  fetchEventBodyCommRequest,
  fetchEventBodyCommSuccess,
  fetchEventDocComm,
  fetchEventDocCommFailure,
  fetchEventDocCommRequest,
  fetchEventDocCommSuccess,
  fetchEventLabels,
  fetchEventLabelsFailure,
  fetchEventLabelsRequest,
  fetchEventLabelsSuccess,
  fetchEventSummary,
  fetchEventSummaryFailure,
  fetchEventSummaryRequest,
  fetchEventSummarySuccess,
  fetchEvents,
  fetchEventsFailure,
  fetchEventsRequest,
  fetchEventsSuccess,
  removeEventLabel,
  removeEventLabelFailure,
  removeEventLabelRequest,
  removeEventLabelSuccess,
  reprocessEvents,
  reprocessEventsFailure,
  reprocessEventsRequest,
  reprocessEventsSuccess,
  reviewEvent,
  reviewEventFailure,
  reviewEventRequest,
  reviewEventSuccess,
  showErrorAlert,
  showSuccessAlert,
} from '../actions';
import { fetchAllCommunicationDocumentSaga } from './communication';

function* fetchEventsSaga({ payload }: ReturnType<typeof fetchEvents>): SagaReturn {
  const navParams = (yield select(getNavParams)) as ReturnType<typeof getNavParams>;

  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.event)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  // creates a copy of the params on the store, this is important because the
  // state shouldn't be mutated
  const params = { ...navParams, ...resourceParams };
  if (params.order_desc !== 'true') {
    delete params.order_desc;
  }
  yield put(fetchEventsRequest());

  const response = (yield call([LitLingoClient.resources.events, 'list'], {
    params: { ...params, ...payload },
  })) as API.Response<API.Events.List>;

  if (response.error != null) {
    yield put(fetchEventsFailure(response.error));
  } else {
    yield put(fetchEventsSuccess(response.data));
  }
}

function* fetchEventSummarySaga({ payload }: ReturnType<typeof fetchEventSummary>): SagaReturn {
  const { eventId } = payload;
  const body = {
    relationships: ['event.actions.created_by', 'annotations.model_kind'],
  };

  yield put(fetchEventSummaryRequest());

  const response = (yield call([LitLingoClient.resources.events.extras, 'summary'], {
    urlParams: { eventId },
    params: body,
  })) as API.Response<API.Events.Summary>;

  if (response.error != null) {
    yield put(fetchEventSummaryFailure(response.error));
  } else {
    yield put(fetchEventSummarySuccess(response.data));
  }
}

function* fetchBulkEventSummariesSaga({
  payload,
}: ReturnType<typeof fetchBulkEventSummaries>): SagaReturn {
  const { communicationId } = payload;

  yield put(fetchBulkEventSummariesRequest(communicationId));

  const response = (yield call(
    [LitLingoClient.resources.communications.extras, 'events_summaries'],
    {
      urlParams: { commUuid: communicationId },
    }
  )) as API.Response<API.Events.BulkSummary>;

  if (response.error != null) {
    yield put(
      fetchBulkEventSummariesFailure({ error: response.error as ErrorObject, communicationId })
    );
  } else {
    yield put(fetchBulkEventSummariesSuccess(response.data));
  }
}

function* fetchEventDocCommSaga(action: ReturnType<typeof fetchEventDocComm>): SagaReturn {
  const { eventId, commUuid } = action.payload;

  yield put(fetchEventDocCommRequest());
  const response = (yield call([LitLingoClient.resources.communications.extras, 'document'], {
    urlParams: { commUuid },
  })) as API.Response<API.Communications.Document>;

  if (response.error != null) {
    yield put(fetchEventDocCommFailure(response.error));
  } else {
    yield put(fetchEventDocCommSuccess({ eventId, commUuid, document: response.data }));
  }
}

function* fetchAllEventDocCommSaga({
  payload,
}: ReturnType<typeof fetchAllEventDocComm>): SagaReturn {
  const { communications } = payload;

  const existingEvents = (yield select(getEventsList)) as ReturnType<typeof getEventsList>;

  yield put(fetchAllEventDocCommRequest());

  const events = communications.reduce<Event[]>(
    (acc, value) => [...acc, ...(value.events ? value.events : [])],
    []
  );

  yield all(
    events
      .filter((e) => !existingEvents.some((ev) => ev.uuid === e.uuid))
      .map((e) =>
        call(fetchEventSummarySaga, {
          payload: {
            eventId: e.uuid,
          },
          type: fetchEventSummary.toString(),
        })
      )
  );

  yield call(fetchAllCommunicationDocumentSaga, {
    payload: {
      communications,
    },
    type: fetchAllCommunicationDocument.toString(),
  });

  yield put(fetchAllEventDocCommSuccess());
}

function* fetchEventBodyCommSaga({ payload }: ReturnType<typeof fetchEventBodyComm>): SagaReturn {
  const { eventId, commUuid } = payload;

  yield put(fetchEventBodyCommRequest());
  const response = (yield call([LitLingoClient.resources.communications.extras, 'body'], {
    urlParams: { commUuid },
  })) as API.Response<API.Communications.Body>;

  if (response.error != null) {
    yield put(fetchEventBodyCommFailure(response.error));
  } else {
    yield put(fetchEventBodyCommSuccess({ eventId, body: response.data.body }));
  }
}

function* fetchCommThreadContextSaga({
  payload,
}: ReturnType<typeof fetchCommThreadContext>): SagaReturn {
  const { eventId, commUuid } = payload;

  yield put(fetchCommThreadContextRequest());
  const response = (yield call([LitLingoClient.resources.communications.extras, 'threadContext'], {
    urlParams: { commUuid },
  })) as API.Response<API.Communications.ThreadContext>;

  if (response.error != null) {
    yield put(fetchCommThreadContextFailure(response.error));
  } else {
    yield put(fetchCommThreadContextSuccess({ eventId, context: response.data.context }));
  }
}

function* fetchEventLabelsSaga(): SagaReturn {
  yield put(fetchEventLabelsRequest());
  const response = (yield call([
    LitLingoClient.resources.events.extras,
    'listLabels',
  ])) as API.Response<API.Events.ListLabels>;

  if (response.error != null) {
    yield put(fetchEventLabelsFailure(response.error));
  } else {
    yield put(fetchEventLabelsSuccess(response.data));
  }
}

function* addEventLabelSaga({ payload }: ReturnType<typeof addEventLabel>): SagaReturn {
  const { eventId, label } = payload;

  yield put(addEventLabelRequest());
  const response = (yield call([LitLingoClient.resources.events.extras, 'addLabel'], {
    urlParams: { eventId },
    data: { value: label },
  })) as API.Response<API.Events.AddLabel>;

  if (response.error != null) {
    yield put(addEventLabelFailure(response.error));
  } else {
    yield put(addEventLabelSuccess(response.data));
  }
}

function* removeEventLabelSaga({ payload }: ReturnType<typeof removeEventLabel>): SagaReturn {
  const { eventId, label } = payload;

  yield put(removeEventLabelRequest());
  const response = (yield call([LitLingoClient.resources.events.extras, 'removeLabel'], {
    urlParams: { eventId },
    data: { value: label },
  })) as API.Response<API.Events.RemoveLabel>;

  if (response.error != null) {
    yield put(removeEventLabelFailure(response.error));
  } else {
    yield put(removeEventLabelSuccess(response.data));
  }
}

function* addCommentEventSaga({ payload }: ReturnType<typeof addCommentEvent>): SagaReturn {
  const { eventId, value } = payload;
  const commentData = {
    type: 'comment',
    value,
  };
  const params = {
    relationships: 'actions.created_by',
    include_pii: 'true',
  };

  yield put(addCommentEventRequest());
  const response = (yield call([LitLingoClient.resources.events.extras, 'review'], {
    urlParams: { eventId },
    params,
    data: commentData,
  })) as API.Response<API.Events.Review>;

  if (response.error != null) {
    yield put(addCommentEventFailure(response.error));
  } else {
    yield put(addCommentEventSuccess(response.data));
  }
}

function* reviewEventSaga({ payload }: ReturnType<typeof reviewEvent>): SagaReturn {
  const { eventId, value, key } = payload;

  yield put(reviewEventRequest());
  const response = (yield call([LitLingoClient.resources.events.extras, 'review'], {
    urlParams: { eventId },
    data: {
      type: key,
      value,
    },
  })) as API.Response<API.Events.Review>;

  if (response.error != null) {
    yield put(reviewEventFailure(response.error));
  } else {
    yield put(reviewEventSuccess(response.data));
  }
}

function* reprocessEventsSaga({ payload }: ReturnType<typeof reprocessEvents>): SagaReturn {
  const navParams = (yield select(getNavParams)) as ReturnType<typeof getNavParams>;
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.event)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  // creates a copy of the params on the store, this is important because the
  // state shouldn't be mutated
  const params = { ...navParams, ...resourceParams };

  delete params.relationships;
  delete params.include_count;
  delete params.include_pii;
  delete params.order_by;
  delete params.order_desc;

  const body = {
    include_no_events: payload,
  };
  if (params.order_desc !== 'true') {
    delete params.order_desc;
  }

  yield put(reprocessEventsRequest());
  const response = (yield call([LitLingoClient.resources.events.extras, 'reprocess'], {
    params,
    data: body,
  })) as API.Response<API.Events.Reprocess>;

  if (response.error != null) {
    yield put(showErrorAlert('Failed - did not start reprocessing communcations'));
    yield put(
      reprocessEventsFailure({ message: 'Failed - did not start reprocessing communcations' })
    );
  } else {
    yield put(showSuccessAlert('Success - Communications Reprocessing'));
    yield put(reprocessEventsSuccess());
  }
}

function* eventsSaga(): SagaReturn {
  yield takeLatest(fetchEvents.toString(), fetchEventsSaga);
  yield takeEvery(fetchEventSummary.toString(), fetchEventSummarySaga);
  yield takeEvery(fetchBulkEventSummaries.toString(), fetchBulkEventSummariesSaga);

  yield takeEvery(fetchEventDocComm.toString(), fetchEventDocCommSaga);
  yield takeLeading(fetchAllEventDocComm.toString(), fetchAllEventDocCommSaga);
  yield takeLatest(fetchEventBodyComm.toString(), fetchEventBodyCommSaga);
  yield takeLatest(fetchCommThreadContext.toString(), fetchCommThreadContextSaga);
  yield takeLatest(fetchEventLabels.toString(), fetchEventLabelsSaga);
  yield takeLatest(addEventLabel.toString(), addEventLabelSaga);
  yield takeLatest(removeEventLabel.toString(), removeEventLabelSaga);
  yield takeLatest(addCommentEvent.toString(), addCommentEventSaga);
  yield takeLatest(reviewEvent.toString(), reviewEventSaga);
  yield takeLatest(reprocessEvents.toString(), reprocessEventsSaga);
}

export default eventsSaga;
