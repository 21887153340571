import EditCategoryModal from 'components/Category/EditCategoryModal';
import daysAgo from 'components/utils/daysAgo';
import platform from 'constants/platform';
import useNavParams from 'hooks/nav/useNavParams';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import type { Category } from 'types';
import { printStringArray } from 'utils/strings';

type ComponentProps = {
  category: Category;
};

type RouterParams = { categoryId: string };

const CustomerCategoryItem: React.FC<ComponentProps> = ({ category }) => {
  const { categoryId } = useParams<RouterParams>();
  const { customer_owned: customerOwned } = useNavParams() as { customer_owned: string };

  const [isEditingCategory, setIsEditingcategory] = useState(false);

  const toggleEditModal = (): void => {
    setIsEditingcategory(!isEditingCategory);
  };

  return (
    <>
      <tr
        aria-hidden
        className={`border-t border-gray-200 hover:bg-litlingo-gray-1 table-row ${
          categoryId === category.uuid ? 'bg-litlingo-gray-0.5' : ''
        }`}
        onClick={toggleEditModal}
      >
        <td className="table-wrapper__new-td">
          <div className="flex items-center text-body px-4">
            <div className="overflow-hidden">
              <div className="truncate font-bold">{category.model?.name}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="truncate">
                {customerOwned ? category.outcome?.name : category.customer?.name}
              </div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td pr-1 py-1">
          <div className="text-body">
            {category.active_platforms &&
              printStringArray(
                Object.entries(platform).reduce<string[]>((acc, [key, value]) => {
                  if (category[key as keyof typeof category]) {
                    return [...acc, value];
                  }
                  return acc;
                }, [])
              )}
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="text-body">{category.active_inbound ? 'Yes' : ' No'}</div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="text-body">{category.active_outbound ? 'Yes' : 'No'}</div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="text-body">
            {category.updated_at != null && daysAgo(category.updated_at)}
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="text-body">{category.updated_by && `By ${category.updated_by.name}`}</div>
        </td>
      </tr>
      {isEditingCategory && (
        <EditCategoryModal
          isModalOpen={isEditingCategory}
          toggleModalOpen={toggleEditModal}
          categoryToEdit={category}
        />
      )}
    </>
  );
};

export default CustomerCategoryItem;
