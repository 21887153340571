import LinkLookup from 'components/LinkLookup';
import daysAgo from 'components/utils/daysAgo';
import useNavParams from 'hooks/nav/useNavParams';
import React from 'react';
import type { Category } from 'types';
import logEvent from 'utils/analytics';

type ComponentProps = {
  category: Category;
  // selected: boolean;
  // handleCategorieselect: (checked: boolean, uuid: UUID) => void;
};

const CategoryListItem: React.FC<ComponentProps> = ({
  category,
  // selected,
  // handleCategorieselect,
}) => {
  const { customer_owned: customerOwned } = useNavParams() as { customer_owned: string };

  /* const handleRowCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();

    handleCategorieselect(e.target.checked, category.uuid);
    const element = document.activeElement as HTMLInputElement;
    if (element) {
      element.blur();
    }
  }; */

  return (
    <>
      <LinkLookup
        routeName={customerOwned ? 'category-manager' : 'global-category-manager'}
        routeParams={{ categoryId: category.uuid }}
        className="border-t border-gray-200 hover:bg-gray-100 table-row"
        onClick={(): Promise<void> => logEvent('single-use-case')}
        as="tr"
      >
        {/* <td className="table-wrapper__new-td flex-col items-center justify-center ">
          <div className="flex flex-col items-center justify-center  min-h-8 ">
            <input
              id={`checkbox-user-${category.uuid}`}
              data-testid="select-button"
              type="checkbox"
              className="cursor-pointer form-checkbox litlingo-checkbox w-5 h-5"
              onClick={(e): void => e.stopPropagation()}
              onChange={(e): void => {
                handleRowCheckboxChange(e);
              }}
              checked={selected}
            />
          </div>
        </td> */}
        <td className="table-wrapper__new-td h-12 pl-4">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="truncate font-bold">{category.model?.name}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td h-12">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="truncate font-bold">{category.rule?.name}</div>
            </div>
          </div>
        </td>

        <td className="table-wrapper__new-td h-12">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="truncate">{category.outcome?.name}</div>
            </div>
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="truncate">{category.customer?.name}</div>
            </div>
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="text-body">
            {category.updated_at != null && daysAgo(category.updated_at)}
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="text-body">{category.updated_by && `By ${category.updated_by.name}`}</div>
        </td>
      </LinkLookup>
    </>
  );
};

export default CategoryListItem;
