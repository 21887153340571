import React from 'react';

const ErrorState: React.FC = () => (
  <div className="litlingo__robot_center">
    <div>
      <svg
        style={{ margin: 'auto' }}
        width="71"
        height="71"
        viewBox="0 0 71 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="35.5" cy="35.5" r="35.5" fill="#F2F2F2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0277 67.6642C22.0156 63.5183 25.6049 60.2775 30 58.7329V55.4592V54.4286H21.6667C17.9848 54.4286 15 51.4609 15 47.8V42.7698C12.1731 42.3678 10 39.9505 10 37.0286C10 35.0437 11.0028 33.2916 12.5322 32.246L13.2585 17.8023C12.3268 17.441 11.6667 16.54 11.6667 15.4857C11.6667 14.1129 12.786 13 14.1667 13C15.5474 13 16.6667 14.1129 16.6667 15.4857C16.6667 16.54 16.0065 17.441 15.0748 17.8023L15.7311 30.8535C18.0798 22.4676 25.8172 16.3143 35 16.3143C44.1828 16.3143 51.9202 22.4676 54.2689 30.8535L54.9252 17.8023C53.9935 17.441 53.3333 16.54 53.3333 15.4857C53.3333 14.1129 54.4526 13 55.8333 13C57.214 13 58.3333 14.1129 58.3333 15.4857C58.3333 16.54 57.6732 17.441 56.7415 17.8023L57.4678 32.246C58.9972 33.2916 60 35.0437 60 37.0286C60 39.9505 57.8269 42.3678 55 42.7698V47.8C55 51.4609 52.0152 54.4286 48.3333 54.4286H40V55.4592V58.2391C44.9022 59.4296 48.9948 62.6733 51.3037 67.0018C46.4348 69.5548 40.8871 71 35 71C29.6429 71 24.5669 69.8033 20.0277 67.6642ZM29.9487 48.8162C29.774 49.3402 29.2077 49.6233 28.6838 49.4487C28.1598 49.274 27.8767 48.7077 28.0513 48.1838C30.3552 41.2721 39.6448 41.2721 41.9487 48.1838C42.1233 48.7077 41.8402 49.274 41.3162 49.4487C40.7923 49.6233 40.226 49.3402 40.0513 48.8162C38.3552 43.7279 31.6448 43.7279 29.9487 48.8162Z"
          fill="#DA7600"
        />
      </svg>
    </div>
    <h3 className="litlingo__color_grey text-lg font-bold pt-3">
      Service not available
    </h3>
    <p className="litlingo__color_grey_2 text-sm pt-1">
      We’re not able to connect right now. Please try again later
    </p>
  </div>
);

export default ErrorState;
