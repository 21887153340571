import { PLUS_ICON } from 'constants/envelopeIcons';
import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';
import getSelectedEnvelope from 'selectors/envelopeReview';
import 'styles/labelsStyle.css';
import type { SavedSearch } from 'types';
import ReviewSetSelectDropdown from './ReviewSetSelectDropdown';

type ReviewSetSelectProps = {
  selectReviewSet: (reviewSet: SavedSearch) => void;
};

const ReviewSetSelect: React.FC<ReviewSetSelectProps> = (props) => {
  const { selectReviewSet } = props;

  const envelope = useSelector(getSelectedEnvelope);

  const [showReviewSetSelect, setShowReviewSetSelect] = useState(false);

  const handleToggleShowReviewSets = (): void => {
    setShowReviewSetSelect(!showReviewSetSelect);
  };

  const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const { styles, attributes } = usePopper(ref.current, popperRef, {
    placement: 'bottom-end',
  });

  const renderDropdown = (): React.ReactPortal | null => {
    if (!envelope) return null;

    const portal = document.getElementById('dropdown-portal');

    const dropdownElement = (
      <div
        className="relative z-100"
        ref={(node): void => {
          setPopperRef(node);
        }}
        style={{ ...styles.popper }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes.popper}
      >
        <ReviewSetSelectDropdown
          handleClose={handleToggleShowReviewSets}
          selectReviewSet={selectReviewSet}
        />
      </div>
    );

    if (portal) {
      return ReactDOM.createPortal(dropdownElement, portal);
    }
    return null;
  };

  return (
    <div className="relative flex flex-col" ref={ref}>
      <button
        type="button"
        className="px-1 py-0.5 w-18 flex flex-row justify-between items-center gap-1 bg-litlingo-gray-0.5 border border-litlingo-gray-3 rounded text-small focus:outline-none"
        onClick={handleToggleShowReviewSets}
      >
        <span className="w-3 h-3">{PLUS_ICON}</span>
        <span>Append</span>
      </button>
      {showReviewSetSelect && renderDropdown()}
    </div>
  );
};

export default ReviewSetSelect;
