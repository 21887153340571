import { setURLParams } from 'actions';
import Permissions from 'components/Permissions';
import withWidget from 'decorators/widget';
import React from 'react';
import { useDispatch } from 'react-redux';

const ExportDashboardWidget: React.FC = () => {
  const dispatch = useDispatch();

  const handleExport = (): void => {
    dispatch(
      setURLParams({
        is_exporting: 'true',
      })
    );
  };

  return (
    <div className="w-full">
      <button
        type="button"
        className="flex justify-center w-full h-full text-body focus:outline-none"
        onClick={handleExport}
      >
        <Permissions action="events.list">
          <div className="text-white font-bold">Export Charts</div>
        </Permissions>
      </button>
    </div>
  );
};

export default withWidget({
  // minWidth: 5,
  // minHeight: 3,
  bgClassColor: 'bg-litlingo-primary',
})(ExportDashboardWidget);
