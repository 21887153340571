/* eslint-disable max-lines */
import { fetchFlaggedText, setURLParams } from 'actions';
import { setEnvelopesSelected, setFromEnvelope } from 'actions/envelopeListView';
import BackToTopButton from 'components/Button/BackToTopButton';
import FilterListHeaderRedesign from 'components/Filters/FilterListHeaderRedesign';
import { envelopeOrderOptions } from 'components/Filters/SortFilter';
import LoadingIndicator from 'components/LoadingIndicator';
import Tooltip from 'components/Tooltip';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useAddTreeToStore from 'hooks/envelopes/useAddTreeToStore';
import React, { useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import { TableComponents, TableVirtuoso, TableVirtuosoHandle } from 'react-virtuoso';
import {
  envelopeListContentState,
  getFromEnvelope,
  getOrderByNewParam,
} from 'selectors/envelopeListView';
import { getEnvelopes } from 'selectors/envelopes';
import { getNavParamsByResourceMemo } from 'selectors/nav';
import { useSelector } from 'store';
import type { CommunicationEnvelopeSummary } from 'types';
import { useHistory } from 'utils/urls';
import EmptyEnvelopeList from './EmptyEnvelopeList';
import { MemoizedEnvelopeListActionsRow } from './EnvelopeListActionsRow';
import { MemoizedEnvelopeListRow } from './EnvelopeListRow';
import { MemoizedEnvelopeListRowWrapper } from './EnvelopeListRowWrapper';

const EnvelopeLIstTableWrapper: TableComponents['Table'] = (props) => (
  <table
    className="table-wrapper__table table-wrapper_table--fixed relative"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

type ComponentProps = {
  selectedFormat: 'expanded' | 'contracted';
  firstLoading: boolean;
  allSelected: boolean;

  setIsAtTop: React.Dispatch<React.SetStateAction<boolean>>;
  setScrolled: React.Dispatch<React.SetStateAction<boolean>>;

  handleScrollSave: (scroll: number) => void;
  handleLoadMore: () => void;
  handleSelectAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheck: (e: React.ChangeEvent<HTMLInputElement>, item: CommunicationEnvelopeSummary) => void;
  handleWheel: (event: React.WheelEvent<HTMLDivElement>) => void;
};

const EnvelopeListTable: React.FC<ComponentProps> = (props) => {
  const {
    firstLoading,
    selectedFormat,
    allSelected,
    setIsAtTop,
    setScrolled,
    handleScrollSave,
    handleLoadMore,
    handleSelectAll,
    handleCheck,
    handleWheel,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const navParams = useSelector((state) =>
    getNavParamsByResourceMemo(state, resourceQueryParamName.envelopes)
  );

  const { loadingAll, envelopesSelected } = useSelector(envelopeListContentState);

  const isOrderingByNewParam = useSelector(getOrderByNewParam);
  const fromEnvelope = useSelector(getFromEnvelope);
  const envelopes = useSelector(getEnvelopes);

  useAddTreeToStore(navParams.filters_search as string);

  const tableScrollContainerRef = useRef<HTMLDivElement>(null);

  const virtuosoRef = useRef<TableVirtuosoHandle>(null);

  useEffect(() => {
    if (allSelected && envelopesSelected.length < envelopes.length && envelopes.length > 0) {
      const notSelected = envelopes
        .filter((e) => !envelopesSelected.includes(e.envelope.uuid))
        .map((e) => e.envelope.uuid);
      dispatch(setEnvelopesSelected(envelopesSelected?.concat(notSelected)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, envelopes, allSelected]);

  // Scroll restoration
  useEffect(() => {
    if (history.action !== 'POP' && !fromEnvelope) return;

    dispatch(setFromEnvelope(false));
    const y = Number(sessionStorage.getItem('scrollPosition_envelopes-list-table'));

    if (y > 0 && envelopes.length > 0) {
      virtuosoRef.current?.scrollToIndex(y);
      if (y > 0) setScrolled(true);
      sessionStorage.removeItem('scrollPosition_envelopes-list-table');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envelopes.length]);

  const handleFetchFlaggedText = (rEnvelopes: string[]): void => {
    if (rEnvelopes.length > 0) {
      dispatch(fetchFlaggedText({ envelopeIds: rEnvelopes }));
    }
  };

  const handleBackToTop = (): void => {
    virtuosoRef.current?.scrollTo({ top: 0, behavior: 'smooth' });

    dispatch(
      setURLParams({
        [`envelopes__offset`]: '0',
      })
    );
  };

  return (
    <>
      <div className="relative flex w-full h-full overflow-hidden" onWheel={handleWheel}>
        <LoadingOverlay
          active={loadingAll && firstLoading}
          spinner={<LoadingIndicator size="10" />}
          className="w-full"
          fadeSpeed={0}
          styles={{
            content: (base) => ({
              ...base,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }),
            wrapper: () => ({}),
            spinner: () => ({}),
            overlay: (base) => ({
              ...base,
              zIndex: 10,
            }),
          }}
        >
          <div className="table-wrapper__container no-scrollbar rounded  flex flex-col w-full h-full overflow-hidden">
            <main className="table-wrapper__new flex flex-col w-full h-full">
              <TableVirtuoso
                ref={virtuosoRef}
                className="custom-scrollbar"
                data={envelopes}
                fixedHeaderContent={(): React.ReactNode => (
                  <>
                    <tr className="table-wrapper__header-tr">
                      <th className="table-wrapper__new-th table-wrapper__th--w-3">
                        <div className="px-2">
                          <Tooltip tooltip="envelopesListActions.selectAll" redesign>
                            <label
                              htmlFor="select-all"
                              className="flex justify-start items-center w-full cursor-pointer"
                            >
                              <input
                                id="select-all"
                                data-testid="select-all"
                                className={`cursor-pointer form-checkbox litlingo-checkbox w-5 h-5 rounded-sm ${
                                  envelopesSelected?.length > 0 &&
                                  envelopesSelected?.length < envelopes.length
                                    ? 'checkbox-select-all-partial'
                                    : ''
                                }`}
                                type="checkbox"
                                checked={
                                  envelopesSelected?.length === envelopes.length &&
                                  envelopesSelected?.length > 0
                                }
                                onChange={handleSelectAll}
                              />
                            </label>
                          </Tooltip>
                        </div>
                      </th>
                      <th
                        className="table-wrapper__new-th table-wrapper__th--w-3"
                        style={{ paddingRight: '4px' }}
                      >
                        #
                      </th>
                      {isOrderingByNewParam && navParams.order_by !== 'random' && (
                        <FilterListHeaderRedesign
                          resource={resourceQueryParamName.envelopes}
                          text={
                            envelopeOrderOptions.find((o) => o.orderBy === navParams.order_by)
                              ?.label ?? ''
                          }
                          field={
                            envelopeOrderOptions.find((o) => o.orderBy === navParams.order_by)
                              ?.orderBy ?? ''
                          }
                          className="relative table-wrapper__new-th-filter table-wrapper__th--w-12 p-1"
                          closeIcon
                        />
                      )}
                      <th className="table-wrapper__new-th table-wrapper__th--w-20">Context</th>
                      <th className="table-wrapper__new-th table-wrapper__th--w-25">Model Hit</th>
                      <th className="table-wrapper__new-th table-wrapper__th--w-40">Preview</th>
                      <th className="table-wrapper__new-th table-wrapper__th--w-12">Review</th>
                    </tr>
                    {envelopesSelected?.length > 0 && (
                      <MemoizedEnvelopeListActionsRow envelopesSelected={envelopesSelected} />
                    )}
                  </>
                )}
                components={{
                  Table: EnvelopeLIstTableWrapper,
                  TableRow: MemoizedEnvelopeListRowWrapper,
                  EmptyPlaceholder: () => (
                    <EmptyEnvelopeList
                      context={{ envelopesCount: envelopes.length, loadingContent: loadingAll }}
                    />
                  ),
                }}
                itemContent={(index, summary): React.ReactNode => (
                  <MemoizedEnvelopeListRow
                    key={summary.envelope.uuid}
                    envelopeSelected={envelopesSelected?.includes(summary.envelope.uuid)}
                    data={summary}
                    handleCheck={handleCheck}
                    expanded={selectedFormat === 'expanded'}
                    envelopeIdx={index}
                  />
                )}
                atTopStateChange={(atTop): void => {
                  setIsAtTop(atTop);
                }}
                atBottomStateChange={(atBottom): void => {
                  if (atBottom && !loadingAll) {
                    handleLoadMore();
                  }
                }}
                rangeChanged={({ startIndex }): void => {
                  handleScrollSave(startIndex);
                }}
                itemsRendered={(items): void => {
                  const renderEnvelopesIds = items.map((i) => i.data?.envelope.uuid || '');
                  handleFetchFlaggedText(renderEnvelopesIds);
                }}
              />
            </main>
          </div>
        </LoadingOverlay>
      </div>

      <div
        id="load-more-section"
        className={`flex flex-row items-center justify-start w-full pl-2 relative transition-all duration-500  ${
          loadingAll && !firstLoading ? 'h-8' : 'h-0'
        }`}
      >
        {loadingAll && !firstLoading && (
          <div className="absolute mt-2 flex flex-row items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <LoadingIndicator size="6" />
          </div>
        )}
      </div>
      <BackToTopButton scrollRef={tableScrollContainerRef} extraAction={handleBackToTop} />
    </>
  );
};

export default EnvelopeListTable;
