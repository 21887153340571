import { redirectToLitlingoLogin } from 'actions';
import { OFFICE_365_ICON } from 'constants/commonIcons';
import React from 'react';
import { useDispatch } from 'react-redux';

const SignInWithOffice365Button: React.FC = () => {
  const dispatch = useDispatch();

  const handleLoginClick = (): void => {
    dispatch(redirectToLitlingoLogin({ providerHint: 'microsoft' }));
  };

  return (
    <button
      type="button"
      className="bg-litlingo-background flex justify-center items-center border border-litlingo-line py-2 w-full focus:outline-none"
      onClick={handleLoginClick}
    >
      <span className="mr-2">{OFFICE_365_ICON}</span>
      <span className="text text--medium">
        Sign in with <b>Office 365</b>
      </span>
    </button>
  );
};

export default SignInWithOffice365Button;
