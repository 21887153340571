import { fetchSingleCustomer, o365VerifyCredentials } from 'actions';
import Card from 'components/Card';
import CustomerEditO365Modal from 'components/Customer/CustomerEditO365Modal';
import CustomerEditSecretModal from 'components/Customer/CustomerEditSecretModal';
import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import Permissions from 'components/Permissions';
import { LEFT_ARROW_ICON, OK_ICON, WRONG_ICON } from 'constants/envelopeIcons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getCustomer,
  getCustomerO365VerifyCredentialsLoading,
  getCustomero365VerifyCredentials,
  getSaveCustomerLoading,
  getUser,
} from 'selectors/auth';
import { useSelector } from 'store';
import CustomerEditUploadS3 from './CustomerEditUploadS3';
import VerifyCredentialsMsg from './VerifyCredentialsMsg';

const CustomerEditOffice: React.FC = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isTenantModalOpen, setTenantModalOpen] = useState(false);
  const [isClientModalOpen, setClientModalOpen] = useState(false);
  const [currentKey, setCurrentKey] = useState('');
  const [verified, setVerified] = useState(false);
  const loading = useSelector(getCustomerO365VerifyCredentialsLoading);
  const customer = useSelector(getCustomer);
  const user = useSelector(getUser);
  const verifiedCredentials = useSelector(getCustomero365VerifyCredentials);
  const saveCustomerLoading = useSelector(getSaveCustomerLoading);
  const apiKeyName = 'o365_api';
  const officeApi = customer?.secrets_summary?.[apiKeyName];
  const currentTenantId = customer?.config?.o365_config?.tenant_id;
  const currentClientId = customer?.config?.o365_config?.client_id;

  useEffect(() => {
    if (user) {
      dispatch(fetchSingleCustomer());
    }
  }, [dispatch, user]);

  const header = (
    <div className="flex justify-between">
      <div className="pt-5 pb-12 flex items-center">
        <LinkLookup
          data-testid="customer-edit-office-left-button"
          routeName="customer"
          className="mx-6 focus:outline-none"
        >
          {LEFT_ARROW_ICON}
        </LinkLookup>
        <span className="text text--large text--bold">Office 365</span>
        {saveCustomerLoading && (
          <div className="ml-2" data-testid="loading-indicator">
            <LoadingIndicator size="5" />
          </div>
        )}
      </div>
    </div>
  );

  const apiKey = (
    <div className="px-5 py-4">
      <div className="flex justify-between">
        <div>
          <p className="text text--medium">Client Secret</p>
          <p className="text-xs">Application Client Secret in your Azure Active Directory</p>
        </div>
        <div className="flex">
          <span>******</span>
          <div className="mx-8">{officeApi ? OK_ICON : WRONG_ICON}</div>
          <button
            data-testid="office-key-edit-button"
            type="button"
            onClick={(): void => setModalOpen(true)}
            className="button button--primary button--blue-textfocus:outline-none"
          >
            {officeApi ? 'Replace' : 'Add'}
          </button>
        </div>
      </div>
    </div>
  );

  const tenantId = (
    <div className="px-5 py-4 text-sm">
      <div className="flex justify-between">
        <div>
          <p className="text text--medium">Directory (tenant) ID</p>
          <p className="text-xs">Directory (tenant) ID in your Azure Active Directory</p>
        </div>
        <div className="flex items-center">
          <span>******</span>
          <div className="mx-8">{currentTenantId ? OK_ICON : WRONG_ICON}</div>
          <button
            type="button"
            onClick={(): void => {
              setCurrentKey('tenant_id');
              setTenantModalOpen(true);
            }}
            className="button button--primary button--blue-textfocus:outline-none"
          >
            {currentTenantId ? 'Replace' : 'Add'}
          </button>
        </div>
      </div>
    </div>
  );

  const clientId = (
    <div className="px-5 py-4 text-sm">
      <div className="flex justify-between">
        <div>
          <p className="text text--medium">Application (client) ID</p>
          <p className="text-xs">Application (client) ID in your Azure Active Directory</p>
        </div>
        <div className="flex items-center">
          <span>******</span>
          <div className="mx-8">{currentClientId ? OK_ICON : WRONG_ICON}</div>
          <button
            type="button"
            onClick={(): void => {
              setCurrentKey('client_id');
              setClientModalOpen(true);
            }}
            className="button button--primary button--blue-textfocus:outline-none"
          >
            {currentClientId ? 'Replace' : 'Add'}
          </button>
        </div>
      </div>
    </div>
  );

  const handleVerifyCredentials = (): void => {
    setVerified(true);
    dispatch(o365VerifyCredentials());
  };

  const verifiedCredentialsView = (): JSX.Element => {
    const message = `Credentials${verifiedCredentials ? ' verified' : ' failed'}`;

    return (
      <Permissions action="customers.advancedOptions">
        <div className="px-5 py-4">
          <div className="flex justify-end items-center">
            {verified && !loading && (
              <VerifyCredentialsMsg ok={verifiedCredentials} message={message} />
            )}
            {loading && (
              <div className="h-8 flex items-center">
                <LoadingIndicator size="5" />
              </div>
            )}
            <button
              type="button"
              className="button button--secondary text text--medium border-litlingo-primary text-litlingo-primary ml-2"
              onClick={handleVerifyCredentials}
            >
              Test credentials
            </button>
          </div>
        </div>
      </Permissions>
    );
  };

  return (
    <div className="litlingo-gray-bg-color-4">
      <Navbar />

      <div
        className="flex flex-row overflow-auto"
        style={{
          minHeight: 'calc(100vh - 4rem)',
          height: 'calc(100vh - 4rem)',
        }}
      >
        <SettingSidebar />
        <div className="mt-0 mx-auto min-w-2xl w-8/12 max-w-6xl text-left">
          <Card
            items={[
              header,
              clientId,
              tenantId,
              apiKey,
              ...(user.roles.includes('super-admin') ? [verifiedCredentialsView()] : []),
              <CustomerEditUploadS3 key="o365" provider="o365" />,
            ]}
          />
          {isModalOpen && (
            <CustomerEditSecretModal
              secretKeyName={apiKeyName}
              toggleShowModal={(): void => setModalOpen(!isModalOpen)}
            />
          )}

          {isTenantModalOpen && (
            <CustomerEditO365Modal
              currentKey={currentKey}
              customerConfig={customer.config}
              toggleShowModal={(): void => setTenantModalOpen(!isTenantModalOpen)}
              title="Insert directory (tenant) ID"
            />
          )}

          {isClientModalOpen && (
            <CustomerEditO365Modal
              currentKey={currentKey}
              customerConfig={customer.config}
              toggleShowModal={(): void => setClientModalOpen(!isClientModalOpen)}
              title="Insert application (client) ID"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerEditOffice;
