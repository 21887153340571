import { createSelector } from '@reduxjs/toolkit';
import { ModalAfterReview } from 'actions';
import { SummaryTagType } from 'components/Envelope/SummaryTag';
import { GlobalState } from 'reducers';
import { EnvelopeReviewRule, SectionType } from 'reducers/envelopeReview';
import { getSurroundingContextRulesIds } from 'selectors/auth';
import type {
  Attachment,
  Communication,
  CommunicationEnvelope,
  CommunicationEnvelopeSummary,
  CommunicationWithEvents,
  EntityTag,
  Event,
  Selector,
  UUID,
} from 'types';
import { TERMINAL_ACTIONS } from 'utils/activityLogLookup';
import sortCommunications from 'utils/communications';

export const TRANSLATION_COMM_TYPES = [
  'translation',
  'email_translation',
  'translation_translation',
  'o365_translation',
  'direct_translation',
];

export const ATTACHMENT_TRANSLATED_TYPES = ['attachment_translation'];
export const ATTACHMENT_TYPES = ['attachment', 'attachment_translation'];

const getSelectedEnvelope: Selector<CommunicationEnvelope | null> = (state) =>
  state.envelopeReview?.selectedEnvelope;

export const getSelectedRule: Selector<EnvelopeReviewRule | null> = (state) =>
  state.envelopeReview?.selectedRule;

export const getSelectedCampaign: Selector<string | null> = (state) =>
  state.envelopeReview?.selectedCampaign;

export const getTags: Selector<EntityTag[] | null> = (state) => state.envelopeReview?.tags;

export const getDeclaredStatus: Selector<string | null> = (state) =>
  state.envelopeReview?.declaredStatus;

export const getSelectedReviewer: Selector<string | null> = (state) =>
  state.envelopeReview.selectedReviewer;

export const getCodingReviewStatus: Selector<boolean> = (state) =>
  state.envelopeReview.codingReview;

export const getBulkReviewStatus: Selector<boolean> = (state) => state.envelopeReview.bulkReview;

export const getSelectedThreads: Selector<UUID[]> = (state) => state.envelopeReview.selectedThreads;

export const getModalAfterReview: Selector<ModalAfterReview> = (state) =>
  state.envelopeReview.modalAfterReview;

export const getHasChanges: Selector<boolean> = (state) => {
  const envelope = state.envelopeReview.selectedEnvelope;
  const { tags } = state.envelopeReview;
  const newReviewValue = state.envelopeReview.declaredStatus;

  if (!envelope) return false;

  if (tags.length !== envelope?.tags?.length) return true;

  const differentTags = !tags.every((tag) =>
    envelope?.tags?.some((t) => t.tag_value?.value === tag.tag_value?.value)
  );

  if (differentTags) return true;

  if (newReviewValue !== envelope.review_value) return true;

  return false;
};

export const getBulkReviewHasChanges: Selector<boolean> = (state) => {
  const envelope = state.envelopeReview.selectedEnvelope;
  const threads = state.envelopes.envelopeThread;

  const reviewValuesThread = Object.values(threads).map((env) => env.envelope.review_value);

  const { tags } = state.envelopeReview;
  const newReviewValue = state.envelopeReview.declaredStatus;

  if (tags.length !== envelope?.tags?.length) return true;

  const differentTags = !tags.every((tag) =>
    envelope?.tags?.some((t) => t.tag_value?.value === tag.tag_value?.value)
  );

  if (differentTags) return true;

  if (newReviewValue !== envelope.review_value) return true;

  if (reviewValuesThread.some((status) => status !== newReviewValue)) return true;

  return false;
};

export const getEnvelopeCommunications: Selector<Communication[] | undefined> = (state) =>
  state.envelopeReview.selectedEnvelope?.communications;

export const getEnvelopeEvents: Selector<Event[] | undefined> = (state) =>
  state.envelopeReview.selectedEnvelope?.events;

export const getCommunicationsWithEvents: Selector<CommunicationWithEvents[] | null> =
  createSelector([getEnvelopeCommunications, getEnvelopeEvents], (communications, events) => {
    if (!events || !communications) return null;

    const comms = communications
      .filter((c) => c.communication_type !== 'attachment')
      .filter((c) => !TRANSLATION_COMM_TYPES.includes(c.communication_type))
      .map((c) => {
        let evs: Event[] | null = [];
        evs = events.filter((e) => !e.hidden).filter((e) => c.uuid === e.communication_uuid);
        return {
          ...c,
          events: evs,
        };
      })
      .sort((a, b): number => sortCommunications(a, b, false));

    return comms;
  });

export const getEnvelopeVersions: Selector<Communication[]> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
      state.envelopeReview.selectedEnvelope,
  ],
  (envelope) => {
    if (envelope?.communications) {
      return envelope.communications
        .filter(
          (c) =>
            c.communication_type !== 'attachment' &&
            !ATTACHMENT_TRANSLATED_TYPES.includes(c.communication_type) &&
            !TRANSLATION_COMM_TYPES.includes(c.communication_type)
        )
        .sort(sortCommunications);
    }

    return [];
  }
);

export const getLastVersion: Selector<Communication | null> = createSelector(
  [getEnvelopeVersions],
  (communication) => communication[0]
);

export const getEnvelopeTranslations: Selector<Communication[]> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
      state.envelopeReview.selectedEnvelope,
  ],
  (envelope) => {
    if (envelope?.communications) {
      return envelope.communications
        .filter((c) => TRANSLATION_COMM_TYPES.includes(c.communication_type))
        .sort((a, b) => sortCommunications(a, b, true))
        .slice(0, 1);
    }
    return [];
  }
);

export const getAttachmentTranslations: Selector<Communication[]> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
      state.envelopeReview.selectedEnvelope,
  ],
  (envelope) => {
    if (envelope?.communications) {
      return envelope.communications.filter((c) =>
        ATTACHMENT_TRANSLATED_TYPES.includes(c.communication_type)
      );
    }
    return [];
  }
);

export const getSelectedEnvelopeAttachments: Selector<Attachment[]> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopeReview']['selectedEnvelope'] =>
      state.envelopeReview.selectedEnvelope,
  ],
  (envelope) => {
    if (envelope?.attachments) {
      return envelope.attachments;
    }

    return [];
  }
);

export const getCommunicationFromAttachment: Selector<Communication | null, [string]> =
  createSelector(
    [getEnvelopeCommunications, (_state, attachmentName): string => attachmentName],
    (envelopeComms, attachmentName) => {
      if (envelopeComms) {
        const commAttachment = envelopeComms.filter((c) => {
          if (
            c.meta_data?.filename?.split('/').pop() === attachmentName &&
            c.communication_type === 'attachment'
          ) {
            return true;
          }
          return false;
        });
        if (commAttachment && commAttachment[0]) {
          return commAttachment[0];
        }
      }

      return null;
    }
  );

export const getCommunicationFromId: Selector<Communication | null, [string]> = createSelector(
  [getEnvelopeCommunications, (_state, communicationId): string => communicationId],
  (envelopeComms, communicationId) => {
    if (envelopeComms) {
      const communications = envelopeComms.filter((c) => {
        if (c.uuid === communicationId) {
          return true;
        }
        return false;
      });
      if (communications && communications[0]) {
        return communications[0];
      }
    }

    return null;
  }
);

export const getOriginalCommunication: Selector<Communication | null, [string]> = createSelector(
  [getEnvelopeCommunications, (_state, parentId): string => parentId],
  (envelopeComms, parentId) => {
    if (envelopeComms) {
      const originalComm = envelopeComms.filter((c) => c.uuid === parentId);
      return originalComm[0];
    }
    return null;
  }
);

export const getSelectedCommunicationWithEvents: Selector<CommunicationWithEvents | null> = (
  state
) => {
  const envelope = state.envelopeReview.selectedEnvelope;
  const communication = state.communication.selectedCommunication;

  if (!envelope || !envelope.events || !communication) return null;

  const communicationEvents = envelope.events.filter(
    (e) => communication.uuid === e.communication_uuid
  );

  return { ...communication, events: communicationEvents };
};

export const getTranslationCommunication: Selector<Communication | null | undefined, [string]> = (
  state,
  attachment
) => {
  const commUuid = state.communication.selectedCommunication?.uuid;
  const envelopeComms = state.envelopeReview.selectedEnvelope?.communications;

  if (commUuid && envelopeComms) {
    const tranlatedComm = envelopeComms.filter(
      (c) => c.meta_data.parent_communication_uuid === commUuid
    );
    if (tranlatedComm) {
      if (attachment) {
        return tranlatedComm.find((c) => c.communication_type === 'attachment_translation');
      }
      if (!attachment) {
        return tranlatedComm.find((c) => TRANSLATION_COMM_TYPES.includes(c.communication_type));
      }
    }
    return null;
  }
  return null;
};

export const getTranslationFromCommunication: Selector<
  Communication | null | undefined,
  [string]
> = (state, uuid) => {
  const envelopeComms = state.envelopeReview.selectedEnvelope?.communications;

  if (uuid && envelopeComms) {
    const tranlatedComm = envelopeComms.filter(
      (c) => c.meta_data.parent_communication_uuid === uuid
    );
    if (tranlatedComm) {
      return tranlatedComm.find((c) => TRANSLATION_COMM_TYPES.includes(c.communication_type));
    }
    return null;
  }
  return null;
};

export const getCommunicationTranslationFromAttachment: Selector<Communication | null, [string]> =
  createSelector(
    [getEnvelopeCommunications, (_state, attachmentName): string => attachmentName],
    (envelopeComms, attachmentName) => {
      if (envelopeComms) {
        const commAttachment = envelopeComms.filter((c) => {
          if (
            c.meta_data?.filename?.split('/').pop() === attachmentName &&
            c.communication_type === 'attachment_translation'
          ) {
            return true;
          }
          return false;
        });
        if (commAttachment && commAttachment[0]) {
          return commAttachment[0];
        }
      }

      return null;
    }
  );

export const getEnvelopeHasAttachmentHit: Selector<boolean> = (state) => {
  const events = state.envelopeReview.selectedEnvelope?.events;
  const communications = state.envelopeReview.selectedEnvelope?.communications;

  if (!events) return false;
  if (!communications) return false;

  return events.some((e) =>
    communications.some(
      (c) => e.communication_uuid === c.uuid && c.communication_type === 'attachment'
    )
  );
};

export const getIsAttachmentHit: Selector<boolean, [UUID]> = (state, ruleId) => {
  const events = state.envelopeReview.selectedEnvelope?.events;
  const communications = state.envelopeReview.selectedEnvelope?.communications;

  if (!events) return false;
  if (!communications) return false;

  const ruleEvents = events.filter((e) => e.rule_uuid === ruleId);

  return ruleEvents.some((e) =>
    communications.some(
      (c) => e.communication_uuid === c.uuid && c.communication_type === 'attachment'
    )
  );
};

export const getIsMessageHit: Selector<boolean, [UUID]> = (state, ruleId) => {
  const events = state.envelopeReview.selectedEnvelope?.events;
  const communications = state.envelopeReview.selectedEnvelope?.communications;

  if (!events) return false;
  if (!communications) return false;

  const ruleEvents = events.filter((e) => e.rule_uuid === ruleId);

  return ruleEvents.some((e) =>
    communications.some(
      (c) => e.communication_uuid === c.uuid && c.communication_type !== 'attachment'
    )
  );
};

export const getRuleHits: Selector<
  Record<string, { attachmentHit: boolean; messageHit: boolean }>
> = createSelector([getEnvelopeCommunications, getEnvelopeEvents], (communications, events) => {
  if (!communications) return {};
  if (!events) return {};

  const hits: Record<string, { attachmentHit: boolean; messageHit: boolean }> = {};

  events.forEach((e) => {
    hits[e.rule_uuid] = {
      attachmentHit: false,
      messageHit: false,
    };
  });

  Object.keys(hits).forEach((r) => {
    const ruleEvents = events.filter((e) => e.rule_uuid === r);

    if (
      ruleEvents.some((e) =>
        communications.some(
          (c) => e.communication_uuid === c.uuid && c.communication_type !== 'attachment'
        )
      )
    ) {
      hits[r].messageHit = true;
    }
    if (
      ruleEvents.some((e) =>
        communications.some(
          (c) => e.communication_uuid === c.uuid && c.communication_type === 'attachment'
        )
      )
    ) {
      hits[r].attachmentHit = true;
    }
  });

  return hits;
});

export type NormalizedCampaignWithRules = {
  [key: string]: CampaignWithRules;
};

export type CampaignWithRules = {
  campaignName: string;
  campaignPriority: number;
  rules: (EnvelopeReviewRule & { attachmentHit: boolean; messageHit: boolean })[];
};

export const getCampaignsRules: Selector<NormalizedCampaignWithRules | null> = createSelector(
  [getEnvelopeEvents, getSurroundingContextRulesIds, getEnvelopeCommunications, getRuleHits],
  (events, excludedRules, envelopeComms, ruleHits) => {
    const campaigns: NormalizedCampaignWithRules = {};

    if (!events) return null;

    const nonHiddenEvents = events
      .filter((e) => e.hidden === false)
      .filter(
        (e) =>
          envelopeComms?.find((c) => c.uuid === e.communication_uuid)?.communication_type !==
          'attachment'
      )
      .sort((a, b) => {
        if (a.campaign && b.campaign) {
          if (a.campaign?.name < b.campaign?.name) {
            return -1;
          }
          if (a.campaign?.name > b.campaign?.name) {
            return 1;
          }
        }
        return 0;
      });

    nonHiddenEvents.forEach((e) => {
      if (e.campaign_uuid && e.campaign) {
        campaigns[e.campaign_uuid] = {
          campaignName: e.campaign.name,
          campaignPriority: e.campaign.priority || 0,
          rules: [],
        };
      }
    });

    nonHiddenEvents.forEach((e) => {
      if (e.campaign_uuid) {
        if (e.rule_uuid && e.rule) {
          if (
            !campaigns[e.campaign_uuid].rules.some((r) => r.uuid === e.rule?.uuid) &&
            !excludedRules.includes(e.rule_uuid)
          ) {
            const ruleToAdd = {
              ...e.rule,
              attachmentHit: ruleHits[e.rule_uuid].attachmentHit || false,
              messageHit: ruleHits[e.rule_uuid].messageHit || false,
            };

            campaigns[e.campaign_uuid].rules.push(ruleToAdd);
          }
        }
      }
    });

    Object.entries(campaigns).forEach(
      ([key, value]) => value.rules.length === 0 && delete campaigns[key]
    );

    return campaigns;
  }
);

export const getCampaignsRulesFromAttachment: Selector<
  NormalizedCampaignWithRules | null,
  [string]
> = createSelector(
  [
    getEnvelopeEvents,
    getSurroundingContextRulesIds,
    getRuleHits,
    (state: GlobalState, attachmentName: string): Communication | null =>
      getCommunicationFromAttachment(state, attachmentName),
  ],
  (events, excludedRules, ruleHits, communication) => {
    const campaigns: NormalizedCampaignWithRules = {};

    if (!events || !communication) return null;

    const nonHiddenEvents = events
      .filter((e) => e.hidden === false)
      .filter((e) => e.communication_uuid === communication.uuid)
      .sort((a, b) => {
        if (a.campaign && b.campaign) {
          if (a.campaign?.name < b.campaign?.name) {
            return -1;
          }
          if (a.campaign?.name > b.campaign?.name) {
            return 1;
          }
        }
        return 0;
      });

    nonHiddenEvents.forEach((e) => {
      if (e.campaign_uuid && e.campaign) {
        campaigns[e.campaign_uuid] = {
          campaignName: e.campaign.name,
          campaignPriority: e.campaign.priority || 0,
          rules: [],
        };
      }
    });

    nonHiddenEvents.forEach((e) => {
      if (e.campaign_uuid) {
        if (e.rule_uuid && e.rule) {
          if (
            !campaigns[e.campaign_uuid].rules.some((r) => r.uuid === e.rule?.uuid) &&
            !excludedRules.includes(e.rule_uuid)
          ) {
            const ruleToAdd = {
              ...e.rule,
              attachmentHit: ruleHits[e.rule_uuid].attachmentHit || false,
              messageHit: ruleHits[e.rule_uuid].messageHit || false,
            };

            campaigns[e.campaign_uuid].rules.push(ruleToAdd);
          }
        }
      }
    });

    Object.entries(campaigns).forEach(
      ([key, value]) => value.rules.length === 0 && delete campaigns[key]
    );

    return campaigns;
  }
);

export const getCampaignsRulesFromCommunication: Selector<
  NormalizedCampaignWithRules | null,
  [string]
> = createSelector(
  [
    getEnvelopeEvents,
    getSurroundingContextRulesIds,
    getRuleHits,
    (_state: GlobalState, communicationId: string): string | null => communicationId,
  ],
  (events, excludedRules, ruleHits, communicationId) => {
    const campaigns: NormalizedCampaignWithRules = {};

    if (!events || !communicationId) return null;

    const nonHiddenEvents = events
      .filter((e) => e.hidden === false)
      .filter((e) => e.communication_uuid === communicationId)
      .sort((a, b) => {
        if (a.campaign && b.campaign) {
          if (a.campaign?.name < b.campaign?.name) {
            return -1;
          }
          if (a.campaign?.name > b.campaign?.name) {
            return 1;
          }
        }
        return 0;
      });

    nonHiddenEvents.forEach((e) => {
      if (e.campaign_uuid && e.campaign) {
        campaigns[e.campaign_uuid] = {
          campaignName: e.campaign.name,
          campaignPriority: e.campaign.priority || 0,
          rules: [],
        };
      }
    });

    nonHiddenEvents.forEach((e) => {
      if (e.campaign_uuid) {
        if (e.rule_uuid && e.rule) {
          if (
            !campaigns[e.campaign_uuid].rules.some((r) => r.uuid === e.rule?.uuid) &&
            !excludedRules.includes(e.rule_uuid)
          ) {
            const ruleToAdd = {
              ...e.rule,
              attachmentHit: ruleHits[e.rule_uuid].attachmentHit || false,
              messageHit: ruleHits[e.rule_uuid].messageHit || false,
            };

            campaigns[e.campaign_uuid].rules.push(ruleToAdd);
          }
        }
      }
    });

    Object.entries(campaigns).forEach(
      ([key, value]) => value.rules.length === 0 && delete campaigns[key]
    );

    return campaigns;
  }
);

export const getHighRiskCampaignsRulesFromCommunication: Selector<
  NormalizedCampaignWithRules | null,
  [string]
> = createSelector(
  [
    getEnvelopeEvents,
    getSurroundingContextRulesIds,
    getRuleHits,
    (_state: GlobalState, communicationId: string): string | null => communicationId,
  ],
  (events, excludedRules, ruleHits, communicationId) => {
    const campaigns: NormalizedCampaignWithRules = {};

    if (!events || !communicationId) return null;

    const nonHiddenEvents = events
      .filter((e) => e.hidden === false)
      .filter((e) => e.communication_uuid === communicationId)
      .filter((e) =>
        e.actions?.some(
          (a) =>
            a.type === 'show_modal' ||
            // @ts-ignore
            a.kind === 'show_modal' ||
            a.type === 'o365_teams_tombstone' ||
            // @ts-ignore
            a.kind === 'o365_teams_tombstone'
        )
      )
      .sort((a, b) => {
        if (a.campaign && b.campaign) {
          if (a.campaign?.name < b.campaign?.name) {
            return -1;
          }
          if (a.campaign?.name > b.campaign?.name) {
            return 1;
          }
        }
        return 0;
      });

    nonHiddenEvents.forEach((e) => {
      if (e.campaign_uuid && e.campaign) {
        campaigns[e.campaign_uuid] = {
          campaignName: e.campaign.name,
          campaignPriority: e.campaign.priority || 0,
          rules: [],
        };
      }
    });

    nonHiddenEvents.forEach((e) => {
      if (e.campaign_uuid) {
        if (e.rule_uuid && e.rule) {
          if (
            !campaigns[e.campaign_uuid].rules.some((r) => r.uuid === e.rule?.uuid) &&
            !excludedRules.includes(e.rule_uuid)
          ) {
            const ruleToAdd = {
              ...e.rule,
              attachmentHit: ruleHits[e.rule_uuid].attachmentHit || false,
              messageHit: ruleHits[e.rule_uuid].messageHit || false,
            };

            campaigns[e.campaign_uuid].rules.push(ruleToAdd);
          }
        }
      }
    });

    Object.entries(campaigns).forEach(
      ([key, value]) => value.rules.length === 0 && delete campaigns[key]
    );

    return campaigns;
  }
);

export const getCampaignsRulesForHistory: Selector<NormalizedCampaignWithRules | null> =
  createSelector(
    [getEnvelopeEvents, getSurroundingContextRulesIds, getRuleHits],
    (events, excludedRules, ruleHits) => {
      const campaigns: NormalizedCampaignWithRules = {};

      if (!events) return null;

      const nonHiddenEvents = events
        .filter((e) => e.hidden === false)
        .filter((e) => e.communication?.communication_type !== 'attachment')
        .filter((e) => {
          if (
            e &&
            e.communication &&
            e.communication?.meta_data &&
            'source' in (e.communication?.meta_data as Record<string, unknown>)
          ) {
            return true;
          }
          return e.actions?.some(
            (a) =>
              a.type === 'show_modal' ||
              // @ts-ignore
              a.kind === 'show_modal' ||
              a.type === 'o365_teams_tombstone'
          );
        })
        .sort((a, b) => {
          if (a.campaign && b.campaign) {
            if (a.campaign?.name < b.campaign?.name) {
              return -1;
            }
            if (a.campaign?.name > b.campaign?.name) {
              return 1;
            }
          }
          return 0;
        });

      nonHiddenEvents.forEach((e) => {
        if (e.campaign_uuid && e.campaign) {
          campaigns[e.campaign_uuid] = {
            campaignName: e.campaign.name,
            campaignPriority: e.campaign.priority || 0,
            rules: [],
          };
        }
      });

      nonHiddenEvents.forEach((e) => {
        if (e.campaign_uuid) {
          if (e.rule_uuid && e.rule) {
            if (
              !campaigns[e.campaign_uuid].rules.some((r) => r.uuid === e.rule?.uuid) &&
              !excludedRules.includes(e.rule_uuid)
            ) {
              const ruleToAdd = {
                ...e.rule,
                attachmentHit: ruleHits[e.rule_uuid].attachmentHit || false,
                messageHit: ruleHits[e.rule_uuid].messageHit || false,
              };

              campaigns[e.campaign_uuid].rules.push(ruleToAdd);
            }
          }
        }
      });

      Object.entries(campaigns).forEach(
        ([key, value]) => value.rules.length === 0 && delete campaigns[key]
      );

      return campaigns;
    }
  );

export const getCampaignRulesFromSummaries: Selector<NormalizedCampaignWithRules | null> =
  createSelector(
    [
      (state: GlobalState): GlobalState['envelopes']['envelopeThread'] =>
        state.envelopes.envelopeThread,
      getRuleHits,
      getSelectedEnvelope,
      getSurroundingContextRulesIds,
    ],
    (threads, ruleHits, selectedEnvelope, excludedRules) => {
      const campaigns: NormalizedCampaignWithRules = {};
      let events: CommunicationEnvelopeSummary['events'] = [];

      if (Object.values(threads).length <= 1) return null;

      Object.values(threads)
        .filter(
          (thread) =>
            thread.envelope.platform_thread_guid === selectedEnvelope?.platform_thread_guid
        )
        .forEach((envelope) => {
          events = [...events, ...envelope.events];
        });

      if (!events) return null;

      const nonHiddenEvents = events.sort((a, b) => {
        if (a.campaign_name && b.campaign_name) {
          if (a.campaign_name < b.campaign_name) {
            return -1;
          }
          if (a.campaign_name > b.campaign_name) {
            return 1;
          }
        }
        return 0;
      });

      nonHiddenEvents.forEach((e) => {
        if (e.campaign_uuid && e.campaign_name) {
          campaigns[e.campaign_uuid] = {
            campaignName: e.campaign_name,
            campaignPriority: e.campaign_priority || 0,
            rules: [],
          };
        }
      });

      nonHiddenEvents.forEach((e) => {
        if (e.campaign_uuid) {
          if (e.rule_uuid && e.rule_name) {
            if (
              !campaigns[e.campaign_uuid].rules.some((r) => r.uuid === e.rule_uuid) &&
              !excludedRules.includes(e.rule_uuid)
            ) {
              const ruleToAdd = {
                name: e.rule_name,
                uuid: e.rule_uuid,
                attachmentHit: ruleHits[e.rule_uuid]?.attachmentHit || false,
                messageHit: ruleHits[e.rule_uuid]?.messageHit || false,
              };

              campaigns[e.campaign_uuid].rules.push(ruleToAdd);
            }
          }
        }
      });

      Object.entries(campaigns).forEach(
        ([key, value]) => value.rules.length === 0 && delete campaigns[key]
      );

      return campaigns;
    }
  );

export const getBackButtonHitted: Selector<boolean> = (state) => state.envelopeReview.backButtonHit;

export const getSelectedSection: Selector<SectionType> = (state) =>
  state.envelopeReview.selectedSection;

export const getReviewOnSkipView: Selector<boolean> = (state) =>
  state.envelopeReview.reviewOnSkipView;

export const getSummaryTag: Selector<SummaryTagType, [UUID]> = createSelector(
  [getSelectedEnvelope, getCampaignsRulesForHistory, getHighRiskCampaignsRulesFromCommunication],
  (envelope, historyCampaignsRules, lastCampaignsRules) => {
    if (envelope?.communications?.length === 1) return 'none';

    if (envelope && envelope.platform !== 'o365_teams') {
      if (
        !envelope.action_summary?.some(
          (a) =>
            Object.keys(TERMINAL_ACTIONS).includes(a.type) ||
            // @ts-ignore
            Object.keys(TERMINAL_ACTIONS).includes(a.kind)
        ) &&
        envelope.integration_type === 'app'
      ) {
        return 'abandoned';
      }
    }

    if (!lastCampaignsRules) return 'reduced-all';

    if (historyCampaignsRules && lastCampaignsRules) {
      let historyRules = Object.values(historyCampaignsRules).reduce<string[]>(
        (acc, val) => [...acc, ...val.rules.map((r) => r.uuid)],
        []
      );
      historyRules = [...new Set(historyRules)];

      let lastRules = Object.values(lastCampaignsRules).reduce<string[]>(
        (acc, val) => [...acc, ...val.rules.map((r) => r.uuid)],
        []
      );
      lastRules = [...new Set(lastRules)];

      if (lastRules.length === 0) return 'reduced-all';
      if (lastRules.length < historyRules.length) return 'reduced-some';
      if (historyRules.some((r) => !lastRules.includes(r))) return 'reduced-some';
    }

    return 'high-risk';
  }
);

export const getRuleFlips: Selector<number, [UUID]> = createSelector(
  [getCampaignsRulesForHistory, getHighRiskCampaignsRulesFromCommunication],
  (historyCampaignsRules, lastCampaignsRules) => {
    if (historyCampaignsRules && lastCampaignsRules) {
      let historyRules = Object.values(historyCampaignsRules).reduce<string[]>(
        (acc, val) => [...acc, ...val.rules.map((r) => r.uuid)],
        []
      );
      historyRules = [...new Set(historyRules)];

      let lastRules = Object.values(lastCampaignsRules).reduce<string[]>(
        (acc, val) => [...acc, ...val.rules.map((r) => r.uuid)],
        []
      );
      lastRules = [...new Set(lastRules)];

      return historyRules.length - lastRules.length;
    }
    return 0;
  }
);

export default getSelectedEnvelope;
