/* eslint-disable camelcase */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import { fetchCommThreadContextSuccess, FetchCommThreadContextSuccessPayload } from 'actions';
import {
  clearTestSentence,
  evaluateTestSentenceFailure,
  fetchAllCommunicationDocumentFailure,
  fetchAllCommunicationDocumentRequest,
  fetchAllCommunicationDocumentSuccess,
  fetchAttachmentsFailure,
  fetchAttachmentsFulfill,
  fetchAttachmentsRequest,
  fetchAttachmentsSuccess,
  fetchCommunicationDocumentRequest,
  fetchCommunicationDocumentSuccess,
  FetchCommunicationDocumentSuccessPayload,
  fetchCommunicationSummaryFailure,
  fetchCommunicationSummaryRequest,
  fetchCommunicationSummarySuccess,
  selectCommunication,
  selectCommunicationType,
  selectTranslation,
  testSentenceFailure,
  testSentenceRequest,
  testSentenceSuccess,
  toggleShowV2Graph,
} from 'actions/communication';
import type {
  Attachment,
  Communication,
  CommunicationSummary,
  ErrorObject,
  Event,
  NormalizedResource,
  TestCommunication,
} from 'types';

type CommunicationState = {
  loading: string[];
  error: ErrorObject | null;
  test: TestCommunication | null;
  items: NormalizedResource<CommunicationSummary>;
  communications: NormalizedResource<Communication>;
  selectedCommunication: (Communication & { events?: Event[]; attachments?: Attachment[] }) | null;
  selectedTranslation: (Communication & { events?: Event[]; attachments?: Attachment[] }) | null;
  showV2: boolean;
};

type CommunicationReducer<P = void> = (
  state: CommunicationState,
  action: PayloadAction<P>
) => CommunicationState;

const defaultState: CommunicationState = {
  test: null,
  loading: [],
  error: null,
  items: {},
  communications: {},
  selectedCommunication: null,
  selectedTranslation: null,
  showV2: true,
};

const handleTestSentenceRequest: CommunicationReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, testSentenceRequest.toString()],
});

const handleTestSentenceSuccess: CommunicationReducer<TestCommunication> = (
  state,
  { payload }
) => ({
  ...state,
  test: { ...payload },
  loading: state.loading.filter((s) => s !== testSentenceRequest.toString()),
});

const handleTestSentenceFailure: CommunicationReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== testSentenceRequest.toString()),
});

const handleEvaluateTestSentenceFailure: CommunicationReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
});

const handleClearTestSentence: CommunicationReducer = (state) => ({
  ...state,
  test: null,
});

const handleFetchCommunicationSummaryRequest: CommunicationReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchCommunicationSummaryRequest.toString()],
});

const handleFetchCommunicationSummarySuccess: CommunicationReducer<CommunicationSummary> = (
  state,
  { payload }
) => ({
  ...state,
  items: {
    ...state.items,
    [payload.uuid]: payload,
  },
  loading: state.loading.filter((s) => s !== fetchCommunicationSummaryRequest.toString()),
});

const handleFetchCommunicationSummaryFailure: CommunicationReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchCommunicationSummaryRequest.toString()),
});

const handleSelectCommunication: CommunicationReducer<selectCommunicationType> = (
  state,
  { payload }
) => ({
  ...state,
  selectedCommunication: payload.communication,
});

const handleSelectTranslation: CommunicationReducer<selectCommunicationType> = (
  state,
  { payload }
) => ({
  ...state,
  selectedTranslation: payload.communication,
});

const handleFetchAttachmentsRequest: CommunicationReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAttachmentsRequest.toString()],
});

const handleFetchAttachmentsSuccess: CommunicationReducer<Attachment[]> = (state, { payload }) => {
  if (!state.selectedCommunication) return state;

  const selectedCommunication = { ...state.selectedCommunication, attachments: payload };

  return {
    ...state,
    selectedCommunication,
  };
};

const handleFetchAttachmentsFailure: CommunicationReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleFetchAttachmentsFulfill: CommunicationReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchAttachmentsRequest.toString()),
});

const handleFetchCommThreadContextSuccess: CommunicationReducer<
  FetchCommThreadContextSuccessPayload
> = (state, { payload }) => {
  const { context } = payload;
  return {
    ...state,
    selectedCommunication: { ...state.selectedCommunication, context } as Communication,
  };
};

const handleToggleShowV2Graph: CommunicationReducer = (state) => ({
  ...state,
  showV2: !state.showV2,
});

const handleFetchCommunicationDocumentRequest: CommunicationReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchCommunicationDocumentRequest.toString()],
});

const handleFetchCommunicationDocumentSuccess: CommunicationReducer<
  FetchCommunicationDocumentSuccessPayload
> = (state, { payload }) => {
  const { communication, document } = payload;

  const newCommunications = {
    ...state.communications,
    [communication.uuid]: {
      ...state.communications[communication.uuid],
      ...communication,
      document,
    },
  };

  return {
    ...state,
    communications: newCommunications,
    loading: state.loading.filter((s) => s !== fetchCommunicationDocumentRequest.toString()),
  };
};

const handleFetchAllCommunicationDocumentRequest: CommunicationReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAllCommunicationDocumentRequest.toString()],
});

const handleFetchAllCommunicationDocumentSuccess: CommunicationReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchAllCommunicationDocumentRequest.toString()),
});

const handleFetchAllCommunicationDocumentFailure: CommunicationReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchAllCommunicationDocumentRequest.toString()),
});

const handlers = {
  [testSentenceRequest.toString()]: handleTestSentenceRequest,
  [testSentenceSuccess.toString()]: handleTestSentenceSuccess,
  [testSentenceFailure.toString()]: handleTestSentenceFailure,
  [clearTestSentence.toString()]: handleClearTestSentence,
  [evaluateTestSentenceFailure.toString()]: handleEvaluateTestSentenceFailure,
  [fetchCommunicationSummaryRequest.toString()]: handleFetchCommunicationSummaryRequest,
  [fetchCommunicationSummarySuccess.toString()]: handleFetchCommunicationSummarySuccess,
  [fetchCommunicationSummaryFailure.toString()]: handleFetchCommunicationSummaryFailure,
  [fetchAttachmentsRequest.toString()]: handleFetchAttachmentsRequest,
  [fetchAttachmentsSuccess.toString()]: handleFetchAttachmentsSuccess,
  [fetchAttachmentsFailure.toString()]: handleFetchAttachmentsFailure,
  [fetchAttachmentsFulfill.toString()]: handleFetchAttachmentsFulfill,
  [selectCommunication.toString()]: handleSelectCommunication,
  [selectTranslation.toString()]: handleSelectTranslation,
  [fetchCommThreadContextSuccess.toString()]: handleFetchCommThreadContextSuccess,
  [toggleShowV2Graph.toString()]: handleToggleShowV2Graph,

  [fetchCommunicationDocumentRequest.toString()]: handleFetchCommunicationDocumentRequest,
  [fetchCommunicationDocumentSuccess.toString()]: handleFetchCommunicationDocumentSuccess,
  [fetchAllCommunicationDocumentRequest.toString()]: handleFetchAllCommunicationDocumentRequest,
  [fetchAllCommunicationDocumentSuccess.toString()]: handleFetchAllCommunicationDocumentSuccess,
  [fetchAllCommunicationDocumentFailure.toString()]: handleFetchAllCommunicationDocumentFailure,
};

const communicationReducer = createReducer(defaultState, handlers);

export default communicationReducer;
