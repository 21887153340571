import React from 'react';
import { v5 as uuidv5 } from 'uuid';

type ComponentProps = {
  title?: string;
  items: (JSX.Element | JSX.Element[])[];
  className?: string;
};

const Card: React.FC<ComponentProps> = ({ title, items, className }) => (
  <div className={`${className} flex flex-col gap-4`}>
    <p className="text-menu-item">{title}</p>
    <div className="flex flex-col bg-white border border-litlingo-gray-2 rounded">
      {items.map((item, idx) => (
        <React.Fragment key={uuidv5(idx.toString(), '6ba7b812-9dad-11d1-80b4-00c04fd430c8')}>
          {idx > 0 && <span className="border-t border-litlingo-gray-2" />}
          {item}
        </React.Fragment>
      ))}
    </div>
  </div>
);

export default Card;
