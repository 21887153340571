import { deleteModel, upsertModel } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import Modal from 'components/Modal';
import SelectRedesign from 'components/Select/SelectRedesign';
import { typeOptionsMap } from 'constants/models';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { MModel } from 'types';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
  modelToEdit?: MModel;
  isCreating?: boolean;
};

const EditModelModal: React.FC<ComponentProps> = ({
  isModalOpen,
  toggleModalOpen,
  modelToEdit,
  isCreating,
}) => {
  const dispatch = useDispatch();
  const model = modelToEdit;

  const [isDeletingModel, setIsDeletingModel] = useState(false);
  const [type, setType] = useState(model?.kind || '');

  const options = Object.entries(typeOptionsMap).map(([key, value]) => ({
    value: key,
    label: value,
  }));

  const { register, getValues, handleSubmit } = useForm<{
    name: string;
    description: string;
    priority: number;
    active: string;
  }>({
    defaultValues: {
      name: model?.name,
      description: model?.description || '',
      priority: model?.priority || 1,
    },
  });

  const deleteButtonStyle = 'text-litlingo-alert underline self-end focus:outline-none';

  const onSubmit = (): void => {
    const { name, description, ...rest } = getValues();

    dispatch(
      upsertModel({
        ...model,
        name,
        description,
        redirect: isCreating,
        kind: type,
        ...rest,
      })
    );
    toggleModalOpen();
  };

  const handleSaveButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const toggleConfirmModal = (): void => setIsDeletingModel(!isDeletingModel);

  const handleDelete = (): void => {
    if (modelToEdit) {
      dispatch(deleteModel({ id: modelToEdit.uuid }));
      setIsDeletingModel(false);
      toggleConfirmModal();
    }
  };

  const renderModelForm = (
    <div className="pt-4 pb-4">
      <form className="flex flex-col items-start">
        <div className="flex flex-col items-start gap-4 w-full">
          <label htmlFor="name" className="flex flex-col gap-1 mt-2 text-body">
            <div className="flex flex-row gap-1 text-small">
              <span>Name</span>
              <span className="litlingo-red-color">*</span>
            </div>

            <input
              id="name"
              ref={register({ required: true, pattern: /\S/i })}
              name="name"
              placeholder="Model name"
              className="form-input h-9 w-66 p-2 text-body rounded placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>

          <label htmlFor="description" className="flex flex-col gap-1 mt-2 text-body">
            <div className="flex flex-row gap-1 text-small">
              <span>Description</span>
            </div>

            <textarea
              id="description"
              ref={register()}
              name="description"
              rows={3}
              placeholder="Use this description to help others understand the purpose of this model."
              className="form-input w-112 p-2 text-body rounded resize-none placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>

          <label htmlFor="priority" className="flex flex-col gap-1 mt-2 text-body">
            <div className="flex flex-row gap-1 text-small">
              <span>Priority (1 - 5)</span>
            </div>

            <input
              id="priority"
              type="number"
              ref={register({ min: 1, max: 5 })}
              name="priority"
              placeholder="1"
              className="form-input h-9 w-20 p-2 text-body rounded placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>
          <div className="flex flex-col gap-1 mt-2 text-body">
            <div className="flex flex-row gap-1 text-small">
              <span>Type</span>
              <span className="litlingo-red-color">*</span>
            </div>
            <div className="flex flex-row items-start gap-5">
              <div className="flex-shrink-0 w-44">
                <SelectRedesign
                  onChange={(value: string): void => setType(value)}
                  options={options}
                  value={type}
                  placeholder="Select Type"
                  dataTestid="model-type-select"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderModelForm}
          title={isCreating ? 'Create new Use Case' : 'Edit Use Case'}
          okButton
          okButtonText="Save"
          okButtonOnClick={handleSaveButton}
          toggleShowModal={toggleModalOpen}
          secondaryButton={modelToEdit !== undefined}
          secondaryButtonOnclick={toggleConfirmModal}
          secondaryButtonStyle={deleteButtonStyle}
          secondaryButtonText="Delete Use Case"
        />
      )}
      {isDeletingModel && (
        <ConfirmModal
          text="Are you sure you want to delete this Use Case?"
          okButtonText="Yes"
          cancelButtonText="No"
          data-testid="delete-modal"
          okButtonOnClick={handleDelete}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </>
  );
};

export default EditModelModal;
