import { clearAlert } from 'actions';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import Transition from 'styles/Transition';

const Alert: React.FC = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alerts.alert);
  if (alert == null) {
    return null;
  }

  const { msg, action } = alert;
  if (!action) return null;

  const { action: dispatchAction, actionMsg, actionPayload } = action;

  const handleCloseAlert = (): void => {
    dispatch(clearAlert());
  };

  const handleAction = (): void => {
    dispatch(dispatchAction(actionPayload));
    dispatch(clearAlert());
  };

  return (
    <div className="text-center z-alert fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
      <Transition
        show
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="min-w-80 p-4 bg-white shadow-lg rounded-lg pointer-events-auto flex flex-row justify-between gap-4">
          <div className="flex flex-row gap-2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47716 15.5229 0 10 0C4.47716 0 0 4.47716 0 10C0 15.5229 4.47716 20 10 20Z"
                fill="#70CEA1"
              />
              <path
                d="M5.57031 11.5694L7.98634 13.9854L14.2739 6.41016"
                stroke="#333333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <div className="flex flex-col gap-1 text-body text-left">
              <span>{msg}</span>
              <div>
                <button
                  type="button"
                  className="text-body focus:outline-none"
                  onClick={handleAction}
                >
                  <span className="text-litlingo-primary underline">{actionMsg}</span>
                </button>
              </div>
            </div>
          </div>
          <div
            className="flex cursor-pointer h-5"
            role="button"
            aria-hidden="true"
            onClick={handleCloseAlert}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.80005 2.80005L17.36 17.362"
                stroke="#333333"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.36 2.80005L2.80005 17.362"
                stroke="#333333"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default Alert;
