import { deletePrompt, upsertPrompt } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import Modal from 'components/Modal';
import SelectRedesign from 'components/Select/SelectRedesign';
import { deploymentOptions, kindOptions, sourceOptions } from 'constants/entities';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Prompt } from 'types';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
  prompt?: Prompt;
  isCreating?: boolean;
};

const EditPromptModal: React.FC<ComponentProps> = ({
  isModalOpen,
  toggleModalOpen,
  prompt,
  isCreating,
}) => {
  const dispatch = useDispatch();

  const [isDeletingPrompt, setIsDeletingPrompt] = useState(false);
  const [kind, setKind] = useState(prompt?.kind || '');
  const [deployment, setDeployment] = useState(prompt?.deployment || '');
  const [source, setSource] = useState(prompt?.source || '');

  const { register, getValues, handleSubmit } = useForm<{
    kind: string;
    deployment: string;
    content: string;
    source: string;
    name: string;
    description: string;
  }>({
    defaultValues: {
      kind: prompt?.kind || '',
      deployment: prompt?.deployment || '',
      content: prompt?.content || '',
      source: prompt?.source ?? '',
      // @ts-ignore
      name: prompt?.name || '',
      // @ts-ignore
      description: prompt?.description || '',
    },
  });

  const deleteButtonStyle = 'text-litlingo-alert underline self-end focus:outline-none';

  const onSubmit = (): void => {
    const { content, name, description } = getValues();

    dispatch(
      upsertPrompt({
        ...prompt,
        kind,
        deployment,
        content,
        source,
        name,
        description,
      })
    );
    toggleModalOpen();
  };

  const handleSaveButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const toggleConfirmModal = (): void => setIsDeletingPrompt(!isDeletingPrompt);

  const handleDelete = (): void => {
    if (prompt) {
      dispatch(deletePrompt({ uuid: prompt.uuid }));
      setIsDeletingPrompt(false);
    }
  };

  const renderModelForm = (
    <div className="pt-4 pb-4">
      <form className="flex flex-col items-start">
        <div className="flex flex-col items-start gap-4 w-full">
          <div className="flex flex-col gap-1">
            <div className="flex flex-row gap-1">
              <span>Name</span>
              <span className="litlingo-red-color">*</span>
            </div>
            <input
              id="name"
              ref={register({ required: true })}
              name="name"
              placeholder="Name"
              className="form-input w-88 p-2 text-body rounded placeholder-italic placeholder-litlingo-gray-6"
            />
          </div>
          <label htmlFor="description" className="flex flex-col gap-1">
            <div className="flex flex-row gap-1">
              <span>Description</span>
            </div>
            <input
              id="description"
              ref={register()}
              name="description"
              placeholder="Description"
              className="form-input w-88 p-2 text-body rounded placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>
          <div className="flex flex-col gap-1">
            <div className="flex flex-row gap-1">
              <span>Kind</span>
              <span className="litlingo-red-color">*</span>
            </div>
            <div className="flex-shrink-0 w-44">
              <SelectRedesign
                onChange={(value: string): void => setKind(value)}
                options={kindOptions}
                value={kind}
                placeholder="Select kind"
                dataTestid="review-type-select"
              />
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex flex-row gap-1">
              <span>Deployment</span>
              <span className="litlingo-red-color">*</span>
            </div>
            <div className="flex-shrink-0 w-44">
              <SelectRedesign
                onChange={(value: string): void => setDeployment(value)}
                options={deploymentOptions}
                value={deployment}
                placeholder="Select deployment"
                dataTestid="review-type-select"
              />
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex flex-row gap-1">
              <span>Source</span>
            </div>
            <div className="flex-shrink-0 w-44">
              <SelectRedesign
                onChange={(value: string): void => setSource(value)}
                options={sourceOptions}
                value={source}
                placeholder="Select source"
                dataTestid="review-type-select"
              />
            </div>
          </div>
          <label htmlFor="content" className="flex flex-col gap-1">
            <div className="flex flex-row gap-1">
              <span>Content</span>
            </div>
            <textarea
              id="content"
              ref={register()}
              name="content"
              rows={6}
              placeholder="content"
              className="form-input w-136 p-2 text-body rounded resize-none placeholder-italic placeholder-litlingo-gray-6"
            />
          </label>

          <div className="w-full border-bottom" />
        </div>
      </form>
    </div>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderModelForm}
          title={isCreating ? 'Create New Prompt' : 'Edit Prompt'}
          okButton
          okButtonText="Save"
          okButtonOnClick={handleSaveButton}
          toggleShowModal={toggleModalOpen}
          secondaryButton={prompt !== undefined}
          secondaryButtonOnclick={toggleConfirmModal}
          secondaryButtonStyle={deleteButtonStyle}
          secondaryButtonText="Delete Prompt"
        />
      )}
      {isDeletingPrompt && (
        <ConfirmModal
          text="Are you sure you want to delete this prompt?"
          okButtonText="Yes"
          cancelButtonText="No"
          data-testid="delete-modal"
          okButtonOnClick={handleDelete}
          toggleShowModal={toggleConfirmModal}
        />
      )}
    </>
  );
};

export default EditPromptModal;
