import filterPills from 'assets/filter-pills.png';
import filters from 'assets/filters.png';
import filterDropdown from 'assets/review-dropdown.png';
import Modal from 'components/Modal';

import { HEADING_TO_EXPLORE_ICON } from 'constants/reviewSets';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCustomerDomain } from 'selectors/auth';
import { useHistory } from 'utils/urls';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
};

const HeadingToExploreModal: React.FC<ComponentProps> = ({ isModalOpen, toggleModalOpen }) => {
  const history = useHistory();
  const customerDomain = useSelector(getCustomerDomain);

  // preloading modal images
  new Image().src = filterDropdown;
  new Image().src = filterPills;
  new Image().src = filters;

  const body = (
    <div className=" flex flex-col mb-4">
      <div className="self-center">{HEADING_TO_EXPLORE_ICON}</div>
      <div className="font-bold font-header text-2xl mt-4">Heading to the Explore Page</div>
      <span>
        The Explore page is where the structure of the review is formed. Get started with three
        simple steps.
      </span>
    </div>
  );
  const handleSaveButton = (): void => {
    history.pushLookup({
      routeName: 'envelope-list',
      customerDomain,
    });
  };
  return (
    <>
      {isModalOpen && (
        <Modal
          title=" "
          body={body}
          okButton
          okButtonText="Continue"
          okButtonOnClick={handleSaveButton}
          cancelButton={false}
          toggleShowModal={toggleModalOpen}
          style={{ width: '38rem', height: '23rem' }}
          xButton
        />
      )}
    </>
  );
};

export default HeadingToExploreModal;
