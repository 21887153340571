import {
  fetchAllCategoriesRequest,
  fetchSingleCategoryRequest,
  upsertCategoryRequest,
} from 'actions/categories';
import { GlobalState } from 'reducers';
import { createSelector } from 'reselect';
import type { Category, NormalizedResource, Selector, UUID } from 'types';

export const getFetchAllCategoriesLoading: Selector<boolean> = (state) =>
  state.categories.loading.includes(fetchAllCategoriesRequest.toString());

export const getFetchSingleCategoryLoading: Selector<boolean> = (state) =>
  state.categories.loading.includes(fetchSingleCategoryRequest.toString());

export const getUpsertCategoryLoading: Selector<boolean> = (state) =>
  state.categories.loading.includes(upsertCategoryRequest.toString());

export const getCategory =
  (id: UUID): Selector<Category> =>
  (state): Category =>
    state.categories.categories[id];

export const getAllCategories: Selector<NormalizedResource<Category>> = (state) =>
  state.categories.categories;

export const getCategoriesList: Selector<Category[]> = createSelector(
  [(state: GlobalState): GlobalState['categories']['categories'] => state.categories.categories],
  (categories) => Object.values(categories)
);

export const getActiveCategory: Selector<UUID> = (state) => state.categories.activeCategory;

export const getCategoriesTotalCount: Selector<number> = (state) => state.categories.count;
