import React from 'react';

const HappyState: React.FC = () => (
  <div className="litlingo__robot_center">
    <div>
      <svg
        style={{ margin: 'auto' }}
        width="71"
        height="71"
        viewBox="0 0 71 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="35.5" cy="35.5" r="35.5" fill="#F2F2F2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30 58.7329C25.6049 60.2775 22.0156 63.5183 20.0277 67.6642C24.5669 69.8033 29.6429 71 35 71C40.8871 71 46.4348 69.5548 51.3037 67.0018C48.9948 62.6733 44.9022 59.4296 40 58.2391V55.4592V54.4286H48.3333C52.0152 54.4286 55 51.4609 55 47.8V42.7698C57.8269 42.3678 60 39.9505 60 37.0286C60 35.0437 58.9972 33.2916 57.4678 32.246L56.7415 17.8023C57.6732 17.441 58.3333 16.54 58.3333 15.4857C58.3333 14.1129 57.214 13 55.8333 13C54.4526 13 53.3333 14.1129 53.3333 15.4857C53.3333 16.54 53.9935 17.441 54.9252 17.8023L54.2689 30.8535C51.9202 22.4676 44.1828 16.3143 35 16.3143C25.8172 16.3143 18.0798 22.4676 15.7311 30.8535L15.0748 17.8023C16.0065 17.441 16.6667 16.54 16.6667 15.4857C16.6667 14.1129 15.5474 13 14.1667 13C12.786 13 11.6667 14.1129 11.6667 15.4857C11.6667 16.54 12.3268 17.441 13.2585 17.8023L12.5322 32.246C11.0028 33.2916 10 35.0437 10 37.0286C10 39.9505 12.1731 42.3678 15 42.7698V47.8C15 51.4609 17.9848 54.4286 21.6667 54.4286H30V55.4592V58.7329ZM29.0569 44.0746C28.8285 43.6773 28.3195 43.5393 27.9199 43.7663C27.5203 43.9933 27.3815 44.4995 27.6098 44.8968C29.3979 48.008 32.1503 49.6643 35 49.6643C37.8497 49.6643 40.6021 48.008 42.3902 44.8968C42.6185 44.4995 42.4797 43.9934 42.0801 43.7663C41.6805 43.5393 41.1715 43.6773 40.9431 44.0746C39.3978 46.7634 37.1503 48.0071 35 48.0071C32.8497 48.0071 30.6021 46.7634 29.0569 44.0746Z"
          fill="#3FB593"
        />
      </svg>
    </div>
    <h3 className="litlingo__color_grey text-lg font-bold pt-3">All clear!</h3>
    <p className="litlingo__color_grey_2 text-sm pt-1">
      your message looks good
    </p>
  </div>
);

export default HappyState;
