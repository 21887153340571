import { all } from 'redux-saga/effects';
import { SagaReturn } from 'types';
import alertsSaga from './alerts';
import analyticsSaga from './analytics';
import annotatorSaga from './annotator';
import annotatorsAuditLogsSaga from './annotatorsAuditLogs';
import assignmentsSaga from './assignments';
import auditLogsSaga from './auditLogs';
import authSaga from './auth';
import campaignRuleOutcomesSaga from './campaignRuleOutcomes';
import campaignsSaga from './campaigns';
import categoriesSaga from './categories';
import communicationSaga from './communication';
import configSaga from './config';
import customReportsSaga from './customReports';
import dashboardsSaga from './dashboards';
import entitiesSaga from './entities';
import envelopeActionsSaga from './envelopeActions';
import envelopesSaga from './envelopes';
import eventsSaga from './events';
import fileUploadsSaga from './fileUploads';
import identifierSaga from './identifier';
import identitiesSaga from './identities';
import invitationsSaga from './invitations';
import modelsSaga from './models';
import navSaga from './nav';
import notificationsSaga from './notifications';
import outcomeSaga from './outcomes';
import relationshipsSaga from './relationships';
import ruleGroupsSaga from './ruleGroups';
import rulesSaga from './rules';
import rulesAuditLogsSaga from './rulesAuditLogs';
import rulesCompareSaga from './rulesCompare';
import savedSearchesSaga from './savedSearches';
import sessionSaga from './session';
import tagGroupsSaga from './tagGroups';
import tagsSaga from './tags';
import tasksSaga from './tasks';
import teamsSaga from './teams';
import templatesSaga from './templates';
import testCasesSaga from './testCases';
import usersSaga from './users';

function* rootSaga(): SagaReturn {
  yield all([
    alertsSaga(),
    navSaga(),
    annotatorSaga(),
    annotatorsAuditLogsSaga(),
    auditLogsSaga(),
    authSaga(),
    campaignsSaga(),
    categoriesSaga(),
    dashboardsSaga(),
    envelopeActionsSaga(),
    identifierSaga(),
    usersSaga(),
    analyticsSaga(),
    communicationSaga(),
    testCasesSaga(),
    campaignRuleOutcomesSaga(),
    outcomeSaga(),
    envelopesSaga(),
    modelsSaga(),
    rulesSaga(),
    ruleGroupsSaga(),
    rulesAuditLogsSaga(),
    rulesCompareSaga(),
    relationshipsSaga(),
    savedSearchesSaga(),
    sessionSaga(),
    fileUploadsSaga(),
    identitiesSaga(),
    invitationsSaga(),
    tagsSaga(),
    tagGroupsSaga(),
    teamsSaga(),
    templatesSaga(),
    configSaga(),
    assignmentsSaga(),
    eventsSaga(),
    notificationsSaga(),
    tasksSaga(),
    customReportsSaga(),
    entitiesSaga(),
  ]);
}

export type RootSaga = typeof rootSaga;

export default rootSaga;
