/* eslint-disable react/jsx-curly-newline */
import React from 'react';

type ComponentProps = {
  items: { name: string; uuid: string }[];
  selectedItems: string[];
  handleSelect: (item: string) => void;
  search: string;
  searchFocused: boolean;
};

const ContextItemsList: React.FC<ComponentProps> = (props) => {
  const { items, selectedItems, handleSelect, search, searchFocused } = props;

  return (
    <div className="h-full flex flex-col gap-2 overflow-auto custom-scrollbar pl-2">
      {items.map((i) => {
        if (!selectedItems.includes(i.uuid) && !searchFocused && !search) return null;
        if (!i.name.toLowerCase().includes(search.toLowerCase())) return null;

        return (
          <div key={i.uuid} className="flex flex-row">
            <label htmlFor={`item-${i.uuid}`} className="flex flex-row items-center gap-2">
              <input
                type="checkbox"
                id={`item-${i.uuid}`}
                className="form-checkbox litlingo-checkbox  h-4 w"
                checked={selectedItems.includes(i.uuid)}
                onChange={(): void => handleSelect(i.uuid)}
              />
              <span>{i.name}</span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default ContextItemsList;
