export const kindOptions = [
  {
    value: 'rerank',
    label: 'Rerank',
    text: '',
  },
  {
    value: 'entity',
    label: 'Entity',
    text: '',
  },
  {
    value: 'message',
    label: 'Message',
    text: '',
  },
];

export const deploymentOptions = [
  {
    value: 'gpt-35-turbo-0125',
    label: 'gpt-35-turbo-0125',
    text: '',
  },
  {
    value: 'Mistral-large-iaxog',
    label: 'Mistral-large-iaxog',
    text: '',
  },
  {
    value: 'gpt4-turbo',
    label: 'gpt4-turbo',
    text: '',
  },
  {
    value: 'gpt4o',
    label: 'gpt4o',
    text: '',
  },
  {
    value: 'gpt4o-mini',
    label: 'gpt4o-mini',
    text: '',
  },
];

export const sourceOptions = [
  {
    value: 'sentences',
    label: 'Sentences',
    text: '',
  },
  {
    value: 'full',
    label: 'Full',
    text: '',
  },
];
