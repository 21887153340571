/* eslint-disable max-lines */
import { SimpleBlackLogo } from '@litlingo/react';
import { logoutUser } from 'actions';
import LinkLookup from 'components/LinkLookup';
import Permissions from 'components/Permissions';
import { SETTINGS_ICON, SIGN_OUT_ICON } from 'constants/headerMenuIcons';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'selectors/auth';
import Transition from 'styles/Transition';
import type { Role } from 'types';
import { USER_HOME } from 'utils/userRoles';

const EmptyNavbar: React.FC = () => {
  const dispatch = useDispatch();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const buttonMenuRef = useRef<HTMLButtonElement | null>(null);

  const user = useSelector(getUser);

  const [menuIsOpen, menuSetIsOpen] = useState(false);

  const menuItemClass =
    'w-full text-left px-4 py-2 block text-menu-item-navbar focus:outline-none transition duration-150 ease-in-out';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (e: any): void => {
    const element = menuRef.current;
    const buttonElement = buttonMenuRef.current;
    if (
      menuRef &&
      buttonMenuRef &&
      element != null &&
      buttonElement != null &&
      !element.contains(e.target) &&
      !buttonElement.contains(e.target)
    ) {
      menuSetIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return (): void => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const getHome = (): string => {
    const homeList = Object.keys(USER_HOME);
    for (let i = 0; i < homeList.length; i += 1) {
      if (user.roles?.includes(homeList[i] as Role)) {
        return USER_HOME[homeList[i]].routeName;
      }
    }
    return USER_HOME.default.routeName;
  };

  const renderLogo = (): JSX.Element => (
    <LinkLookup routeName={getHome()} className="inline-flex items-center">
      <div className="h-3/5 w-28">
        <SimpleBlackLogo />
      </div>
    </LinkLookup>
  );

  return (
    <>
      <nav
        className="sticky top-0 z-navbar bg-litlingo-gray-600 h-16"
        style={{ filter: 'drop-shadow(5px 6px 18px rgba(0, 0, 0, 0.1))' }}
      >
        <div className="max-w-7xl mx-auto px-4  lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">{renderLogo()}</div>
            <div className="flex items-center">
              <div className="flex flex-row h-full">
                <div className="ml-4 relative self-center">
                  <button
                    type="button"
                    onClick={(): void => menuSetIsOpen(!menuIsOpen)}
                    ref={buttonMenuRef}
                    className="flex text-sm border-2 rounded-lg focus:outline-none focus:border-gray-500 transition duration-150 ease-in-out border-litlingo-gray-200"
                    id="user-menu"
                    aria-label="User menu"
                    aria-haspopup="true"
                  >
                    <div className="px-2 py-1">
                      {user.customer && (
                        <span className="text-xss font-header font-bold leading-4 items-center justify-center pr-2 text-white">
                          {user.customer.name}
                        </span>
                      )}
                      {user.email && (
                        <span className="inline-flex h-8 w-8 items-center justify-center rounded-full text text--large text--bold leading-none text-litlingo-gray-800 bg-yellow-200">
                          {user.email[0].toUpperCase()}
                        </span>
                      )}
                    </div>
                  </button>
                  <Transition
                    show={menuIsOpen}
                    enter="transition ease-out duration-100 transform"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75 transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <div
                      ref={menuRef}
                      className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-50"
                    >
                      <div className="py-1 flex flex-col rounded-md bg-litlingo-gray-600 shadow-xs">
                        <tbody className="flex flex-col">
                          <Permissions action="customers.list">
                            <tr className="hover:bg-litlingo-gray-400-100">
                              <td className="pl-3 hover:bg-litlingo-gray-400-100">
                                {SETTINGS_ICON}
                              </td>
                              <td>
                                <LinkLookup
                                  data-skip
                                  className={menuItemClass}
                                  routeName="customer"
                                  data-testid="customer-profile-menu-link"
                                >
                                  Settings
                                </LinkLookup>
                              </td>
                            </tr>
                          </Permissions>

                          <button
                            type="button"
                            className={`${menuItemClass} m-0 hover:bg-litlingo-gray-400-100`}
                            onClick={(): void => {
                              dispatch(logoutUser());
                            }}
                            data-skip
                          >
                            <tr>
                              <td>{SIGN_OUT_ICON}</td>
                              <td className="pl-3">Sign Out</td>
                            </tr>
                          </button>
                        </tbody>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default EmptyNavbar;
