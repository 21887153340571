/* eslint-disable max-lines */
import LinkLookup from 'components/LinkLookup';
import Redacted from 'components/Redacted';
import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCustomerZendeskDomain } from 'selectors/auth';
import { getSelectedCommunication } from 'selectors/communications';
import { getCommsFullMessageHighlighted } from 'selectors/events';

import { Communication } from '@litlingo/client';
import { ATTACHMENT_ICON } from 'constants/envelopeIcons';
import { SectionType } from 'reducers/envelopeReview';
import getSelectedEnvelope, { getSelectedSection } from 'selectors/envelopeReview';
import {
  getBccRecipientsFromCommunication,
  getCcRecipientsFromCommunication,
  getEnvelope,
  getRecipientsFromCommunication,
  getToRecipientsFromCommunication,
} from 'selectors/envelopes';
import { useSelector } from 'store';
import { useClampText } from 'use-clamp-text';
import { verifyEmail } from 'utils/strings';
import HighlightedSentenceComponent from './HighlightedSentence';

const getRecipientsString = (recipients: Communication['recipients']): string =>
  recipients.reduce((acc, recipient, idx): string => {
    if (recipient.id && recipient.display_name) {
      if (idx === 0) {
        if (recipient.type === 'email') {
          return `${recipient.display_name} ${
            verifyEmail.test(recipient.id) ? `(${recipient.id})` : null
          }`;
        }
        return recipient.display_name;
      }
      if (idx !== 0) {
        if (recipient.type === 'email') {
          return `${acc}, ${recipient.display_name} ${
            verifyEmail.test(recipient.id) ? `(${recipient.id})` : null
          }`;
        }
        return `${acc}, ${recipient.display_name}`;
      }
    }
    return '';
  }, '');

type ComponentProps = {
  renderSubject?: boolean;
};

const EnvelopeMessageInfo: React.FC<ComponentProps> = ({ renderSubject = true }) => {
  const sections: SectionType[] = ['message', 'history', 'attachment'];
  const { envelopeId } = useParams<{ envelopeId: string }>();
  const selectedEnvelope = useSelector(getSelectedEnvelope);

  const envelope = useSelector((state) => getEnvelope(state, selectedEnvelope?.uuid ?? envelopeId));
  const zendeskDomain = useSelector(getCustomerZendeskDomain);

  const recipients = useSelector(getRecipientsFromCommunication);
  const recipientsTo = useSelector(getToRecipientsFromCommunication);
  const recipientsCc = useSelector(getCcRecipientsFromCommunication);
  const recipientsBcc = useSelector(getBccRecipientsFromCommunication);

  const communication = useSelector(getSelectedCommunication);
  const selectedSection = useSelector(getSelectedSection);

  const filteredEvents = communication?.events
    ? communication.events.filter((event) => !event.hidden)
    : [];
  const eventId = filteredEvents && filteredEvents[0] ? filteredEvents[0].uuid : '';
  const res = useSelector((state) =>
    getCommsFullMessageHighlighted(state, communication?.uuid || '', 'false', 'false')
  );

  const [showMore, setShowMore] = useState(false);
  const [clampedSender, setClampedSender] = useState(false);
  const [clampedSubject, setClampedSubject] = useState(false);

  const recipientSenderRef = (node: HTMLDivElement): void => {
    if (node) {
      const newC = node.scrollHeight > node.clientHeight + 2;
      setClampedSender(newC);
    }
  };

  const recipientSubjectRef = (node: HTMLDivElement): void => {
    if (node) {
      const newC = node.scrollHeight > node.clientHeight + 2;
      setClampedSubject(newC);
    }
  };

  const [toRef, { noClamp: toClamp, clampedText: toClampedText, key: toKey }] = useClampText({
    text: getRecipientsString(recipientsTo),
    lines: 2,
    ellipsis: 12.5,
    expanded: showMore,
  });

  const [ccRef, { noClamp: ccClamp, clampedText: ccClampedText, key: ccKey }] = useClampText({
    text: getRecipientsString(recipientsCc),
    lines: 2,
    ellipsis: 12.5,
    expanded: showMore,
  });

  const [bccRef, { noClamp: bccClamp, clampedText: bccClampedText, key: bccKey }] = useClampText({
    text: getRecipientsString(recipientsBcc),
    lines: 2,
    ellipsis: 12.5,
    expanded: showMore,
  });

  const getFromField = (): string => {
    if (
      envelope.created_by?.email &&
      verifyEmail.test(envelope.created_by?.email as string) &&
      envelope.created_by?.name
    ) {
      return `${
        verifyEmail.test(envelope.created_by?.name)
          ? envelope.created_by?.name
          : capitalize(envelope.created_by?.name)
      } ${
        verifyEmail.test(envelope.created_by?.email) ? `(${envelope.created_by?.email})` : null
      } `;
    }
    return envelope.created_by?.name ?? '';
  };

  const getDate = (date: string): string => {
    const fixedDate = date.charAt(date.length - 1) !== 'Z' ? date.concat('Z') : date;
    const eventDate = moment(fixedDate).utc().local();
    return eventDate.format('MMM DD hh:mm A');
  };

  const subjectElement = (): JSX.Element | null => {
    if (renderSubject) {
      return (
        <div className="flex flex-row items-center gap-2">
          <div className="flex flex-row gap-1">
            <span className="font-bold">
              {envelope.platform === 'o365_teams' ? 'Channel: ' : 'Subject: '}
            </span>
            {res && res.length > 0 && res[0].type === 'subject' ? (
              <div className="flex flex-row">
                <div
                  ref={recipientSubjectRef}
                  className={`break-all ${showMore ? '' : 'clamped-sender'}`}
                >
                  <HighlightedSentenceComponent
                    eventId={eventId}
                    commUuid={communication?.uuid || ''}
                    fullMessageOptions="HIGHLIGHTED"
                    hideHighlight={!sections.includes(selectedSection)}
                    onlySubject
                  />
                </div>
                {!showMore && clampedSubject && recipients !== null && (
                  <span
                    data-testid="show-all-button"
                    className="self-end ml-2 whitespace-no-wrap underline text-litlingo-primary focus:outline-none"
                  >
                    Show More
                  </span>
                )}
              </div>
            ) : (
              <span>{communication?.subject}</span>
            )}
          </div>
          {communication?.meta_data?.attachment_list &&
            communication.meta_data.attachment_list.length > 0 &&
            communication?.communication_type !== 'attachment' && (
              <div className="w-4"> {ATTACHMENT_ICON} </div>
            )}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div
        className="flex flex-col gap-1 text-body "
        onMouseEnter={(): void => {
          setShowMore(true);
        }}
        onMouseLeave={(): void => setShowMore(false)}
      >
        {envelope && (
          <div className="overflow-hidden">
            <div className="flex flex-row gap-1">
              <span className="font-bold">From: </span>

              <Redacted field="communication_envelopes.created_by">
                <div className="flex flex-row">
                  <div
                    ref={recipientSenderRef}
                    className={`break-normal ${showMore ? '' : 'clamped-sender'}`}
                  >
                    {getFromField()}
                  </div>
                  {!showMore && clampedSender && recipients !== null && (
                    <span
                      data-testid="show-all-button"
                      className="self-end ml-2 whitespace-no-wrap underline text-litlingo-primary focus:outline-none"
                    >
                      Show More
                    </span>
                  )}
                </div>
              </Redacted>
            </div>

            {recipientsTo && recipientsTo.length > 0 && envelope.platform !== 'zendesk' && (
              <div className="flex flex-row gap-1">
                <span className="font-bold">To:</span>
                <Redacted field="communications.recipients">
                  <div ref={toRef as React.LegacyRef<HTMLDivElement>} key={toKey}>
                    {toClampedText}
                    {!showMore && !toClamp && recipients !== null && (
                      <span
                        data-testid="show-all-button"
                        className=" ml-2 whitespace-no-wrap underline text-litlingo-primary focus:outline-none"
                      >
                        Show More
                      </span>
                    )}
                  </div>
                </Redacted>
              </div>
            )}

            <div className="flex flex-row gap-1">
              <span className="font-bold">CC:</span>
              {recipientsCc != null &&
              recipientsCc.length > 0 &&
              envelope.platform !== 'zendesk' ? (
                <Redacted field="communications.recipients">
                  <div ref={ccRef as React.LegacyRef<HTMLDivElement>} key={ccKey}>
                    {ccClampedText}
                    {!showMore && !ccClamp && recipientsCc !== null && (
                      <span
                        data-testid="show-all-button"
                        className=" ml-2 whitespace-no-wrap underline text-litlingo-primary focus:outline-none"
                      >
                        Show More
                      </span>
                    )}
                  </div>
                </Redacted>
              ) : (
                'N/A'
              )}
            </div>

            <div className="flex flex-row gap-1">
              <span className="font-bold">BCC:</span>
              {recipientsBcc != null &&
              recipientsBcc.length > 0 &&
              envelope.platform !== 'zendesk' ? (
                <Redacted field="communications.recipients">
                  <div ref={bccRef as React.LegacyRef<HTMLDivElement>} key={bccKey}>
                    {bccClampedText}
                    {!showMore && !bccClamp && recipientsBcc !== null && (
                      <span
                        data-testid="show-all-button"
                        className=" ml-2 whitespace-no-wrap underline text-litlingo-primary focus:outline-none"
                      >
                        Show More
                      </span>
                    )}
                  </div>
                </Redacted>
              ) : (
                'N/A'
              )}
            </div>

            <div className="flex flex-row gap-1">
              <span className="font-bold">Date:</span>
              <div>{getDate(communication?.sent_at || '')}</div>
            </div>
            {envelope.platform === 'slack' ? (
              <div className="flex flex-row gap-1">
                <span className="font-bold">Channel:</span>
                <span>
                  {envelope.communications &&
                    envelope.communications[0] &&
                    // @ts-ignore asda
                    `${envelope.communications[0].meta_data?.channel}`}
                </span>
              </div>
            ) : (
              subjectElement()
            )}
          </div>
        )}

        {envelope && envelope.platform === 'zendesk' && (
          /* zendeskDomain && */
          <div className="flex flex-row self-end gap-2 text-xss font-normal leading-4 text-litlingo-info">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://${zendeskDomain}.zendesk.com/agent/tickets/${envelope.platform_thread_guid}`}
            >
              <span className="underline cursor-pointer">View ticket</span>
            </a>
            <LinkLookup
              routeName="envelope-list"
              target="_blank"
              queryParams={{
                envelopes__platform_thread_guid: envelope.platform_thread_guid,
              }}
            >
              <span className="underline cursor-pointer">View thread</span>
            </LinkLookup>
          </div>
        )}
      </div>
    </>
  );
};

export default EnvelopeMessageInfo;
