import { fetchEntitiesRequest, fetchPromptsRequest, fetchSinglePromptRequest } from 'actions';
import { Entity, Prompt, Selector } from 'types';

export const getPrompt: Selector<Prompt | null> = (state) => state.entities.activePrompt;

export const getEntities: Selector<Entity[]> = (state) => state.entities.entities;
export const getEntitiesTotalCount: Selector<number> = (state) => state.entities.entitiesCount;
export const getEntitiesLoading: Selector<boolean> = (state) =>
  state.entities.loading.includes(fetchEntitiesRequest.toString());

export const getPrompts: Selector<Prompt[]> = (state) => state.entities.prompts;
export const getPromptsTotalCount: Selector<number> = (state) => state.entities.promptsCount;
export const getPromptsLoading: Selector<boolean> = (state) =>
  state.entities.loading.includes(fetchPromptsRequest.toString());

export const getSinglePromptLoading: Selector<boolean> = (state) =>
  state.entities.loading.includes(fetchSinglePromptRequest.toString());
