/* eslint-disable max-lines */
import { generateTerms, receiveNewKeyword } from 'actions';
import RenderForm from 'components/LanguageMatcher/AddLanguageMatcherInput/RenderForm';
import LanguageMatcherList from 'components/LanguageMatcher/LanguageMatcherList';
import LoadingIndicator from 'components/LoadingIndicator';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getFormSpec, getGeneratedTerms, getGeneratedTermsLoading } from 'selectors/annotator';
import { useSelector } from 'store';
import type { LanguageMatcher, LanguageMatcherType, UUID } from 'types';

type ComponentProps = {
  languageMatcherId: UUID;
  type: LanguageMatcherType;
  form: keyof LanguageMatcher;
};

const AddLanguageMatcherInput: React.FC<ComponentProps> = (props) => {
  const { languageMatcherId, type, form } = props;
  const dispatch = useDispatch();
  const {
    inputType,
    inputValue,
    inputErrors,
    inputChoices,
    inputName,
    inputRequired,
    inputHidden,
  } = useSelector((state) => getFormSpec(state, languageMatcherId, type, form));

  const generatedTerms = useSelector(getGeneratedTerms);
  const generateTermsLoading = useSelector(getGeneratedTermsLoading);

  const [error, setError] = useState<string | null>(null);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (inputErrors && inputErrors.includes(form)) {
      setError('Must add at least one permutation');
    } else {
      setError(null);
    }
  }, [form, inputErrors]);

  const handleGenerateTerms = (): void => {
    if (Array.isArray(inputValue)) {
      dispatch(
        generateTerms({
          includedTerms: [...inputValue],
        })
      );
    }
  };

  const handleAddSuggestedTerm = (term: string): void => {
    dispatch(
      receiveNewKeyword({
        languageMatcherId,
        parentType: type,
        data: {
          key: form,
          value: [term],
        },
      })
    );

    dispatch(
      receiveNewKeyword({
        languageMatcherId,
        parentType: type,
        data: {
          key: 'saved',
          value: false,
        },
      })
    );
  };

  if (inputHidden === true) {
    return null;
  }

  const filteredGeneratedTerms = [...new Set(generatedTerms.map((t) => t.toLowerCase()))].filter(
    (t) =>
      Array.isArray(inputValue) &&
      !inputValue.map((term) => term.toLowerCase()).includes(t.toLowerCase())
  );

  const renderedGeneratedTerms = (): JSX.Element => {
    if (generatedTerms.length > 0 && filteredGeneratedTerms.length === 0) {
      return <span className="text-small">No new suggestions</span>;
    }

    return (
      <>
        {filteredGeneratedTerms.map((term: string) => (
          <button
            type="button"
            key={term}
            className="rounded h-5 px-1 bg-litlingo-primary-60 text-small focus:outline-none"
            onClick={(): void => handleAddSuggestedTerm(term)}
          >
            {term}
          </button>
        ))}
      </>
    );
  };

  return (
    <div className="flex flex-col gap-1 mt-6">
      <label htmlFor="email" className="block litlingo-annotator-label-name">
        {`${inputName || ''} ${inputRequired ? '*' : ''}`}
      </label>
      <RenderForm
        inputType={inputType}
        inputValue={inputValue || false}
        inputChoices={inputChoices}
        form={form}
        type={type}
        languageMatcherId={languageMatcherId}
        setError={setError}
      />
      <span className="text-sm text-red-500">{error}</span>
      {inputValue && Array.isArray(inputValue) && (
        <div className="flex flex-wrap">
          {inputValue
            .slice()
            .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
            .map((keyword, idx) => (
              <React.Fragment key={keyword}>
                <LanguageMatcherList
                  hidden={idx > 9 && !showAll}
                  id={languageMatcherId}
                  keyword={keyword}
                  type={form}
                />
                {idx === 10 && !showAll && (
                  <div
                    role="button"
                    aria-hidden
                    className="ml-2 cursor-pointer"
                    onClick={(): void => {
                      setShowAll(true);
                    }}
                  >
                    <span className="text-sm text-blue-800 hover:underline">Show all</span>
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>
      )}

      {inputName === 'Terms' && (
        <div className="flex flex-row gap-2 justify-between py-1 border-t border-litlingo-primary-60">
          <div className="flex flex-row flex-wrap gap-1">{renderedGeneratedTerms()}</div>
          <button
            type="button"
            className="button button--secondary text-small h-5 min-w-16 justify-center px-0"
            onClick={handleGenerateTerms}
            disabled={!(Array.isArray(inputValue) && inputValue.length > 0)}
          >
            <span className="whitespace-no-wrap">
              {generateTermsLoading ? <LoadingIndicator size="3" /> : 'Generate'}
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default AddLanguageMatcherInput;
