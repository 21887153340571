import { deleteModelCategorySuccess, selectCustomerConfig, showSuccessAlert } from 'actions';
import {
  createCategoriesuccess,
  createCategory,
  createCategoryFailure,
  createCategoryFulfill,
  createCategoryRequest,
  createCategoryRule,
  deleteCategoriesuccess,
  deleteCategory,
  deleteCategoryFailure,
  deleteCategoryFulfill,
  deleteCategoryOutcomeSuccess,
  deleteCategoryRequest,
  fetchAllCategories,
  fetchAllCategoriesFailure,
  fetchAllCategoriesFulfill,
  fetchAllCategoriesRequest,
  fetchAllCategoriesSuccess,
  fetchSingleCategoriesuccess,
  fetchSingleCategory,
  fetchSingleCategoryFailure,
  fetchSingleCategoryFulfill,
  fetchSingleCategoryRequest,
  upsertCategoriesuccess,
  upsertCategory,
  upsertCategoryFailure,
  upsertCategoryFulfill,
  upsertCategoryRequest,
} from 'actions/categories';
import {
  createRuleGroupFailure,
  fetchRuleCustomers,
  setHighlightCustomerScope,
} from 'actions/ruleGroup';
import { apiClientV2 as LitLingoClientV2 } from 'client';
import { push } from 'connected-react-router';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getCustomerDomain } from 'selectors/auth';
import { getNavParams, getNavParamsByResource } from 'selectors/nav';
import type { API, APIV2, RouteParams, SagaReturn } from 'types';
import { reverse } from 'utils/urls';
import { v4 } from 'uuid';

function* fetchSingleCategoriesaga(action: ReturnType<typeof fetchSingleCategory>): SagaReturn {
  const { payload } = action;
  yield put(fetchSingleCategoryRequest());
  const response = (yield call(
    [LitLingoClientV2.resources.categories, 'retrieve'],
    payload.categoryId,
    {
      params: {
        include_pii: 'true',
        relationships: ['rule', 'customer'],
      },
    }
  )) as API.Response<APIV2.Categories.Retrieve>;
  if (response.error != null) {
    yield put(fetchSingleCategoryFailure(response.error));
  } else {
    yield put(fetchSingleCategoriesuccess(response.data));
  }
  yield put(fetchSingleCategoryFulfill());
}

function* fetchCategoriesList(paramsArg: Partial<RouteParams>): SagaReturn {
  yield put(fetchAllCategoriesRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.category)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  const params = { ...paramsArg, include_count: true, ...resourceParams };

  const response = (yield call([LitLingoClientV2.resources.categories, 'list'], {
    params,
  })) as API.Response<APIV2.Categories.List>;
  if (response.error != null) {
    yield put(fetchAllCategoriesFailure(response.error));
  } else {
    yield put(fetchAllCategoriesSuccess(response.data));
  }
  yield put(fetchAllCategoriesFulfill());
}

function* fetchAllCategoriesSaga({ payload }: ReturnType<typeof fetchAllCategories>): SagaReturn {
  const navParams = (yield select(getNavParams)) as RouteParams;
  const params = {
    ...navParams,
    include_pii: 'true',
    relationships: ['updated_by', 'rule', 'customer', 'model', 'outcome'],
    ...payload,
  };

  yield call(fetchCategoriesList, params);
}

function* createCategoriesaga({ payload }: ReturnType<typeof createCategory>): SagaReturn {
  const { modelId, ruleId, outcomeId, customerId, redirect, fromRule, scope } = payload;

  yield put(createCategoryRequest());

  const ruleResponse = (yield call([LitLingoClientV2.resources.ruleGroups.extras, 'getRevisions'], {
    params: {
      rule_uuids: [ruleId],
      include_pii: true,
      order_by: 'created_at',
      order_desc: 'true',
      heads_only: 'true',
    },
  })) as API.Response<APIV2.RuleGroups.GetRevisions>;

  if (ruleResponse.error != null) {
    yield put(createCategoryFailure(ruleResponse.error));
  } else {
    const categoryData = {
      model_uuid: modelId,
      rule_revision_uuid: ruleResponse.data.records[0].uuid,
      customer_uuid: customerId,
      outcome_uuid: outcomeId,
      config: {},
    };

    const response = (yield call([LitLingoClientV2.resources.categories, 'upsert'], {
      data: categoryData,
      params: { relationships: ['customer', 'outcome'] },
    })) as API.Response<APIV2.Categories.Upsert>;

    if (response.error != null) {
      yield put(createCategoryFailure(response.error));
    } else {
      yield put(createCategoriesuccess(response.data));
      if (fromRule) {
        yield put(fetchRuleCustomers(response.data.rule_revision_uuid));
      }
      if (scope) {
        yield put(selectCustomerConfig(customerId));
        yield put(setHighlightCustomerScope(customerId));
      }
      if (redirect) {
        let customerDomain = 'global';
        if (!redirect.includes('global')) {
          customerDomain =
            ((yield select(getCustomerDomain)) as ReturnType<typeof getCustomerDomain>) || '';
        }

        const path = reverse({
          routeName: redirect,
          routeParams: { categoryId: response.data.uuid },
          customerDomain,
        });
        yield put(push(path));
      }
    }
    yield put(createCategoryFulfill());
  }
}

function* createCategoryRuleSaga({ payload }: ReturnType<typeof createCategoryRule>): SagaReturn {
  const { modelId, outcomeId, customerId, redirect, fromRule, scope, ruleName } = payload;

  yield put(createCategoryRequest());

  const ruleGroupResponse = (yield call([LitLingoClientV2.resources.ruleGroups, 'upsert'], {
    data: { name: ruleName ?? 'New Rule' },
  })) as API.Response<APIV2.RuleGroups.Upsert>;
  if (ruleGroupResponse.error != null) {
    yield put(createRuleGroupFailure(ruleGroupResponse.error));
  } else {
    const ruleRevisionResponse = (yield call(
      [LitLingoClientV2.resources.ruleGroups.extras, 'upsertRuleRevision'],
      {
        urlParams: { ruleId: ruleGroupResponse.data.uuid },
        data: {
          name: ruleName ?? 'New Rule',
          description: '',
          config: {
            operands: [],
            operator: 'AND',
            uuid: v4(),
          },
        },
      }
    )) as API.Response<APIV2.RuleGroups.UpsertRuleRevision>;
    if (ruleRevisionResponse.error != null) {
      yield put(createCategoryFailure(ruleRevisionResponse.error));
    } else {
      const categoryData = {
        model_uuid: modelId,
        rule_revision_uuid: ruleRevisionResponse.data.uuid,
        customer_uuid: customerId,
        outcome_uuid: outcomeId,
        config: {},
      };

      const response = (yield call([LitLingoClientV2.resources.categories, 'upsert'], {
        data: categoryData,
        params: { relationships: ['customer', 'outcome'] },
      })) as API.Response<APIV2.Categories.Upsert>;

      if (response.error != null) {
        yield put(createCategoryFailure(response.error));
      } else {
        yield put(createCategoriesuccess(response.data));
        if (fromRule) {
          yield put(fetchRuleCustomers(response.data.rule_revision_uuid));
        }
        if (scope) {
          yield put(selectCustomerConfig(customerId));
          yield put(setHighlightCustomerScope(customerId));
        }
        if (redirect) {
          let customerDomain = 'global';
          if (!redirect.includes('global')) {
            customerDomain =
              ((yield select(getCustomerDomain)) as ReturnType<typeof getCustomerDomain>) || '';
          }

          const path = reverse({
            routeName: redirect,
            routeParams: { ruleId: ruleGroupResponse.data.uuid },
            customerDomain,
          });
          yield put(push(path));
        }
      }
      yield put(createCategoryFulfill());
    }
  }
}

function* deleteCategoriesaga(action: ReturnType<typeof deleteCategory>): SagaReturn {
  const { payload } = action;
  const { id, ruleId, modelId, outcome } = payload;
  yield put(deleteCategoryRequest(id));

  const response = (yield call(
    [LitLingoClientV2.resources.categories, 'delete'],
    id
  )) as API.Response<APIV2.Categories.Delete>;

  if (response.error != null) {
    yield put(deleteCategoryFailure(response.error));
  } else {
    yield put(deleteCategoriesuccess(id));
    if (ruleId) {
      yield put(fetchRuleCustomers(ruleId));
    }
    if (modelId) {
      yield put(deleteModelCategorySuccess({ categoryId: id, id: modelId }));
      yield put(showSuccessAlert('Deleted Model'));
    }
    if (outcome) {
      yield put(deleteCategoryOutcomeSuccess({ id }));
      yield put(showSuccessAlert('Deleted Outcome'));
    }
    // yield put(prepareUndeleteAlert({ resource: 'categories', id, fetchAction: fetchAllCategories }));
  }
  yield put(deleteCategoryFulfill());
}

function* upsertCategoriesaga(action: ReturnType<typeof upsertCategory>): SagaReturn {
  const { payload } = action;

  yield put(upsertCategoryRequest());

  const response = (yield call([LitLingoClientV2.resources.categories, 'upsert'], {
    data: payload,
    params: { relationships: ['customer', 'updated_by', 'outcome', 'rule', 'model'] },
  })) as API.Response<APIV2.Categories.Upsert>;
  if (response.error != null) {
    yield put(upsertCategoryFailure(response.error));
  } else {
    yield put(showSuccessAlert('Saved Category'));
    yield put(upsertCategoriesuccess(response.data));

    if (payload.redirect) {
      const customerDomain = 'global';
      const path = reverse({
        routeName: 'global-category-manager',
        routeParams: { categoryId: response.data.uuid },
        customerDomain,
      });
      yield put(push(path));
    }
  }

  yield put(upsertCategoryFulfill());
}

function* categoriesSaga(): SagaReturn {
  yield takeLatest(fetchAllCategories.toString(), fetchAllCategoriesSaga);
  yield takeLatest(upsertCategory.toString(), upsertCategoriesaga);
  yield takeLatest(fetchSingleCategory.toString(), fetchSingleCategoriesaga);
  yield takeLatest(createCategory.toString(), createCategoriesaga);
  yield takeLatest(createCategoryRule.toString(), createCategoryRuleSaga);
  yield takeEvery(deleteCategory.toString(), deleteCategoriesaga);
}

export default categoriesSaga;
