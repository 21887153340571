import { deleteCategory, upsertCategory, upsertModelCategory } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import Modal from 'components/Modal';
import SelectOutcomeModal from 'components/Outcome/SelectOutcomeModal';
import { NEW_EDIT_ICON } from 'constants/commonIcons';
import platform, {
  allPlatforms,
  areasToMonitor,
  notImplementedPlatforms,
} from 'constants/platform';
import useNavParams from 'hooks/nav/useNavParams';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Category } from 'types';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
  categoryToEdit: Category;
  isCreating?: boolean;
  fromModel?: boolean;
};

type PlatformsType = {
  [K in keyof typeof platform]?: boolean;
};

type AreasToMonitorType = {
  [K in keyof typeof areasToMonitor]?: boolean;
};

const EditCategoryModal: React.FC<ComponentProps> = ({
  isModalOpen,
  toggleModalOpen,
  categoryToEdit,
  isCreating,
  fromModel = false,
}) => {
  const dispatch = useDispatch();
  const { customer_owned: customerOwned } = useNavParams() as { customer_owned: string };

  const category = categoryToEdit;

  const [isDeletingCategory, setIsDeletingCategory] = useState(false);
  const [isSelectingOutcome, setIsSelectingOutcome] = useState(false);

  const [outcome, setOutcome] = useState<{ name: string | undefined; uuid: string }>({
    name: category.outcome?.name,
    uuid: category.outcome_uuid,
  });

  const platformDefaults: PlatformsType = {};
  (Object.keys(platform) as Array<keyof typeof platform>).forEach((p) => {
    platformDefaults[p] = category ? category[p] : false;
  });

  const areasDefaults: AreasToMonitorType = {};
  (Object.keys(areasToMonitor) as Array<keyof typeof areasToMonitor>).forEach((p) => {
    areasDefaults[p] = category ? category[p] : false;
  });

  const { register, getValues, handleSubmit } = useForm<
    PlatformsType &
      AreasToMonitorType & {
        name: string;
        description: string;
        active: string;
      }
  >({
    defaultValues: {
      // starship active: category?.active.toString(),
      ...platformDefaults,
      ...areasDefaults,
    },
  });

  const deleteButtonStyle = 'text-litlingo-alert underline self-end focus:outline-none';

  const onSubmit = (): void => {
    const values = getValues();

    if (fromModel) {
      dispatch(
        upsertModelCategory({
          ...category,
          // starship active: active === 'true',
          redirect: isCreating,
          config: { ...category.config, ...values },
          outcome_uuid: outcome.uuid,
        })
      );
    } else {
      dispatch(
        upsertCategory({
          ...category,
          // starship active: active === 'true',
          redirect: isCreating,
          config: { ...category.config, ...values },
          outcome_uuid: outcome.uuid,
        })
      );
    }
    toggleModalOpen();
  };

  const handleSaveButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const toggleConfirmModal = (): void => setIsDeletingCategory(!isDeletingCategory);
  const toggleOutcomeModal = (): void => setIsSelectingOutcome(!isSelectingOutcome);

  const handleDelete = (): void => {
    if (categoryToEdit) {
      dispatch(
        deleteCategory({
          id: categoryToEdit.uuid,
          modelId: categoryToEdit.model_uuid,
          redirect: false,
        })
      );
      setIsDeletingCategory(false);
      toggleConfirmModal();
      toggleModalOpen();
    }
  };

  const handleSelectOutcome = (o: { name: string; uuid: string }): void => {
    setOutcome(o);
    toggleOutcomeModal();
  };

  const renderOutcome = (): JSX.Element => (
    <div className="flex flex-row gap-1">
      <span className="text-heading-3">{outcome.name}</span>
      <button type="button" className="w-4 focus:outline-none" onClick={toggleOutcomeModal}>
        {NEW_EDIT_ICON('text-litlingo-primary-120')}
      </button>
    </div>
  );

  const renderPlatforms = (): JSX.Element[] =>
    Object.entries(allPlatforms).map(([key, p]) => (
      <label
        htmlFor={`${key}-checkbox`}
        className="flex flex-row gap-2 text-body items-center"
        key={key}
      >
        <input
          disabled={Object.keys(notImplementedPlatforms).includes(key)}
          id={`${key}-checkbox`}
          ref={register()}
          type="checkbox"
          name={key}
          className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out"
        />
        <div className="flex flex-row gap-1 text-body">{p}</div>
      </label>
    ));

  const renderAreas = (): JSX.Element[] =>
    Object.entries(areasToMonitor).map(([key, p]) => (
      <label
        htmlFor={`${key}-checkbox`}
        className="flex flex-row gap-2 text-body items-center"
        key={key}
      >
        <input
          disabled={Object.keys(notImplementedPlatforms).includes(key)}
          id={`${key}-checkbox`}
          ref={register()}
          type="checkbox"
          name={key}
          className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out"
        />
        <div className="flex flex-row gap-1 text-body">{p}</div>
      </label>
    ));

  const renderModelForm = (
    <div className="pt-4 pb-4">
      <form className="flex flex-col items-start">
        <div className="flex flex-col items-start gap-4 w-full">
          {customerOwned && renderOutcome()}

          <div className="w-full border-b border-litlingo-gray-1" />

          <div className="w-full">
            <span className="text-heading-3">Settings</span>
            <div className="flex flex-row pt-2">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-body">Platforms</span>
                <div className="flex flex-col gap-2 pl-4">{renderPlatforms()}</div>
              </div>
              <div className="flex flex-col w-full">
                <span className="text-body">Areas to monitor</span>
                <div className="flex flex-col gap-2 pl-4">{renderAreas()}</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderModelForm}
          title="Edit Category"
          okButton
          okButtonText="Save"
          okButtonOnClick={handleSaveButton}
          toggleShowModal={toggleModalOpen}
          secondaryButton={categoryToEdit !== undefined}
          secondaryButtonOnclick={toggleConfirmModal}
          secondaryButtonStyle={deleteButtonStyle}
          secondaryButtonText="Delete Category"
        />
      )}
      {isDeletingCategory && (
        <ConfirmModal
          text="Are you sure you want to delete this Category?"
          okButtonText="Yes"
          cancelButtonText="No"
          data-testid="delete-modal"
          okButtonOnClick={handleDelete}
          toggleShowModal={toggleConfirmModal}
        />
      )}
      {isSelectingOutcome && (
        <SelectOutcomeModal toggleModalOpen={toggleOutcomeModal} onSelect={handleSelectOutcome} />
      )}
    </>
  );
};

export default EditCategoryModal;
