import { clearTree, requestTreeFiltersToogle } from 'actions';
import { allowedReadOnlyDimensions, metricsLinksMap } from 'constants/dashboard';
import { INFO_ICON } from 'constants/dashboardIcons';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetError from 'decorators/widget/WidgetError';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getNavParamsByResource, getNavWidgetFilters } from 'selectors/nav';
import { getPermissionsPolicy } from 'selectors/permissions';
import { DashboardMetric, DefaultWidgetComponentProps } from 'types';
import logEvent from 'utils/analytics';
import { buildPeriodTrendAndSimpleDataFromMetrics } from 'utils/dashboard';
import { getValuesFromDimensions, setNewValue, transformToString } from 'utils/parserTree';
import { isActionAuthorized } from 'utils/permissions';
import { useHistory } from 'utils/urls';

export const PlatformsWidget: React.FC<DefaultWidgetComponentProps> = (props) => {
  const { widgetData, queryData, widgetId } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUser);
  const policy = useSelector(getPermissionsPolicy);
  const customerDomain = useSelector(getCustomerDomain);
  const widgetFilters = useSelector(getNavWidgetFilters(widgetId));
  const filters = useSelector(getNavParamsByResource(resourceQueryParamName.metrics));

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  const processedData = buildPeriodTrendAndSimpleDataFromMetrics(widgetData, queryData, true);

  if ('error' in processedData && processedData.error != null) {
    return <WidgetError msg={processedData.error} />;
  }

  if ('data' in processedData && processedData.data.length === 0) {
    return <WidgetEmptyState />;
  }

  const handleClick = (query: DashboardMetric): void => {
    const [id, type] = widgetId.split('--');
    logEvent(`Dashboard-click-${id}-${type}`);
    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];

    if (info == null || !isActionAuthorized(info.action, user.roles, policy)) {
      return;
    }

    const dimensions: Record<string, string | string[]> = {};
    const infoDimension = info.dimensions;
    if (infoDimension != null) {
      // Top level dimensions
      Object.entries(infoDimension).forEach(([dimension, param]) => {
        const allowed = allowedReadOnlyDimensions[query.metric];

        // if allowedDimension is not defined or the dimension is not included,
        // just ignore it
        if (allowed == null || !allowed.includes(dimension)) {
          return;
        }

        let value: string | string[] = '';
        if (filters[dimension] != null) {
          const topValue = filters[dimension];
          value = topValue;
        }

        if (widgetFilters[dimension] != null) {
          const widgetValue = filters[dimension];
          value = widgetValue;
        }

        dimensions[`${info.resource}__${param}`] = value;
      });
      // Metric level dimensions
      if (query.dimensions != null) {
        query.dimensions.forEach((dimension) => {
          const allowed = allowedReadOnlyDimensions[query.metric];

          // if allowedDimension is not defined or the dimension is not included,
          // just ignore it
          if (allowed == null || !allowed.includes(dimension.name)) {
            return;
          }

          if (dimension.name === 'active_prevent') {
            dimensions[`${info.resource}__key_actions_and`] = 'sent_within_policy';
          }

          if (!dimensions[`${info.resource}__${infoDimension[dimension.name]}`]) {
            dimensions[`${info.resource}__${infoDimension[dimension.name]}`] = dimension.value;
          }
        });
      }

      if (query.metric.includes('active_prevent')) {
        if (query.metric === 'active_prevent') {
          dimensions[`${info.resource}__key_actions_and`] = 'sent_within_policy';
        }

        if (query.metric === 'active_prevent_sent_anyways') {
          dimensions[`${info.resource}__key_actions_and`] = 'send_anyways';
        }
      }
    }

    const dayAdjust = moment.duration(1, 'days').asSeconds();
    // Metric level dimensions have more priority than top leve dimensions

    const start = moment(
      (queryData.end_time -
        (moment
          .duration(query.period, query.unit_of_time as moment.unitOfTime.DurationConstructor)
          .asSeconds() -
          dayAdjust)) *
        1000
    )
      .startOf('day')
      .utc()
      .format(FULL_DATE_FORMAT);

    const end = moment.utc(queryData.end_time * 1000).format(FULL_DATE_FORMAT);

    const tree = getValuesFromDimensions(dimensions);

    const newTree = setNewValue(tree, 'date_range', `${start}<>${end}`, '', true);

    dispatch(requestTreeFiltersToogle({ value: true }));
    dispatch(clearTree());
    history.pushLookup({
      customerDomain,
      routeName: info.route,
      queryParams: {
        [`${info.resource}__filters_search`]: transformToString(newTree),
        [`${info.resource}__has_events`]: ['true', 'false'],
        ...dimensions,
      },
    });
  };

  return (
    <div className="h-full w-full grid grid-cols-2 gap-4 pt-1 pb-2">
      {'data' in processedData &&
        processedData.data.map(
          (data, idx) =>
            queryData.queries[idx]?.id && (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`${queryData.queries[idx]?.id}--${idx}`}
                className={`${idx === 0 ? 'border-r border-litlingo-gray-1' : ''}`}
              >
                <div className="flex flex-row justify-start items-center gap-4 text-base leading-5 text font-bold">
                  <div className="min-w-10 w-10 self-start">{data.icon && data.icon}</div>
                  <div>
                    <div
                      className="cursor-pointer hover:underline text-xl font-bold mt-1"
                      aria-hidden
                      onClick={(): void => handleClick(queryData.queries[idx])}
                    >
                      {data.count}
                    </div>
                    <div className="font-normal text-xss">{data.label}</div>
                  </div>
                </div>
                <div className="text-litlingo-gray-4 mt-2 text-xss">{data.perChange}</div>
              </div>
            )
        )}
    </div>
  );
};

export default withWidget({
  fetchAvgPeriod: true,
  icon: INFO_ICON,
  iconContent: 'The average is based on the last number time',
})(PlatformsWidget);
