import { CLOSE_ICON } from 'constants/envelopeIcons';
import { SEARCH_ICON } from 'constants/filterIcons';
import React, { useState } from 'react';

type ComponentProps = {
  placeholder?: string;
  className?: string;
  handleSearch: (value: string) => void;
  focused: boolean;
  setFocused: React.Dispatch<React.SetStateAction<boolean>>;
};

const ContextSearch: React.FC<ComponentProps> = (props) => {
  const { placeholder, className, handleSearch, setFocused, focused } = props;
  const [body, setBody] = useState<string>('');

  const handleSearchChange = (eventValue: string): void => {
    handleSearch(eventValue);
  };

  return (
    <div className={`relative h-8 w-104 ${className}`}>
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <div className="absolute left-0 ml-1.5 w-5">{SEARCH_ICON()}</div>
      </div>

      {focused && (
        <div className="absolute inset-y-0 right-0 pl-3 flex items-center">
          <button
            type="button"
            className="absolute focus:outline-none"
            style={{ right: 8 }}
            onClick={(): void => {
              setFocused(false);
              handleSearchChange('');
              setBody('');
            }}
          >
            {CLOSE_ICON}
          </button>
        </div>
      )}
      <input
        id="search"
        name="search"
        className="form-input h-full w-full text-litlingo-gray-6 pl-8 text-body placeholder-italic placeholder-litlingo-gray-4"
        placeholder={placeholder || 'Search'}
        onChange={(event): void => {
          handleSearchChange(event.target.value);
          setBody(event.target.value);
        }}
        onFocus={(): void => setFocused(true)}
        value={body}
        autoComplete="off"
      />
    </div>
  );
};

export default ContextSearch;
