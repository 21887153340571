/*  eslint-disable camelcase */
import { annotateEventSentences, Annotation } from '@litlingo/react';
import { createSelector } from '@reduxjs/toolkit';
import {
  addBulkEnvelopeTagsRequest,
  addEnvelopeTagRequest,
  exportEnvelopesSearch,
  fetchAllEnvelopesRequest,
  fetchEnvelopeThreadRequest,
  fetchMoreLikeThisRequest,
  fetchSingleEnvelopeRequest,
  removeEnvelopeTagRequest,
  reprocessEnvelopeRequest,
  reviewAndContinueNextEnvelopeRequest,
  reviewEnvelopeRequest,
} from 'actions';
import { SummaryTagType } from 'components/Envelope/SummaryTag';
import { GlobalState } from 'reducers';
import { getSingleEvent } from 'selectors/events';
import type {
  Action,
  Communication,
  CommunicationEnvelope,
  CommunicationEnvelopeEsDocs,
  CommunicationEnvelopeEsDocsAsIndexed,
  CommunicationEnvelopeSummary,
  EntityTag,
  EnvelopeAction,
  MoreLikeThisPoint,
  RenderedAction,
  Review,
  Selector,
  UUID,
} from 'types';
import {
  IGNORE_TYPES,
  MESSAGE_IGNORE_TYPES,
  REVIEW_IGNORE_TYPES,
  TERMINAL_ACTION_WITHIN_POLICY,
  TERMINAL_ACTIONS,
  TERMINAL_ACTIONS_OUT_OF_POLICY,
} from 'utils/activityLogLookup';
import sortCommunications from 'utils/communications';
import {
  DataNode,
  getAllFieldsValuesWithLabels,
  lookAllForField,
  Tree,
  TreeFieldsValuesWithLabels,
  ValueNode,
} from 'utils/parserTree';
import { getSurroundingContextIdentifiersIds, getSurroundingContextRulesIds } from './auth';
import getSelectedEnvelope from './envelopeReview';

export const getFetchAllEnvelopesLoading: Selector<boolean> = (state) =>
  state.envelopes.loading.includes(fetchAllEnvelopesRequest.toString());

export const addBulkEnvelopeTagsLoading: Selector<boolean> = (state) =>
  state.envelopes.loading.includes(addBulkEnvelopeTagsRequest.toString());

export const getFetchSingleEnvelopeLoading: Selector<boolean> = (state) =>
  state.envelopes.loading.includes(fetchSingleEnvelopeRequest.toString());

export const getEnvelopes: Selector<CommunicationEnvelopeSummary[]> = createSelector(
  [(state: GlobalState): GlobalState['envelopes']['summaries'] => state.envelopes.summaries],
  (summaries) => Object.values(summaries)
);

export const getEnvelope: Selector<CommunicationEnvelope, [UUID]> = (state, envelopeId) =>
  state.envelopes.envelopes[envelopeId] || null;

export const getEnvelopeSummary: Selector<CommunicationEnvelopeSummary, [UUID]> = (
  state,
  envelopeId
) => state.envelopes.summaries[envelopeId];

export const getEnvelopeThreadLoading: Selector<boolean> = (state) =>
  state.envelopes.loading.includes(fetchEnvelopeThreadRequest.toString());

export const getEnvelopeThread: Selector<CommunicationEnvelopeSummary[]> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopes']['envelopeThread'] =>
      state.envelopes.envelopeThread,
  ],
  (threads) => Object.values(threads)
);

export const getEnvelopeThreadCount: Selector<number> = (state) =>
  state.envelopes.envelopeThreadCount;

export const getThreadHasHitsFromSelectedCampaign: Selector<boolean, [UUID]> = (
  state,
  envelopeId
) => {
  const { selectedCampaign, selectedRule } = state.envelopeReview;

  if (selectedRule) return false;

  const envelopeEvents = state.envelopes?.envelopeThread[envelopeId]?.events;

  return envelopeEvents.some((e) => e.campaign_uuid === selectedCampaign);
};

export const getThreadHasHitsFromSelectedRule: Selector<boolean, [UUID]> = (state, envelopeId) => {
  const { selectedRule } = state.envelopeReview;
  const envelopeEvents = state.envelopes?.envelopeThread[envelopeId]?.events;

  return envelopeEvents.some((e) => e.rule_uuid === selectedRule?.uuid);
};

export const getThreadHasHitsFromSelected: Selector<boolean, [UUID]> = (state, envelopeId) =>
  getThreadHasHitsFromSelectedCampaign(state, envelopeId) ||
  getThreadHasHitsFromSelectedRule(state, envelopeId);

export const getThreadsWithMatches: Selector<CommunicationEnvelopeSummary[]> = (state) => {
  const envelopes = state.envelopes?.envelopeThread;
  const { selectedEnvelope } = state.envelopeReview;

  return Object.values(envelopes)
    .filter((env) => env.envelope.platform_thread_guid === selectedEnvelope?.platform_thread_guid)
    .filter((e) => e.events && e.events.length > 0);
};

export const getEnvelopesListIds: Selector<UUID[]> = (state) => state.envelopes.listIds;

export const getEnvelopesFlaggedTextListIds: Selector<UUID[]> = (state) =>
  state.envelopes.flaggedTextlistIds;

export const getAnnotatedSetencesFromSummary =
  (
    envelopeId: UUID,
    eventId: UUID
  ): Selector<ReturnType<typeof annotateEventSentences>['sentences'] | null> =>
  ({ envelopes }): ReturnType<typeof annotateEventSentences>['sentences'] | null => {
    const envelopeSummary = envelopes.summaries[envelopeId];
    const event = envelopeSummary.events.find((e) => e.uuid === eventId);

    if (event == null || event.annotations == null || event.sentences == null) {
      return null;
    }

    return annotateEventSentences(event.sentences, event.annotations).sentences;
  };

export const getSentencesFromSummary =
  (
    envelopeId: UUID,
    eventId: UUID
  ): Selector<ReturnType<typeof annotateEventSentences>['sentences'] | null> =>
  ({ envelopes }): ReturnType<typeof annotateEventSentences>['sentences'] | null => {
    const envelopeSummary = envelopes.summaries[envelopeId];
    const event = envelopeSummary.events.find((e) => e.uuid === eventId);

    if (event == null || event.sentences == null) {
      return null;
    }

    return annotateEventSentences(event.sentences, []).sentences;
  };

export const getAnnotatedSetencesFromSummaryByCommunication =
  (
    envelopeId: UUID,
    communicationId: UUID,
    selectedRuleId: UUID
  ): Selector<ReturnType<typeof annotateEventSentences>['sentences'] | null> =>
  ({ envelopes }): ReturnType<typeof annotateEventSentences>['sentences'] | null => {
    const envelopeSummary = envelopes.summaries[envelopeId];
    const events = envelopeSummary?.events.filter((e) => e.communication_uuid === communicationId);

    if (
      !events ||
      events.length === 0 ||
      events[0].annotations == null ||
      events[0].sentences == null
    ) {
      return null;
    }

    if (!selectedRuleId) {
      const sentences = events.reduce(
        (acc, e) => ({ ...acc, ...e.sentences }),
        {} as CommunicationEnvelopeSummary['events'][number]['sentences']
      );
      if (!sentences) return null;

      return annotateEventSentences(sentences, []).sentences;
    }

    const sentences = events.reduce(
      (acc, e) => (e.rule_uuid === selectedRuleId ? { ...acc, ...e.sentences } : acc),
      {} as CommunicationEnvelopeSummary['events'][number]['sentences']
    );
    if (!sentences) return null;

    let annotations: Annotation[] = [];

    events.forEach((e) => {
      if (e.rule_uuid === selectedRuleId && e.annotations) {
        annotations = [...annotations, ...e.annotations];
      }
    });

    const highlightedAnnotations = annotations.map((a) => ({
      ...a,
      highlight: true,
    }));

    return annotateEventSentences(sentences, highlightedAnnotations).sentences;
  };

export const getNoMatchSetencesFromSummaryByCommunication: Selector<
  ReturnType<typeof annotateEventSentences>['sentences'] | null,
  [UUID]
> = (state, envelopeId) => {
  const envelopeSummary = state.envelopes.summaries[envelopeId];
  const event = envelopeSummary?.noMatchEvent;

  if (!event || !event.sentences || Object.values(event.sentences).length === 0) {
    return null;
  }

  return annotateEventSentences(event.sentences, []).sentences;
};

export const getCommEnvelopeActions: Selector<RenderedAction[], [UUID]> = (state, envelopeId) => {
  const renderedActions = state.envelopes.envelopes[envelopeId]?.action_summary;
  const actions = renderedActions?.filter((action) => !IGNORE_TYPES.includes(action.type));
  if (!actions) {
    return [];
  }
  return actions.slice().sort((a, b) => {
    const dateA: Date = a.created_at ? new Date(a.created_at) : new Date();
    const dateB: Date = b.created_at ? new Date(b.created_at) : new Date();
    return dateB.getTime() - dateA.getTime();
  });
};

export const getCommEnvelopeMessageHistory: Selector<RenderedAction[], [UUID]> = (
  state,
  envelopeId
) => {
  const renderedActions = state.envelopes.envelopes[envelopeId]?.action_summary;
  const actions = renderedActions?.filter((action) => !MESSAGE_IGNORE_TYPES.includes(action.type));
  if (!actions) {
    return [];
  }
  return actions.slice().sort((a, b) => {
    const dateA: Date = a.created_at ? new Date(a.created_at) : new Date();
    const dateB: Date = b.created_at ? new Date(b.created_at) : new Date();
    return dateB.getTime() - dateA.getTime();
  });
};

export const getCommEnvelopeReviewHistory: Selector<RenderedAction[], [UUID]> = (
  state,
  envelopeId
) => {
  const renderedActions = state.envelopes.envelopes[envelopeId]?.action_summary;
  const actions = renderedActions?.filter((action) => !REVIEW_IGNORE_TYPES.includes(action.type));
  if (!actions) {
    return [];
  }
  return actions.slice().sort((a, b) => {
    const dateA: Date = a.created_at ? new Date(a.created_at) : new Date();
    const dateB: Date = b.created_at ? new Date(b.created_at) : new Date();
    return dateB.getTime() - dateA.getTime();
  });
};

export const getEnvelopesTotalCount: Selector<number> = (state) => state.envelopes.count;

export const getPreviousSingleEnvelope: Selector<
  (CommunicationEnvelope & { page_id?: string }) | null,
  [UUID]
> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopes']['summaries'] => state.envelopes.summaries,
    (state: GlobalState): GlobalState['envelopes']['listIds'] => state.envelopes.listIds,
    (_state: GlobalState, envelopeId: string): string => envelopeId,
  ],
  (summaries, listIds, envelopeId): (CommunicationEnvelope & { page_id?: string }) | null => {
    const currentIdx = listIds.indexOf(envelopeId);

    if (currentIdx === -1 || currentIdx === 0) {
      return null;
    }

    return {
      ...summaries[listIds[currentIdx - 1]].envelope,
      page_id: summaries[listIds[currentIdx - 1]].page_id,
    };
  }
);

export const getNextSingleEnvelope: Selector<
  (CommunicationEnvelope & { page_id?: string }) | null,
  [UUID]
> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopes']['summaries'] => state.envelopes.summaries,
    (state: GlobalState): GlobalState['envelopes']['listIds'] => state.envelopes.listIds,
    (_state: GlobalState, envelopeId: string): string => envelopeId,
  ],
  (summaries, listIds, envelopeId): (CommunicationEnvelope & { page_id?: string }) | null => {
    const currentIdx = listIds.indexOf(envelopeId);

    if (currentIdx === -1 || currentIdx === listIds.length - 1) {
      return null;
    }

    return {
      ...summaries[listIds[currentIdx + 1]].envelope,
      page_id: summaries[listIds[currentIdx + 1]].page_id,
    };
  }
);

export const getSingleEnvelopeTags =
  (envelopeId: UUID): Selector<EntityTag[]> =>
  (state): EntityTag[] => {
    const envelopeSummary = getEnvelope(state, envelopeId);
    if (envelopeSummary == null || envelopeSummary.tags == null) {
      return [];
    }

    return envelopeSummary.tags;
  };

export const getIsRead: Selector<boolean, [UUID]> = (state, envelopeId) => {
  const envelopeSummary = getEnvelopeSummary(state, envelopeId);
  if (envelopeSummary == null) {
    return true;
  }
  return envelopeSummary.envelope.is_read;
};

export const getReviewAndContinueLoading: Selector<boolean> = (state) =>
  state.envelopes.loading.includes(reviewAndContinueNextEnvelopeRequest.toString());

export const getReviewEnvelopeLoading: Selector<boolean> = (state) =>
  state.envelopes.loading.includes(reviewEnvelopeRequest.toString());

export const addEnvelopeTagLoading: Selector<boolean> = (state) =>
  state.envelopes.loading.includes(addEnvelopeTagRequest.toString());

export const removeEnvelopeTagLoading: Selector<boolean> = (state) =>
  state.envelopes.loading.includes(removeEnvelopeTagRequest.toString());

export const getMoreLikeThis: Selector<MoreLikeThisPoint[]> = (state) =>
  state.envelopes.moreLikeThis;

export const getMoreLikeThisLoading: Selector<boolean> = (state) =>
  state.envelopes.loading.includes(fetchMoreLikeThisRequest.toString());

export const hasWithinPolicy: Selector<boolean, [UUID]> = (state, envelopeId) => {
  const envelope = getEnvelope(state, envelopeId);
  if (!envelope || !envelope.events) return false;
  let exists = false;
  envelope.events.forEach((envelopeEvent) => {
    const event = getSingleEvent(state, envelopeEvent.uuid);
    if (
      event &&
      event.actions &&
      event.actions.find((el) => el.type === TERMINAL_ACTION_WITHIN_POLICY)
    )
      exists = true;
  });

  return exists;
};

export const envelopeWithinPolicy: Selector<boolean, [UUID]> = (state, envelopeId) => {
  const envelope = getEnvelope(state, envelopeId);
  if (!envelope || !envelope.action_summary) return false;
  let exists = false;
  envelope.action_summary.forEach((action) => {
    if (action.type === TERMINAL_ACTION_WITHIN_POLICY) {
      exists = true;
    }
  });

  return exists;
};

export const envelopeOutOfPolicy: Selector<boolean, [UUID]> = (state, envelopeId) => {
  const envelope = getEnvelope(state, envelopeId);
  if (!envelope || !envelope.action_summary) return false;
  let exists = false;
  envelope.action_summary.forEach((action) => {
    if (TERMINAL_ACTIONS_OUT_OF_POLICY.includes(action.type)) {
      exists = true;
    }
  });

  return exists;
};

export const getWithinPolicyValue: Selector<Action['value'] | null, [UUID]> = (
  state,
  envelopeId
) => {
  const envelope = getEnvelope(state, envelopeId);
  if (!envelope || !envelope.events) return null;
  let payload = null;
  envelope.events.forEach((event) => {
    if (event.actions) {
      event.actions.forEach((action) => {
        if (action.type === TERMINAL_ACTION_WITHIN_POLICY) {
          payload = action.value;
        }
      });
    }
  });

  return payload;
};

export const getCommunicationFromEnvelope: Selector<Communication, [UUID]> = (state, commId) => {
  const envelopes = Object.values(state.envelopes.envelopes);
  let communication = {} as Communication;
  envelopes.forEach((envelope) => {
    const selected = envelope.communications?.find((element) => element.uuid === commId);
    if (selected) communication = selected;
  });

  return communication;
};

export const getEnvelopeEsDocs: Selector<CommunicationEnvelopeEsDocs> = (state) =>
  state.envelopes.esDocs;

export const getEnvelopeEsDocsAsIndexed: Selector<CommunicationEnvelopeEsDocsAsIndexed> = (state) =>
  state.envelopes.esDocsAsIndexed;

export const getExportEnvelopesSEarchLoading: Selector<boolean> = (state) =>
  state.envelopes.loading.includes(exportEnvelopesSearch.toString());

export const getReprocessEnvelopeLoading: Selector<boolean> = (state) =>
  state.envelopes.loading.includes(reprocessEnvelopeRequest.toString());

export const getFetchFlaggedTextLoading: Selector<boolean, [UUID]> = (state, envelopeId) =>
  state.envelopes.loading.includes(envelopeId);

export const getEnvelopeInFlaggedIds: Selector<boolean, [UUID]> = (state, envelopeId) =>
  state.envelopes.flaggedTextlistIds.includes(envelopeId);

export const getSingleEnvelopeComments: Selector<EnvelopeAction[], [UUID]> = (
  state,
  envelopeId
) => {
  let comments: EnvelopeAction[] = [];
  const rawActions = state.envelopes.envelopes[envelopeId]?.actions;
  if (!rawActions) {
    return comments;
  }

  comments = rawActions
    .filter((item) => item.type === 'comment')
    .sort((a, b) => {
      if (a.created_at == null || b.created_at == null) {
        return 0;
      }

      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return dateB.getTime() - dateA.getTime();
    });

  return comments;
};

export const getRecipientsFromEnvelope: Selector<Communication['recipients'], [UUID]> = (
  state,
  envelopeId
) => {
  const envelope = state.envelopes.envelopes[envelopeId];
  if (!envelope) return [];
  const { communications } = envelope;

  const initialValue: Communication['recipients'] = [];

  if (communications) {
    const recipients = communications.reduce<Communication['recipients']>((rec, comm) => {
      if (comm.recipients) {
        const t = [...rec, ...comm.recipients];
        return t;
      }
      return rec;
    }, initialValue);

    return recipients;
  }
  return [];
};

export const getRecipientsFromCommunication: Selector<Communication['recipients']> = (state) => {
  const communication = state.communication.selectedCommunication;

  if (!communication) return [];

  const { recipients } = communication;

  if (recipients) return recipients;

  return [];
};

export const getToRecipientsFromCommunication: Selector<Communication['recipients']> = (state) => {
  const communication = state.communication.selectedCommunication;

  if (!communication) return [];

  const { recipients } = communication;

  if (recipients) return recipients.filter((r) => r.role === 'to');

  return [];
};

export const getCcRecipientsFromCommunication: Selector<Communication['recipients']> = (state) => {
  const communication = state.communication.selectedCommunication;

  if (!communication) return [];

  const { recipients } = communication;

  if (recipients) return recipients.filter((r) => r.role === 'cc');

  return [];
};

export const getBccRecipientsFromCommunication: Selector<Communication['recipients']> = (state) => {
  const communication = state.communication.selectedCommunication;

  if (!communication) return [];

  const { recipients } = communication;

  if (recipients) return recipients.filter((r) => r.role === 'bcc');

  return [];
};

export const getLastReview: Selector<Review | null, [UUID]> = (state, envelopeId) => {
  const envelope = state.envelopes.envelopes[envelopeId];
  if (!envelope) return null;

  const reviews: Review[] = envelope.reviews ? [...envelope.reviews] : [];

  if (!reviews || reviews.length === 0) return null;

  reviews.sort((a, b) => (a.updated_at && b.updated_at && a.updated_at > b.updated_at ? -1 : 1));

  return reviews[0];
};

export const getEnvelopeTeamsNames: Selector<string[], [UUID]> = createSelector(
  [getEnvelope],
  (envelope) => {
    if (envelope && envelope.created_by && envelope.created_by.teams) {
      return envelope.created_by?.teams?.map((t) => t.name);
    }

    return [];
  }
);

export const getEnvelopeIsInternal: Selector<boolean, [UUID]> = (state, envelopeId) => {
  const envelope = state.envelopes.envelopes[envelopeId];

  if (envelope) return envelope.internal;

  return false;
};

export const getEnvelopeIsPartOfThread: Selector<boolean> = (state) => {
  const threads = state.envelopes.envelopeThread;

  if (Object.values(threads).length > 1) return true;
  return false;
};

export const getRecipientsByPlatform = (
  comm: Communication,
  envelope: CommunicationEnvelope
): Communication['recipients'] => {
  if (comm.recipients && comm.recipients.length > 1)
    if (comm.platform === 'gmail') {
      return comm.recipients.filter(
        (r) => r.role !== 'from' && r.id !== envelope.created_by?.email
      );
    }

  if (comm.platform === 'o365') {
    return comm.recipients.filter(
      (r) => r.role !== 'from' && r.id !== envelope.created_by?.email_index
    );
  }

  if (comm.platform === 'slack') {
    return comm.recipients.filter(
      (r) => r.role !== 'from' && r.id !== envelope.created_by?.slack_id
    );
  }

  if (comm.platform === 'o365_teams') {
    return comm.recipients.filter(
      (r) => r.role !== 'from' && r.display_name !== envelope.created_by?.name
    );
  }

  return (comm.recipients || []).filter((r) => r.role !== 'from');
};

const isSlackChannel = (envelope: CommunicationEnvelope): boolean => {
  if (envelope.platform === 'slack') {
    return envelope.communication_type === 'channel';
  }

  return false;
};

const isTeamsChannel = (envelope: CommunicationEnvelope): boolean => {
  if (envelope.platform === 'o365_teams') {
    return envelope.communications?.some((c) => c.communication_type === 'channel') || false;
  }
  return false;
};

export const getEnvelopeHasSeveralRecipients: Selector<boolean, [UUID]> = (state, envelopeId) => {
  const envelope = state.envelopes.envelopes[envelopeId];
  if (envelope && envelope.communications) {
    const recipients = envelope.communications.reduce<Communication['recipients']>((rec, com) => {
      const comRecipients = getRecipientsByPlatform(com, envelope);
      return [...rec, ...comRecipients];
    }, []);

    const uniqueRecipients = recipients.filter(
      (r, idx, arr) => arr.findIndex((re) => re.id === r.id) === idx
    );

    return uniqueRecipients.length > 1 || isSlackChannel(envelope) || isTeamsChannel(envelope);
  }
  return false;
};

export const getCommunicationHasAttachments: Selector<boolean> = (state) => {
  const communication = state.communication.selectedCommunication;

  if (communication?.attachments) {
    return communication.attachments.length > 0;
  }

  return false;
};

export const getSurroundingContextRulesNames: Selector<string[]> = createSelector(
  [getSurroundingContextRulesIds, getSelectedEnvelope],
  (surroundingContextRulesIds, envelope) => {
    const surroundingContextRulesNames: string[] = [];

    if (surroundingContextRulesIds) {
      surroundingContextRulesIds.forEach((sRule) => {
        const event = envelope?.events?.find(
          (e) => e.rule?.uuid === sRule || e.rule_uuid === sRule
        );
        if (event && event.rule) {
          surroundingContextRulesNames.push(event.rule?.name);
        }
      });
    }

    return surroundingContextRulesNames;
  }
);

export const getSurroundingContextIdentifiersNames: Selector<string[]> = createSelector(
  [
    getSurroundingContextIdentifiersIds,
    getSelectedEnvelope,
    (state: GlobalState): GlobalState['events']['events'] => state.events.events,
  ],
  (surroundingContextIdentifiersIds, envelope, events) => {
    const filteredEvents = Object.values(events).filter((e) =>
      envelope?.events?.some((ev) => ev.uuid === e.uuid)
    );

    const surroundingContextIdentifiersNames: string[] = [];

    if (surroundingContextIdentifiersIds) {
      surroundingContextIdentifiersIds.forEach((sIdentifier) => {
        const event = filteredEvents?.find((e) =>
          e.annotations?.some((a) => a.annotator_uuid === sIdentifier)
        );
        const annotation = event?.annotations?.find((a) => a.annotator_uuid === sIdentifier);

        if (event && annotation) {
          surroundingContextIdentifiersNames.push(annotation.name);
        }
      });
    }

    return surroundingContextIdentifiersNames;
  }
);

export const getSurroundingContext: Selector<string[], [UUID]> = createSelector(
  [
    getEnvelopeTeamsNames,
    getEnvelopeIsPartOfThread,
    getEnvelopeHasSeveralRecipients,
    getCommunicationHasAttachments,
    getSurroundingContextRulesNames,
    getSurroundingContextIdentifiersNames,
    (state: GlobalState): GlobalState['events']['events'] => state.events.events,
    (_state, envelopeId): UUID => envelopeId,
  ],
  (
    teams,
    isPartOfThread,
    hasSeveralRecipients,
    hasAttachments,
    surroundingContextRulesNames,
    surroundingContextIdentifiersNames,
    events,
    envelopeId
  ) => {
    const context: string[] = [];

    if (teams.length > 0) {
      const text = teams.reduce((acc, t, idx, arr) => {
        if (idx === arr.length - 1) return `${acc} ${t}`;
        if (idx === arr.length - 2) return `${acc} ${t} and`;
        if (arr.length > 1) return `${acc} ${t},`;
        return `${acc} ${t}`;
      }, 'Sender is in ');

      context.push(text);
    }

    // TODO: fix the datathat drives this
    // if (getEnvelopeIsInternal(state, envelopeId)) {
    //   context.push('Sent internally');
    // } else {
    //   context.push('Sent externally');
    // }

    if (isPartOfThread) {
      context.push('Part of thread');
    }

    if (hasSeveralRecipients) {
      context.push('Between individual and group');
    } else {
      context.push('Between two individuals');
    }

    if (hasAttachments) {
      context.push('Has attachments');
    }

    surroundingContextRulesNames.forEach((ruleName) => {
      context.push(ruleName);
    });

    surroundingContextIdentifiersNames.forEach((identifierName) => {
      context.push(identifierName);
    });

    const uniqueLlmOutput = new Set();

    Object.values(events).forEach((e) => {
      if (e.communication_envelope_uuid === envelopeId && e.annotations) {
        e.annotations.forEach((anno) => {
          // @ts-ignore
          if (anno.description && !anno.model_kind?.includes('no_context')) {
            // @ts-ignore
            uniqueLlmOutput.add(anno.description);
          }
        });
      }
    });

    uniqueLlmOutput.forEach((e) => {
      // @ts-ignore
      context.push(e);
    });

    return context;
  }
);

export const getTree: Selector<Tree> = (state) => state.envelopes.filtersTree;

export const getSelectedFilters: Selector<ValueNode[]> = (state) => state.envelopes.selectedFilters;
export const getSelectedFields: Selector<DataNode[]> = (state) => state.envelopes.selectedFields;

export const getRequestTree: Selector<boolean> = (state) => state.envelopes.requestTreeFilters;

export const getAllFilterValuesFromTreeWithLabels: Selector<TreeFieldsValuesWithLabels> =
  createSelector(
    [(state: GlobalState): GlobalState['envelopes']['filtersTree'] => state.envelopes.filtersTree],
    (filtersTree) => getAllFieldsValuesWithLabels(filtersTree)
  );

export const getFilterValuesFromTree: Selector<string[], [string]> = createSelector(
  [
    (state: GlobalState): GlobalState['envelopes']['filtersTree'] => state.envelopes.filtersTree,
    (_, field): string => field,
  ],
  (filtersTree, field) => lookAllForField(filtersTree, field)
);

export const makeGetFilterValuesFromTree = (): Selector<string[], [string]> =>
  createSelector(
    [
      (state: GlobalState): GlobalState['envelopes']['filtersTree'] => state.envelopes.filtersTree,
      (_, field): string => field,
    ],
    (filtersTree, field) => lookAllForField(filtersTree, field)
  );

export const getSummaryTagFromSummaries: Selector<SummaryTagType, [UUID]> = createSelector(
  [getEnvelopeSummary],
  (summary) => {
    if (!summary) return 'none';
    const { envelope, key_actions, events } = summary;
    if (key_actions.length === 0) return 'none';
    const { communications } = envelope;

    if (
      !Object.keys(TERMINAL_ACTIONS).some((a) => key_actions.includes(a)) &&
      envelope.integration_type === 'app'
    ) {
      return 'abandoned';
    }

    if (!communications || !events) return 'reduced-all';
    const lastCommunication = [...communications].sort(sortCommunications)[0];

    let lastRules: string[] = [];
    let otherRules: string[] = [];

    events.forEach((e) => {
      if (e.rule_uuid) {
        if (e.communication_uuid === lastCommunication.uuid) {
          lastRules.push(e.rule_uuid);
        }
        otherRules.push(e.rule_uuid);
      }
    });

    lastRules = [...new Set(lastRules)];
    otherRules = [...new Set(otherRules)];

    if (!lastCommunication) return 'reduced-all';
    if (lastRules.length === 0) return 'reduced-all';

    if (lastRules.length < otherRules.length) return 'reduced-some';
    if (otherRules.some((r) => !lastRules.includes(r))) return 'reduced-some';

    return 'high-risk';
  }
);
