/* eslint-disable import/no-named-as-default */
import UserLicensesWidget from '../UserLicensesWidget';
import ActivePreventWidget from './ActivePreventWidget';
import ActivityPlatformsWidget from './ActivityPlatformsWidget';
import ActivityWidget, { ActivityWidgetSimple } from './ActivityWidget';
import AssignmentDateHistogramWithAverageWidget from './AssignmentDateHistogramWithAverageWidget';
import DateHistogramWidget from './DateHistogramWidget';
import ExportDashboardWidget from './ExportDashboardWidget';
import GreenSimpleMetricsWidget from './GreenSimpleMetricsWidget';
import HistogramSingleMetricWidget from './HistogramSingleMetricWidget';
import MetricWithTrendWidget from './MetricWithTrendWidget';
import NewSimpleMetricsWidget, { NewActivePreventWidget } from './NewSimpleMetricsWidget';
import Activity from './OldDashboard/Activity';
import ActivityForGpac from './OldDashboard/ActivityForGpac';
import ActivityWithPending from './OldDashboard/ActivityWithPending';
import Envelopes from './OldDashboard/Envelopes';
import Events from './OldDashboard/Events';
import ModelPerformance from './OldDashboard/ModelPerformance';
import PendingAssignments from './OldDashboard/PendingAssignment';
import PendingEvents from './OldDashboard/PendingEvents';
import Platforms from './OldDashboard/Platforms';
import SavedSearches from './OldDashboard/SavedSearches';
import TagAnalytics from './OldDashboard/TagAnalytics';
import ViewEvents from './OldDashboard/ViewEvents';
import WorkforceRisk from './OldDashboard/WorkforceRisk';
import PendingReviewWidget from './PendingReviewWidget';
import PieMetricWidget from './PieMetricWidget';
import PlatformsWidget, { PlatformsWidgetSimple } from './PlatformsWidget';
import PrioritiesTrendRedesignWidget from './PrioritiesTrendRedesignWidget';
import PrioritiesTrendWidget from './PrioritiesTrendWidget';
import ProgressBarWidget from './ProgressBarWidget';
import ReviewButtonWidget from './ReviewButtonWidget';
import ReviewSetMetricsWidget from './ReviewSetMetricsWidget';
import ReviewValueMetricsWidget from './ReviewValueMetricsWidget';
import SentimentWidget from './SentimentWidget';
import ShowAllWidget from './ShowAllWidget';
import SimpleMetricsWidget from './SimpleMetricsWidget';
import SingleReviewSetMetricWidget from './SingleReviewSetMetricWidget';
import SparkLineMetricWidget from './SparkLineMetricWidget';
import StackedDateHistogramLineWidget from './StackedDateHistogramLineWidget';
import StackedDateHistogramWidget from './StackedDateHistogramWidget';
import StackedHistogramWidget from './StackedHistogramWidget';
import StaticDateRange from './StaticDateRange';
import TableCollapsableWidget from './TableCollapsableWidget';
import TableTwoWidget from './TableTwoWidget';
import TableWidget from './TableWidget';
import TagsVerticalHistogram from './TagsVerticalHistogram';
import VerticalHistogramWidget from './VerticalHistogramWidget';
import WaffleWidget from './WaffleWidget';

export const widgetsMap = {
  activity: Activity,
  activity_with_pending: ActivityWithPending,
  activity_for_gpac: ActivityForGpac,
  events: Events,
  envelopes: Envelopes,
  platforms: Platforms,
  pending_events: PendingEvents,
  model_performance: ModelPerformance,
  workforce_risk: WorkforceRisk,
  tag_analytics: TagAnalytics,
  tag_histogram: TagsVerticalHistogram,
  simple_metrics: SimpleMetricsWidget,
  review_value_metrics: ReviewValueMetricsWidget,
  review_set_metrics: ReviewSetMetricsWidget,
  single_review_set_metrics: SingleReviewSetMetricWidget,
  view_events: ViewEvents,
  pending_assignment: PendingAssignments,
  export_charts: ExportDashboardWidget,
  show_all: ShowAllWidget,
  saved_searches: SavedSearches,
  date_histogram_widget: DateHistogramWidget,
  assignment_date_histogram_with_average_widget: AssignmentDateHistogramWithAverageWidget,
  progress_bar_widget: ProgressBarWidget,
  stacked_histogram_widget: StackedHistogramWidget,
  stacked_date_histogram_widget: StackedDateHistogramWidget,
  histogram_single_metric: HistogramSingleMetricWidget,
  vertical_histogram: VerticalHistogramWidget,
  waffle_widget: WaffleWidget,
  metric_with_trend: MetricWithTrendWidget,
  pie_metric: PieMetricWidget,
  platforms_widget: PlatformsWidget,
  platforms_widget_simple: PlatformsWidgetSimple,
  priorities_trend: PrioritiesTrendWidget,
  sparkline_metric: SparkLineMetricWidget,
  sentiment_widget: SentimentWidget,
  review_button_widget: ReviewButtonWidget,
  green_simple_metrics: GreenSimpleMetricsWidget,
  stacked_date_histogram_line_widget: StackedDateHistogramLineWidget,
  new_activity: ActivityWidget,
  activity_simple: ActivityWidgetSimple,
  active_prevent: ActivePreventWidget,
  static_date_range: StaticDateRange,
  table_widget: TableWidget,
  table_two_widget: TableTwoWidget,
  table_collapsable_widget: TableCollapsableWidget,
  new_activity_platforms: ActivityPlatformsWidget,
  priorities_trend_redesign: PrioritiesTrendRedesignWidget,
  pending_review_widget: PendingReviewWidget,
  new_simple_metrics_widget: NewSimpleMetricsWidget,
  new_active_prevent_widget: NewActivePreventWidget,
  user_licenses_widget: UserLicensesWidget,
};

export const widgetsDefaultByMetricMap = {
  date_histogram: 'date_histogram_widget',
};
