/* eslint-disable max-lines */
import { deleteURLParams } from 'actions';
import { checkSelectedWidgets, clearSelectedWidgets, signalExporting } from 'actions/dashboard';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDashboardState, getSelectedWidgets } from 'selectors/dashboard';
import { useSelector } from 'store';

const ExportFilter: React.FC = () => {
  const dispatch = useDispatch();
  const { activeUuid } = useParams<{ activeUuid: string }>();
  const dashboardState = useSelector(getDashboardState(activeUuid));
  const selectedWidgets = useSelector(getSelectedWidgets);

  const { widgets } = dashboardState;

  const handleExport = (): void => {
    dispatch(signalExporting());
  };

  const handleSelectWidget = (value: string): void => {
    dispatch(checkSelectedWidgets(value));
  };

  const handleCancel = (): void => {
    dispatch(deleteURLParams(['is_exporting']));
    dispatch(clearSelectedWidgets());
  };

  const options = [
    ...widgets
      // @ts-ignore
      .filter((w) => !!w.export_label)
      .sort((a, b) => {
        if (a.rows_start > b.rows_start) return 1;
        if (a.rows_start < b.rows_start) return -1;
        if (a.columns_start > b.columns_start) return 1;
        if (a.columns_start < b.columns_start) return -1;
        return 1;
      })
      // @ts-ignore
      .map((w) => ({ label: w.export_label, value: w.export_label })),
  ];

  return (
    <div className="mt-10 flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row justify-between">
          <div className="text-heading-3">
            <span className="text-white">Charts to Export</span>
          </div>
        </div>
        {selectedWidgets.length > 0 && (
          <>
            <div className="flex flex-col gap-2">
              {options
                .filter((o) => selectedWidgets.includes(o.value))
                .map((o) => (
                  <label
                    key={o.value}
                    htmlFor={o.value}
                    className="flex flex-row gap-1 items-center"
                  >
                    <input
                      type="checkbox"
                      id={o.value}
                      name={o.value}
                      checked
                      className="form-checkbox litlingo-checkbox"
                      onChange={(): void => handleSelectWidget(o.value)}
                    />
                    <span>{o.label}</span>
                  </label>
                ))}
            </div>

            <div className="border-b border-white" />
          </>
        )}
        <div className="flex flex-col gap-2">
          {options
            .filter((o) => !selectedWidgets.includes(o.value))
            .map((o) => (
              <label key={o.value} htmlFor={o.value} className="flex flex-row gap-1 items-center">
                <input
                  type="checkbox"
                  id={o.value}
                  name={o.value}
                  checked={false}
                  className="form-checkbox litlingo-checkbox"
                  onChange={(): void => handleSelectWidget(o.value)}
                />
                <span>{o.label}</span>
              </label>
            ))}
        </div>
      </div>
      <div className="flex flex-row gap-2 justify-end">
        <button
          type="button"
          className="button button--secondary justify-center h-8 w-22"
          onClick={handleCancel}
        >
          <span className="font-bold">Exit</span>
        </button>
        <button
          type="button"
          className="button button--tertiary justify-center h-8 w-22"
          onClick={handleExport}
          disabled={selectedWidgets.length === 0}
        >
          <span className="font-bold">Export</span>
        </button>
      </div>
    </div>
  );
};

export default ExportFilter;
