import { clearTree, requestTreeFiltersToogle } from 'actions';
import { allowedReadOnlyDimensions, metricsLinksMap } from 'constants/dashboard';
import { FULL_DATE_FORMAT } from 'constants/formats';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import withWidget from 'decorators/widget';
import WidgetEmptyState from 'decorators/widget/WidgetEmptyState';
import WidgetError from 'decorators/widget/WidgetError';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getNavParamsByResource, getNavWidgetFilters } from 'selectors/nav';
import { getPermissionsPolicy } from 'selectors/permissions';
import { DashboardMetric, DefaultWidgetComponentProps } from 'types';
import logEvent from 'utils/analytics';
import { buildSimpleDataFromMetrics } from 'utils/dashboard';
import { getValuesFromDimensions, setNewValue, transformToString } from 'utils/parserTree';
import { isActionAuthorized } from 'utils/permissions';
import { useHistory } from 'utils/urls';

export const ActivityWidget: React.FC<DefaultWidgetComponentProps> = (props) => {
  const { widgetData, queryData, widgetId } = props;

  const history = useHistory();
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const policy = useSelector(getPermissionsPolicy);
  const customerDomain = useSelector(getCustomerDomain);
  const widgetFilters = useSelector(getNavWidgetFilters(widgetId));
  const filters = useSelector(getNavParamsByResource(resourceQueryParamName.metrics));

  if (widgetData == null || queryData == null) {
    return <WidgetLoading />;
  }

  const processedData = buildSimpleDataFromMetrics(widgetData, queryData);

  if ('error' in processedData && processedData.error != null) {
    return <WidgetError msg={processedData.error} />;
  }

  if ('data' in processedData && processedData.data.length === 0) {
    return <WidgetEmptyState />;
  }

  const handleClick = (query: DashboardMetric): void => {
    const [id, type] = widgetId.split('--');
    logEvent(`Dashboard-click-${id}-${type}`);

    if (query == null) {
      return;
    }

    const info = metricsLinksMap[query.metric];
    if (info == null || !isActionAuthorized(info.action, user.roles, policy)) {
      return;
    }

    const dimensions: Record<string, string | string[]> = {};
    const infoDimension = info.dimensions;
    if (infoDimension != null) {
      // Top level dimensions
      Object.entries(infoDimension).forEach(([dimension, param]) => {
        const allowed = allowedReadOnlyDimensions[query.metric];

        // if allowedDimension is not defined or the dimension is not included,
        // just ignore it
        if (allowed == null || !allowed.includes(dimension)) {
          return;
        }

        let value: string | string[] = '';
        if (filters[dimension] != null) {
          const topValue = filters[dimension];
          value = topValue;
        }

        if (widgetFilters[dimension] != null) {
          const widgetValue = filters[dimension];
          value = widgetValue;
        }

        dimensions[`${info.resource}__${param}`] = value;
      });

      // Metric level dimensions
      if (query.dimensions != null) {
        query.dimensions.forEach((dimension) => {
          const allowed = allowedReadOnlyDimensions[query.metric];

          // if allowedDimension is not defined or the dimension is not included,
          // just ignore it
          if (allowed == null || !allowed.includes(dimension.name)) {
            return;
          }

          if (!dimensions[`${info.resource}__${infoDimension[dimension.name]}`]) {
            dimensions[`${info.resource}__${infoDimension[dimension.name]}`] = dimension.value;
          }
        });
      }
    }

    // Metric level dimensions have more priority than top leve dimensions
    const start = moment.utc(queryData.start_time * 1000).format(FULL_DATE_FORMAT);
    const end = moment.utc(queryData.end_time * 1000).format(FULL_DATE_FORMAT);

    const tree = getValuesFromDimensions(dimensions);

    const newTree = setNewValue(tree, 'date_range', `${start}<>${end}`, '', true);

    dispatch(
      requestTreeFiltersToogle({
        value: true,
      })
    );
    dispatch(clearTree());
    const hasEventsFilter = ['true'];
    if (query.metric === 'total') {
      hasEventsFilter.push('false');
    }
    history.pushLookup({
      customerDomain,
      routeName: info.route,
      queryParams: {
        [`${info.resource}__filters_search`]: transformToString(newTree),
        [`${info.resource}__has_events`]: hasEventsFilter,
        ...dimensions,
      },
    });
  };

  return (
    <div className="relative flex flex-row gap-3 w-full h-full">
      <div className="relative h-4/5 overflow-hidden">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 115 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="cursor-pointer"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.6636 44L0.52048 0.000239247L114.102 0.00025177L93.9587 44L20.6636 44Z"
            fill="#58A280"
            onClick={(): void => handleClick(queryData.queries[0])}
          />
          <path
            className="cursor-pointer"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M93.8744 44L72.7146 88L41.9066 88L20.7468 44L93.8744 44Z"
            fill="#A3E1C1"
            onClick={(): void => handleClick(queryData.queries[1])}
          />
        </svg>
      </div>
      <div className="flex flex-col items-start h-4/5">
        {'data' in processedData &&
          processedData.data.map(
            (data, idx) =>
              queryData.queries[idx]?.id && (
                <div key={data.label} className="flex flex-col justify-center h-1/2">
                  <span
                    className="title font-bold text-xss leading-4 cursor-pointer hover:underline"
                    aria-hidden
                    onClick={(): void => handleClick(queryData.queries[idx])}
                  >
                    {data.count}
                  </span>
                  <span className="font-normal text-xss text-litlingo-gray-6 leading-4">
                    {data.label}
                  </span>
                </div>
              )
          )}
      </div>
    </div>
  );
};

export const ActivityWidgetSimple = withWidget({})(ActivityWidget);

export default withWidget({
  fetchOnePeriod: true,
})(ActivityWidget);
