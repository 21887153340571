import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { useNavParamsByResource } from 'hooks/nav/useNavParamsByResource';
import { RequestParamsType, RequestResponse } from 'hooks/types/client';
import useRequest from '../client/useRequest';

type Options = { redirectOnError: boolean };

type UseIdentifiers = (options?: Options) => RequestResponse<'identifiers.list'>;

const useIdentifiers: UseIdentifiers = (options) => {
  const resourceParams = useNavParamsByResource(resourceQueryParamName.identifier);

  const params: RequestParamsType = {
    queryParams: {
      ...resourceParams,
      relationships: ['updated_by', 'model_groups', 'rule_identifiers'],
      include_count: true,
      include_pii: true,
    },
    redirectOnError: options?.redirectOnError,
  };

  const result = useRequest('identifiers.list', params, {});

  return result;
};

export default useIdentifiers;
