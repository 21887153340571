import RedirectLookup from 'components/RedirectLookup';
import { DEFAULT_ROLES } from 'constants/userRoles';
import { difference } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { getUserHome } from 'selectors/permissions';

type RedirectToHomeProps = {
  roles: string[];
};
const RedirectToHome: React.FC<RedirectToHomeProps> = (props) => {
  const { roles } = props;
  const homePages = useSelector(getUserHome);

  // If user roles doesn't contain any admin role
  if (roles.filter((role) => role.includes('admin')).length === 0) {
    return <RedirectLookup routeName={homePages['not-authorized'].routeName} />;
  }
  // If user roles contain only one admin role
  if (difference(roles, DEFAULT_ROLES).length === 1) {
    const page = Object.keys(homePages).find((key) => roles.includes(key)) as string;
    return <RedirectLookup routeName={homePages[page].routeName} />;
  }
  return <RedirectLookup routeName={homePages.default.routeName} />;
};
export default RedirectToHome;
