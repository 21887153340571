import { fetchActiveIntegrations } from 'actions';
import Card from 'components/Card';
import LinkLookup from 'components/LinkLookup';
import { OFFICE_365_ICON } from 'constants/commonIcons';
import {
  CHROME_ICON,
  GMAIL_ICON,
  HUBSPOT_ICON,
  SALESFORCE_ICON,
  SLACK_ICON,
  ZENDESK_ICON,
} from 'constants/envelopeIcons';
import { NEW_CHEVRON_LEFT } from 'constants/filterIcons';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getPlatformNames } from 'selectors/events';
import { useSelector } from 'store';

const CustomerIntegrations: React.FC = () => {
  const dispatch = useDispatch();
  const platforms = useSelector(getPlatformNames);

  useEffect(() => {
    dispatch(fetchActiveIntegrations({ searchValue: '' }));
  }, [dispatch]);

  const outlook = (
    <LinkLookup
      data-testid="integrations-office-button"
      routeName="customer-office"
      className="hover:bg-litlingo-gray-0.5 focus:outline-none"
    >
      <div className="p-4 flex justify-between items-center">
        <div className="flex flex-items items-center gap-2">
          <div className="flex justify-center w-5">{OFFICE_365_ICON}</div>
          <span className="text-body">Office 365</span>
        </div>
        <div className="transform rotate-180">{NEW_CHEVRON_LEFT}</div>
      </div>
    </LinkLookup>
  );

  const gmail = (
    <LinkLookup
      data-testid="integrations-gmail-button"
      routeName="customer-gmail"
      className="hover:bg-litlingo-gray-0.5 focus:outline-none"
    >
      <div className="p-4 flex justify-between items-center">
        <div className="flex flex-items items-center gap-2">
          <div className="flex justify-center w-5">{GMAIL_ICON}</div>
          <span className="text-body">Gmail</span>
        </div>
        <div className="transform rotate-180">{NEW_CHEVRON_LEFT}</div>
      </div>
    </LinkLookup>
  );

  const chrome = (
    <LinkLookup
      data-testid="integrations-chrome-button"
      type="button"
      routeName="customer-chrome"
      className="hover:bg-litlingo-gray-0.5 focus:outline-none"
    >
      <div className="p-4 flex justify-between items-center">
        <div className="flex flex-items items-center gap-2">
          <div className="flex justify-center w-5">{CHROME_ICON}</div>
          <span className="text-body">Chrome</span>
        </div>
        <div className="transform rotate-180">{NEW_CHEVRON_LEFT}</div>
      </div>
    </LinkLookup>
  );

  const zendesk = (
    <LinkLookup
      data-testid="integrations-zendesk-button"
      routeName="customer-zendesk"
      className="hover:bg-litlingo-gray-0.5 focus:outline-none"
    >
      <div className="p-4 flex justify-between items-center">
        <div className="flex flex-items items-center gap-2">
          <div className="flex justify-center w-5">{ZENDESK_ICON}</div>
          <span className="text-body">Zendesk</span>
        </div>
        <div className="transform rotate-180">{NEW_CHEVRON_LEFT}</div>
      </div>
    </LinkLookup>
  );

  const slack = (
    <LinkLookup
      data-testid="integrations-slack-button"
      routeName="customer-slack"
      className="hover:bg-litlingo-gray-0.5 focus:outline-none"
    >
      <div className="p-4 flex justify-between items-center">
        <div className="flex flex-items items-center gap-2">
          <div className="flex justify-center w-5">{SLACK_ICON}</div>
          <span className="text-body">Slack</span>
        </div>
        <div className="transform rotate-180">{NEW_CHEVRON_LEFT}</div>
      </div>
    </LinkLookup>
  );

  const hubspot = (
    <div data-testid="integrations-hubspot-button" className="p-4 bg-litlingo-gray-1">
      <div className="flex justify-between items-center">
        <div className="flex flex-items items-center gap-2">
          <div className="flex justify-center w-5">{HUBSPOT_ICON}</div>
          <span className="text-body">Hubspot</span>
        </div>
        <div className="transform rotate-180 opacity-50">{NEW_CHEVRON_LEFT}</div>
      </div>
    </div>
  );

  const salesforce = (
    <div data-testid="integrations-salesforce-button" className="p-4 bg-litlingo-gray-1">
      <div className="flex justify-between items-center">
        <div className="flex flex-items items-center gap-2">
          <div className="flex justify-center w-5">{SALESFORCE_ICON}</div>
          <span className="text-body">Salesforce</span>
        </div>
        <div className="transform rotate-180 opacity-50">{NEW_CHEVRON_LEFT}</div>
      </div>
    </div>
  );

  const optionsMapping = {
    o365: outlook,
    teams: null,
    gmail,
    chrome,
    zendesk,
    slack,
    glia: null,
    hubspot,
    salesforce,
  };

  const renderItems: JSX.Element[] = [];

  Object.entries(optionsMapping).forEach(([key, val]) => {
    if (platforms.find((p) => p.uuid === key) && val) {
      renderItems.push(val);
    }
  });

  return <Card title="Integrations" items={renderItems} className="w-full" />;
};

export default CustomerIntegrations;
