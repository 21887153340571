/* eslint-disable max-lines */
/* eslint-disable camelcase */

import { Communication, CommunicationClone } from '@litlingo/client';
import * as Sentry from '@sentry/react';
import {
  changeEnvelopeInReview,
  fetchOwnAssignments,
  navigateToOriginalComm,
  reviewSkippedEnvelopes,
  selectCommunication,
  selectCustomerCloneComm,
  setModalAfterReview,
} from 'actions';
import changeThreadHighlightMode, { openAttachment } from 'actions/envelopeView';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import Navbar from 'components/Navbar';
import { MODAL_BACKGROUND, MODAL_BACKGROUND_REDESIGN } from 'constants/common';
import keyMap from 'constants/configHotKeys';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import useCommunicationClone from 'hooks/communications/useCommunicationClones';
import React, { useEffect, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { SkeletonTheme } from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  getAssignmentsLoading,
  getReviewSkippedEnvelopesLoading,
  getUsersSingleAssigment,
} from 'selectors/assignments';
import { getCustomerDomain, getUser } from 'selectors/auth';
import { getSelectedCommunication } from 'selectors/communications';
import {
  ATTACHMENT_TYPES,
  getEnvelopeVersions,
  getModalAfterReview,
  getTranslationFromCommunication,
} from 'selectors/envelopeReview';
import { getEnvelope, getFetchSingleEnvelopeLoading } from 'selectors/envelopes';
import { getTranslationMode } from 'selectors/envelopeView';
import { getNavParamsByResourceMemo } from 'selectors/nav';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import { useHistory } from 'utils/urls';
import EnvelopeContent from './EnvelopeContent';
import EnvelopeHeader from './EnvelopeHeader';
import EnvelopeLeftSidebar from './EnvelopeLeftSidebar';
import EnvelopeRightSidebar from './EnvelopeRightSidebar';
import EnvelopeSkeleton from './EnvelopeSkeleton';

const CLONE_REGEX =
  // eslint-disable-next-line no-useless-escape
  /([A-Za-z0-9-.=\/_\\\@]+)(?:-[A-Za-z0-9.\/=]+-[A-Za-z0-9.\/=]+-[A-Za-z0-9.\/=]+-[A-Za-z0-9.\/=]+-[A-Za-z0-9.\/=]+-clone)/;

const Envelope: React.FC = () => {
  const { envelopeId } = useParams<{ envelopeId: string }>();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const loading = useSelector(getFetchSingleEnvelopeLoading);
  const envelope = useSelector((state) => getEnvelope(state, envelopeId));
  const { data, isLoading, error, refetch, isFetching } = useCommunicationClone(
    envelope && envelope?.communications?.length ? envelope.communications[0].uuid : ''
  );
  const customerDomain = useSelector(getCustomerDomain);
  const singleAssigment = useSelector(getUsersSingleAssigment);
  const assignmentLoading = useSelector(getAssignmentsLoading);
  const selectedCommunication = useSelector(getSelectedCommunication);
  const translationMode = useSelector(getTranslationMode);

  const modalAfterReview = useSelector(getModalAfterReview);

  const envelopeVersions = useSelector(getEnvelopeVersions);
  const versionBase = envelopeVersions[0];

  const reviewSkippedLoading = useSelector(getReviewSkippedEnvelopesLoading);
  const version: Communication = versionBase;

  const envelopeTranslation = useSelector((state) =>
    getTranslationFromCommunication(state, version?.uuid || '')
  );

  const { currentReview } = useSelector((state) =>
    getNavParamsByResourceMemo(state, resourceQueryParamName.envelopes)
  );

  const [commentValue, setCommentValue] = useState('');
  const [assignmentLoaded, setAssignmentLoaded] = useState(false);
  const [cloneCommsModalOpen, setCloneCommsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchOwnAssignments({ is_open: true }));
  }, [dispatch]);

  useEffect(() => {
    if (envelope) {
      dispatch(changeEnvelopeInReview({ envelope }));
      dispatch(changeThreadHighlightMode(false));
    }
  }, [envelope, dispatch]);

  useEffect(() => {
    if (
      selectedCommunication &&
      ATTACHMENT_TYPES.includes(selectedCommunication.communication_type)
    ) {
      dispatch(openAttachment(true));
    } else {
      dispatch(openAttachment(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunication?.uuid, envelopeId]);

  useEffect(() => {
    if (version) {
      if (translationMode && envelopeTranslation) {
        dispatch(selectCommunication({ communication: envelopeTranslation }));
      } else {
        dispatch(selectCommunication({ communication: version }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version, dispatch]);

  useEffect(() => {
    if (assignmentLoading) setAssignmentLoaded(true);

    if (currentReview && !singleAssigment && !assignmentLoading && assignmentLoaded) {
      Sentry.captureMessage('User redirected from review mode to normal mode', {
        extra: { envelopeId, user, customerDomain },
      });

      const newQueryParams = location.search
        .split('&')
        .filter((param) => !param.includes('currentReview'))
        .join('&');

      history.pushLookup({
        customerDomain,
        routeName: 'envelope-detail',
        routeParams: { envelopeId },
        queryParams: newQueryParams,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentLoading, currentReview, singleAssigment]);

  useEffect(() => {
    if (currentReview === undefined) return;
    logEvent('envelope-load-page', { reviewSet: currentReview });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envelopeId, currentReview]);

  const handlers = {
    FOCUS_TAGS_INPUT: (): void => {
      const ele = document.querySelector('.react-tags__search-input') as HTMLInputElement;
      if (!ele || ele === document.activeElement) return;
      ele.value = '';
      setTimeout(() => {
        ele.focus();
      }, 100);
    },
  };

  const handleClickCloneAction = (): void => {
    if (!envelope.communications || envelope.communications?.length === 0) return;
    if ('source' in envelope?.communications?.[0].meta_data) {
      const metaData = envelope?.communications?.[0].meta_data as Record<
        string,
        string | Record<string, string>
      >;
      const source = metaData.source as Record<string, string>;
      const originalCustomerUuid = source.customer_uuid;
      const comm = envelope.communications[0];
      const platformGuidReg = comm.platform_guid.match(CLONE_REGEX);
      if (!platformGuidReg || platformGuidReg.length < 2) return;
      const platformGuid = platformGuidReg[1];
      dispatch(
        navigateToOriginalComm({
          platformGuid,
          customerUuid: originalCustomerUuid,
          prevEnvelopeId: envelopeId,
        })
      );
    } else {
      if (data && data.length === 1) {
        const comm = data[0];
        if (!comm.customer?.domain) return;
        dispatch(
          selectCustomerCloneComm({
            domain: comm.customer?.domain,
            prevEnvelopeId: envelopeId,
            newEnvelopeId: comm.envelope.uuid,
          })
        );
        return;
      }
      if (!isLoading && data?.length === 0) refetch();

      setCloneCommsModalOpen(true);
    }
  };

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  const reviewDoneModal = (
    <>
      {MODAL_BACKGROUND}
      <div className="px-4 pb-6">
        <h2 className="text text--bold text--2xextra-large">Nicely done!</h2>
        <p className="my-2">
          There are no more events in {singleAssigment?.saved_search?.name} to review at this time.
        </p>
      </div>
    </>
  );

  const reviewWithSkipsModal = (
    <>
      {MODAL_BACKGROUND}
      <div className="px-4 pb-6">
        <h2 className="text text--bold text--2xextra-large">Revisit Skips?</h2>
        <p className="my-2">
          Great job getting through this set. There were a couple that you skipped. Would you like
          to revisit them before closing out?
        </p>
      </div>
    </>
  );

  const customerListComponent = ({
    customer,
    envelope: commEnvelope,
  }: CommunicationClone): JSX.Element => (
    <button
      type="button"
      key={customer.domain}
      onClick={(e): void => {
        e.preventDefault();
        if (customer.domain) {
          dispatch(
            selectCustomerCloneComm({
              domain: customer.domain,
              prevEnvelopeId: envelopeId,
              newEnvelopeId: commEnvelope.uuid,
            })
          );
          setCloneCommsModalOpen(false);
        }
      }}
      className={`flex flex-row justify-between items-stretch w-full pl-6 pr-4 text-body text-litlingo-gray-3 border-b  border-litlingo-gray-1  focus:outline-none 
            bg-white hover:bg-litlingo-gray-1`}
    >
      <span className="py-1.5 font-bold text-litlingo-gray-5">{customer.name}</span>
    </button>
  );

  const cloneCustomerCommsModalBody = (): JSX.Element | JSX.Element[] =>
    !error && data && data.length > 0 ? (
      data.map((item) => customerListComponent(item))
    ) : (
      <div
        className="flex flex-row justify-between items-stretch w-full pl-6 pr-4 text-body text-litlingo-gray-3 border-b  border-litlingo-gray-1  focus:outline-none 
            bg-white"
      >
        <span className="py-1.5 font-bold text-litlingo-gray-5">
          No communication has been cloned from this communication yet.
        </span>
      </div>
    );
  const cloneCustomerCommsModal = (
    <>
      {MODAL_BACKGROUND_REDESIGN}
      {isLoading || isFetching ? (
        <LoadingIndicator className="m-auto mt-20" size="20" />
      ) : (
        cloneCustomerCommsModalBody()
      )}
    </>
  );

  const comm = envelope?.communications?.[0];

  return (
    <>
      <SkeletonTheme color="#D9DBE9" highlightColor="#DFE1EF">
        <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
        <div
          className="bg-white"
          style={{
            height: `calc(100vh - ${warningBarOffset}px)`,
          }}
        >
          <Navbar />
          <div
            className="flex flex-row z-0"
            style={{
              height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
            }}
          >
            <EnvelopeLeftSidebar />

            <div className="flex flex-col gap-4 w-full h-full overflow-hidden z-10">
              <EnvelopeHeader
                cloneModal={Boolean(
                  envelope && 'source' in (comm?.meta_data as Record<string, unknown>)
                )}
                handleClickCloneAction={handleClickCloneAction}
                showCloneButton={comm?.processing_status === 'complete'}
              />
              <div className="flex flex-col gap-4 w-full h-full overflow-hidden rounded-lg">
                {loading ? <EnvelopeSkeleton /> : <EnvelopeContent />}
              </div>
            </div>

            <div className="envelope-right-sidebar">
              <EnvelopeRightSidebar setCommentValue={setCommentValue} commentValue={commentValue} />
            </div>
          </div>
        </div>
        {modalAfterReview && modalAfterReview.modal === 'withoutSkips' && (
          <Modal
            body={reviewDoneModal}
            title=" "
            style={{ 'padding-left': '0', 'padding-right': '0' }}
            okButton
            okButtonText="Finish"
            okButtonOnClick={(): void => {
              history.pushLookup({
                customerDomain,
                routeName: 'review-set',
              });
              dispatch(setModalAfterReview(null));
            }}
            okButtonStyle="mr-4"
            cancelButton={false}
            xButton={false}
          />
        )}
        {modalAfterReview && modalAfterReview.modal === 'withSkips' && (
          <Modal
            body={reviewWithSkipsModal}
            title=" "
            style={{ 'padding-left': '0', 'padding-right': '0' }}
            okButton
            okButtonText="Revisit Skips"
            okButtonOnClick={(): void => {
              dispatch(reviewSkippedEnvelopes());
            }}
            okButtonStyle="mr-4"
            cancelButton
            cancelButtonOnclick={(): void => {
              history.pushLookup({
                customerDomain,
                routeName: 'review-set',
              });
              dispatch(setModalAfterReview(null));
            }}
            cancelButtonText={`No, I'am Done`}
            xButton={false}
            okButtonDisabled={reviewSkippedLoading}
            loading={reviewSkippedLoading}
          />
        )}
        {cloneCommsModalOpen && (
          <Modal
            body={cloneCustomerCommsModal}
            title="Sandboxes with this communication"
            cancelButton={false}
            xButton
            toggleShowModal={(): void => {
              setCloneCommsModalOpen(false);
            }}
          />
        )}
      </SkeletonTheme>
    </>
  );
};

export default Envelope;
