/* eslint-disable react/jsx-curly-newline */
import { saveCustomer } from 'actions';
import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getSaveCustomerLoading,
  getSurroundingContextIdentifiersIds,
  getSurroundingContextRulesIds,
  getUserCustomer,
} from 'selectors/auth';
import { getIdentifiers } from 'selectors/identifier';
import { getRuleGroupsList } from 'selectors/ruleGroups';
import { useSelector } from 'store';
import ContextItemsList from './ContextItemsList';
import ContextSearch from './ContextSearch';

const ExploreConfiguration: React.FC = () => {
  const dispatch = useDispatch();

  const identifiers = useSelector(getIdentifiers);
  const rules = useSelector(getRuleGroupsList);
  const customerIdentifiers = useSelector(getSurroundingContextIdentifiersIds);
  const customerRules = useSelector(getSurroundingContextRulesIds);
  const customer = useSelector(getUserCustomer);
  const loading = useSelector(getSaveCustomerLoading);

  const [resource, setResource] = useState<'identifier' | 'model'>('identifier');
  const [selectedIdentifiers, setSelectedIdentifiers] = useState<string[]>(customerIdentifiers);
  const [selectedRules, setSelectedRules] = useState<string[]>(customerRules);
  const [search, setSearch] = useState('');
  const [searchFocused, setSearchFocused] = useState(false);

  const handleSave = (): void => {
    if (customer) {
      dispatch(
        saveCustomer({
          ...customer,
          config: {
            ...customer.config,
            ui_surrounding_context_rules: selectedRules,
            ui_surrounding_context_identifiers: selectedIdentifiers,
          },
        })
      );
    }
  };

  const handleCancel = (): void => {
    setSelectedIdentifiers(customerIdentifiers);
    setSelectedRules(customerRules);
  };

  const handleSelect = (item: string): void => {
    if (resource === 'identifier') {
      if (!selectedIdentifiers.includes(item)) {
        setSelectedIdentifiers((si) => [...si, item]);
      } else {
        setSelectedIdentifiers((si) => si.filter((i) => i !== item));
      }
    } else if (!selectedRules.includes(item)) {
      setSelectedRules((sr) => [...sr, item]);
    } else {
      setSelectedRules((sr) => sr.filter((i) => i !== item));
    }
  };

  const disableSave =
    customerIdentifiers.every((ci) => selectedIdentifiers.includes(ci)) &&
    selectedIdentifiers.every((ci) => customerIdentifiers.includes(ci)) &&
    customerRules.every((ci) => selectedRules.includes(ci)) &&
    selectedRules.every((ci) => customerRules.includes(ci));

  return (
    <div className="litlingo-gray-bg-color-4">
      <Navbar />

      <div
        className="flex flex-row overflow-hidden"
        style={{
          minHeight: 'calc(100vh - 4rem)',
          height: 'calc(100vh - 4rem)',
        }}
      >
        <SettingSidebar />
        <div className="w-full h-full flex flex-col text-body overflow-hidden">
          <div className="pl-10 pr-6 pt-6 pb-3 flex flex-col gap-5 bg-litlingo-gray-0.5 border-b border-litlingo-gray-2">
            <div className="flex flex-row gap-1">
              <LinkLookup routeName="customer" className="text-body hover:underline">
                General
              </LinkLookup>
              <span>{`>`}</span>
              <span>Explore Configuration</span>
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row gap-2 items-center">
                <span className="text-heading-3 whitespace-no-wrap">Message Context</span>
                <span>
                  Add any of the identifiers or models below as searchable context in the Explore
                  page.
                </span>
              </div>
              <div className="flex flex-row gap-1.5">
                <button
                  type="button"
                  className="w-37 h-8 button button--secondary flex justify-center"
                  onClick={handleCancel}
                  disabled={disableSave}
                >
                  <span className="font-bold">Cancel</span>
                </button>
                <button
                  type="button"
                  className="w-37 h-8 button button--primary flex justify-center"
                  onClick={handleSave}
                  disabled={disableSave}
                >
                  {loading ? <LoadingIndicator size="5" /> : 'Save'}
                </button>
              </div>
            </div>
          </div>
          <div className="w-full h-full pt-5 pb-20 pl-25 flex bg-white overflow-hidden">
            <div className="w-80 h-full flex flex-col gap-2 overflow-hidden">
              <div className="flex flex-row">
                <button
                  type="button"
                  className={`text-body w-full h-7 border-t border-b border-l border-litlingo-gray-1 rounded-l ${
                    resource === 'identifier' ? 'bg-litlingo-gray-1' : 'bg-white'
                  } focus:outline-none`}
                  onClick={(): void => setResource('identifier')}
                >
                  <span className={`${resource === 'identifier' ? 'font-bold' : ''}`}>
                    Identifiers
                  </span>
                </button>
                <button
                  type="button"
                  className={`text-body w-full h-7 border-t border-b border-r border-litlingo-gray-1 rounded-r ${
                    resource === 'model' ? 'bg-litlingo-gray-1' : 'bg-white'
                  } focus:outline-none`}
                  onClick={(): void => setResource('model')}
                >
                  <span className={`${resource === 'model' ? 'font-bold' : ''}`}>Models</span>
                </button>
              </div>
              <ContextSearch
                handleSearch={(value): void => setSearch(value)}
                className="w-full"
                placeholder={resource === 'identifier' ? 'Search identifier' : 'Search model'}
                focused={searchFocused}
                setFocused={setSearchFocused}
              />
              <ContextItemsList
                items={resource === 'identifier' ? identifiers : rules}
                selectedItems={resource === 'identifier' ? selectedIdentifiers : selectedRules}
                handleSelect={handleSelect}
                search={search}
                searchFocused={searchFocused}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreConfiguration;
