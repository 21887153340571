import { deletePrompt } from 'actions';
import ConfirmModal from 'components/ConfirmModal';
import LinkLookup from 'components/LinkLookup';
import { COPY_CLIPBOARD, NEW_EDIT_ICON } from 'constants/commonIcons';
import AlertsContext from 'contexts/AlertsContext';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'types';
import EditPromptModal from './EditPromptModal';

type ComponentProps = {
  prompt: Prompt;
};

const PromptListRow: React.FC<ComponentProps> = ({ prompt }) => {
  const dispatch = useDispatch();

  const [isEditingPrompt, setIsEditingPrompt] = useState(false);
  const [isDeletingPrompt, setIsDeletingPrompt] = useState(false);

  const { showSuccessAlert } = useContext(AlertsContext);

  const toggleEditModal = (): void => setIsEditingPrompt(!isEditingPrompt);
  const toggleDeleteModal = (): void => setIsDeletingPrompt(!isDeletingPrompt);

  const handleDeletePrompt = (): void => {
    dispatch(deletePrompt({ uuid: prompt.uuid }));
    toggleDeleteModal();
  };

  const handleCopyClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    testPhrase: string
  ): Promise<void> => {
    event.stopPropagation();
    event.preventDefault();
    await navigator.clipboard.writeText(testPhrase);
    showSuccessAlert('Prompt UUID copied to your clipboard');
  };

  return (
    <>
      <LinkLookup
        routeName="prompt-detail"
        routeParams={{
          promptId: prompt.uuid,
        }}
        className={` border-t border-gray-200 hover:bg-gray-100 table-row`}
        as="tr"
      >
        <td className="table-wrapper__new-td pl-4 h-8">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="p-1 font-bold">
                {
                  // @ts-ignore
                  prompt?.name ?? '–'
                }
              </div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="p-1">
                {
                  // @ts-ignore
                  prompt?.description ?? '–'
                }
              </div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td pl-4 h-8">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="p-1 font-bold">{prompt.kind}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center text-body">
            <div className="overflow-hidden">
              <div className="p-1">{prompt.deployment}</div>
            </div>
          </div>
        </td>

        <td className="table-wrapper__new-td ">
          <div className="flex items-center text-body">
            <div className="overflow-hidden ">
              <div className="p-1 truncate">{prompt.content}</div>
            </div>
          </div>
        </td>

        <td className="table-wrapper__new-td">
          <div className="px-1 py-2 text-body">{prompt.source ?? '–'}</div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex flex-no-wrap gap-x-4 p-1">
            <button
              type="button"
              data-testid="edit-button"
              className="h-5 w-5 focus:outline-none flex-shrink-0"
              onClick={(e): void => {
                e.stopPropagation();
                toggleEditModal();
              }}
            >
              {NEW_EDIT_ICON('text-litlingo-black')}
            </button>
            <button
              type="button"
              data-testid="delete-button"
              className="focus:outline-none"
              onClick={(e): void => {
                e.stopPropagation();
                toggleDeleteModal();
              }}
            >
              <svg
                className="mr-1.5 h-5 w-5 text-black"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </button>
            <button
              className="focus:outline-none text-black"
              type="button"
              data-testid="copy-button"
              onClick={(event): Promise<void> => handleCopyClick(event, prompt.uuid)}
            >
              {COPY_CLIPBOARD}
            </button>
          </div>
        </td>
      </LinkLookup>
      {isEditingPrompt && (
        <EditPromptModal
          isModalOpen={isEditingPrompt}
          toggleModalOpen={toggleEditModal}
          prompt={prompt}
        />
      )}
      {isDeletingPrompt && (
        <ConfirmModal
          data-testid="delete-modal"
          text="Are you sure you want to delete this promt?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={handleDeletePrompt}
          toggleShowModal={toggleDeleteModal}
        />
      )}
    </>
  );
};

export default PromptListRow;
