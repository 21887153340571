/* eslint-disable camelcase */
import React from 'react';
import { getCommunicationsWithEvents } from 'selectors/envelopeReview';
import { getAllEventDocCommLoading } from 'selectors/events';
import { useSelector } from 'store';
import CommunicationHistoryItem from './CommunicationHistoryItem';
import EnvelopeSkeleton from './EnvelopeSkeleton';

type ComponentProps = {
  // communication: CommunicationWithEvents;
};

const CommunicationHistoryContent: React.FC<ComponentProps> = () => {
  const communications = useSelector(getCommunicationsWithEvents);
  const loadingDocs = useSelector(getAllEventDocCommLoading);

  if (loadingDocs) {
    return <EnvelopeSkeleton />;
  }

  return (
    <div className="relative w-full flex flex-col gap-4 overflow-hidden">
      <div className="flex flex-col pl-7">
        {communications && (
          <div className="flex flex-col">
            <div className="relative">
              <div className="absolute w-3 h-3 text-litlingo-gray-2" style={{ top: -1, left: -9 }}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line x1="9" y1="0" x2="10" y2="6" stroke="white" strokeWidth={3} />
                </svg>
              </div>
              {communications.slice(1).map((communication, idx, arr) => (
                <div
                  key={communication.uuid}
                  className={`border-l-2 border-litlingo-gray-2 ${
                    idx === arr.length - 1 ? 'pb-12' : 'pb-6'
                  } ? : `}
                >
                  <CommunicationHistoryItem communication={communication} />
                </div>
              ))}
            </div>
            <div className="relative pl-5.5 text-litlingo-gray-5 border-l-2 border-transparent">
              <span className="italic">Begining of draft</span>

              <div className="absolute w-3 h-3 text-litlingo-gray-2" style={{ top: -10, left: -7 }}>
                <svg viewBox="0 0 12 12" fill="currentColor">
                  <circle cx={6} cy={6} r={6} />
                </svg>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommunicationHistoryContent;
