import { createAction } from '@reduxjs/toolkit';
import type {
  API,
  BulkEventSummaries,
  CommunicationDocumentV2,
  CommunicationWithEvents,
  ErrorObject,
  Event,
  EventSummary,
  UUID,
} from 'types';

export type FetchEventsPayload = {
  // eslint-disable-next-line camelcase
  communication_envelope_uuids: UUID;
};

export type FetchBulkEventsPayload = {
  communicationId: UUID;
};

export const fetchEvents = createAction<FetchEventsPayload>('EVENTS/FETCH');
export const fetchEventsRequest = createAction('EVENTS/FETCH_REQUEST');
export const fetchEventsSuccess =
  createAction<API.WrappedAPIResponse<Event>>('EVENTS/FETCH_SUCCESS');
export const fetchEventsFailure = createAction<ErrorObject>('EVENTS/FETCH_FAILURE');

type FetchEventSummaryPayload = {
  eventId: UUID;
};

export const fetchEventSummary = createAction<FetchEventSummaryPayload>('EVENT_SUMMARY/FETCH');
export const fetchEventSummaryRequest = createAction('EVENT_SUMMARY/FETCH_REQUEST');
export const fetchEventSummarySuccess = createAction<EventSummary>('EVENT_SUMMARY/FETCH_SUCCESS');
export const fetchEventSummaryFailure = createAction<ErrorObject>('EVENT_SUMMARY/FETCH_FAILURE');

type FetchEventDocCommPayload = { eventId: UUID; commUuid: UUID };
export const fetchEventDocComm = createAction<FetchEventDocCommPayload>('EVENT_DOC_COMM/FETCH');
export const fetchEventDocCommRequest = createAction('EVENT_DOC_COMM/FETCH_REQUEST');

export type FetchEventDocCommSuccessPayload = {
  eventId: UUID;
  commUuid: UUID;
  document: CommunicationDocumentV2;
};
export const fetchEventDocCommSuccess = createAction<FetchEventDocCommSuccessPayload>(
  'EVENT_DOC_COMM/FETCH_SUCCESS'
);
export const fetchEventDocCommFailure = createAction<ErrorObject>('EVENT_DOC_COMM/FETCH_FAILURE');

type FetchAllEventDocCommPayload = { communications: CommunicationWithEvents[] };
export const fetchAllEventDocComm = createAction<FetchAllEventDocCommPayload>(
  'EVENT_DOC_COMM/FETCH_ALL'
);
export const fetchAllEventDocCommRequest = createAction('EVENT_DOC_COMM/FETCH_ALL_REQUEST');
export const fetchAllEventDocCommSuccess = createAction('EVENT_DOC_COMM/FETCH_ALL_SUCCESS');
export const fetchAllEventDocCommFailure = createAction<ErrorObject>(
  'EVENT_DOC_COMM/FETCH_ALL_FAILURE'
);

type FetchEventBodyCommPayload = {
  eventId: UUID;
  commUuid: UUID;
};
export const fetchEventBodyComm = createAction<FetchEventBodyCommPayload>('EVENT_BODY_COMM/FETCH');
export const fetchEventBodyCommRequest = createAction('EVENT_BODY_COMM/FETCH_REQUEST');
export type FetchEventBodyCommSuccessPayload = {
  eventId: UUID;
  body: string;
};
export const fetchEventBodyCommSuccess = createAction<FetchEventBodyCommSuccessPayload>(
  'EVENT_BODY_COMM/FETCH_SUCCESS'
);
export const fetchEventBodyCommFailure = createAction<ErrorObject>('EVENT_BODY_COMM/FETCH_FAILURE');

export const fetchCommThreadContext = createAction<FetchEventBodyCommPayload>(
  'COMM_THREAD_CONTEXT/FETCH'
);
export const fetchCommThreadContextRequest = createAction('COMM_THREAD_CONTEXT/FETCH_REQUEST');
export type FetchCommThreadContextSuccessPayload = {
  eventId: UUID;
} & API.Communications.ThreadContext;
export const fetchCommThreadContextSuccess = createAction<FetchCommThreadContextSuccessPayload>(
  'COMM_THREAD_CONTEXT/FETCH_SUCCESS'
);
export const fetchCommThreadContextFailure = createAction<ErrorObject>(
  'COMM_THREAD_CONTEXT/FETCH_FAILURE'
);

export const fetchEventLabels = createAction('EVENT_LABELS/FETCH');
export const fetchEventLabelsRequest = createAction('EVENT_LABELS/FETCH_REQUEST');
export const fetchEventLabelsSuccess = createAction<string[]>('EVENT_LABELS/FETCH_SUCCESS');
export const fetchEventLabelsFailure = createAction<ErrorObject>('EVENT_LABELS/FETCH_FAILURE');

type addEventLabelPayload = {
  eventId: UUID;
  label: string;
};
export const addEventLabel = createAction<addEventLabelPayload>('EVENT_LABELS/ADD');
export const addEventLabelRequest = createAction('EVENT_LABELS/ADD_REQUEST');
export const addEventLabelSuccess = createAction<Event>('EVENT_LABELS/ADD_SUCCESS');
export const addEventLabelFailure = createAction<ErrorObject>('EVENT_LABELS/ADD_FAILURE');

type removeEventLabelPayload = {
  eventId: UUID;
  label: string;
};
export const removeEventLabel = createAction<removeEventLabelPayload>('EVENT_LABELS/REMOVE');
export const removeEventLabelRequest = createAction('EVENT_LABELS/REMOVE_REQUEST');
export const removeEventLabelSuccess = createAction<Event>('EVENT_LABELS/REMOVE_SUCCESS');
export const removeEventLabelFailure = createAction<ErrorObject>('EVENT_LABELS/REMOVE_FAILURE');

type AddCommentEventPayload = {
  eventId: UUID;
  value: string;
};
export const addCommentEvent = createAction<AddCommentEventPayload>('EVENTS/ADD_COMMENT');
export const addCommentEventRequest = createAction('EVENTS/ADD_COMMENT_REQUEST');
export const addCommentEventSuccess = createAction<Event>('EVENTS/ADD_COMMENT_SUCCESS');
export const addCommentEventFailure = createAction<ErrorObject>('EVENTS/ADD_COMMENT_FAILURE');

type reviewEventPayload = {
  eventId: UUID;
  value: string;
  key: string;
};

export const reviewEvent = createAction<reviewEventPayload>('EVENTS/REVIEW_REQUEST');
export const reviewEventRequest = createAction('EVENTS/REVIEW_REQUEST');
export const reviewEventSuccess = createAction<Event>('EVENTS/REVIEW_SUCCESS');
export const reviewEventFailure = createAction<ErrorObject>('EVENTS/REVIEW_FAILURE');

type reprocessEventsPayload = {
  payload: boolean;
};
export const reprocessEvents = createAction<reprocessEventsPayload>('EVENTS/REPROCESS');
export const reprocessEventsRequest = createAction('EVENTS/REPROCESS_REQUEST');
export const reprocessEventsSuccess = createAction('EVENTS/REPROCESS_SUCCESS');
export const reprocessEventsFailure = createAction<ErrorObject>('EVENTS/REPROCESS_FAILURE');

export type selectEventType = {
  eventId: string;
};

export const selectEvent = createAction<selectEventType>('EVENTS/SELECT');

export const fetchBulkEventSummaries = createAction<FetchBulkEventsPayload>(
  'BULK_EVENT_SUMMARIES/FETCH'
);
export const fetchBulkEventSummariesRequest = createAction<string>(
  'BULK_EVENT_SUMMARIES/FETCH_REQUEST'
);
export const fetchBulkEventSummariesSuccess = createAction<BulkEventSummaries>(
  'BULK_EVENT_SUMMARIES/FETCH_SUCCESS'
);
export const fetchBulkEventSummariesFailure = createAction<{
  error: ErrorObject;
  communicationId: string;
}>('BULK_EVENT_SUMMARIES/FETCH_FAILURE');
