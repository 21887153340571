import { fetchSavedSearchesRequest, upsertSavedSearchParamsRequest } from 'actions';
import { GlobalState } from 'reducers';
import { createSelector } from 'reselect';
import type { NormalizedResource, SavedSearch, Selector, UUID } from 'types';
import { savedSearchSort } from 'utils/savedSearches';
import { getUser } from './auth';

export const getSavedSearchesLoading: Selector<boolean> = (state) =>
  state.savedSearches.loading.includes(fetchSavedSearchesRequest.toString());

export const getSavedSearches: Selector<NormalizedResource<SavedSearch>> = (state) =>
  state.savedSearches.items;

export const getSavedSearchesTotalCount: Selector<number> = (state) => state.savedSearches.count;

export const getSavedSearchesList: Selector<SavedSearch[]> = createSelector(
  (state: GlobalState): NormalizedResource<SavedSearch> => state.savedSearches.items,
  (items) => Object.keys(items).map((uuid) => items[uuid])
);

export const getDisplayInAssignmentsSavedSearches: Selector<SavedSearch[]> = createSelector(
  (state: GlobalState): NormalizedResource<SavedSearch> => state.savedSearches.items,
  (items) => {
    const savedSearches = Object.keys(items).map((uuid) => items[uuid]);

    return savedSearches.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  }
);

export const getSortedOwnSavedSearchesList: Selector<SavedSearch[]> = createSelector(
  (state: GlobalState): NormalizedResource<SavedSearch> => state.savedSearches.mySearches,
  (items) => {
    const savedSearches = Object.keys(items).map((uuid) => items[uuid]);

    return savedSearches.sort(savedSearchSort);
  }
);

export const getSortedOwnActiveSavedSearchesList: Selector<SavedSearch[]> = createSelector(
  (state: GlobalState): NormalizedResource<SavedSearch> => state.savedSearches.mySearches,
  (items) => {
    const savedSearches = Object.keys(items)
      .map((uuid) => items[uuid])
      .filter((s) => s.record_count > 0);

    return savedSearches.sort(savedSearchSort);
  }
);

export const getSortedOwnInactiveSavedSearchesList: Selector<SavedSearch[]> = createSelector(
  (state: GlobalState): NormalizedResource<SavedSearch> => state.savedSearches.mySearches,
  (items) => {
    const savedSearches = Object.keys(items)
      .map((uuid) => items[uuid])
      .filter((s) => s.record_count === 0);

    return savedSearches.sort(savedSearchSort);
  }
);

export const getHasActiveReviewSets: Selector<boolean> = ({ savedSearches }) => {
  const savedSearchesList = savedSearches.items;

  return Object.values(savedSearchesList).some((s) => s.record_count > 0);
};

export const getSavedSearch =
  (savedSearchId: UUID | undefined): Selector<SavedSearch | null> =>
  ({ savedSearches }): SavedSearch | null =>
    savedSearchId ? savedSearches.items[savedSearchId] : null;

export const getUserIsAssignedToReviewSet =
  (reviewSetId: UUID): Selector<boolean> =>
  (state): boolean => {
    const user = getUser(state);
    const reviewSet = getSavedSearch(reviewSetId)(state);

    if (reviewSet == null || reviewSet.users == null) {
      return false;
    }

    return reviewSet.users.some((u) => u.uuid === user.uuid);
  };

export const getSelectedReviewSet: Selector<SavedSearch | null> = (state) =>
  state.savedSearches?.selectedItem;

export const getUpsertSavedSearchParamsLoading: Selector<boolean> = (state) =>
  state.savedSearches.loading.includes(upsertSavedSearchParamsRequest.toString()) || false;

export const getSubsamplesModalOpen: Selector<boolean> = (state) =>
  state.savedSearches.subsamplesModalOpen;

export const getMessagesModalOpen: Selector<boolean> = (state) =>
  state.savedSearches.messagesModalOpen;
