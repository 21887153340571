/* eslint-disable max-lines */
/* eslint-disable camelcase */
/* eslint-disable react/require-default-props */
import { SavedSearch } from '@litlingo/client';
import { fetchSavedSearches } from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import useClickOutside from 'components/utils/useClickOutside';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSavedSearchesList,
  getSavedSearchesLoading,
  getSavedSearchesTotalCount,
} from 'selectors/savedSearches';
import logEvent from 'utils/analytics';

type ResourceFilterProps = {
  className?: string;
  dataTestid?: string;
  selectReviewSet: (reviewSet: SavedSearch) => void;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
};

const ReviewSetSelectDropdown: React.FC<ResourceFilterProps> = (props) => {
  const { className, dataTestid, handleClose, selectReviewSet } = props;

  const dispatch = useDispatch();

  const [offset, setOffset] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);
  const reviewSets = useSelector(getSavedSearchesList);
  const totalCount = useSelector(getSavedSearchesTotalCount);
  const loadingReviewSets = useSelector(getSavedSearchesLoading);

  const handleClickOutside = useCallback(() => {
    handleClose(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useClickOutside(wrapperRef, handleClickOutside);

  useEffect(() => {
    dispatch(fetchSavedSearches({ broad_search: debouncedSearchValue }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedSearchValue(searchValue), 300);
    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  const handleInputChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: SavedSearch
  ): void => {
    logEvent('envelopes-list-filter-models');

    selectReviewSet(item);
    event.stopPropagation();
    handleClose(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();
    const value = e?.target.value.toLowerCase();
    setSearchValue(value);
  };

  const handleLoadMore = (): void => {
    dispatch(
      fetchSavedSearches({
        limit: 25,
        broad_search: debouncedSearchValue,
        offset: offset + 25,
      })
    );
    setOffset((o) => o + 25);
  };

  return (
    <div
      ref={wrapperRef}
      className={`mt-1 flex flex-col w-52 rounded-md z-100 ${className || ''}`}
      style={{ boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)' }}
    >
      <div className="relative flex flex-row justify-start items-center w-full min-h-8  px-2  bg-litlingo-white envelope-list-tags border-b border-litlingo-gray-2 rounded-t-md">
        <input
          ref={inputRef}
          id="search"
          name="search"
          className="react-tags__search-input max-h-5 w-full envelope-list-tags ml-1 font-normal text-base text-litlingo-gray-5"
          placeholder="Add to assignment"
          data-testid={dataTestid}
          type="text"
          onChange={handleSearchChange}
          onFocus={(e): void => e.stopPropagation()}
          onClick={(e): void => e.stopPropagation()}
          value={searchValue}
          autoComplete="off"
        />
      </div>

      <LoadingOverlayWrapper
        active={loadingReviewSets}
        spinner={<LoadingIndicator />}
        fadeSpeed={0}
      >
        <div className="w-full min-h-8 max-h-52 overflow-auto rounded-b-md bg-litlingo-white custom-scrollbar ">
          {reviewSets.length === 0 && !loadingReviewSets ? (
            <div className="px-2 py-1 text-body">No assignments found</div>
          ) : (
            reviewSets.map((item: SavedSearch) => (
              <button
                key={item.uuid}
                type="button"
                onClick={(e): void => handleInputChange(e, item)}
                className="w-full pl-2 pr-1 py-1 hover:bg-litlingo-gray-1 text-left"
              >
                <span className="text-litlingo-gray-6 text-body text-left font-normal select-none break-word">
                  {item.name}
                </span>
              </button>
            ))
          )}

          {offset + 25 < totalCount - 1 && (
            <button
              type="button"
              className="w-full py-1 text-small border-t border-litlingo-gray-2 focus:outline-none"
              onClick={(e): void => {
                e.stopPropagation();
                handleLoadMore();
              }}
            >
              <span className="text-litlingo-primary">Load More</span>
            </button>
          )}
        </div>
      </LoadingOverlayWrapper>
    </div>
  );
};

export default ReviewSetSelectDropdown;
