/* eslint-disable max-lines */
/* eslint-disable no-unused-expressions */
import {
  PostTestSentencePayloadV2,
  clearTestSentence,
  postTestSentenceV2,
  replaceURLParams,
} from 'actions';
import LoadingIndicator from 'components/LoadingIndicator';
import SentencesViolatedSectionGlobal from 'components/SentencesViolatedSection/SentencesViolatedGLobal';
import keyMap from 'constants/configHotKeys';
import { platformNames } from 'constants/platform';
import React, { useRef, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAnnotatedTestSentenceResult,
  getRulesViolatedV2,
  getShowV2Graph,
  getTestSentenceLoading,
} from 'selectors/communications';
import { getNavParams } from 'selectors/nav';
import type { Platform } from 'types';
import logEvent from 'utils/analytics';

type ComponentProps = {
  width?: string;
  testPhrase?: string;
  modelId?: string;
  identifierId?: string;
  v2Version?: boolean;
};

const TestSentenceVisualizerV2: React.FC<ComponentProps> = ({
  width,
  testPhrase,
  modelId,
  identifierId,
  v2Version = false,
}) => {
  const { useEffect } = React;
  const dispatch = useDispatch();
  const bodyParam = useSelector(getNavParams).body_visualizer || '';

  const resultLines = useSelector(getAnnotatedTestSentenceResult);

  const testSentenceLoading = useSelector(getTestSentenceLoading);
  const { rulesViolated: rulesViolatedV2 } = useSelector(getRulesViolatedV2);

  const [platform, setPlatform] = useState<Platform | 'all'>('all');
  const [body, setBody] = useState<string>(bodyParam as string);
  const [debouncedBody, setDebouncedBody] = useState('');
  const showV2 = useSelector(getShowV2Graph);

  const RunButtonRef = useRef<HTMLButtonElement>(null);
  const SaveButtonRef = useRef<HTMLButtonElement>(null);
  const TextAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!v2Version) {
      dispatch(clearTestSentence());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedBody(body), 500);
    return (): void => {
      clearTimeout(timeoutId);
    };
  }, [body]);

  // works as a componentWillUnmount to avoid having data when returning to this view
  // @ts-ignore
  useEffect(() => {
    if (debouncedBody !== bodyParam) {
      dispatch(replaceURLParams({ body_visualizer: debouncedBody }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedBody, dispatch]);

  useEffect(() => {
    if (testPhrase) {
      setBody(testPhrase || '');
    }
  }, [testPhrase]);

  useEffect(() => {
    if (!bodyParam) return;
    setBody(bodyParam as string);
  }, [bodyParam]);

  const handlePlatformChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    logEvent('tests-platform-change');
    setPlatform(event.target.value as Platform);
  };

  const submitTest = (useV2 = false): void => {
    const payload: PostTestSentencePayloadV2 = {
      body,
      platform,
      force_v2_graphs: useV2,
    };
    if (identifierId) {
      payload.identifiers = [{ uuid: identifierId }];
    }
    if (modelId) {
      payload.models = [{ uuid: modelId }];
    }
    dispatch(postTestSentenceV2(payload));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    logEvent('tests-run-test');

    submitTest(showV2);
  };

  const handlers = {
    RUN_TEST_PHRASE: (): void => {
      if (document.activeElement === TextAreaRef?.current) {
        RunButtonRef?.current?.click();
      }
    },
    RUN_TEST_PHRASE_ALT: (): void => {
      if (document.activeElement === TextAreaRef?.current) {
        RunButtonRef?.current?.click();
      }
    },
    SAVE_TO_SUITE: (event: KeyboardEvent | undefined): void => {
      if (event) {
        event.preventDefault();
        if (document.activeElement === TextAreaRef?.current) {
          SaveButtonRef?.current?.click();
        }
      }
    },
  };

  return (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <div>
        <div className="p-4">
          <div className="flex flex-row gap-4">
            {!v2Version && (
              <div className={`${width || 'w-2/3'}`}>
                <form onSubmit={handleSubmit} data-testid="test-viz-phrase-form">
                  <div className="text-left">
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px"
                    >
                      <div className="mt-1 sm:mt-0 ">
                        <div className="flex rounded-md shadow-sm">
                          <textarea
                            ref={TextAreaRef}
                            id="about"
                            data-testid="test-viz-phrase-input"
                            rows={6}
                            className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            onChange={(event): void => {
                              setBody(event.target.value);
                            }}
                            value={body}
                          />
                        </div>
                      </div>
                    </label>
                  </div>
                  <div>
                    <p className="mt-2 text-xs text-gray-600 font-normal">
                      <strong>Ctrl + Enter </strong>
                      runs test.
                      <strong> Ctrl + S </strong>
                      saves test.
                    </p>
                  </div>
                  <div className="flex flex-row gap-2 justify-between">
                    <div className="mt-2 flex justify-end self-end flex-1">
                      <div className="flex justify-end">
                        <div className="flex justify-end items-end">
                          {!modelId && !identifierId && (
                            <div className="mr-8 flex">
                              <span className="mr-2 self-center text text--medium">Platform</span>
                              <select
                                aria-label="Selected tab"
                                className="form-select block pl-3 pr-10 py-px text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 transition ease-in-out duration-150 text text--large"
                                onChange={handlePlatformChange}
                                value={platform}
                                data-testid="platform-select"
                              >
                                {(
                                  Object.keys(platformNames) as Array<keyof typeof platformNames>
                                ).map((key) => (
                                  <option className="text" key={key} value={key}>
                                    {platformNames[key]}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                          <span className="inline-flex rounded-md shadow-sm">
                            <button
                              ref={RunButtonRef}
                              type="submit"
                              data-testid="run-test-button"
                              className={`button button--primary w-28 flex justify-center self-end ${
                                body === '' ? 'bg-opacity-25' : ''
                              }`}
                              disabled={body === ''}
                            >
                              <span className="text-white font-bold">
                                {testSentenceLoading ? <LoadingIndicator size="5" /> : 'Run Test'}
                              </span>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {resultLines && (
              <SentencesViolatedSectionGlobal
                showV2
                rulesViolated={rulesViolatedV2}
                rulesViolatedFrom={modelId ? 'models' : 'identifiers'}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TestSentenceVisualizerV2;
