/* eslint-disable camelcase */

import { fetchSavedSearchesFulfill, showSuccessAlert, upsertSavedSearchFulfill } from 'actions';
import {
  deleteCustomReport,
  deleteCustomReportFailure,
  deleteCustomReportFulfill,
  deleteCustomReportRequest,
  deleteCustomReportSuccess,
  fetchCustomReports,
  fetchCustomReportsFailure,
  fetchCustomReportsRequest,
  fetchCustomReportsSuccess,
  upsertCustomReport,
  upsertCustomReportFailure,
  upsertCustomReportRequest,
  upsertCustomReportSuccess,
} from 'actions/customReports';
import { apiClient as LitLingoClient } from 'client';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getNavParams, getNavParamsByResource } from 'selectors/nav';
import type { API, SagaReturn } from 'types';

function* fetchCustomReportsListSaga(action: ReturnType<typeof fetchCustomReports>): SagaReturn {
  const { payload } = action;
  yield put(fetchCustomReportsRequest());
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.customReport)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const navParams = (yield select(getNavParams)) as ReturnType<typeof getNavParams>;

  const params = {
    include_count: true,
    include_pii: 'true',
    ...payload,
    ...resourceParams,
    ...navParams,
  };

  const response = (yield call([LitLingoClient.resources.customReports, 'list'], {
    params: { ...params, relationships: ['search', 'user'] },
  })) as API.Response<API.CustomReports.List>;
  if (response.error != null) {
    yield put(fetchCustomReportsFailure(response.error));
  } else {
    yield put(fetchCustomReportsSuccess(response.data));
  }
  yield put(fetchSavedSearchesFulfill());
}

function* upsertCustomReportSaga(action: ReturnType<typeof upsertCustomReport>): SagaReturn {
  const { payload } = action;

  const data = {
    include_count: true,
    ...payload,
  };

  yield put(upsertCustomReportRequest());
  const response = (yield call([LitLingoClient.resources.customReports, 'upsert'], {
    data,
    params: { relationships: ['search', 'user'] },
  })) as API.Response<API.CustomReports.Upsert>;
  if (response.error) {
    yield put(upsertCustomReportFailure(response.error));
  } else {
    yield put(upsertCustomReportSuccess(response.data));
    yield put(showSuccessAlert('Report Created'));
  }
  yield put(upsertSavedSearchFulfill());
}

function* deleteCustomReportSaga(action: ReturnType<typeof deleteCustomReport>): SagaReturn {
  const { payload } = action;
  yield put(deleteCustomReportRequest());

  const response = (yield call(
    [LitLingoClient.resources.customReports, 'delete'],
    payload.uuid
  )) as API.Response<API.CustomReports.Delete>;
  if (response.error != null) {
    yield put(deleteCustomReportFailure(response.error));
  } else {
    yield put(deleteCustomReportSuccess(payload.uuid));
    yield put(showSuccessAlert('Report Deleted'));
  }
  yield put(deleteCustomReportFulfill());
}

function* customReportsSaga(): SagaReturn {
  yield takeLatest(upsertCustomReport.toString(), upsertCustomReportSaga);
  yield takeLatest(deleteCustomReport.toString(), deleteCustomReportSaga);
  yield takeLatest(fetchCustomReports.toString(), fetchCustomReportsListSaga);
}

export default customReportsSaga;
