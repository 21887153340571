/* eslint-disable @typescript-eslint/no-unused-vars */
import { fetchUsersCount } from 'actions';
import withWidget from 'decorators/widget';
import WidgetLoading from 'decorators/widget/WidgetLoading';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserLicensesNumber } from 'selectors/auth';
import { getUsersTotalCount, getUsersTotalCountLoading } from 'selectors/users';

const UserLicensesWidget: React.FC = () => {
  const dispatch = useDispatch();
  const userLicenses = useSelector(getUserLicensesNumber);
  const usersCount = useSelector(getUsersTotalCount);
  const usersCountLoading = useSelector(getUsersTotalCountLoading);

  useEffect(() => {
    dispatch(fetchUsersCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (usersCountLoading) {
    return <WidgetLoading />;
  }

  return (
    <div className="p-0 border border-litlingo-gray-2 rounded flex  items-center w-full h-full">
      <div className="flex flex-row  h-full w-full p-4 text-center min-w-22.5">
        <div className={`flex flex-col w-full justify-center items-center px-2 `}>
          <span className="text-heading-2">{userLicenses - usersCount}</span>
          <span className="text-small">Seats</span>
        </div>
      </div>
      <div className="border-r border-litlingo-gray-2 h-3/5" />
      <div className="flex flex-row  h-full w-full p-4 text-center min-w-27.5">
        <div className={`flex flex-col w-full justify-center items-center px-2 `}>
          <span className="text-heading-2">{userLicenses}</span>
          <span className="text-small">Licenses</span>
        </div>
      </div>
    </div>
  );
};

export default withWidget({
  fetchOnePeriod: true,
})(UserLicensesWidget);
