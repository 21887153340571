/* eslint-disable max-lines */
import { UUID } from '@litlingo/client';
import LinkLookup from 'components/LinkLookup';
import Permissions from 'components/Permissions';
import GeneralSidebar from 'components/Sidebar/GeneralSidebar';

import useNavParams from 'hooks/nav/useNavParams';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import routes from 'routes';
import { getUser } from 'selectors/auth';
import { meetsRolesReqs } from 'utils/permissions';

type Option = {
  label: string;
  routeName: string;
  routeParams?: Record<string, string>;
  queryParams?: Record<string, string>;
  permissions?: string;
  render?: boolean;
  disabled?: boolean;
};

type Group = {
  groupName: string;
  disabled?: boolean;
  options: Option[];
};

type RouteParams = {
  userId: UUID;
};

const SettingSidebar: React.FC = () => {
  const user = useSelector(getUser);

  const route = useRouteMatch<{ userId: string }>();
  const navParams = useNavParams() as { scroll: string };
  const routeName = routes.find((r) => r.path === route.path)?.name;
  const { userId } = useParams<RouteParams>();

  const renderSettings = (): boolean =>
    meetsRolesReqs(user.roles, ['super-admin', 'system-admin', 'admin']);

  const limited = !user.roles.includes('super-admin');

  const sidebarOptions: Group[] = [
    {
      groupName: 'Workspace',
      options: [
        {
          label: 'General',
          routeName: 'customer',
          routeParams: {},
          queryParams: { scroll: 'General' },
          permissions: 'customers.list',
        },
        {
          label: 'Data',
          routeName: 'customer',
          routeParams: {},
          queryParams: { scroll: 'Data' },
          permissions: 'customers.list',
        },
        {
          label: 'Actions',
          routeName: 'customer',
          routeParams: {},
          queryParams: { scroll: 'Actions' },
          permissions: 'customers.list',
          disabled: limited,
        },
        {
          label: 'Advanced',
          routeName: 'customer',
          routeParams: {},
          queryParams: { scroll: 'Advanced' },
          permissions: 'customers.list',
          disabled: limited,
        },
      ],
    },
    {
      groupName: 'Team',
      disabled: limited,

      options: [
        {
          label: 'My Account',
          routeName: 'user',
          routeParams: { userId: user.uuid },
          permissions: 'customers.list',
          disabled: limited,
        },
        {
          label: 'Users',
          routeName: 'users',
          permissions: 'customers.list',
          disabled: limited,
        },
        {
          label: 'Divisions',
          routeName: 'teams-manager',
          permissions: 'customers.list',
          disabled: limited,
        },
      ],
    },
    {
      groupName: 'Review',
      disabled: limited,

      options: [
        {
          label: 'Digests',
          routeName: 'custom-reports',
          permissions: 'customers.list',
          disabled: limited,
        },
        {
          label: 'Tags',
          routeName: 'tags',
          permissions: 'customers.list',
          disabled: limited,
        },
        {
          label: 'Templates',
          routeName: 'templates-manager',
          permissions: 'customers.list',
          disabled: limited,
        },
        {
          label: 'Tasks',
          routeName: 'tasks',
          permissions: 'customers.list',
          disabled: limited,
        },
        {
          label: 'Entities',
          routeName: 'entities',
          permissions: 'customers.list',
          disabled: limited,
        },
        {
          label: 'Prompts',
          routeName: 'prompts',
          permissions: 'customers.list',
          disabled: limited,
        },
      ],
    },
  ];

  const borderDecider = (name: string, query: Record<string, string> | undefined): boolean => {
    if (routeName === name) {
      if (name === 'customer') {
        if (query && query.scroll && query.scroll === navParams.scroll) {
          return true;
        }
        if (query && query.scroll && query.scroll === 'General' && !navParams.scroll) {
          return true;
        }

        return false;
      }

      if (name === 'user') {
        if (userId && user.uuid === userId) {
          return true;
        }
        return false;
      }

      return true;
    }

    if (name === 'users') {
      if (routeName === 'user-profile') {
        return true;
      }
    }

    if (name === 'tags') {
      if (routeName === 'tag-groups') {
        return true;
      }
    }

    if (
      routeName &&
      routeName.includes('customer') &&
      query &&
      query.scroll &&
      query.scroll === 'General'
    ) {
      return true;
    }

    return false;
  };

  const sidebar = (
    <div className="flex flex-col gap-6 bg-litlingo-primary-140">
      <div className="text-heading-2">
        <span className="text-white">Settings</span>
      </div>
      <div className="pl-4 flex flex-col gap-6">
        {sidebarOptions.map((group) => (
          <div className="flex flex-col gap-2 text-heading-3" key={group.groupName}>
            <span className={`${group.disabled ? 'text-litlingo-gray-3' : 'text-white'}`}>
              {group.groupName}
            </span>

            <div className="pl-6 flex flex-col gap-2">
              {group.options.map((option) => {
                if (option.render === false) {
                  return null;
                }

                return (
                  <Permissions key={option.label} action={option.permissions}>
                    <LinkLookup
                      as="a"
                      routeName={option.routeName}
                      queryParams={option.queryParams || {}}
                      routeParams={option.routeParams || {}}
                      preventNavigation={(): boolean => option.disabled || false}
                      className={`font-normal ${
                        option.disabled ? 'text-litlingo-gray-3' : 'text-white'
                      } ${
                        borderDecider(option.routeName, option.queryParams)
                          ? 'border-b-2 border-litlingo-secondary-100'
                          : ''
                      }`}
                    >
                      {option.label}
                    </LinkLookup>
                  </Permissions>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return renderSettings() ? <GeneralSidebar baseSidebar={sidebar} /> : null;
};
export default SettingSidebar;
