import RawEmail from 'components/HighlightedSentenceComponent/RawEmail';
import React from 'react';
import type { UUID } from 'types';
import WrapperHighlightedSentence from './WrapperHighlightedSentence';

type ComponentProps = {
  eventId: UUID;
  commUuid: UUID | null;
  fullMessageOptions: string;
  onlySubject?: boolean;
  borderSeparation?: string;
  highRiskOnly?: boolean;
  hideHighlight?: boolean;
  underlineOnly?: boolean;
};

const HighlightedSentenceComponent: React.FC<ComponentProps> = ({
  eventId,
  commUuid,
  fullMessageOptions,
  onlySubject,
  borderSeparation,
  highRiskOnly = false,
  hideHighlight = false,
  underlineOnly = false,
}) => {
  const FULL_OPTIONS = {
    HIGHLIGHTED: 'HIGHLIGHTED',
    PLAIN: 'PLAIN',
  };

  const renderContent = (): JSX.Element | null => {
    if (fullMessageOptions === FULL_OPTIONS.HIGHLIGHTED) {
      return (
        <WrapperHighlightedSentence
          eventId={eventId}
          commUuid={commUuid}
          onlySubject={onlySubject || false}
          borderSeparation={borderSeparation}
          highRiskOnly={highRiskOnly}
          hideHighlight={hideHighlight}
          underlineOnly={underlineOnly}
        />
      );
    }
    if (fullMessageOptions === FULL_OPTIONS.PLAIN) {
      return <RawEmail eventId={eventId} commUuid={commUuid} />;
    }
    return null;
  };

  return <>{renderContent()}</>;
};

export default HighlightedSentenceComponent;
