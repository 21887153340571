import EmptyTableState from 'components/EmptyTableState';
import ListFooter from 'components/ListFooter';
import LoadingIndicator from 'components/LoadingIndicator';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useSelector } from 'react-redux';

import { getPrompts, getPromptsLoading, getPromptsTotalCount } from 'selectors/entities';
import PromptListRow from './PromptListRow';

const PromptTable: React.FC = () => {
  const prompts = useSelector(getPrompts);
  const loading = useSelector(getPromptsLoading);
  const isEmpty = prompts.length === 0;

  return (
    <div className="mt-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <LoadingOverlay
        active={loading}
        spinner={<LoadingIndicator size="10" />}
        fadeSpeed={0}
        styles={{
          content: (base) => ({
            ...base,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }),
          wrapper: (base) => ({
            ...base,
            height: '100%',
          }),
          spinner: () => ({}),
          overlay: (base) => ({
            ...base,
            zIndex: 10,
          }),
        }}
      >
        <div className="table-wrapper__new">
          <table className="table-wrapper__table">
            <thead>
              <tr>
                <th className="table-wrapper__new-th table-wrapper__th--w-15 pl-4">Name</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-22 px-1">Description</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-15 pl-4">Kind</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-22 px-1">Deployment</th>
                <th className="table-wrapper__new-th  px-1">Content</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-15 px-1">Source</th>
                <th className="table-wrapper__new-th table-wrapper__th--w-15"> Actions</th>
              </tr>
            </thead>
            <tbody className="table-wrapper__tbody">
              {isEmpty ? (
                <EmptyTableState message="There are no prompts to display" colCount="7" />
              ) : (
                prompts?.map((prompt) => <PromptListRow prompt={prompt} key={prompt.uuid} />)
              )}
            </tbody>
          </table>
          <ListFooter
            resourceStateName={resourceQueryParamName.prompts}
            resourceName="prompt"
            getResourceList={getPrompts}
            getResourceTotalCount={getPromptsTotalCount}
            getResourceLoading={getPromptsLoading}
          />
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default PromptTable;
