import { createDraftSafeSelector, createSelector } from '@reduxjs/toolkit';
import {
  fetchAnnotatorsByIdsRequest,
  fetchCampaignsForFilterPillsRequest,
  fetchRulesForFilterPillsRequest,
  fetchTagGroupsRequest,
  fetchTagsForFilterPillsRequest,
  fetchTeamsForFilterPillsRequest,
  fetchUsersForFilterPillsRequest,
} from 'actions';
import { EnvelopeToPreviewPayload, FromAssignmentsPayload } from 'actions/envelopeListView';
import { FilterOrder } from 'reducers/envelopeListView';
import { getEnvelopesTotalCount, getFetchAllEnvelopesLoading } from 'selectors/envelopes';
import type { Selector, UUID } from 'types';
import { getCustomerFurtherReviewTagGroup, getCustomersAllowFulTextSearch } from './auth';
import { getReviewLabelOptionsForDashboard } from './reviewStatus';
import { getFurtherReviewTagGroup } from './tagGroup';

const getIsFilterOpen =
  (filterKey: string): Selector<boolean | null> =>
  ({ ui }): boolean | null => {
    const filter = ui.envelopeListView.filtersState[filterKey];

    if (filter) {
      return filter.isOpen;
    }
    return null;
  };

export const getSavedSelectedEnvelopes: Selector<UUID[]> = (state) =>
  state.ui?.envelopeListView.selectedEnvelopesState;

export const getFiltersOrder: Selector<FilterOrder> = (state) =>
  state.ui?.envelopeListView.filtersOrder;

export const getStateFromAssignments: Selector<FromAssignmentsPayload | null> = (state) =>
  state.ui?.envelopeListView.fromAssignments;

export const getIsFirstVisit: Selector<boolean> = (state) => state.ui?.envelopeListView.firstVisit;

export const getEnvelopeToPreview: Selector<EnvelopeToPreviewPayload> = (state) =>
  state.ui?.envelopeListView.envelopeToPreview;

export const getHoveredEnvelope: Selector<UUID | null> = (state) =>
  state.ui?.envelopeListView.hoveredEnvelope;

export const getIsReviewSetCreated: Selector<boolean> = (state) =>
  state.ui?.envelopeListView.createdReviewSet;

export const getOrderByNewParam: Selector<boolean> = (state) =>
  state.ui?.envelopeListView.newOrderParam;

export const getFromEnvelope: Selector<boolean> = (state) =>
  state.ui?.envelopeListView.fromEnvelope;

export const getExploreSubsamplesModalOpen: Selector<boolean> = (state) =>
  state.ui.envelopeListView.subsamplesModalOpen;

export const getExploreMessagesModalOpen: Selector<boolean> = (state) =>
  state.ui.envelopeListView.messagesModalOpen;

export const getTaggingEnvelope: Selector<boolean> = (state) =>
  state.ui.envelopeListView.taggingEnvelope;

export const getNavParamsFetchingFilters: Selector<boolean> = (state) =>
  [
    state.users.loading.includes(fetchUsersForFilterPillsRequest.toString()),
    state.teams.loading.includes(fetchTeamsForFilterPillsRequest.toString()),
    state.campaign.loading.includes(fetchCampaignsForFilterPillsRequest.toString()),
    state.rule.loading.includes(fetchRulesForFilterPillsRequest.toString()),
    state.annotator.loading.includes(fetchAnnotatorsByIdsRequest.toString()),
    state.tagGroups.loading.includes(fetchTagGroupsRequest.toString()),
    state.tags.loading.includes(fetchTagsForFilterPillsRequest.toString()),
  ].some(Boolean);

export const envelopeListContentState = createSelector(
  [
    getFetchAllEnvelopesLoading,
    getEnvelopesTotalCount,
    getSavedSelectedEnvelopes,
    getStateFromAssignments,
  ],
  (loadingAll, totalCount, envelopesSelected, comingFromAssignments) => ({
    loadingAll,
    totalCount,
    envelopesSelected,
    comingFromAssignments,
  })
);

export const eventsSidebarState = createDraftSafeSelector(
  [
    getCustomerFurtherReviewTagGroup,
    getFurtherReviewTagGroup,
    getCustomersAllowFulTextSearch,
    getReviewLabelOptionsForDashboard,
  ],
  (furtherReviewTagGroupId, furtherReviewTagGroup, showSearchBar, reviewLabelOptions) => ({
    furtherReviewTagGroupId,
    furtherReviewTagGroup,
    showSearchBar,
    reviewLabelOptions,
  })
);

export default getIsFilterOpen;
