import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  DomainResponsePayload,
  addUserMetadata,
  deleteUserFailure,
  deleteUserSuccess,
  fetchCustomerRecipientsDomainFailure,
  fetchCustomerRecipientsDomainRequest,
  fetchCustomerRecipientsDomainSuccess,
  fetchCustomerUsersDomainFailure,
  fetchCustomerUsersDomainRequest,
  fetchCustomerUsersDomainSuccess,
  fetchTeamUsersCountFailure,
  fetchTeamUsersCountRequest,
  fetchTeamUsersCountSuccess,
  fetchUserIntegrationsCountFailure,
  fetchUserIntegrationsCountRequest,
  fetchUserIntegrationsCountSuccess,
  fetchUserMetadataSuccess,
  fetchUserRequest,
  fetchUserRolesCountFailure,
  fetchUserRolesCountRequest,
  fetchUserRolesCountSuccess,
  fetchUserRolesFailure,
  fetchUserRolesRequest,
  fetchUserRolesSuccess,
  fetchUserSuccess,
  fetchUsersCountRequest,
  fetchUsersCountSuccess,
  fetchUsersFailure,
  fetchUsersForFilterPillsFailure,
  fetchUsersForFilterPillsRequest,
  fetchUsersForFilterPillsSuccess,
  fetchUsersRequest,
  fetchUsersSuccess,
  fetchUsersSuccessAppend,
  saveUserFailure,
  saveUserRequest,
  saveUserSuccess,
  saveUsersFulfill,
  saveUsersSuccess,
  selectUser,
  undeleteUserFailure,
  undeleteUserFulfill,
  undeleteUserRequest,
  undeleteUserSuccess,
  unlockEnvelopeFailure,
  unlockEnvelopeFulfill,
  unlockEnvelopeRequest,
  unlockEnvelopeSuccess,
  upsertUserMetadataSuccess,
} from 'actions';
import type { API, EntityMetadata, ErrorObject, NormalizedResource, UUID, User } from 'types';

export type UserState = {
  loading: string[];
  error: ErrorObject | null;
  selectedUser: User | null;
  items: NormalizedResource<User>;
  filterItems: NormalizedResource<User>;
  availableRoles: string[];
  currentUserMetadata: EntityMetadata;
  count: number;
  senderDomains: string[];
  recipientsDomains: string[];
  senderDomainsCount: number;
  recipientsDomainsCount: number;
  rolesCount: { [key: string]: number };
  integrationsCount: { [key: string]: number };
  teamUsersCount: { [key: string]: number };
};

type UserReducer<P = void> = (state: UserState, payload: PayloadAction<P>) => UserState;

export const defaultState: UserState = {
  loading: [],
  error: null,
  selectedUser: null,
  items: {},
  filterItems: {},
  availableRoles: [],
  currentUserMetadata: {} as EntityMetadata,
  count: 0,
  senderDomains: [],
  recipientsDomains: [],
  senderDomainsCount: 0,
  recipientsDomainsCount: 0,
  rolesCount: {},
  integrationsCount: {},
  teamUsersCount: {},
};

const handleFetchUsersRequest: UserReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchUsersRequest.toString()],
});

const handleFetchUsersFailure: UserReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchUsersRequest.toString()),
});

const handleFetchUsersSuccess: UserReducer<API.WrappedAPIResponse<User>> = (state, { payload }) => {
  const normalizedUsers: NormalizedResource<User> = {};
  payload.records.forEach((user) => {
    normalizedUsers[user.uuid] = user;
  });
  return {
    ...state,
    items: normalizedUsers,
    loading: state.loading.filter((s) => s !== fetchUsersRequest.toString()),
    count: payload.count,
  };
};

const handleFetchUsersSuccessAppend: UserReducer<API.WrappedAPIResponse<User>> = (
  state,
  { payload }
) => {
  const normalizedUsers: NormalizedResource<User> = {};
  payload.records.forEach((user) => {
    normalizedUsers[user.uuid] = user;
  });
  return {
    ...state,
    items: { ...state.items, ...normalizedUsers },
    loading: state.loading.filter((s) => s !== fetchUsersRequest.toString()),
    count: payload.count,
  };
};

const handleFetchUsersForFilterPillsRequest: UserReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchUsersForFilterPillsRequest.toString()],
});

const handleFetchUsersForFilterPillsFailure: UserReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchUsersForFilterPillsRequest.toString()),
});

const handleFetchUsersForFilterPillsSuccess: UserReducer<API.WrappedAPIResponse<User>> = (
  state,
  { payload }
) => {
  const normalizedUsers: NormalizedResource<User> = {};
  payload.records.forEach((user) => {
    normalizedUsers[user.uuid] = user;
  });
  return {
    ...state,
    filterItems: normalizedUsers,
    loading: state.loading.filter((s) => s !== fetchUsersForFilterPillsRequest.toString()),
    count: payload.count,
  };
};

const handleSelectUser: UserReducer<User | null> = (state, { payload }) => ({
  ...state,
  selectedUser: payload,
});

const handleSaveUserRequest: UserReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, saveUserRequest.toString()],
});

const handleSaveUserFailure: UserReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== saveUserRequest.toString()),
});

const handleSaveUserSuccess: UserReducer<User> = (state, { payload }) => {
  const normalizedUsers: NormalizedResource<User> = {
    [payload.uuid]: payload,
  };

  return {
    ...state,
    selectedUser: payload,
    items: { ...state.items, ...normalizedUsers },
    loading: state.loading.filter((s) => s !== saveUserRequest.toString()),
  };
};

const handleFetchUserRolesRequest: UserReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, handleFetchUserRolesRequest.toString()],
});

const handleFetchUserRolesFailure: UserReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== handleFetchUserRolesRequest.toString()),
});

const handleFetchUserRolesSuccess: UserReducer<string[]> = (state, { payload }) => ({
  ...state,
  availableRoles: [...payload],
  loading: state.loading.filter((s) => s !== handleFetchUserRolesRequest.toString()),
});

const handleDeleteUserSuccess: UserReducer<string[]> = (state, { payload: ids }) => {
  const users = { ...state.items };

  ids.forEach((i) => {
    delete users[i];
  });

  return {
    ...state,
    items: users,
  };
};

const handleDeleteUserFailure: UserReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleUndeleteUserRequest: UserReducer = (state) => ({
  ...state,
  loading: [...state.loading, undeleteUserRequest.toString()],
});

const handleUndeleteUserSuccess: UserReducer<UUID> = (state, { payload }) => ({
  ...state,
  items: {
    ...state.items,
    [payload]: { ...state.items[payload], deleted_at: null, deleted_by_uuid: null },
  },
});

const handleUndeleteUserFailure: UserReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleUndeleteUserFulfill: UserReducer<ErrorObject> = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== undeleteUserRequest.toString()),
});

const handleUnlockEnvelopeRequest: UserReducer = (state) => ({
  ...state,
  loading: [...state.loading, unlockEnvelopeRequest.toString()],
});

const handleUnlockEnvelopeSuccess: UserReducer<UUID> = (state) => ({
  ...state,
});

const handleUnlockEnvelopeFailure: UserReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleUnlockEnvelopeFulfill: UserReducer<ErrorObject> = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== unlockEnvelopeRequest.toString()),
});

const handleFetchUserRequest: UserReducer = (state) => ({
  ...state,
  loading: [...state.loading, fetchUserRequest.toString()],
});

const handleFetchUserSuccess: UserReducer<User> = (state, { payload }) => ({
  ...state,
  items: {
    ...state.items,
    [payload.uuid]: payload,
  },
  loading: state.loading.filter((r) => r !== fetchUserRequest.toString()),
});

const handleSaveUsersFulfill: UserReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== saveUserRequest.toString()),
});

const handleFetchUserMetadataSuccess: UserReducer<EntityMetadata> = (state, { payload }) => ({
  ...state,
  currentUserMetadata: payload,
});

const handleFetchCustomerUsersDomainRequest: UserReducer = (state) => ({
  ...state,
  loading: [...state.loading, fetchCustomerUsersDomainRequest.toString()],
});

const handleFetchCustomerUsersDomainSuccess: UserReducer<DomainResponsePayload> = (
  state,
  { payload }
) => ({
  ...state,
  senderDomains: payload.domains,
  senderDomainsCount: payload.count,
  loading: state.loading.filter((s) => s !== fetchCustomerUsersDomainRequest.toString()),
});

const handleFetchCustomerUsersDomainFailure: UserReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchCustomerUsersDomainRequest.toString()),
});

const handleFetchCustomerRecipientsDomainRequest: UserReducer = (state) => ({
  ...state,
  loading: [...state.loading, fetchCustomerRecipientsDomainRequest.toString()],
});

const handleFetchCustomerRecipientsDomainSuccess: UserReducer<DomainResponsePayload> = (
  state,
  { payload }
) => ({
  ...state,
  recipientsDomains: payload.domains,
  recipientsDomainsCount: payload.count,
  loading: state.loading.filter((s) => s !== fetchCustomerRecipientsDomainRequest.toString()),
});

const handleFetchCustomerRecipientsDomainFailure: UserReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchCustomerRecipientsDomainRequest.toString()),
});

const handleFetchUserRolesCountRequest: UserReducer = (state: UserState) => ({
  ...state,
  loading: [...state.loading, fetchUserRolesCountRequest.toString()],
});

const handleFetchUserRolesCountSuccess: UserReducer<API.Users.RolesCount> = (
  state,
  { payload }
) => ({
  ...state,
  rolesCount: { ...state.rolesCount, ...payload },
  loading: state.loading.filter((s) => s !== fetchUserRolesCountRequest.toString()),
});

const handleFetchUserRolesCountFailure: UserReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchUserRolesCountRequest.toString()),
});

const handleFetchUserIntegrationsCountRequest: UserReducer = (state: UserState) => ({
  ...state,
  loading: [...state.loading, fetchUserIntegrationsCountRequest.toString()],
});

const handleFetchUserIntegrationsCountSuccess: UserReducer<API.Users.IntegrationCount> = (
  state,
  { payload }
) => ({
  ...state,
  integrationsCount: { ...state.integrationsCount, ...payload },
  loading: state.loading.filter((s) => s !== fetchUserIntegrationsCountRequest.toString()),
});

const handleFetchUserIntegrationsCountFailure: UserReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchUserIntegrationsCountRequest.toString()),
});

const handleFetchTeamUsersCountRequest: UserReducer = (state: UserState) => ({
  ...state,
  loading: [...state.loading, fetchTeamUsersCountRequest.toString()],
});

const handleFetchTeamUsersCountSuccess: UserReducer<API.Users.TeamUsersCount> = (
  state,
  { payload }
) => ({
  ...state,
  teamUsersCount: { ...state.teamUsersCount, ...payload },
  loading: state.loading.filter((s) => s !== fetchTeamUsersCountRequest.toString()),
});

const handleFetchTeamUsersCountFailure: UserReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchTeamUsersCountRequest.toString()),
});

const handleSaveUsersSuccess: UserReducer<User[]> = (state, { payload }) => {
  const users = { ...state.items };

  payload.forEach((user) => {
    users[user.uuid] = user;
  });

  return {
    ...state,
    items: { ...users },
  };
};

const handleFetchUsersCountRequest: UserReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchUsersCountRequest.toString()],
});

const handleFetchUsersCountSuccess: UserReducer<API.Users.Count> = (state, { payload }) => ({
  ...state,
  count: payload.count,
  loading: state.loading.filter((s) => s !== fetchUsersCountRequest.toString()),
});

const handlers = {
  [fetchUsersRequest.toString()]: handleFetchUsersRequest,
  [fetchUsersSuccess.toString()]: handleFetchUsersSuccess,
  [fetchUsersSuccessAppend.toString()]: handleFetchUsersSuccessAppend,
  [fetchUsersFailure.toString()]: handleFetchUsersFailure,
  [fetchUsersForFilterPillsRequest.toString()]: handleFetchUsersForFilterPillsRequest,
  [fetchUsersForFilterPillsSuccess.toString()]: handleFetchUsersForFilterPillsSuccess,
  [fetchUsersForFilterPillsFailure.toString()]: handleFetchUsersForFilterPillsFailure,
  [selectUser.toString()]: handleSelectUser,
  [saveUserRequest.toString()]: handleSaveUserRequest,
  [saveUserSuccess.toString()]: handleSaveUserSuccess,
  [saveUserFailure.toString()]: handleSaveUserFailure,
  [fetchUserRolesRequest.toString()]: handleFetchUserRolesRequest,
  [fetchUserRolesSuccess.toString()]: handleFetchUserRolesSuccess,
  [fetchUserRolesFailure.toString()]: handleFetchUserRolesFailure,
  [deleteUserSuccess.toString()]: handleDeleteUserSuccess,
  [deleteUserFailure.toString()]: handleDeleteUserFailure,
  [undeleteUserRequest.toString()]: handleUndeleteUserRequest,
  [undeleteUserSuccess.toString()]: handleUndeleteUserSuccess,
  [undeleteUserFailure.toString()]: handleUndeleteUserFailure,
  [undeleteUserFulfill.toString()]: handleUndeleteUserFulfill,
  [unlockEnvelopeRequest.toString()]: handleUnlockEnvelopeRequest,
  [unlockEnvelopeSuccess.toString()]: handleUnlockEnvelopeSuccess,
  [unlockEnvelopeFailure.toString()]: handleUnlockEnvelopeFailure,
  [unlockEnvelopeFulfill.toString()]: handleUnlockEnvelopeFulfill,
  [fetchUserRequest.toString()]: handleFetchUserRequest,
  [fetchUserSuccess.toString()]: handleFetchUserSuccess,
  [saveUsersFulfill.toString()]: handleSaveUsersFulfill,
  [fetchUserMetadataSuccess.toString()]: handleFetchUserMetadataSuccess,
  [upsertUserMetadataSuccess.toString()]: handleFetchUserMetadataSuccess,
  [addUserMetadata.toString()]: handleFetchUserMetadataSuccess,
  [fetchCustomerUsersDomainRequest.toString()]: handleFetchCustomerUsersDomainRequest,
  [fetchCustomerUsersDomainSuccess.toString()]: handleFetchCustomerUsersDomainSuccess,
  [fetchCustomerUsersDomainFailure.toString()]: handleFetchCustomerUsersDomainFailure,
  [fetchCustomerRecipientsDomainRequest.toString()]: handleFetchCustomerRecipientsDomainRequest,
  [fetchCustomerRecipientsDomainSuccess.toString()]: handleFetchCustomerRecipientsDomainSuccess,
  [fetchCustomerRecipientsDomainFailure.toString()]: handleFetchCustomerRecipientsDomainFailure,
  [fetchUserRolesCountRequest.toString()]: handleFetchUserRolesCountRequest,
  [fetchUserRolesCountSuccess.toString()]: handleFetchUserRolesCountSuccess,
  [fetchUserRolesCountFailure.toString()]: handleFetchUserRolesCountFailure,
  [fetchUserIntegrationsCountRequest.toString()]: handleFetchUserIntegrationsCountRequest,
  [fetchUserIntegrationsCountSuccess.toString()]: handleFetchUserIntegrationsCountSuccess,
  [fetchUserIntegrationsCountFailure.toString()]: handleFetchUserIntegrationsCountFailure,
  [fetchTeamUsersCountRequest.toString()]: handleFetchTeamUsersCountRequest,
  [fetchTeamUsersCountSuccess.toString()]: handleFetchTeamUsersCountSuccess,
  [fetchTeamUsersCountFailure.toString()]: handleFetchTeamUsersCountFailure,
  [saveUsersSuccess.toString()]: handleSaveUsersSuccess,
  [fetchUsersCountRequest.toString()]: handleFetchUsersCountRequest,
  [fetchUsersCountSuccess.toString()]: handleFetchUsersCountSuccess,
};

const usersReducer = createReducer(defaultState, handlers);

export default usersReducer;
