import { createAction } from '@reduxjs/toolkit';
import amplitude from 'amplitude-js';
import type {
  APIV2,
  Customer,
  CustomerConfig,
  CustomerUserToken,
  ErrorObject,
  User,
  UUID,
} from 'types';
import { LookupProps } from 'utils/urls';

export type ChangeCustomerSuccessPayload = {
  user: CustomerUserToken;
};

export type RedirectToLitlingoLoginPayload = {
  providerHint?: string;
};

export const redirectToLitlingoLogin = createAction<RedirectToLitlingoLoginPayload>(
  'AUTH/REDIRECT_TO_LITLINGO'
);
export const redirectToLitlingoLoginRequest = createAction('AUTH/REDIRECT_TO_LITLINGO_REQUEST');
export const redirectToLitlingoLoginFailure = createAction<ErrorObject>(
  'AUTH/REDIRECT_TO_LITLINGO_FAILURE'
);
export const redirectToLitlingoLoginFulfill = createAction<ErrorObject>(
  'AUTH/REDIRECT_TO_LITLINGO_FULFILL'
);

export const authGmail = createAction<string>('AUTH/AUTH_GMAIL');

// get perms from google to store your emails
export const authRequest = createAction('AUTH/AUTH_REQUEST');

export type AuthSuccessPayload = {
  userCustomers: CustomerUserToken[];
};
export const authSuccess = createAction<AuthSuccessPayload>('AUTH/AUTH_SUCCESS');
export const authError = createAction<ErrorObject>('AUTH/AUTH_FAILURE');

export type VerifySuccessPayload = {
  user: APIV2.Users.Me;
};
export const verifyAuth = createAction<{ domain: string }>('AUTH/VERIFY');
export const verifyRequest = createAction('AUTH/VERIFY_REQUEST');
export const verifySuccess = createAction<VerifySuccessPayload>('AUTH/VERIFY_SUCCESS');
export const verifyFailure = createAction<ErrorObject>('AUTH/VERIFY_FAILURE');

type FetchAllCustomersPayload = { limit?: number };

export const fetchAllCustomers = createAction<FetchAllCustomersPayload>('CUSTOMERS/FETCH');
export const fetchCustomersRequest = createAction('CUSTOMERS/FETCH_REQUEST');
export const fetchCustomersSuccess = createAction<Customer[]>('CUSTOMERS/FETCH_SUCCESS');
export const fetchCustomersFailure = createAction<ErrorObject>('CUSTOMERS/FETCH_FAILURE');

export const fetchSingleCustomer = createAction('CUSTOMERS/FETCH_SINGLE');
export const fetchSingleCustomerRequest = createAction('CUSTOMERS/FETCH_SINGLE_REQUEST');
export const fetchSingleCustomerSuccess = createAction<Customer>('CUSTOMERS/FETCH_SINGLE_SUCCESS');
export const fetchSingleCustomerFailure = createAction<ErrorObject>(
  'CUSTOMERS/FETCH_SINGLE_FAILURE'
);

export type SaveCustomerPayload = Partial<Customer> & {
  // secret will be an string or a valid JSON, we can create a proper type for
  // the JSON but it's not worth the effort because JSON.parse returns any
  secrets?: { [secret: string]: string | { [key: string]: string } };
};

export const saveCustomer = createAction<SaveCustomerPayload | { config: CustomerConfig }>(
  'CUSTOMER/SAVE'
);
export const saveCustomerRequest = createAction('CUSTOMER/SAVE_REQUEST');
export const saveCustomerSuccess = createAction<Customer>('CUSTOMER/SAVE_SUCCESS');
export const saveCustomerFailure = createAction<ErrorObject>('CUSTOMER/SAVE_FAILURE');

export const createCustomer = createAction<Pick<Customer, 'name' | 'domain'>>('CUSTOMER/CREATE');
export const createCustomerRequest = createAction('CUSTOMER/CREATE_REQUEST');
export const createCustomerSuccess = createAction('CUSTOMER/CREATE_SUCCESS');
export const createCustomerFailure = createAction<ErrorObject>('CUSTOMER/CREATE_FAILURE');

type ReprocessCommsType = {
  // eslint-disable-next-line camelcase
  start_time: string;
  // eslint-disable-next-line camelcase
  end_time: string;
};

export const reprocessComms = createAction<ReprocessCommsType>('CUSTOMER/REPROCESS_COMMS');
export const fetchReprocessCommsRequest = createAction('CUSTOMER/REPROCESS_COMMS_REQUEST');
export const fetchReprocessCommsSucess = createAction('CUSTOMER/REPROCESS_COMMS_SUCCESS');
export const fetchReprocessCommsFailure = createAction<ErrorObject>(
  'CUSTOMER/REPROCESS_COMMS_FAILURE'
);

type CreateNewSandboxType = {
  name: string;
  // eslint-disable-next-line camelcase
  start_time: string;
  // eslint-disable-next-line camelcase
  campaign_uuids: UUID[];
};

export const createNewSandbox = createAction<CreateNewSandboxType>('CUSTOMER/CREATE_SANDBOX');
export const createNewSandboxRequest = createAction('CUSTOMER/CREATE_SANBOX_REQUEST');
export const createNewSandboxSuccess = createAction<Customer>('CUSTOMER/CREATE_SANBOX_SUCCESS');
export const createNewSandboxFailure = createAction<ErrorObject>('CUSTOMER/CREATE_SANBOX_FAILURE');

export const fetchAllSandboxes = createAction('CUSTOMER/FETCH_ALL_SANDBOX');
export const fetchAllSandboxesRequest = createAction('CUSTOMER/FETCH_ALL_SANBOX_REQUEST');
export const fetchAllSandboxesSuccess = createAction<Customer[]>(
  'CUSTOMER/FETCH_ALL_SANBOX_SUCCESS'
);
export const fetchAllSandboxesFailure = createAction<ErrorObject>(
  'CUSTOMER/FETCH_ALL_SANBOX_FAILURE'
);

export const reindexComms = createAction('CUSTOMER/REINDEX_COMMS');
export const fetchReindexCommsRequest = createAction('CUSTOMER/REINDEX_COMMS_REQUEST');
export const fetchReindexCommsSucess = createAction('CUSTOMER/REINDEX_COMMS_SUCCESS');
export const fetchReindexCommsFailure = createAction<ErrorObject>('CUSTOMER/REINDEX_COMMS_FAILURE');

export type FetchIndexesCommsPayload = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  indexes: any;
};
export const fetchIndexesComms = createAction('CUSTOMER/INDEXES_COMMS');
export const fetchIndexesCommsRequest = createAction('CUSTOMER/INDEXES_COMMS_REQUEST');
export const fetchIndexesCommsSuccess = createAction<FetchIndexesCommsPayload>(
  'CUSTOMER/INDEXES_COMMS_SUCCESS'
);
export const fetchIndexesCommsFailure = createAction<ErrorObject>('CUSTOMER/INDEXES_COMMS_FAILURE');

export type FetchLogMetricsPayload =
  // eslint-disable-next-line camelcase
  { queue_name: string; length: number; by_priority?: Record<string, unknown> | null }[];

export const fetchLogMetrics = createAction('CUSTOMER/LOG_METRICS');
export const fetchLogMetricsRequest = createAction('CUSTOMER/LOG_METRICS_REQUEST');
export const fetchLogMetricsSuccess = createAction<FetchLogMetricsPayload>(
  'CUSTOMER/LOG_METRICS_SUCCESS'
);
export const fetchLogMetricsFailure = createAction<ErrorObject>('CUSTOMER/LOG_METRICS_FAILURE');

export type FetchIndexingStatusPayload = {
  pending: number;
  scheduled: number;
  complete: number;
  failed: number;
  null: number;
};

export const fetchIndexingStatus = createAction('CUSTOMER/INDEXING_STATUS');
export const fetchIndexingStatusRequest = createAction('CUSTOMER/INDEXING_STATUS_REQUEST');
export const fetchIndexingStatusSuccess = createAction<FetchIndexingStatusPayload>(
  'CUSTOMER/INDEXING_STATUS_SUCCESS'
);
export const fetchIndexingStatusFailure = createAction<ErrorObject>(
  'CUSTOMER/INDEXING_STATUS_FAILURE'
);

export type FetchProcessingStatusPayload = {
  // eslint-disable-next-line camelcase
  not_ready: number;
  pending: number;
  complete: number;
  failed: number;
};

export const fetchProcessingStatus = createAction('CUSTOMER/PROCESSING_STATUS');
export const fetchProcessingStatusRequest = createAction('CUSTOMER/PROCESSING_STATUS_REQUEST');
export const fetchProcessingStatusSuccess = createAction<FetchProcessingStatusPayload>(
  'CUSTOMER/PROCESSING_STATUS_SUCCESS'
);
export const fetchIProcessingStatusFailure = createAction<ErrorObject>(
  'CUSTOMER/PROCESSING_STATUS_FAILURE'
);

export type PayloadCheckCustomer = {
  curDomain: string;
};

export const fetchZendesk = createAction('CUSTOMER/FETCH_ZENDESK');
export const fetchZendeskRequest = createAction('CUSTOMER/FETCH_ZENDESK_REQUEST');

export type FetchZendeskSuccessPayload = {
  key: string;
};
export const fetchZendeskSuccess = createAction<FetchZendeskSuccessPayload>(
  'CUSTOMER/FETCH_ZENDESK_SUCCESS'
);
export const fetchZendeskFailure = createAction<ErrorObject>('CUSTOMER/FETCH_ZENDESK_FAILURE');

export const editCustomer = createAction<Partial<Customer>>('CUSTOMER/EDIT');

export const setUserAmplitude = (uuid: UUID): void => {
  amplitude.getInstance().setUserId(uuid);
};

export const logoutUser = createAction('AUTH/LOGOUT_USER');
export const logoutUserRequest = createAction('AUTH/LOGOUT_USER_REQUEST');
export const logoutUserFulfill = createAction('AUTH/LOGOUT_USER_FULFILL');

export const selectCustomerCloneComm = createAction<{
  domain: string;
  newEnvelopeId: string;
  prevEnvelopeId: string;
}>('CUSTOMER/SELECT_CUSTOMER_CLONE_COMM_SAGA');
export const selectCustomerCloneCommRequest = createAction(
  'CUSTOMER/SELECT_CUSTOMER_CLONE_COMM_REQUEST'
);
export const selectCustomerCloneCommSuccess = createAction(
  'CUSTOMER/SELECT_CUSTOMER_CLONE_COMM_SUCCESS'
);
export const selectCustomerCloneCommFailure = createAction<ErrorObject>(
  'CUSTOMER/SELECT_CUSTOMER_CLONE_COMM_FAILURE'
);
export const navigateToOriginalComm = createAction<{
  customerUuid: string;
  platformGuid: string;
  prevEnvelopeId: string;
}>('CUSTOMER/NAVIGATE_TO_ORIGINAL_COMM');
export const selectCustomer = createAction<{ domain: string }>('CUSTOMER/SELECT_CUSTOMER');
export const selectCustomerRequest = createAction('CUSTOMER/SELECT_CUSTOMER_REQUEST');
export const selectCustomerSuccess = createAction<User>('CUSTOMER/SELECT_CUSTOMER_SUCCESS');
export const selectCustomerFailure = createAction<ErrorObject>('CUSTOMER/SELECT_CUSTOMER_FAILURE');
export const selectCustomerFulfill = createAction('CUSTOMER/SELECT_CUSTOMER_FULFILL');

export const selectCustomerAndRedirect = createAction<{ domain: string; redirect: LookupProps }>(
  'CUSTOMER/SELECT_CUSTOMER_REDIRECT'
);

export const spoofRoles = createAction<{
  userUuid: string | undefined;
  userEmail: string | undefined;
}>('USER/SPOOF_ROLES');
export const spoofRolesRequest = createAction('USER/SPOOF_ROLES_REQUEST');
export const spoofRolesSuccess = createAction('USER/SPOOF_ROLES_SUCCESS');
export const spoofRolesFailure = createAction<ErrorObject>('USER/SPOOF_ROLES_FAILURE');

export const zendeskVerifyCredentials = createAction('CUSTOMER/ZENDESK_VERIFY_CREDENTIALES');
export const zendeskVerifyCredentialsRequest = createAction(
  'CUSTOMER/ZENDESK_VERIFY_CREDENTIALES_REQUEST'
);

export type VerifyCredentialsSuccessPayload = {
  ok: boolean;
  verified: boolean;
};

export const zendeskVerifyCredentialsSuccess = createAction<VerifyCredentialsSuccessPayload>(
  'CUSTOMER/ZENDESK_VERIFY_CREDENTIALES_SUCCESS'
);
export const zendeskVerifyCredentialsFailure = createAction<ErrorObject>(
  'CUSTOMER/ZENDESK_VERIFY_CREDENTIALES_FAILURE'
);

export const gmailVerifyCredentials = createAction('CUSTOMER/GMAIL_VERIFY_CREDENTIALES');
export const gmailVerifyCredentialsRequest = createAction(
  'CUSTOMER/GMAIL_VERIFY_CREDENTIALES_REQUEST'
);

export const gmailVerifyCredentialsSuccess = createAction<VerifyCredentialsSuccessPayload>(
  'CUSTOMER/GMAIL_VERIFY_CREDENTIALES_SUCCESS'
);
export const gmailVerifyCredentialsFailure = createAction<ErrorObject>(
  'CUSTOMER/GMAIL_VERIFY_CREDENTIALES_FAILURE'
);

export const o365VerifyCredentials = createAction('CUSTOMER/O365_VERIFY_CREDENTIALES');
export const o365VerifyCredentialsRequest = createAction(
  'CUSTOMER/O365_VERIFY_CREDENTIALES_REQUEST'
);

export const o365VerifyCredentialsSuccess = createAction<VerifyCredentialsSuccessPayload>(
  'CUSTOMER/O365_VERIFY_CREDENTIALES_SUCCESS'
);
export const o365VerifyCredentialsFailure = createAction<ErrorObject>(
  'CUSTOMER/O365_VERIFY_CREDENTIALES_FAILURE'
);

export const toggleShortcutsModal = createAction('CUSTOMER/SHOW_DIALOG');

export const downloadCustomerDocument = createAction<{ documentId: string }>(
  'CUSTOMER/DOWNLOAD_DOCUMENT'
);
export const downloadCustomerDocumentSuccess = createAction('CUSTOMER/DOWNLOAD_DOCUMENT_SUCCESS');
export const downloadCustomerDocumentFailure = createAction<ErrorObject>(
  'CUSTOMER/DOWNLOAD_DOCUMENT_FAILURE'
);
export const downloadCustomerDocumentFulfill = createAction('CUSTOMER/DOWNLOAD_DOCUMENT_FULFILL');

export const checkLoginRedirect = createAction('AUTH/CHECK_LOGIN_REDIRECT');
export const checkLoginRedirectRequest = createAction('AUTH/CHECK_LOGIN_REDIRECT_REQUEST');
export const checkLoginRedirectSuccess = createAction<Customer[]>(
  'AUTH/CHECK_LOGIN_REDIRECT_SUCCESS'
);
export const checkLoginRedirectFailure = createAction<ErrorObject>(
  'AUTH/CHECK_LOGIN_REDIRECT_FAILURE'
);

export const fetchCustomersMe = createAction('CUSTOMERS/FETCH_ME');
export const fetchCustomersMeRequest = createAction('CUSTOMERS/FETCH_ME_REQUEST');
export const fetchCustomersMeSuccess = createAction<Customer[]>('CUSTOMERS/FETCH_ME_SUCCESS');
export const fetchCustomersMeFailure = createAction<ErrorObject>('CUSTOMERS/FETCH_ME_FAILURE');
export const fetchCustomersMeFulfill = createAction('CUSTOMERS/FETCH_ME_FULFILL');

export type FetchCustomerChangelogType = {
  auditlogUuid: UUID;
  version: string;
};

export const fetchCustomerChangelog = createAction<FetchCustomerChangelogType>(
  'CUSTOMERS/FETCH_CHANGELOG'
);

export const exportUsers = createAction('CUSTOMERS/EXPORT_USERS');
export const exportUsersRequest = createAction('CUSTOMERS/EXPORT_USERS_REQUEST');
export const exportUsersSuccess = createAction('CUSTOMERS/EXPORT_USERS_SUCCESS');
export const exportUsersFailure = createAction<ErrorObject>('CUSTOMERS/EXPORT_USERS_FAILURE');
export const exportUsersFulfill = createAction('CUSTOMERS/EXPORT_USERS_FULFILL');
