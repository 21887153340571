import { reindexComms, showSuccessAlert } from 'actions';
import Card from 'components/Card';
import ConfirmModal from 'components/ConfirmModal';
import Modal from 'components/Modal';
import Permissions from 'components/Permissions';
import { COPY_CLIPBOARD } from 'constants/commonIcons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import CreateSandbox from './CreateSandbox';
import CustomerReprocessComms from './CustomerReprocessComms';

const CustomerActions: React.FC = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [actionAfterConfirm, setActionAfterConfirm] = useState('handleReindexComm');
  const zendeskToken = useSelector((state) => state.auth.zendeskKey);

  const handleReindexComm = async (): Promise<void> => {
    dispatch(reindexComms());
  };

  const handleCopyClick = async (): Promise<void> => {
    if (zendeskToken != null) {
      await navigator.clipboard.writeText(zendeskToken);
      dispatch(showSuccessAlert('Key copied to your clipboard'));
    }
  };

  const secretSummary = (): JSX.Element => (
    <div className="mb-4 mt-6">
      <div className="flex">
        <input
          id="secret"
          name="secret"
          defaultValue={zendeskToken != null ? zendeskToken : ''}
          disabled
          // ref={register()}
          className="form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 mb-2"
        />
        <button className="focus:outline-none " type="button" onClick={handleCopyClick}>
          {COPY_CLIPBOARD}
        </button>
      </div>
      <div className="flex justify-start items-center">
        <div>
          <svg
            fill="none"
            className="text-gray-600 w-4 h-4 mr-2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
          </svg>
        </div>
        <div>
          <p className="text-gray-600 text-sm">Keys are encrypted to keep your account safe</p>
        </div>
      </div>
    </div>
  );

  const reprocessComms = <CustomerReprocessComms />;

  const reindexCommunications = (
    <div className="p-4 flex justify-between">
      <div className="text-body">
        <span>Reindex Communications</span>
      </div>

      <Permissions action="customers.reprocessComms">
        <button
          data-testid="reindex-communitations-button"
          className="button button--primary h-8"
          onClick={(): void => {
            setShowConfirmModal(true);
            setActionAfterConfirm('handleReindexComm');
          }}
          type="button"
        >
          Reindex All
        </button>
      </Permissions>
    </div>
  );

  const newSandbox = <CreateSandbox />;

  return (
    <>
      <Card title="Actions" items={[reprocessComms, reindexCommunications, newSandbox]} />
      {isModalOpen && (
        <Modal
          body={secretSummary()}
          title="Zendesk Key"
          okButton
          okButtonText="Done"
          okButtonOnClick={(): void => setModalOpen(false)}
          toggleShowModal={(): void => setModalOpen(false)}
        />
      )}
      {showConfirmModal && (
        <ConfirmModal
          title="Confirm action"
          text="Are you sure you want to do this?"
          okButtonText="Yes"
          cancelButtonText="No"
          okButtonOnClick={(): void => {
            setShowConfirmModal(false);
            switch (actionAfterConfirm) {
              case 'handleReindexComm':
                handleReindexComm();
                break;
              default:
            }
          }}
          toggleShowModal={(): void => setShowConfirmModal(false)}
        />
      )}
    </>
  );
};

export default CustomerActions;
