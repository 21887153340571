import { Entity, UUID } from '@litlingo/client';
import { mergeEntities } from 'actions';
import Modal from 'components/Modal';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
  selectedEntities: Entity[];
  setSelectedEntities: React.Dispatch<React.SetStateAction<string[]>>;
};

const MergeEntitiesModal: React.FC<ComponentProps> = ({
  isModalOpen,
  toggleModalOpen,
  selectedEntities,
  setSelectedEntities,
}) => {
  const dispatch = useDispatch();
  const [entitySource, setEntitySource] = useState<UUID>();

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, entityId: UUID): void => {
    e.stopPropagation();
    if (e.target.checked) {
      setEntitySource(entityId);
    } else {
      setEntitySource(undefined);
    }

    const element = document.activeElement as HTMLInputElement;
    if (element) {
      element.blur();
    }
  };

  const body = (
    <div className=" flex flex-col mb-4 h-full">
      <span className="mt-2">Identify the source entity.</span>

      <div className=" flex flex-col border-t-2 border-litlingo-gray-3 min-h-56 mt-4">
        {selectedEntities.map((entity) => (
          <div className=" border-b py-2 " key={entity.uuid}>
            <div className="flex flex-row justify-between items-center">
              <div className="text">
                <label htmlFor={`custom-checkbox-${entity.uuid}`} className="cursor-pointer">
                  <input
                    id={`custom-checkbox-${entity.uuid}`}
                    type="checkbox"
                    className="custom-checkbox mr-1"
                    onChange={(e): void => handleCheckboxChange(e, entity.uuid)}
                    checked={entitySource === entity.uuid}
                  />
                  <span className="custom" />
                </label>
                {entity.name}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const handleSaveButton = (): void => {
    if (entitySource) {
      dispatch(
        mergeEntities({
          source: entitySource,
          destination: selectedEntities
            .filter((entity) => entity.uuid !== entitySource)
            .map((entity) => entity.uuid)[0],
        })
      );
    }
    setSelectedEntities([]);
    toggleModalOpen();
  };

  const handleCancelReplace = (): void => {
    setSelectedEntities([]);
    toggleModalOpen();
  };

  return (
    <>
      {isModalOpen && (
        <Modal
          title="From your selections"
          body={body}
          toggleShowModal={toggleModalOpen}
          style={{ width: '37.5rem' }}
          okButton
          okButtonText="Use as Source"
          okButtonOnClick={handleSaveButton}
          okButtonDisabled={!entitySource}
          cancelButton
          cancelButtonOnclick={handleCancelReplace}
          xButton
        />
      )}
    </>
  );
};

export default MergeEntitiesModal;
