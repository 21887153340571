import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  checkSelectedWidgets,
  clearSelectedWidgets,
  fetchAllDashboardsFailure,
  fetchAllDashboardsRequest,
  fetchAllDashboardsSuccess,
  setActiveDashboard,
  setTimeRange,
  signalExporting,
  TimeRange,
} from 'actions/dashboard';
import { Dashboard } from 'types';

type DashboardState = {
  dashboards: { [name: string]: Dashboard };
  activeUuid: string | null;
  loading: string[];
  timeRange: {
    after: string;
    before: string;
  };
  exporting: number;
  selectedWidgets: string[];
};

type DashboardReducer<P = void> = (
  state: DashboardState,
  payload: PayloadAction<P>
) => DashboardState;

const handleFetchAllRequest: DashboardReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAllDashboardsRequest.toString()],
});

const handleFetchAllSuccess: DashboardReducer<{ records: Dashboard[] }> = (state, { payload }) => ({
  ...state,
  dashboards: payload?.records?.reduce((acc, item) => {
    const resources: { [name: string]: Dashboard } = { ...acc };
    resources[item.uuid] = item;
    return resources;
  }, {}),
  loading: state.loading.filter((s) => s !== fetchAllDashboardsRequest.toString()),
});

const handleFetchAllFailure: DashboardReducer = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchAllDashboardsRequest.toString()),
});

const handleSetActiveDashboard: DashboardReducer<string | null> = (state, { payload }) => ({
  ...state,
  activeUuid: payload,
});

const handleSetTimeRange: DashboardReducer<TimeRange> = (state, { payload }) => ({
  ...state,
  timeRange: {
    ...state.timeRange,
    after: payload.after,
    before: payload.before,
  },
});

const handleSingnalExporting: DashboardReducer = (state) => ({
  ...state,
  exporting: state.exporting + 1,
});

const handleCheckSelectedWidgets: DashboardReducer<string> = (state, { payload }) => ({
  ...state,
  selectedWidgets: state.selectedWidgets.includes(payload)
    ? state.selectedWidgets.filter((w) => w !== payload)
    : [...state.selectedWidgets, payload],
});

const handleClearSelectedWidgets: DashboardReducer<string> = (state) => ({
  ...state,
  selectedWidgets: [],
});

const defaultState: DashboardState = {
  dashboards: {},
  activeUuid: null,
  loading: [],
  timeRange: {
    after: '',
    before: '',
  },
  exporting: 0,
  selectedWidgets: [],
};

const handlers = {
  [fetchAllDashboardsRequest.toString()]: handleFetchAllRequest,
  [fetchAllDashboardsSuccess.toString()]: handleFetchAllSuccess,
  [fetchAllDashboardsFailure.toString()]: handleFetchAllFailure,
  [setActiveDashboard.toString()]: handleSetActiveDashboard,
  [setTimeRange.toString()]: handleSetTimeRange,
  [signalExporting.toString()]: handleSingnalExporting,
  [checkSelectedWidgets.toString()]: handleCheckSelectedWidgets,
  [clearSelectedWidgets.toString()]: handleClearSelectedWidgets,
};

const dashboardsReducer = createReducer(defaultState, handlers);

export default dashboardsReducer;
