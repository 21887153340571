import LinkLookup from 'components/LinkLookup';
import daysAgo from 'components/utils/daysAgo';
import { typeOptionsMap } from 'constants/models';
import React from 'react';
import type { Identifier, UUID } from 'types';

type ComponentProps = {
  identifier: Identifier;
  handleIdentifierSelect: (checked: boolean, uuid: UUID) => void;
  selected: boolean;
  showIdentifierModels: boolean;
};

const IdentifierListRow: React.FC<ComponentProps> = ({
  identifier,
  handleIdentifierSelect,
  selected,
  showIdentifierModels,
}) => {
  const identifierGroups = [
    ...new Set(
      identifier.model_groups
        ?.filter((g) => g)
        .map((g) => typeOptionsMap[g as keyof typeof typeOptionsMap])
    ),
  ] as string[];

  const handleRowCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.stopPropagation();

    handleIdentifierSelect(e.target.checked, identifier.uuid);
    const element = document.activeElement as HTMLInputElement;
    if (element) {
      element.blur();
    }
  };

  return (
    <>
      <LinkLookup
        routeName="global-identifier-manager"
        routeParams={{ identifierId: identifier.uuid }}
        className="border-t border-gray-200 hover:bg-gray-100 table-row"
        as="tr"
      >
        <td className="table-wrapper__new-td flex items-center justify-center ">
          <div className="flex items-center h-8 ">
            <input
              id={`checkbox-user-${identifier.uuid}`}
              data-testid="select-button"
              type="checkbox"
              className="cursor-pointer form-checkbox litlingo-checkbox w-5 h-5"
              onClick={(e): void => e.stopPropagation()}
              onChange={(e): void => {
                handleRowCheckboxChange(e);
              }}
              checked={selected}
            />
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center">
            <div>
              <div className="text-body">{`${identifier.name}`}</div>
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center">
            <div>
              <div className="text-body">Custom</div>
            </div>
          </div>
        </td>

        <td className="table-wrapper__new-td ">
          <div className="text-body">{identifier.rule_identifiers?.length || '–'}</div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="flex items-center">
            <div className="text-body ">
              {identifier.updated_at != null && daysAgo(identifier.updated_at)}
            </div>
          </div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="text-body">{identifier.updated_by?.name || '–'}</div>
        </td>
        <td className="table-wrapper__new-td">
          <div className="text-body truncate">
            {identifierGroups.length ? identifierGroups.join(', ') : '–'}
          </div>
        </td>
      </LinkLookup>
      {selected && showIdentifierModels && !!identifier.rule_identifiers?.length && (
        <>
          <tr className="border-t border-gray-200 bg-litlingo-gray-0 table-row h-8">
            <td className="table-wrapper__new-td" />
            <td className="table-wrapper__new-td font-bold text-base">
              <div className="pl-8">Model</div>
            </td>
            <td className="table-wrapper__new-td" />
            <td className="table-wrapper__new-td" />
            <td className="table-wrapper__new-td" />
            <td className="table-wrapper__new-td" />
          </tr>
          {identifier.rule_identifiers?.map((r) => (
            <tr key={r.uuid} className="border-t border-gray-200 bg-litlingo-gray-0 table-row h-8">
              <td className="table-wrapper__new-td " />
              <td className="table-wrapper__new-td ">
                <div className="pl-8">{r.name}</div>
              </td>
              <td className="table-wrapper__new-td" />
              <td className="table-wrapper__new-td" />
              <td className="table-wrapper__new-td" />
              <td className="table-wrapper__new-td" />
            </tr>
          ))}
        </>
      )}
    </>
  );
};

export default IdentifierListRow;
