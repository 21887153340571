/* eslint-disable camelcase */
import {
  addUserMetadata,
  addUserMetadataFailure,
  addUserMetadataFulfill,
  addUserMetadataRequest,
  addUserMetadataSuccess,
  applyPermissionsToSelectedUsersAndSave,
  applyPermissionsToSelectedUsersAndSaveFullFil,
  applyPermissionsToSelectedUsersAndSaveQuery,
  applyPermissionsToSingleSelectedUserAndSave,
  applyPermissionsToSingleSelectedUserAndSaveFullfil,
  deleteUser,
  deleteUserFailure,
  deleteUserRequest,
  deleteUserSuccess,
  fetchCustomerRecipientsDomain,
  fetchCustomerRecipientsDomainFailure,
  fetchCustomerRecipientsDomainRequest,
  fetchCustomerRecipientsDomainSuccess,
  fetchCustomerUsersDomain,
  fetchCustomerUsersDomainFailure,
  fetchCustomerUsersDomainRequest,
  fetchCustomerUsersDomainSuccess,
  fetchReviewers,
  fetchTeamUsersCount,
  fetchTeamUsersCountFailure,
  fetchTeamUsersCountRequest,
  fetchTeamUsersCountSuccess,
  fetchUser,
  fetchUserFailure,
  fetchUserIntegrationsCount,
  fetchUserIntegrationsCountFailure,
  fetchUserIntegrationsCountRequest,
  fetchUserIntegrationsCountSuccess,
  fetchUserMetadata,
  fetchUserMetadataFailure,
  fetchUserMetadataFulfill,
  fetchUserMetadataRequest,
  fetchUserMetadataSuccess,
  fetchUserRequest,
  fetchUserRoles,
  fetchUserRolesCount,
  fetchUserRolesCountFailure,
  fetchUserRolesCountRequest,
  fetchUserRolesCountSuccess,
  fetchUserRolesFailure,
  fetchUserRolesRequest,
  fetchUserRolesSuccess,
  fetchUserSuccess,
  fetchUsers,
  fetchUsersCount,
  fetchUsersCountFailure,
  fetchUsersCountRequest,
  fetchUsersCountSuccess,
  fetchUsersFailure,
  fetchUsersForFilter,
  fetchUsersForFilterAllUsers,
  fetchUsersForFilterPills,
  fetchUsersForFilterPillsFailure,
  fetchUsersForFilterPillsRequest,
  fetchUsersForFilterPillsSuccess,
  fetchUsersRequest,
  fetchUsersSuccess,
  fetchUsersSuccessAppend,
  saveUser,
  saveUserFailure,
  saveUserRequest,
  saveUserSuccess,
  saveUsers,
  saveUsersFailure,
  saveUsersFulfill,
  saveUsersSuccess,
  sendUserInvitation,
  showSuccessAlert,
  undeleteUser,
  undeleteUserFailure,
  undeleteUserFulfill,
  undeleteUserRequest,
  undeleteUserSuccess,
  unlockEnvelope,
  unlockEnvelopeFailure,
  unlockEnvelopeFulfill,
  unlockEnvelopeRequest,
  unlockEnvelopeSuccess,
  upsertUserMetadata,
  upsertUserMetadataFailure,
  upsertUserMetadataFulfill,
  upsertUserMetadataRequest,
  upsertUserMetadataSuccess,
} from 'actions';
import {
  fetchUserInvitationState,
  fetchUserInvitationStateFailure,
  fetchUserInvitationStateFulfill,
  fetchUserInvitationStateRequest,
  fetchUserInvitationStateSuccess,
} from 'actions/userPermissionsPage';
import { apiClient as LitLingoClient } from 'client';
import { push } from 'connected-react-router';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { userTypesMapping } from 'constants/userRoles';
import pluralize from 'pluralize';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getCustomerDomain } from 'selectors/auth';
import { getNavParams, getNavParamsByResource } from 'selectors/nav';
import {
  getExcludedInsight,
  getExcludedPrevent,
  getExcludedRoles,
  getExcludedTeams,
  getIncludedInsight,
  getIncludedPrevent,
  getIncludedRoles,
  getIncludedTeams,
  getPrivacyLevel,
  getSelectedUserEmail,
  getSelectedUserName,
  getSelectedUsers,
} from 'selectors/userPermissionsPage';
import { getUser } from 'selectors/users';
import type { API, Role, SagaReturn, User } from 'types';
import { reverse } from 'utils/urls';
import { userRolesToTypes, userTypesToRoles } from 'utils/userRoles';

// eslint-disable-next-line camelcase
function* fetchUsersList(params: { include_pii: string; broad_search: string }): SagaReturn {
  yield put(fetchUsersRequest());

  const response = (yield call([LitLingoClient.resources.users, 'list'], {
    params,
  })) as API.Response<API.Users.List>;
  if (response.error != null) {
    yield put(fetchUsersFailure(response.error));
  } else {
    yield put(fetchUsersSuccess(response.data));
  }
}

function* fetchUsersListForFilter(params: {
  include_pii: string;
  broad_search: string;
  offset?: number;
}): SagaReturn {
  yield put(fetchUsersRequest());

  const response = (yield call([LitLingoClient.resources.users, 'list'], {
    params,
  })) as API.Response<API.Users.List>;
  if (response.error != null) {
    yield put(fetchUsersFailure(response.error));
  } else if (params.offset !== undefined && params.offset === 0) {
    yield put(fetchUsersSuccess(response.data));
  } else {
    yield put(fetchUsersSuccessAppend(response.data));
  }
}

function* saveUserSaga(action: ReturnType<typeof saveUser>): SagaReturn {
  const { payload } = action;
  yield put(saveUserRequest());
  const response = (yield call([LitLingoClient.resources.users, 'upsert'], {
    data: payload,
    params: { relationships: 'teams' },
  })) as API.Response<API.Users.Upsert>;

  if (response.error) {
    yield put(saveUserFailure(response.error));
  } else {
    yield put(saveUserSuccess(response.data));
    yield put(showSuccessAlert('User Saved'));
  }
}

function* deleteUserSaga(action: ReturnType<typeof deleteUser>): SagaReturn {
  const { payload } = action;
  yield put(deleteUserRequest());
  const response = (yield call([LitLingoClient.resources.users.extras, 'deleteUsers'], {
    data: { uuids: payload.uuids },
  })) as API.Response<API.Users.Delete>;
  if (response.error != null) {
    yield put(deleteUserFailure(response.error));
  } else {
    yield put(deleteUserSuccess(payload.uuids));
  }
}

function* fetchUserSaga(action: ReturnType<typeof fetchUser>): SagaReturn {
  const { payload } = action;
  yield put(fetchUserRequest());
  const response = (yield call([LitLingoClient.resources.users, 'retrieve'], payload.userId, {
    params: { include_pii: 'true', relationships: ['teams', 'entity_metadata'] },
  })) as API.Response<API.Users.Retrieve>;
  if (response.error != null) {
    yield put(fetchUserFailure(response.error));
  } else {
    yield put(fetchUserSuccess(response.data));
  }
}

function* undeleteUserSaga(action: ReturnType<typeof undeleteUser>): SagaReturn {
  const { payload } = action;
  yield put(undeleteUserRequest());
  const response = (yield call([LitLingoClient.resources.users.extras, 'undelete'], {
    urlParams: { userId: payload.uuid },
  })) as API.Response<API.Users.Undelete>;
  if (response.error != null) {
    yield put(undeleteUserFailure(response.error));
  } else {
    yield put(undeleteUserSuccess(payload.uuid));
    yield put(showSuccessAlert('User undeleted successfully'));
  }

  yield put(undeleteUserFulfill());
}

function* fetchUserRolesSaga(): SagaReturn {
  yield put(fetchUserRolesRequest());
  const response = (yield call([
    LitLingoClient.resources.users.extras,
    'availableRoles',
  ])) as API.Response<API.Users.AvailableRoles>;

  if (response.error != null) {
    yield put(fetchUserRolesFailure(response.error));
  } else {
    yield put(fetchUserRolesSuccess(response.data));
  }
}

function* fetchUsersForFilterSaga(action: ReturnType<typeof fetchUsersForFilter>): SagaReturn {
  const { payload } = action;
  const { searchValue = '', limit = 25, roles = [], offset = 0 } = payload;
  const params = {
    include_pii: 'true',
    broad_search: searchValue,
    limit,
    roles,
    offset,
    include_count: true,
  };
  yield call(fetchUsersListForFilter, params);
}

function* fetchUsersForFilterAllUsersSaga(
  action: ReturnType<typeof fetchUsersForFilterAllUsers>
): SagaReturn {
  const { payload } = action;
  const { searchValue = '', limit = 25, roles = [], offset = 0 } = payload;
  const params = {
    include_pii: 'true',
    broad_search: searchValue,
    limit,
    roles,
    offset,
    include_count: true,
    true_users: false,
  };
  yield call(fetchUsersListForFilter, params);
}

function* fetchReviewersSaga(action: ReturnType<typeof fetchUsersForFilter>): SagaReturn {
  const { payload } = action;
  const { searchValue = '', limit = 25, offset = 0 } = payload;
  const params = {
    include_pii: 'true',
    broad_search: searchValue,
    roles: ['reviewer', 'super-reviewer'],
    limit,
    offset,
    include_count: true,
  };
  yield call(fetchUsersListForFilter, params);
}

function* fetchUsersForFilterPillsSaga(
  action: ReturnType<typeof fetchUsersForFilterPills>
): SagaReturn {
  const { uuids } = action.payload;

  const params = { uuids, include_pii: true, true_users: false };
  yield put(fetchUsersForFilterPillsRequest());

  const response = (yield call([LitLingoClient.resources.users, 'list'], {
    params,
  })) as API.Response<API.Users.List>;
  if (response.error != null) {
    yield put(fetchUsersForFilterPillsFailure(response.error));
  } else {
    yield put(fetchUsersForFilterPillsSuccess(response.data));
  }
}

function* fetchUsersSaga(): SagaReturn {
  const navParams = (yield select(getNavParams)) as ReturnType<typeof getNavParams>;
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.user)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;
  let roles: Role[] = [];

  if (resourceParams.roles) {
    roles = userTypesToRoles(resourceParams.roles as string[]);
  }

  const params = {
    ...navParams,
    ...resourceParams,
    ...(roles.length > 0 ? { roles: userTypesToRoles(resourceParams.roles as string[]) } : {}),
  };
  // @ts-ignore
  yield call(fetchUsersList, params);
}

function* unlockEnvelopeSaga(): SagaReturn {
  yield put(unlockEnvelopeRequest());

  const response = (yield call([
    LitLingoClient.resources.users.extras,
    'unlockEnvelope',
  ])) as API.Response<API.Users.UnlockEnvelope>;

  if (response.error != null) {
    yield put(unlockEnvelopeFailure(response.error));
  } else {
    yield put(unlockEnvelopeSuccess());
  }

  yield put(unlockEnvelopeFulfill());
}

function* saveUsersSaga(action: ReturnType<typeof saveUsers>): SagaReturn {
  const { users, sendInvitation, redirect, fromPermissionsPage } = action.payload;

  const teamsToAdd = (yield select(getIncludedTeams)) as ReturnType<typeof getIncludedTeams>;
  let teamsToRemove = (yield select(getExcludedTeams)) as ReturnType<typeof getExcludedTeams>;

  if (users.length === 1) {
    const user = (yield select(getUser(users[0].uuid))) as User;
    const userInitialTeams = user?.teams;

    if (userInitialTeams) {
      teamsToRemove = userInitialTeams.filter((t) => !teamsToAdd.find((t2) => t2.uuid === t.uuid));
    }
  }

  const usersToUpdate = users.map((u) => {
    const {
      name,
      email,
      privacy_level,
      roles,
      insight_active_email,
      insight_active_chat,
      prevent_active_email,
      prevent_active_chat,
      teams,
    } = u;

    if (fromPermissionsPage) {
      return {
        name,
        email,
        privacy_level,
        roles,
        insight_active_email,
        insight_active_chat,
        prevent_active_email,
        prevent_active_chat,
        teams: [...(teams || [])?.map((t) => t.uuid)],
      };
    }

    return {
      name,
      email,
      privacy_level,
      roles,
      insight_active_email,
      insight_active_chat,
      prevent_active_email,
      prevent_active_chat,
    };
  });

  yield put(saveUserRequest());

  const response = (yield call([LitLingoClient.resources.users.extras, 'updateUsers'], {
    data: {
      users: usersToUpdate,
      included_teams: fromPermissionsPage ? [] : [...teamsToAdd.map((t) => t.uuid)],
      excluded_teams: [...teamsToRemove.map((t) => t.uuid)],
    },
    params: { relationships: 'teams' },
  })) as API.Response<API.Users.UpdateUsers>;

  if (response.error) {
    yield put(saveUsersFailure(response.error));
  } else {
    const savedUsers = response.data.users;
    yield put(saveUsersSuccess(savedUsers));
    yield put(showSuccessAlert(`${pluralize('User', savedUsers.length)} Saved`));

    if (sendInvitation) {
      yield put(sendUserInvitation({ users: savedUsers }));
    }

    if (redirect) {
      const customerDomain = (yield select(getCustomerDomain)) as ReturnType<
        typeof getCustomerDomain
      >;
      const path = reverse({ routeName: 'users', customerDomain });
      yield put(push(path));
    }
  }

  yield put(saveUsersFulfill());
}

function* applyPermissionsToSelectedUsersAndSaveSaga(): SagaReturn {
  const selectedUsers = (yield select(getSelectedUsers)) as ReturnType<typeof getSelectedUsers>;

  const rolesToAdd = (yield select(getIncludedRoles)) as ReturnType<typeof getIncludedRoles>;
  const rolesToRemove = (yield select(getExcludedRoles)) as ReturnType<typeof getExcludedRoles>;

  const insightToAdd = (yield select(getIncludedInsight)) as ReturnType<typeof getIncludedInsight>;
  const insightToRemove = (yield select(getExcludedInsight)) as ReturnType<
    typeof getExcludedInsight
  >;

  const preventToAdd = (yield select(getIncludedPrevent)) as ReturnType<typeof getIncludedPrevent>;
  const preventToRemove = (yield select(getExcludedPrevent)) as ReturnType<
    typeof getExcludedPrevent
  >;

  const privacyLevel = (yield select(getPrivacyLevel)) as ReturnType<typeof getPrivacyLevel>;

  const modifiedUsers = selectedUsers.map((user) => JSON.parse(JSON.stringify(user)) as User);

  modifiedUsers.forEach((user) => {
    const userTypesRoles = userRolesToTypes(user.roles as Role[]);
    // add roles
    rolesToAdd.forEach((role) => {
      if (!userTypesRoles?.find((r) => r.label === role)) {
        const userType = Object.values(userTypesMapping).find((uT) => uT.label === role);
        if (userType) {
          userTypesRoles.push(userType);
        }
      }
    });

    // remove roles
    const newRoles = userTypesRoles?.filter((r) => !rolesToRemove.some((role) => role === r.label));
    user.roles = userTypesToRoles(newRoles.map((r) => r.label));

    // add insight
    insightToAdd.forEach((insight) => {
      if (insight === 'email') {
        user.insight_active_email = true;
      }
      if (insight === 'chat') {
        user.insight_active_chat = true;
      }
    });

    // remove insight
    insightToRemove.forEach((insight) => {
      if (insight === 'email') {
        user.insight_active_email = false;
      }
      if (insight === 'chat') {
        user.insight_active_chat = false;
      }
    });

    // add prevent
    preventToAdd.forEach((prevent) => {
      if (prevent === 'email') {
        user.prevent_active_email = true;
      }
      if (prevent === 'chat') {
        user.prevent_active_chat = true;
      }
    });

    // remove prevent
    preventToRemove.forEach((prevent) => {
      if (prevent === 'email') {
        user.prevent_active_email = false;
      }
      if (prevent === 'chat') {
        user.prevent_active_chat = false;
      }
    });

    user.privacy_level = (privacyLevel || 'standard') as User['privacy_level'];
  });

  yield put(saveUsers({ users: modifiedUsers }));

  yield put(applyPermissionsToSelectedUsersAndSaveFullFil(modifiedUsers));
}

function* applyPermissionsToSelectedUsersAndSaveQuerySaga(): SagaReturn {
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.user)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const params = {
    ...resourceParams,
    offset: 0,
    limit: -1,
  };

  const teamsToAdd = (yield select(getIncludedTeams)) as ReturnType<typeof getIncludedTeams>;
  const teamsToRemove = (yield select(getExcludedTeams)) as ReturnType<typeof getExcludedTeams>;

  const rolesToAdd = (yield select(getIncludedRoles)) as ReturnType<typeof getIncludedRoles>;
  const rolesToRemove = (yield select(getExcludedRoles)) as ReturnType<typeof getExcludedRoles>;

  const insightToAdd = (yield select(getIncludedInsight)) as ReturnType<typeof getIncludedInsight>;
  const insightToRemove = (yield select(getExcludedInsight)) as ReturnType<
    typeof getExcludedInsight
  >;

  const preventToAdd = (yield select(getIncludedPrevent)) as ReturnType<typeof getIncludedPrevent>;
  const preventToRemove = (yield select(getExcludedPrevent)) as ReturnType<
    typeof getExcludedPrevent
  >;

  const response = (yield call([LitLingoClient.resources.users.extras, 'bulkUpdateQuery'], {
    data: {
      included_teams: [...teamsToAdd.map((t) => t.uuid)],
      excluded_teams: [...teamsToRemove.map((t) => t.uuid)],
      included_roles: userTypesToRoles(rolesToAdd),
      excluded_roles: userTypesToRoles(rolesToRemove),
      included_insight: insightToAdd,
      excluded_insight: insightToRemove,
      included_prevent: preventToAdd,
      excluded_prevent: preventToRemove,
    },
    params,
  })) as API.Response<API.Users.BulkUpdateQuery>;

  if (response.error != null) {
    yield put(saveUserFailure(response.error));
  } else {
    yield put(showSuccessAlert('The changes were applied, you will receive an email shortly'));
  }
}

function* applyPermissionsToSingleSelectedUserAndSaveSaga(): SagaReturn {
  const selectedUser = ((yield select(getSelectedUsers)) as ReturnType<typeof getSelectedUsers>)[0];
  const rolesToAdd = (yield select(getIncludedRoles)) as ReturnType<typeof getIncludedRoles>;
  const insightToAdd = (yield select(getIncludedInsight)) as ReturnType<typeof getIncludedInsight>;
  const preventToAdd = (yield select(getIncludedPrevent)) as ReturnType<typeof getIncludedPrevent>;
  const privacyLevel = (yield select(getPrivacyLevel)) as ReturnType<typeof getPrivacyLevel>;

  const name = (yield select(getSelectedUserName)) as ReturnType<typeof getSelectedUserName>;
  const email = (yield select(getSelectedUserEmail)) as ReturnType<typeof getSelectedUserEmail>;

  const modifiedUser = JSON.parse(JSON.stringify(selectedUser));

  modifiedUser.name = name;
  modifiedUser.email = email;

  modifiedUser.roles = userTypesToRoles(rolesToAdd) as Role[];

  modifiedUser.insight_active_email = false;
  modifiedUser.insight_active_chat = false;
  modifiedUser.prevent_active_email = false;
  modifiedUser.prevent_active_chat = false;

  insightToAdd.forEach((insight) => {
    if (insight === 'email') {
      modifiedUser.insight_active_email = true;
    }
    if (insight === 'chat') {
      modifiedUser.insight_active_chat = true;
    }
  });

  preventToAdd.forEach((prevent) => {
    if (prevent === 'email') {
      modifiedUser.prevent_active_email = true;
    }
    if (prevent === 'chat') {
      modifiedUser.prevent_active_chat = true;
    }
  });

  modifiedUser.privacy_level = privacyLevel || 'standard';

  yield put(saveUsers({ users: [modifiedUser] }));

  yield put(applyPermissionsToSingleSelectedUserAndSaveFullfil([modifiedUser]));
}

function* addUserMetadataSaga(action: ReturnType<typeof addUserMetadata>): SagaReturn {
  const { type, userUuid, data } = action.payload;

  const userMetadata = {
    entity_type: type,
    entity_uuid: userUuid,
    data,
  };
  yield put(addUserMetadataRequest());

  const response = (yield call([LitLingoClient.resources.entityMetadata, 'upsert'], {
    data: userMetadata,
  })) as API.Response<API.EntitiesMetadata.Upsert>;

  if (response.error != null) {
    yield put(addUserMetadataFailure(response.error));
  } else {
    yield put(addUserMetadataSuccess(response.data));
  }

  yield put(addUserMetadataFulfill());
}

function* upsertUserMetadataSaga(action: ReturnType<typeof upsertUserMetadata>): SagaReturn {
  const userMetadata = {
    ...action.payload,
  };
  yield put(upsertUserMetadataRequest());

  const response = (yield call([LitLingoClient.resources.entityMetadata, 'upsert'], {
    data: userMetadata,
  })) as API.Response<API.EntitiesMetadata.Upsert>;

  if (response.error != null) {
    yield put(upsertUserMetadataFailure(response.error));
  } else {
    yield put(upsertUserMetadataSuccess(response.data));
  }

  yield put(upsertUserMetadataFulfill());
}

function* fetchUserMetadataSaga(action: ReturnType<typeof fetchUserMetadata>): SagaReturn {
  const { userId } = action.payload;

  yield put(fetchUserMetadataRequest());

  const response = (yield call([LitLingoClient.resources.entityMetadata, 'list'], {
    params: {
      entity_uuid: userId,
    },
  })) as API.Response<API.EntitiesMetadata.List>;

  if (response.error != null) {
    yield put(fetchUserMetadataFailure(response.error));
  } else {
    yield put(fetchUserMetadataSuccess(response.data.records[0]));
  }

  yield put(fetchUserMetadataFulfill());
}
//

function* fetchUserInvitationStateSaga(
  action: ReturnType<typeof fetchUserInvitationState>
): SagaReturn {
  const { userId } = action.payload;

  yield put(fetchUserInvitationStateRequest());

  const response = (yield call([LitLingoClient.resources.users.extras, 'invitationState'], {
    urlParams: {
      userId,
    },
  })) as API.Response<API.Users.InvitationStates>;

  if (response.error != null) {
    yield put(fetchUserInvitationStateFailure(response.error));
  } else {
    yield put(fetchUserInvitationStateSuccess(response.data));
  }
  yield put(fetchUserInvitationStateFulfill());
}

type DomainResponse = API.Response<API.Users.SenderDomains & { count: number }>;

function* fetchCustomerUsersDomainSaga(
  action: ReturnType<typeof fetchCustomerUsersDomain>
): SagaReturn {
  const { searchValue, limit = 25, offset = 0 } = action.payload;

  yield put(fetchCustomerUsersDomainRequest());
  const params: Record<string, string | number> = {
    limit,
    offset,
  };

  if (searchValue) {
    params.broad_search = searchValue;
  }

  const response = (yield call([LitLingoClient.resources.users.extras, 'senderDomains'], {
    params,
  })) as DomainResponse;
  if (response.error != null) {
    yield put(fetchCustomerUsersDomainFailure(response.error));
  } else {
    yield put(fetchCustomerUsersDomainSuccess(response.data));
  }
}

function* fetchCustomerRecipientsDomainSaga(
  action: ReturnType<typeof fetchCustomerRecipientsDomain>
): SagaReturn {
  const { searchValue, limit = 25, offset = 0 } = action.payload;

  yield put(fetchCustomerRecipientsDomainRequest());
  const params: Record<string, string | number> = {
    limit,
    offset,
  };

  if (searchValue) {
    params.broad_search = searchValue;
  }

  const response = (yield call([LitLingoClient.resources.users.extras, 'recipientDomains'], {
    params,
  })) as DomainResponse;

  if (response.error != null) {
    yield put(fetchCustomerRecipientsDomainFailure(response.error));
  } else {
    yield put(fetchCustomerRecipientsDomainSuccess(response.data));
  }
}

function* fetchUserRolesCountSaga(): SagaReturn {
  yield put(fetchUserRolesCountRequest());
  const response = (yield call([
    LitLingoClient.resources.users.extras,
    'rolesCount',
  ])) as API.Response<API.Users.IntegrationCount>;

  if (response.error != null) {
    yield put(fetchUserRolesCountFailure(response.error));
  } else {
    yield put(fetchUserRolesCountSuccess(response.data));
  }
}

function* fetchUserIntegrationsCountSaga(): SagaReturn {
  yield put(fetchUserIntegrationsCountRequest());
  const response = (yield call([
    LitLingoClient.resources.users.extras,
    'integrationsCount',
  ])) as API.Response<API.Users.RolesCount>;

  if (response.error != null) {
    yield put(fetchUserIntegrationsCountFailure(response.error));
  } else {
    yield put(fetchUserIntegrationsCountSuccess(response.data));
  }
}

function* fetchTeamUsersCountSaga(): SagaReturn {
  yield put(fetchTeamUsersCountRequest());
  const response = (yield call([
    LitLingoClient.resources.users.extras,
    'teamUsersCount',
  ])) as API.Response<API.Users.TeamUsersCount>;

  if (response.error != null) {
    yield put(fetchTeamUsersCountFailure(response.error));
  } else {
    yield put(fetchTeamUsersCountSuccess(response.data));
  }
}

function* fetchUsersCountSaga(): SagaReturn {
  const resourceParams = (yield select(
    getNavParamsByResource(resourceQueryParamName.user)
  )) as ReturnType<ReturnType<typeof getNavParamsByResource>>;

  const params = {
    ...resourceParams,
    is_external: false,
    true_users: true,
  };

  yield put(fetchUsersCountRequest());
  const response = (yield call([LitLingoClient.resources.users.extras, 'count'], {
    params,
  })) as API.Response<API.Users.Count>;

  if (response.error != null) {
    yield put(fetchUsersCountFailure(response.error));
  } else {
    yield put(fetchUsersCountSuccess(response.data));
  }
}

function* usersSaga(): SagaReturn {
  yield takeLatest(fetchUserRoles.toString(), fetchUserRolesSaga);
  yield takeLatest(fetchUsersForFilterPills.toString(), fetchUsersForFilterPillsSaga);
  yield takeLatest(saveUser.toString(), saveUserSaga);
  yield takeLatest(deleteUser.toString(), deleteUserSaga);
  yield takeLatest(undeleteUser.toString(), undeleteUserSaga);
  yield takeLatest(fetchUsers.toString(), fetchUsersSaga);
  yield takeLatest(fetchUsersForFilter.toString(), fetchUsersForFilterSaga);
  yield takeLatest(fetchUsersForFilterAllUsers.toString(), fetchUsersForFilterAllUsersSaga);
  yield takeLatest(fetchReviewers.toString(), fetchReviewersSaga);
  yield takeLatest(unlockEnvelope.toString(), unlockEnvelopeSaga);
  yield takeEvery(fetchUser.toString(), fetchUserSaga);
  yield takeEvery(saveUsers.toString(), saveUsersSaga);
  yield takeEvery(
    applyPermissionsToSelectedUsersAndSave.toString(),
    applyPermissionsToSelectedUsersAndSaveSaga
  );
  yield takeEvery(
    applyPermissionsToSelectedUsersAndSaveQuery.toString(),
    applyPermissionsToSelectedUsersAndSaveQuerySaga
  );

  yield takeEvery(
    applyPermissionsToSingleSelectedUserAndSave.toString(),
    applyPermissionsToSingleSelectedUserAndSaveSaga
  );
  yield takeEvery(addUserMetadata.toString(), addUserMetadataSaga);
  yield takeEvery(upsertUserMetadata.toString(), upsertUserMetadataSaga);
  yield takeEvery(fetchUserMetadata.toString(), fetchUserMetadataSaga);
  yield takeEvery(fetchUserInvitationState.toString(), fetchUserInvitationStateSaga);
  yield takeEvery(fetchCustomerUsersDomain.toString(), fetchCustomerUsersDomainSaga);
  yield takeEvery(fetchCustomerRecipientsDomain.toString(), fetchCustomerRecipientsDomainSaga);
  yield takeLatest(fetchUserRolesCount.toString(), fetchUserRolesCountSaga);
  yield takeLatest(fetchUserIntegrationsCount.toString(), fetchUserIntegrationsCountSaga);
  yield takeLatest(fetchTeamUsersCount.toString(), fetchTeamUsersCountSaga);
  yield takeLatest(fetchUsersCount.toString(), fetchUsersCountSaga);
}

export default usersSaga;
