/* eslint-disable max-lines */
import { selectCampaignEnvelopeReview, selectRuleEnvelopeReview, selectSection } from 'actions';
import changeThreadHighlightMode from 'actions/envelopeView';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EnvelopeReviewRule, SectionType } from 'reducers/envelopeReview';
import { getCustomerDomain } from 'selectors/auth';
import {
  CampaignWithRules,
  getCampaignRulesFromSummaries,
  getSelectedCampaign,
  getSelectedRule,
  getSelectedSection,
} from 'selectors/envelopeReview';
import { getEnvelope } from 'selectors/envelopes';
import { useSelector } from 'store';
import { reverse, useHistory } from 'utils/urls';
import CampaignRules from './CampaignRules';

const sortCampaigns = (
  [, a]: [string, CampaignWithRules],
  [, b]: [string, CampaignWithRules]
): number => {
  const aPrio = a.campaignPriority && a.campaignPriority !== 0 ? a.campaignPriority : 1000;
  const bPrio = b.campaignPriority && b.campaignPriority !== 0 ? b.campaignPriority : 1000;

  if (aPrio < bPrio) {
    return -1;
  }
  if (bPrio > aPrio) {
    return 1;
  }

  if (a.campaignName < b.campaignName) {
    return -1;
  }
  if (a.campaignName > b.campaignName) {
    return 1;
  }

  return 0;
};

const EnvelopeThreads: React.FC = () => {
  const section: SectionType = 'thread';

  const dispatch = useDispatch();
  const history = useHistory();

  const { envelopeId } = useParams<{ envelopeId: string }>();

  const envelope = useSelector((state) => getEnvelope(state, envelopeId));
  const customerDomain = useSelector(getCustomerDomain);

  const campaignsRules = useSelector(getCampaignRulesFromSummaries);

  const selectedCampaign = useSelector(getSelectedCampaign);
  const selectedRule = useSelector(getSelectedRule);
  const selectedSection = useSelector(getSelectedSection);

  const selectCampaign = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string): void => {
    e.stopPropagation();

    if (!campaignsRules) return;
    dispatch(changeThreadHighlightMode(true));
    dispatch(selectCampaignEnvelopeReview(id));
    dispatch(selectRuleEnvelopeReview(null));
    dispatch(selectSection(section));
  };

  const selectRuleCampaign = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rule: EnvelopeReviewRule,
    campaignId: string
  ): void => {
    e.stopPropagation();
    dispatch(changeThreadHighlightMode(true));

    dispatch(selectCampaignEnvelopeReview(campaignId));
    dispatch(selectRuleEnvelopeReview(rule));

    dispatch(selectSection(section));

    let route = {};

    route = {
      routeName: 'global-rule-group-manager',
      routeParams: { ruleId: rule.uuid },
      customerDomain,
    };

    const path = reverse(route);

    if ((e.ctrlKey || e.metaKey) && selectedRule?.uuid === rule.uuid) {
      window.open(path, '_blank');
      return;
    }

    if (selectedRule?.uuid === rule.uuid) {
      history.pushLookup(route);
    }
  };

  if (!envelope || !campaignsRules || Object.keys(campaignsRules).length === 0) return null;

  return (
    <div className="w-full flex flex-col bg-white border border-litlingo-gray-0.5 overflow-hidden text-small">
      <div
        className={`h-8 w-full flex flex-row items-center justify-between px-3 ${
          selectedSection === section ? 'bg-litlingo-highlight' : 'bg-white'
        }`}
      >
        <span className="font-bold">Threads</span>
      </div>
      <div>
        {campaignsRules &&
          Object.entries(campaignsRules)
            .sort(sortCampaigns)
            .map(([key, value]) => (
              <CampaignRules
                key={key}
                campaignId={key}
                campaignRules={value}
                selectedCampaign={selectedCampaign}
                selectCampaign={selectCampaign}
                selectRuleCampaign={selectRuleCampaign}
                section={section}
              />
            ))}
      </div>
    </div>
  );
};

export default EnvelopeThreads;
