import { fetchSingleCustomer, showSuccessAlert } from 'actions';
import LinkLookup from 'components/LinkLookup';
import LoadingIndicator from 'components/LoadingIndicator';
import Permissions from 'components/Permissions';
import { NEW_EDIT_ICON } from 'constants/commonIcons';
import { NEW_CHEVRON_LEFT } from 'constants/filterIcons';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import { getCustomer, getSaveCustomerLoading, getUser } from 'selectors/auth';
import { useSelector } from 'store';
import CustomerEditGeneral from './CustomerEditGeneral';

const CustomerGeneral: React.FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const customer = useSelector(getCustomer);
  const loading = useSelector(getSaveCustomerLoading);

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (user) {
      dispatch(fetchSingleCustomer());
    }
  }, [dispatch, user]);

  const toggleEditing = (): void => {
    setIsEditing((e) => !e);
  };

  const handleCustomerCopyClick = async (): Promise<void> => {
    await navigator.clipboard.writeText(customer.uuid);
    dispatch(showSuccessAlert('Company UUID copied to your clipboard'));
  };

  const companyName = (
    <div className="flex flex-row justify-between pr-1">
      <span className="flex items-center text-heading-2">{customer.name}</span>
      <Permissions action="customers.advancedOptions">
        <button type="button" className="w-5 focus:outline-none" onClick={toggleEditing}>
          {NEW_EDIT_ICON('text-litlingo-primary-120')}
        </button>
      </Permissions>
    </div>
  );

  const domain = (
    <div className="flex flex-col gap-2 text-body">
      <span className="font-bold">URL slug</span>
      <span className="pl-4">{`litlingo.com/${customer.domain}`}</span>
    </div>
  );

  const companyUUID = (
    <div className="flex flex-col gap-2 text-body pr-2">
      <span className="font-bold">Company UUID</span>
      <div className="flex flex-row justify-between">
        <span className="pl-4">{customer.uuid}</span>
        <button
          data-testid="uuid-copy-clipboard-button"
          type="button"
          onClick={handleCustomerCopyClick}
          className="focus:outline-none"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.50036 4.33587C6.16287 4.33587 5.88903 4.6097 5.88903 4.9472C5.88903 5.28469 6.16287 5.55752 6.49935 5.55752H15.7561C16.0936 5.55752 16.3674 5.28469 16.3674 4.9472C16.3674 4.6097 16.0936 4.33587 15.7561 4.33587H6.50036ZM6.50036 8.20488C6.16287 8.20488 5.88903 8.47771 5.88903 8.8152C5.88903 9.15269 6.16287 9.42552 6.49935 9.42552H15.7561C16.0936 9.42552 16.3674 9.15269 16.3674 8.8152C16.3674 8.47771 16.0936 8.20488 15.7561 8.20488H6.50036ZM6.50036 12.0729C6.16287 12.0729 5.88903 12.3457 5.88903 12.6832C5.88903 13.0207 6.16287 13.2945 6.49935 13.2945H15.7561C16.0936 13.2945 16.3674 13.0207 16.3674 12.6832C16.3674 12.3457 16.0936 12.0729 15.7561 12.0729H6.50036ZM4.61182 1.22064L17.6447 1.22165C17.9337 1.22569 18.1671 1.46113 18.1681 1.75113V15.8792C18.1671 16.1692 17.9337 16.4046 17.6447 16.4087L4.61182 16.4097C4.32283 16.4047 4.08943 16.1692 4.08842 15.8792V1.75113C4.08943 1.46113 4.32284 1.22571 4.61182 1.22064ZM4.61485 0C3.64885 0.00303116 2.86677 0.786141 2.86677 1.75312V15.8773C2.86677 16.8433 3.64885 17.6274 4.61485 17.6305H17.6415C18.6075 17.6274 19.3896 16.8433 19.3896 15.8773V1.75312C19.3896 0.786116 18.6075 0.00302651 17.6415 0H4.61485ZM0.61133 2.36952H0.610319C0.272825 2.36952 0 2.64336 0 2.97984V18.2469C0 19.2129 0.78208 19.997 1.74808 20H15.9126C16.2501 20 16.524 19.7262 16.524 19.3897C16.524 19.0522 16.2501 18.7784 15.9126 18.7784L1.74601 18.7794C1.45601 18.7743 1.2226 18.5389 1.2216 18.2489V2.98002C1.2216 2.64354 0.947815 2.36952 0.61133 2.36952Z"
              fill="#5C5C5C"
            />
          </svg>
        </button>
      </div>
    </div>
  );

  const companyProvider = (
    <div className="flex flex-col gap-2 text-body">
      <span className="font-bold">Company Provider</span>
      <span className="pl-4">{capitalize(customer.provider_id)}</span>
    </div>
  );

  const allowedDomains = (
    <LinkLookup
      data-testid="customer-allowed-domains-button"
      routeName="customer-allowed-domains"
      className="hover:bg-litlingo-gray-0.5 focus:outline-none"
    >
      <div className="p-4 flex flex-col gap-1 border-t border-litlingo-gray-2">
        <div className="flex flex-row items-center justify-between text-body">
          <p>Allowed domains</p>
          <div className="transform rotate-180">{NEW_CHEVRON_LEFT}</div>
        </div>
        <p className="text-small">
          Users with a Gmail account at these domains will be allowed to sign in
        </p>
      </div>
    </LinkLookup>
  );

  const dashboards = (
    <LinkLookup
      data-testid="customer-dashboards-button"
      routeName="customer-dashboards"
      className="hover:bg-litlingo-gray-0.5 focus:outline-none"
    >
      <div className="p-4 flex flex-col gap-1 border-t border-litlingo-gray-2">
        <div className="flex flex-row items-center justify-between text-body">
          <p>Dashboards</p>
          <div className="transform rotate-180">{NEW_CHEVRON_LEFT}</div>
        </div>
      </div>
    </LinkLookup>
  );

  const exploreConfigurations = (
    <LinkLookup
      data-testid="customer-dashboards-button"
      routeName="customer-explore-configuration"
      className="hover:bg-litlingo-gray-0.5 focus:outline-none"
    >
      <div className="p-4 flex flex-col gap-1 border-t border-litlingo-gray-2">
        <div className="flex flex-row items-center justify-between text-body">
          <p>Explore Configurations</p>
          <div className="transform rotate-180">{NEW_CHEVRON_LEFT}</div>
        </div>
      </div>
    </LinkLookup>
  );

  return (
    customer && (
      <div className="w-full flex">
        <LoadingOverlay
          data-testid="loading-overlay"
          active={loading}
          spinner={<LoadingIndicator size="20" />}
          fadeSpeed={0}
          className="relative flex flex-col gap-4 w-full"
          styles={{
            content: (base) => ({
              ...base,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }),
            wrapper: () => ({}),
            spinner: () => ({}),
            overlay: (
              base: Record<string, unknown>
            ): { [key: string]: number; zIndex: number } => ({
              ...base,
              zIndex: 10,
            }),
          }}
        >
          <p className="text-menu-item">General</p>
          {isEditing ? (
            <CustomerEditGeneral toggleEdit={toggleEditing} />
          ) : (
            <div className="flex flex-col bg-white border border-litlingo-gray-2 rounded">
              <div className="px-4 pt-6 pb-4 flex flex-col gap-4">
                {companyName}
                {domain}
                {companyUUID}
                {companyProvider}
              </div>
              <Permissions action="customers.advancedOptions">
                <div className="flex flex-col">
                  {allowedDomains}
                  {dashboards}
                  {exploreConfigurations}
                </div>
              </Permissions>
            </div>
          )}
        </LoadingOverlay>
      </div>
    )
  );
};

export default CustomerGeneral;
