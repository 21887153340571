import LoadingIndicator from 'components/LoadingIndicator';
import GlobalNavbar from 'components/Navbar/GlobalNavbar';
import TestResultsVisualizer from 'components/TestResultsVisualizer';
import TestSentenceVisualizerV2 from 'components/TestSentenceVisualizerV2';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTestSentenceLoading } from 'selectors/communications';

const GlobalTestPage: React.FC = () => {
  const loading = useSelector(getTestSentenceLoading);

  return (
    <div className="min-h-screen min-w-screen bg-white">
      <GlobalNavbar />
      <div className="py-10 min-h-wo-navbar">
        <header>
          <div className="max-w-7xl mx-auto px-1 sm:px-12 lg:px-14">
            <div className="mt-2 md:flex md:items-center md:justify-between">
              <div className="flex-1 min-w-0">
                <div className="flex flex-row items-center">
                  <h2 className="title">Test Sentence Visualizer</h2>
                  {loading && <LoadingIndicator className="ml-2" size="5" />}
                </div>
              </div>
            </div>
          </div>
        </header>
        <main>
          <div className="mt-5 mb-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="mt-6 sm:mt-5 sm:border-t sm:border-gray-200 sm:pt-5 text-left">
              <div>
                <TestSentenceVisualizerV2 />
                <TestResultsVisualizer
                  annotationBasePath="/global/identifier-revisions"
                  v2Version
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default GlobalTestPage;
