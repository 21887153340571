import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SettingSidebar from 'components/Navbar/SettingsSidebar';
import React from 'react';
import { useSelector } from 'react-redux';
import { getPrompt, getSinglePromptLoading } from 'selectors/entities';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';

const Prompt: React.FC = (): JSX.Element => {
  const prompt = useSelector(getPrompt);

  const loading = useSelector(getSinglePromptLoading);

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  if (loading) {
    return <LoadingIndicator className="m-auto mt-20" size="20" />;
  }

  return (
    <div
      className="flex flex-col bg-white min-w-screen"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />
      <div
        className="flex flex-row"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <SettingSidebar />
        <div className=" mt-10 px-8 flex flex-col items-start gap-4 w-full">
          <label htmlFor="kind" className="flex flex-col gap-1">
            <div className="flex flex-row gap-1">
              <span className="font-bold">Kind</span>
            </div>
            <div>
              <span>{prompt?.kind}</span>
            </div>
          </label>

          <label htmlFor="deployment" className="flex flex-col gap-1">
            <div className="flex flex-row gap-1">
              <span className="font-bold">Deployment</span>
            </div>
            <div>
              <span>{prompt?.deployment}</span>
            </div>
          </label>
          <label htmlFor="source" className="flex flex-col gap-1">
            <div className="flex flex-row gap-1">
              <span className="font-bold">Source</span>
            </div>
            <div>
              <span>{prompt?.source}</span>
            </div>
          </label>
          <label htmlFor="content" className="flex flex-col gap-1">
            <div className="flex flex-row gap-1">
              <span className="font-bold">Content</span>
            </div>
            <div>
              <span>{prompt?.content}</span>
            </div>
          </label>
          <div className="w-full border-bottom" />
        </div>
      </div>
    </div>
  );
};

export default Prompt;
