import React from 'react';

type ComponentProps = {
  isOn: boolean;
  handleToggleClick:
    | (() => void)
    | ((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void);
  disabled?: boolean;
  dataTestid?: string;
};

const ToggleButton: React.FC<ComponentProps> = (props) => {
  const { isOn, handleToggleClick, disabled, dataTestid } = props;

  return (
    <button
      data-testid={dataTestid || 'id'}
      type="button"
      role="checkbox"
      tabIndex={0}
      aria-checked="false"
      onClick={handleToggleClick}
      className={`${isOn ? 'bg-litlingo-success' : 'bg-litlingo-gray-2'}
      ${disabled ? 'pointer-events-none' : ''}
      ${disabled ? 'bg-litlingo-gray-2' : ''}
      h-6 w-12 relative inline-flex flex-shrink-0 items-center gap-1 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none`}
    >
      <div
        className={`${
          isOn ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'
        } absolute text-small left-1 h-full flex items-center justify-center transition-opacity`}
      >
        <span className={`${!disabled ? 'text-white' : ''}`}>On</span>
      </div>

      <span
        aria-hidden="true"
        className={`${
          isOn ? 'translate-x-6' : 'translate-x-0'
        } relative inline-block h-5 w-5 rounded-full bg-litlingo-gray-0.5 z-10 shadow transform transition ease-in-out duration-200`}
      >
        {/* <!-- On: "opacity-100 ease-in duration-200", Off: "opacity-0 ease-out duration-100" --> */}
      </span>

      <div
        className={`${
          isOn ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'
        } absolute text-small right-1 h-full flex items-center justify-center transition-opacity`}
      >
        Off
      </div>
    </button>
  );
};

export default ToggleButton;
