/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useSelector } from 'react-redux';

import CollapsableMainSection from 'components/CollapsableCard/CollapsableMainSection';
import { startCase } from 'lodash';
import moment from 'moment';
import { getCustomerDomain } from 'selectors/auth';
import { getSelectedReviewSet } from 'selectors/savedSearches';
import { SavedSearchAuditLog } from 'types';
import { useHistory } from 'utils/urls';

const ChangelogSection: React.FC = () => {
  const reviewSet = useSelector(getSelectedReviewSet);
  const history = useHistory();
  const customerDomain = useSelector(getCustomerDomain);

  const handleClickExlporeButton = (url: string): void => {
    history.pushLookup({
      customerDomain,
      routeName: 'envelope-list',
      queryParams: url,
    });
  };

  const renderValue = (value: string): JSX.Element => {
    if (value.includes('filters_search')) {
      return (
        <li
          className=" self-start underline text-litlingo-primary-120 focus:outline-none break-all cursor-pointer "
          onClick={(): void => handleClickExlporeButton(value)}
        >
          {value}
        </li>
      );
    }
    return <li>{startCase(value)}</li>;
  };

  if (!reviewSet) return null;

  return (
    <CollapsableMainSection headerTitle="Change Log" containerClasses="h-full" isDefaultClosed>
      <div className="flex flex-col">
        {reviewSet.audit_logs
          // @ts-ignore
          ?.toSorted((l1: SavedSearchAuditLog, l2: SavedSearchAuditLog) =>
            moment(l1.created_at).isBefore(moment(l2.created_at)) ? 1 : -1
          )
          .map((log: SavedSearchAuditLog) => (
            <div key={log.uuid} className="px-8 py-4 flex flex-col border-b border-litlingo-gray-1">
              <div className="flex flex-row justify-between">
                <span>{startCase(log.created_by?.name || '')}</span>
                <span>{moment(log.created_at).format('MM/DD/YYYY')}</span>
              </div>
              <ul className="pl-2 list-disc list-inside">{renderValue(log.value ?? '')}</ul>
            </div>
          ))}
      </div>
    </CollapsableMainSection>
  );
};

export default ChangelogSection;
