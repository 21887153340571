import { fetchSingleCategory } from 'actions';
import { fetchIdentifierRevision, fetchSingleIdentifier } from 'actions/identifier';
import { fetchSingleModel } from 'actions/models';
import { fetchAllRuleGroupsWithSummary, fetchSingleRuleGroup } from 'actions/ruleGroup';
import CategoriesList from 'components/CategoriesList';
import Category from 'components/Category';
import Global from 'components/Global';
import Identifier from 'components/Identifier';
import IdentifierList from 'components/IdentifierList';
import IdentifierName from 'components/IdentifierName';
import IdentifierType from 'components/IdentifierType';
import Model from 'components/Model';
import ModelsList from 'components/ModelsList';
import ModelManager from 'components/RuleGroupManager';
import RuleGroupsList from 'components/RuleGroupsList';
import GlobalTestPage from 'components/TestPage/GlobalTestPage';
import TestResultsVisualizerPage from 'components/TestResultsVisualizer/TestResultsVisualizerPage';
import { RouteSpec, SuperRouteSpec } from 'types';

const globalRoutes: (RouteSpec | SuperRouteSpec)[] = [
  {
    name: 'global',
    path: '/global',
    component: Global,
    exact: true,
  },
  {
    name: 'global-rule-groups-list',
    path: '/global/rule-group',
    component: RuleGroupsList,
    private: true,
    exact: true,
    permissions: 'rules.list',
    data: {
      actions: [fetchAllRuleGroupsWithSummary],
      params: {
        order_by: {
          defaultValue: 'created_at',
        },
        relationships: {
          defaultValue: ['updated_by'],
          list: true,
        },
        order_desc: {
          defaultValue: 'true',
        },
        include_pii: {
          defaultValue: 'true',
        },
        include_count: {
          defaultValue: '1',
        },
        rule_groups: {
          nestedResource: true,
          params: {
            relationships: {
              defaultValue: ['updated_by', 'created_by  '],
              list: true,
            },
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            include_pii: {
              defaultValue: 'true',
            },
            name: {},
            order_by: {
              defaultValue: 'created_at',
              options: ['created_at', 'name', 'created_by_uuid', 'updated_at'],
            },
            order_desc: {
              defaultValue: 'true',
            },
          },
        },
      },
    },
  },
  {
    name: 'global-rule-group-manager',
    path: '/global/rule-group/:ruleId',
    component: ModelManager,
    exact: true,
    private: true,
    permissions: 'rules.retrieve',
    data: {
      idFromPath: true,
      actions: [fetchSingleRuleGroup],
      params: {
        body: {},
        order_by: {
          defaultValue: 'created_at',
        },
        include_count: {
          defaultValue: '1',
        },
        order_desc: {
          defaultValue: 'true',
        },
        highlighted_rule: {},
        identifiers: {
          nestedResource: true,
          params: {
            broad_search: {},
          },
        },
        test_cases: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            broad_search: {},
            order_desc: {
              defaultValue: 'true',
            },
            order_by: {
              defaultValue: 'created_at',
              options: [
                'created_at',
                'created_by_uuid',
                'test_string',
                'should_match',
                'most_recent_run_passed',
              ],
            },
            match_criterias: {
              list: true,
            },
          },
        },
        audit_logs: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '10',
            },
            offset: {
              defaultValue: '0',
            },
            relationships: {
              defaultValue: ['updated_by'],
            },
            include_count: {
              defaultValue: '1',
            },
            order_by: {
              defaultValue: 'updated_at',
            },
            order_desc: {
              defaultValue: 'true',
            },
          },
        },
      },
    },
  },
  {
    name: 'global-identifiers-list',
    path: '/global/identifiers',
    component: IdentifierList,
    private: true,
    permissions: 'annotators.list',
    data: {
      params: {
        include_count: {
          defaultValue: '1',
        },
        identifiers: {
          nestedResource: true,
          params: {
            order_by: {
              defaultValue: 'updated_at',
              options: ['created_at', 'type', 'name'],
            },
            order_desc: {
              defaultValue: 'true',
              options: ['true', 'false'],
            },
            offset: {
              defaultValue: '0',
            },
            limit: {
              defaultValue: '25',
            },
            name: {},
            broad_search: {},
            groups: {
              list: true,
            },
          },
        },
      },
    },
  },
  {
    name: 'global-identifier-revision-manager',
    path: '/global/identifier-revisions/:identifierRevisionId',
    component: Identifier,
    private: true,
    permissions: 'annotators.retrieve',
    data: {
      idFromPath: true,
      actions: [fetchIdentifierRevision],
      params: {
        body: {},
        test_cases: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            broad_search: {},
            order_desc: {
              defaultValue: 'true',
            },
            order_by: {
              defaultValue: 'created_at',
              options: [
                'created_at',
                'created_by_uuid',
                'test_string',
                'should_match',
                'most_recent_run_passed',
              ],
            },
            match_criterias: {
              list: true,
            },
          },
        },
        audit_logs: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '10',
            },
            offset: {
              defaultValue: '0',
            },
            relationships: {
              defaultValue: ['updated_by'],
            },
            include_count: {
              defaultValue: '1',
            },
            order_by: {
              defaultValue: 'updated_at',
            },
            order_desc: {
              defaultValue: 'true',
            },
          },
        },
      },
    },
  },
  {
    name: 'global-identifier-manager',
    path: '/global/identifier/:identifierId',
    component: Identifier,
    private: true,
    permissions: 'annotators.retrieve',
    data: {
      idFromPath: true,
      actions: [fetchSingleIdentifier],
      params: {
        body: {},
        include_count: {
          defaultValue: '1',
        },
        test_cases: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            broad_search: {},
            order_desc: {
              defaultValue: 'true',
            },
            order_by: {
              defaultValue: 'created_at',
              options: [
                'created_at',
                'created_by_uuid',
                'test_string',
                'should_match',
                'most_recent_run_passed',
              ],
            },
            match_criterias: {
              list: true,
            },
          },
        },
        audit_logs: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '10',
            },
            offset: {
              defaultValue: '0',
            },
            relationships: {
              defaultValue: ['updated_by'],
            },
            include_count: {
              defaultValue: '1',
            },
            order_by: {
              defaultValue: 'updated_at',
            },
            order_desc: {
              defaultValue: 'true',
            },
          },
        },
      },
    },
  },
  {
    name: 'global-identifier-list-new-type',
    path: '/global/identifier-revision/list/new/:type',
    component: IdentifierName,
    private: true,
    permissions: 'annotators.create',
  },
  {
    name: 'global-identifier-choose-type',
    path: '/global/identifier-revision/choose-type',
    component: IdentifierType,
    private: true,
    permissions: 'annotators.create',
  },
  {
    name: 'global-models-list',
    path: '/global/models',
    component: ModelsList,
    private: true,
    permissions: 'models.list',
    data: {
      params: {
        include_count: {
          defaultValue: '1',
        },
        models: {
          nestedResource: true,
          params: {
            order_by: {
              defaultValue: 'updated_at',
              options: ['created_at', 'type', 'name'],
            },
            order_desc: {
              defaultValue: 'true',
              options: ['true', 'false'],
            },
            offset: {
              defaultValue: '0',
            },
            limit: {
              defaultValue: '25',
            },
            name: {},
            broad_search: {},
            kind: {
              defaultValue: 'testing',
            },
            fuzzy_name_search: {
              defaultValue: 'true',
            },
          },
        },
      },
    },
  },

  {
    name: 'global-model-manager',
    path: '/global/model/:modelId',
    component: Model,
    private: true,
    permissions: 'campaigns.retrieve',
    data: {
      idFromPath: true,
      actions: [fetchSingleModel],
      params: {
        body_visualizer: {},
        include_count: {
          defaultValue: '1',
        },
        test_cases: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            broad_search: {},
            order_desc: {
              defaultValue: 'true',
            },
            order_by: {
              defaultValue: 'created_at',
              options: [
                'created_at',
                'created_by_uuid',
                'test_string',
                'should_match',
                'most_recent_run_passed',
              ],
            },
            match_criterias: {
              list: true,
            },
          },
        },
        audit_logs: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '10',
            },
            offset: {
              defaultValue: '0',
            },
            relationships: {
              defaultValue: ['updated_by'],
            },
            include_count: {
              defaultValue: '1',
            },
            order_by: {
              defaultValue: 'updated_at',
            },
            order_desc: {
              defaultValue: 'true',
            },
          },
        },
      },
    },
  },
  {
    name: 'global-categories-list',
    path: '/global/categories',
    component: CategoriesList,
    private: true,
    permissions: 'categories.list',
    data: {
      params: {
        include_count: {
          defaultValue: '1',
        },
        categories: {
          nestedResource: true,
          params: {
            order_by: {
              defaultValue: 'updated_at',
              options: ['created_at', 'type', 'name'],
            },
            order_desc: {
              defaultValue: 'true',
              options: ['true', 'false'],
            },
            offset: {
              defaultValue: '0',
            },
            limit: {
              defaultValue: '25',
            },
            name: {},
            broad_search: {},
          },
        },
      },
    },
  },
  {
    name: 'global-category-manager',
    path: '/global/category/:categoryId',
    component: Category,
    private: true,
    permissions: 'categories.retrieve',
    data: {
      idFromPath: true,
      actions: [fetchSingleCategory],
      params: {
        body: {},
        include_count: {
          defaultValue: '1',
        },
        test_cases: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '25',
            },
            offset: {
              defaultValue: '0',
            },
            broad_search: {},
            order_desc: {
              defaultValue: 'true',
            },
            order_by: {
              defaultValue: 'created_at',
              options: [
                'created_at',
                'created_by_uuid',
                'test_string',
                'should_match',
                'most_recent_run_passed',
              ],
            },
            match_criterias: {
              list: true,
            },
          },
        },
        audit_logs: {
          nestedResource: true,
          params: {
            limit: {
              defaultValue: '10',
            },
            offset: {
              defaultValue: '0',
            },
            relationships: {
              defaultValue: ['updated_by'],
            },
            include_count: {
              defaultValue: '1',
            },
            order_by: {
              defaultValue: 'updated_at',
            },
            order_desc: {
              defaultValue: 'true',
            },
          },
        },
      },
    },
  },
  {
    name: 'global-test-viz',
    path: '/global/test-viz',
    component: GlobalTestPage,
    private: true,
    data: {
      params: {
        body_visualizer: {},
      },
    },
  },
  {
    name: 'global-test-viz-result',
    path: '/global/test-viz-result',
    component: TestResultsVisualizerPage,
    private: true,
  },
];
export default globalRoutes;
