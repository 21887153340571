import LoadingIndicator from 'components/LoadingIndicator';
import Navbar from 'components/Navbar';
import SearchInput from 'components/SearchInput';
import { resourceQueryParamName } from 'constants/resourceQueryNames';

import SettingSidebar from 'components/Navbar/SettingsSidebar';
import React from 'react';
import { useSelector } from 'react-redux';
import { getActiveTasksLoading } from 'selectors/tasks';
import { NAV_BAR_HEIGHT_REM } from 'utils/dimensions';
import EntityTable from './EntityTable';

const EntityList: React.FC = () => {
  const loading = useSelector(getActiveTasksLoading);

  const warningBarOffset =
    document != null &&
    document.getElementById != null &&
    document.getElementById('customer-warning')?.offsetHeight;

  return (
    <div
      className="flex flex-col min-w-screen bg-white overflow-hidden"
      style={{
        height: `calc(100vh - ${warningBarOffset}px)`,
      }}
    >
      <Navbar />
      <div
        className="flex flex-row"
        style={{
          height: `calc(100vh - ${warningBarOffset}px - ${NAV_BAR_HEIGHT_REM}rem)`,
        }}
      >
        <SettingSidebar />
        <div className="pb-6 flex flex-col gap-2 h-full w-full overflow-hidden">
          <div className="flex flex-col gap-2 pt-6 px-10">
            <div className="flex flex-row items-center">
              <h2 className="text-heading-2">Entities</h2>
              {loading && <LoadingIndicator className="ml-2" size="5" />}
            </div>

            <SearchInput
              resource={resourceQueryParamName.entities}
              field="name"
              logEventMessage="entity-search"
              className="h-8 max-w-60"
              placeholder="Search entities"
            />
          </div>

          <EntityTable />
        </div>
      </div>
    </div>
  );
};

export default EntityList;
