/* eslint-disable max-lines */
import { UUID } from '@litlingo/client';
import classNames from 'classnames';
import React from 'react';

type ComponentProps = {
  selectedEntities: UUID[];
  toggleModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const EntityListActionRow: React.FC<ComponentProps> = ({ selectedEntities, toggleModalOpen }) => {
  const disableButtons = selectedEntities.length !== 2;

  return (
    <tr className="bg-litlingo-gray-2">
      <th
        colSpan={6}
        className="table-wrapper__new-td table-wrapper__bulk-area bg-litlingo-gray-2 "
      >
        <div className="table-wrapper__new-cell-content flex flex-row justify-between items-start gap-3 py-2 m-0 h-auto overflow-visible">
          <div className="flex flex-row justify-between items-start gap-2 ml-6 h-auto overflow-visible">
            <button
              type="button"
              disabled={disableButtons}
              onClick={(): void => toggleModalOpen(true)}
              className={classNames('button button--secondary h-8 ', {
                'disabled-button': disableButtons,
              })}
            >
              <span className="text-litlingo-gray-600 font-bold">Merge</span>
            </button>
          </div>

          {/* <div className="flex flex-row justify-end h-8">
            <div className="text-body whitespace-no-wrap self-end mx-2">{`${annotatorCount.toLocaleString()} selected`}</div>
            <button
              type="button"
              className={classNames('button button--secondary flex justify-center w-32 h-8 ml-2', {
                'disabled-button': disableDeleteButton,
              })}
              data-testid="bulk-delete-button"
              onClick={handleDeleteAnnotator}
            >
              <span className="text-litlingo-red-bg font-bold">Delete</span>
            </button>
          </div> */}
        </div>
      </th>
    </tr>
  );
};

export const MemoizedEntityListActionsRow = React.memo(EntityListActionRow);

export default EntityListActionRow;
