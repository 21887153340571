import { fetchSingleCustomer, saveCustomer } from 'actions';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getCustomer, getUser } from 'selectors/auth';
import { useSelector } from 'store';

type ComponentProps = {
  toggleEdit: () => void;
};

const CustomerEditGeneral: React.FC<ComponentProps> = ({ toggleEdit }) => {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const customer = useSelector(getCustomer);

  const { register, getValues, handleSubmit, formState } = useForm({
    defaultValues: { name: customer.name, domain: customer.domain },
  });

  useEffect(() => {
    if (user) {
      dispatch(fetchSingleCustomer());
    }
  }, [dispatch, user]);

  const onSubmit = (): void => {
    const { name, domain } = getValues();
    dispatch(saveCustomer({ name, domain }));
    toggleEdit();
  };

  const handleSave = (): void => {
    handleSubmit(onSubmit)();
  };

  const { dirtyFields } = formState;

  return (
    <div className="p-4 flex flex-col gap-5 bg-white border border-litlingo-gray-2 rounded">
      <div className="flex flex-row gap-2 justify-end">
        <button
          type="button"
          onClick={toggleEdit}
          className="button button--secondary flex justify-center w-20 h-8"
        >
          <span className="font-bold">Cancel</span>
        </button>
        <button
          type="button"
          onClick={handleSave}
          className="button button--primary flex justify-center w-20 h-8"
          disabled={dirtyFields.size === 0}
        >
          <span>Save</span>
        </button>
      </div>
      <div className="flex flex-col gap-4 pb-20">
        <div className="flex flex-col gap-2 text-body">
          <span className="font-bold">Company Name</span>
          <input
            className="form-textarea text-heading-2 h-9"
            id="name"
            name="name"
            ref={register({ required: true })}
          />
        </div>
        <div className="flex flex-col gap-2 text-body">
          <span className="font-bold">URL Slug</span>
          <div className="pl-4 flex flex-row gap-1 items-center">
            <span>litlingo.com/</span>
            <input
              className="form-textarea text-body h-9 w-full"
              id="domain"
              name="domain"
              ref={register({ required: true })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerEditGeneral;
